import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import SmoothCollapse from 'react-smooth-collapse'

class MiniResponse extends Component{
  render(){
    let { message, type } = this.props 

    if(!type){
      type = 'error'
    }

    let className = 'mini-error '+type
    let iconName

    if(message){
      className += ' visible'
    }

    switch(type){
      case 'error':
        iconName = 'exclamation-triangle'
        break
      case 'success':
        iconName = 'check'
        break
    }

    return (
      <SmoothCollapse expanded={message ? true : false}>
        <div className={className}>
          <FontAwesome name={iconName} />
          { message }
        </div>
      </SmoothCollapse>
    )
  }
}

export default MiniResponse
