import gql from 'graphql-tag'

export const UserFragment = gql`
    fragment UserFragment on User {
        id
        firstName
        lastName
        email
    }
`

export const AddressFragment = gql`
    fragment AddressFragment on Address {
        id
        level
        building
        unitNumber
        address
        offPlan
        streetNumber
        streetName
        suburb
        state
        country
        postcode
    }
`

export const AddressFragmentTwo = gql`
    fragment AddressFragmentTwo on Address {
        id
        level
        building
        unitNumber
        address
        offPlan
        streetNumber
        streetName
        suburb
        state
        country
        postcode
    }
`

export const AddressFragmentThree = gql`
    fragment AddressFragmentThree on Address {
        id
        level
        building
        unitNumber
        address
        offPlan
        streetNumber
        streetName
        suburb
        state
        country
        postcode
    }
`

export const AddressFragmentFour = gql`
    fragment AddressFragmentFour on Address {
        id
        level
        building
        unitNumber
        address
        offPlan
        streetNumber
        streetName
        suburb
        state
        country
        postcode
    }
`

export const EventFragment = gql`
    fragment EventFragment on Event {
        id
        createdAt
        updatedAt
        deletedAt
        title
        description
        type
        date
        isSystem
        addedBy {
            id
            firstName
            lastName
        }
        liability {
            id
            startDate
            loan {
                id
                name
            }
        }
        isRepeating
        repeatNumber
        repeatUnit
        files {
            id
            createdAt
            updatedAt
            deletedAt
            filename
            url
        }
        note {
            id
            text
        }
        company {
            id
            name
        }
        person {
            id
            firstName
            lastName
            notifyOfBirthday
        }
        isDismissed
        repeatingDateDismissed
        modifiedValuesJson
        modifiedValuesObject
    }
`

// because fuck apollo.
export const EventFragmentTwo = gql`
    fragment EventFragmentTwo on Event {
        id
        createdAt
        updatedAt
        deletedAt
        title
        description
        type
        date
        isSystem
        addedBy {
            id
            firstName
            lastName
        }
        liability {
            id
            startDate
            loan {
                id
                name
            }
        }
        isRepeating
        repeatNumber
        repeatUnit
        files {
            id
            createdAt
            updatedAt
            deletedAt
            filename
            url
        }
        note {
            id
            text
        }
        company {
            id
        }
        person {
            id
            firstName
            lastName
            notifyOfBirthday
        }
        isDismissed
        repeatingDateDismissed
        modifiedValuesJson
        modifiedValuesObject
    }
`

export const EventFragmentThree = gql`
    fragment EventFragmentThree on Event {
        id
        createdAt
        updatedAt
        deletedAt
        title
        description
        type
        date
        isSystem
        addedBy {
            id
            firstName
            lastName
        }
        liability {
            id
            startDate
            loan {
                id
                name
            }
        }
        isRepeating
        repeatNumber
        repeatUnit
        files {
            id
            createdAt
            updatedAt
            deletedAt
            filename
            url
        }
        note {
            id
            text
        }
        company {
            id
        }
        person {
            firstName
            lastName
            notifyOfBirthday
        }
        isDismissed
        repeatingDateDismissed
        modifiedValuesJson
        modifiedValuesObject
    }
`


export const EventFragmentFour = gql`
    fragment EventFragmentFour on Event {
        id
        createdAt
        updatedAt
        deletedAt
        title
        description
        type
        date
        isSystem
        addedBy {
            id
            firstName
            lastName
        }
        liability {
            id
            startDate
            loan {
                id
                name
            }
        }
        isRepeating
        repeatNumber
        repeatUnit
        files {
            id
            createdAt
            updatedAt
            deletedAt
            filename
            url
        }
        note {
            id
            text
        }
        person {
            id
            firstName
            lastName
            notifyOfBirthday
        }
        company {
            id
            name
        }
        isDismissed
        repeatingDateDismissed
        modifiedValuesJson
        modifiedValuesObject
    }
`

export const LiabilityFragment = gql`
    fragment LiabilityFragment on Liability {
        id
        createdAt
        updatedAt
        deletedAt
        name
        loanTerm
        interestOnlyTerm
        startDate
        financePurpose
        amount
        initialBalance
        introRateYears
        introRatePercent
        repaymentAmount
        repaymentFrequency
        discountPercent
        bankAccountName
        bankAccountBsb
        bankAccountNumber
        offsetAccountBsb
        offsetAccountNumber
        settlementRate
        assets {
            id
            deletedAt
            name
            value
            address {
                ...AddressFragmentThree
            }
        }
        people {
            id
            percent
            deletedAt
            person {
                id
                firstName
                lastName
                deletedAt
            }
        }
        companies {
            id
            percent
            deletedAt
            company {
                id
                name
                deletedAt
            }
        }
        loan {
            id
            name
            interestRatesOOPI {
                id
                createdAt
                rate
            },
            interestRatesOOIO {
                id
                createdAt
                rate
            },
            interestRatesIVPI {
                id
                createdAt
                rate
            },
            interestRatesIVIO {
                id
                createdAt
                rate
            },
            lender {
                id
                name
            }
        }
        events {
            ...EventFragment
        }
        notes {
            id
            createdAt
            updatedAt
            deletedAt
            text
            author {
                id
                firstName
                lastName
            }
            editedBy {
                id
                firstName
                lastName
            }
        }
        fixedRatePeriods {
            id
            createdAt
            updatedAt
            deletedAt
            startDate
            term
            customRate
        }
    }
    ${EventFragment}
    ${AddressFragmentThree}
`

export const AssetFragment = gql`
    fragment AssetFragment on Asset {
        id
        createdAt
        updatedAt
        deletedAt
        name
        address {
            ...AddressFragmentTwo
        }
        addressOffPlan
        propertyType
        value
        valueIsCertified
        valuationDate
        isInvestment
        rentalIncomeValue
        rentalIncomeFrequency
        rentalHasAgent
        rentalAgentContact
        isUnencumbered
        zoningType
        liabilities {
            id
            deletedAt
            name
        }
        people {
            id
            percent
            person {
                id
                firstName
                lastName
            }
        }
        companies {
            id
            percent
            company {
                id
                name
            }
        }
    }
    ${AddressFragmentTwo}
`

export const CompanyFragment = gql`
    fragment CompanyFragment on Company {
        id
        createdAt
        updatedAt
        deletedAt
        name
        tradingName
        type
        abn
        acn
        registrationDate
        phoneWork
        website
        email
        address {
            ...AddressFragmentFour
        }
        people {
            id
            firstName
            lastName
        }
        industry
        isContactExistingPerson
        contact {
            id
            firstName
            lastName
            email
        }
        externalContactName
        externalContactPhone
        externalContactEmail
        referrer {
            id
            firstName
            lastName
            email
        }
        actingOnTrust
        trustName
        notes {
            id
            createdAt
            updatedAt
            deletedAt
            text
            author {
                id
                firstName
                lastName
            }
            editedBy {
                id
                firstName
                lastName
            }
        }
        broker {
            id
            firstName
            lastName
        }
    }
    ${AddressFragmentFour}
`

export const PersonFragment = gql`
    fragment PersonFragment on Person {
        id
        createdAt
        updatedAt
        title
        firstName
        middleName
        lastName
        preferredName
        address {
            ...AddressFragment
        }
        dateOfBirth
        notifyOfBirthday
        gender
        maritalStatus
        email
        phonePreference
        phoneWork
        phoneMobile
        actingOnTrust
        trustName
        spouse {
            id
            firstName
            lastName
            email
        }
        companies {
            id
            name
        }
        dependants {
            id
            name
            yearOfBirth
        }
        events {
            ...EventFragmentTwo
        }
        notes {
            id
            createdAt
            updatedAt
            deletedAt
            text
            author {
                id
                firstName
                lastName
            }
            editedBy {
                id
                firstName
                lastName
            }
        }
        broker {
            id
            firstName
            lastName
        }
    }
    ${EventFragmentTwo}
    ${AddressFragment}
`
