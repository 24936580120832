import React, { Component } from 'react'
import { Switch, Route, Redirect, NavLink, withRouter } from "react-router-dom"

class TabSection extends Component {
  convertLabelToPath = (label) => {
    let newPath = this.props.match.url+"/"+(label.toLowerCase())
    
    return newPath
  }

  render(){
    return (
      <div className="tab-section">
        <ul className="tabs">
          {
            this.props.tabs.map((tab, i) => {
              return (
                <li key={i}>
                  <NavLink className="h3" to={this.convertLabelToPath(tab.label)}>
                    { tab.label }
                  </NavLink>
                </li>
              )
            })
          }
        </ul>
        <div className="content-box">
          <Switch>
            { this.props.tabs.map((tab, i) => {
              return (
                  <Route key={i} path={this.convertLabelToPath(tab.label)} render={(routeProps) => {
                    const Component = tab.component;

                    return (
                      <Component data={tab.data} />
                    )
                  }} />
              )
            })}
            <Redirect from={"/"} to={this.convertLabelToPath(this.props.tabs[0].label)} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(TabSection)
