import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import xzcvbn from 'zxcvbn'

class PasswordInfo extends Component {
    constraints = []
    state = {
        password: '',
        score: 0
    }

    componentWillMount = () => {
        this.processConstraints()
    }

    componentDidUpdate = () => {
        if(this.props.password == this.state.password){
            return
        }

        this.processConstraints()
    }

    processConstraints = () => {
        let password = this.props.password ? this.props.password : ''

        let score = (xzcvbn(password).score * 25)

        this.constraints = [
            {
                label: '8 or more characters',
                isValid: password.length >= 8
            },
            {
                label: 'At least 1 number',
                isValid: /\d/.test(password)
            },
            {
                label: 'Upper and lowercase characters',
                isValid: password.match(/[a-z]/) && password.match(/[A-Z]/)
            }
        ]

        let isValid = true

        this.constraints.forEach(constraint => {
            if(!constraint.isValid){
                isValid = false
            }
        })

        this.props.setValidity(isValid)

        this.setState({
            password,
            score
        })
        
    }

    render(){
        let className = 'password-info'

        if(this.props.isVisible){
            className += ' visible'
        }

        return(
            <div className={className}>
                <span>
                    Strength:
                </span>
                <div className="strength-indicator">
                    <div style={{width: this.state.score+'%' }}></div>
                </div>
                <ul>
                    {
                        this.constraints.map((constraint, index) => {
                            return (
                                <li key={index}>
                                    <FontAwesome name={constraint.isValid ? "check" : "times"} />
                                    <span>
                                        { constraint.label }
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

export default PasswordInfo