import React, { Component } from 'react'
import Select from 'react-select'
import ValidationError from 'components/Errors/ValidationError'
import { isEqual } from 'lodash'

class SelectInput extends Component {
  state = {
    isOpen: false 
  }

  componentDidMount(){
      setTimeout(() => {
          if(!this.props.value){
            if(this.props.values.length && !this.props.noDefault){
              this.props.changeCallback(this.props.values[0].value);
            }
          }
      },2)
  }

  componentDidUpdate = (prevProps) => {
    let currentValue = null 

    if(this.props.value){
      currentValue = this.props.value.id ? this.props.value.id : this.props.value
    }

    if(!isEqual(this.props.values, prevProps.values)){
      let isValueInNewSet = false 

      this.props.values.forEach(value => {
        if(value.value === currentValue){
          isValueInNewSet = true
        }
      })

      if((!isValueInNewSet || !currentValue) && this.props.values && this.props.values.length && !this.props.noDefault){
        this.props.changeCallback(this.props.values[0].value);
      }
    }
  }

  onChange = value => {
    this.props.changeCallback(value.value);
  }

  toggleActiveState = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  
  render(){
    const {
      value,
      values,
      label,
      disabled,
      error
    } = this.props

    let className = 'select-container '
    let wrapperName = 'input-wrap '

    if(error){
      wrapperName += 'error'
    }

    if(this.state.isOpen){
      wrapperName += ' active'
    }

    let selectedValue = null

    if(value){
      // sometimes it'll be an object with an ID, rather than a string.
      selectedValue = values.find(loopedValue => {
        let comparisonValue = value.id ? value.id : value

        if(value.value){
          comparisonValue = value.value
        }

        return loopedValue.value === comparisonValue
      })
    }

    return (
      <div className={wrapperName}>
        <label>
          { label ? label : '\u00A0' }
        </label>
        <div className={className}>
            <Select 
              menuContainerStyle={{ zIndex: 5 }} 
              onMenuOpen={this.toggleActiveState} 
              onMenuClose={this.toggleActiveState} 
              isDisabled={disabled ? true : false} 
              onChange={this.onChange} 
              value={selectedValue} 
              options={values} 
            />
        </div>
        <ValidationError error={error} />
      </div>
    )
  }
}

export default SelectInput
