import XLSX from 'xlsx'

const s2ab = s => {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i=0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

const download = (url, name) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}

export const exportToExcel = (data, title, criteriaString) => {
    const exportData = [...data]

    const columnLength = data[0].length

    const queryRow = [criteriaString]

    for (let i = 0; i < (columnLength - 1); i++) {
        queryRow.push('')
    }

    exportData.unshift(queryRow)

    const maxCharactersByColumn = []

    const workSheet = XLSX.utils.aoa_to_sheet(exportData)

    data.forEach(row => {
        row.forEach((column, index) => {
            const thisLength = column.length

            if(!maxCharactersByColumn[index] || maxCharactersByColumn[index].wch < thisLength){
                maxCharactersByColumn[index] = {
                    wch: thisLength   
                }
            }
        })
    })

    workSheet['!cols'] = maxCharactersByColumn

    workSheet['!merges'] = [{
        s: {
            c: 0,
            r: 0
        },
        e: {
            c: (columnLength - 1),
            r: 0
        }
    }]

    const workBook = {
        SheetNames: ['1'],
        Sheets: []
    }

    workBook.Sheets['1'] = workSheet

    const wbout = XLSX.write(workBook, {bookType:'xlsx', bookSST:true, type: 'binary'})

    let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], {type:'application/octet-stream'}))

    download(url, title+'.xlsx')
}