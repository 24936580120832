import React, { Component } from 'react'

import Button from 'components/Button/Button'

import { graphql, compose } from 'react-apollo'

import { CLOSE_MODAL } from 'graphql/modal-graphql'

import { 
    UPSERT_EVENT,
    INIT_DATA,
    TOGGLE_DISMISS_EVENT
} from 'graphql/all'

import { 
    stripTypenamesFromMutations, 
    getTitleFromEvent,
    dismissableEvent,
    numberWithCommas,
    capitaliseFirstLetter
} from 'utils/utils'

import ModalForm from '../ModalComponents/ModalForm'
import ValidationError from 'components/Errors/ValidationError'
import * as yup from 'yup'

import moment from 'moment'

import { cloneDeep, forIn } from 'lodash'

class ModalEvent extends Component {
    state = {
        fields: {
            id: null,
            title: '',
            description: '',
            date: null,
            isRepeating: false,
            repeatNumber: null,
            repeatUnit: null,
            files: [],
            existingFiles: [],
            noteText: null,
            modifiedValues: null,
        },
        hasErrors: false,
        errors: {},
        isEditing: true,
        shouldToggleDismiss: false,
        showLiabilitySelector: false
    }

    object = null

    componentWillUnmount = async () => {
        const {
            id,
            date,
            isDismissed
        } = this.object

        if(!id){
            return
        }

        if(isDismissed != this.state.isDismissed){
            try {
                await this.props.toggleDismissEvent({
                    variables: {
                        payload: {
                            id,
                            date
                        },
                    },
                })
            } catch (error) {
                console.log(error)
                this.props.setLoading(false);
            }
        }
    }

    formatValue = (value, key, isFormattingKey) => {
        let response = value

        if(isFormattingKey){
            switch(key){
                case 'amount':
                    response = 'Amount'
                    break
                case 'name':
                    response = 'Name'
                    break
                case 'loanTerm':
                    response = 'Loan term'
                    break
				case 'discountPercent':
                    response = 'Discount'
                    break
				case 'financePurpose':
                    response = 'Finance purpose'
                    break
				case 'startDate':
                    response = 'Start date'
                    break
                case 'interestOnlyTerm':
                    response = 'Interest-only term'
                    break
                case 'initialBalance': 
                    response = 'Initial balance'
                    break
                case 'introRateYears': 
                    response = 'Introductory rate (years)'
                    break
                case 'introRatePercent': 
                    response = 'Introductory rate (percent)'
                    break
                case 'repaymentAmount': 
                    response = 'Repayment amount'
                    break
                case 'repaymentFrequency': 
                    response = 'Repayment frequency'
                    break
            }
        }else{
            if(!value){
                return '(None)'
            }


            switch(key){
                case 'amount':
                case 'repaymentAmount': 
                case 'initialBalance': 
                    response = '$'+numberWithCommas(response)
                    break
				case 'startDate':
                    response = moment(response).format('Do MMMM Y')
                    break
                case 'financePurpose':
                case 'repaymentFrequency': 
                    response = capitaliseFirstLetter(response)
                    break
                case 'loanTerm':
                case 'introRateYears': 
                case 'interestOnlyTerm':
                    response = response+' years'
                    break
                case 'discountPercent':
                case 'introRatePercent': 
                    response = response+'%'
                    break
            }
        }

        return response
    }

    componentWillMount() {
        const { 
            object,
            initData
        } = this.props

        if (object) {
            this.object = object

            let newState = {
                fields: {
                    ...this.state.fields,
                    ...object,
                    files: [],
                    existingFiles: object.files ? object.files : [],
                    noteText: object.note ? object.note.text : null,
                    modifiedValues: object.type === 'LIABILITY_MODIFIED' ? (
                        <table className="modifications-list">
                            <thead>
                                <tr>
                                    <td>
                                        Property
                                    </td>
                                    <td>
                                        Old value
                                    </td>
                                    <td>
                                        New value
                                    </td>
                                </tr>
                            </thead>
                            { object.modifiedValuesObject ?
                                Object.keys(object.modifiedValuesObject).map(key => {
                                    const values = object.modifiedValuesObject[key]

                                    return (
                                        <tr key={key}>
                                            <td>
                                                { this.formatValue(null, key, true) }
                                            </td>
                                            <td>
                                                { this.formatValue(values.old, key) }
                                            </td>
                                            <td>
                                                { this.formatValue(values.new, key) }
                                            </td>
                                        </tr>
                                    )
                                })
                            : 
                                JSON.parse(object.modifiedValuesJson).map(value => {
                                    let valueName

                                    switch (value) {
                                        case 'loanTerm':
                                            valueName = 'Loan term'
                                            break
                                        case 'discountPercent':
                                            valueName = 'Interest rate discount'
                                            break
                                        case 'financePurpose':
                                            valueName = 'Finance purpose'
                                            break
                                        case 'amount':
                                            valueName = 'Amount'
                                            break
                                        case 'startDate':
                                            valueName = 'Start date'
                                            break
                                    }

                                    return (
                                        <tr key={value}>
                                            <td>
                                                { valueName }
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    ) : null
                },
                isEditing: this.props.object.id ? false : true,
                isDismissed: this.props.object.isDismissed ? true : false,
                blockEditTitle: false,
                showLiabilitySelector: !this.props.object.liabilityId ? true : false
            }

            if (object.type && object.type !== 'CUSTOM') {
                let title = getTitleFromEvent(object)
                newState.blockEditTitle = true
                newState.fields.title = title
            }

            let clientLiabilities = []
            let parent = null

            if(object.personId){
                parent = initData.user.people.find(person => {
                    return person.id === object.personId
                })
            }else if(object.companyId){
                parent = initData.user.companies.find(company => {
                    return company.id === object.companyId
                })
            }

            if(parent){
                parent.liabilities.forEach(liabilityRelation => {
                    if(liabilityRelation.personRelation){
                        clientLiabilities.push(liabilityRelation.personRelation)
                    }else if(liabilityRelation.companyRelation){
                        clientLiabilities.push(liabilityRelation.companyRelation)
                    }
                })
            }

            newState.liabilities = clientLiabilities

            this.setState(newState)
        }
    }

    handleChange = (key, value) => {
        if (key !== 'files') {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [key]: value
                }
            })
        } else {
            this.setState({
                fields: {
                    ...this.state.fields,
                    files: value.files,
                    existingFiles: value.existingFiles
                }
            })
        }
    }

    handleError = error => {
        const errors = error.inner

        let newErrorState = {}

        errors.forEach(error => {
            newErrorState[error.path] = error.message
        })

        this.setState({
            hasErrors: true,
            errors: newErrorState
        })
    }

    removeNonPayloadData = fields => {
        let returnedFields = cloneDeep(fields)

        delete returnedFields.repeatingDateDismissed
        delete returnedFields.modifiedValuesJson

        return returnedFields
    }

    handleSubmit = async () => {
        const validationSchema = yup.object().shape({
            title: yup.string().required('Please enter a title.'),
            date: yup.string().required('Please enter a date.').typeError(`You'll need to choose a date.`),
        })

        try {
            await validationSchema.validate({ ...this.state.fields }, { abortEarly: false })
        } catch (error) {
            return this.handleError(error)
        }

        this.props.setLoading(true);

        this.setState({
            hasErrors: false
        })

        const fields = stripTypenamesFromMutations(this.removeNonPayloadData(this.state.fields))

        let existingFileIDs = []
        fields.existingFiles.forEach(file => {
            existingFileIDs.push({
                id: file.id
            })
        })

        fields.existingFiles = existingFileIDs

        delete fields.isSystem
        delete fields.addedBy
        delete fields.liability
        delete fields.modifiedValues
        delete fields.modifiedValuesJson
        delete fields.modifiedValuesObject

        // these might be unnecessary?
        if (fields.person) {
            fields.personId = fields.person.id
        }

        if (fields.company) {
            fields.companyId = fields.company.id
        }

        let note = null

        if (fields.noteText) {
            note = {
                id: fields.note.id,
                text: fields.noteText
            }
        }

        delete fields.note
        delete fields.noteText
        delete fields.person
        delete fields.company
        delete fields.people
        delete fields.loanName
        delete fields.assets
        delete fields.companies

        try {
            await this.props.upsertEvent({
                variables: {
                    payload: {
                        ...fields,
                        note
                    },
                },
                update: (store, { data }) => {
                    console.log('success!')
                    console.log(data)
                },
            }).then(() => {
                this.props.handleSuccess()
            })
        } catch (error) {
            console.log(error)
            this.props.setLoading(false)
        }
    }

    editEvent = () => {
        this.setState({
            isEditing: true
        })
    }

    dismissEvent = async () => {
        const {
            date,
            isRepeating
        } = this.props.object

        this.setState({
            isDismissed: !this.state.isDismissed,
            repeatingDateDismissed: isRepeating ? date : null
        })

        if(!this.state.isDismissed) {
            this.props.closeModal()
        }
        
    }

    getLiabilities = _ => {
        const liabilities = [{
            label: '(Select person)',
            value: undefined
        }]

        if(this.state.liabilities){
            this.state.liabilities.forEach(liability => {
                liabilities.push({
                    label: liability.name,
                    value: liability.id
                })
            })
        }

        return liabilities
    }

    render() {
        const {
            fields,
            blockEditTitle,
            isEditing,
            errors,
            hasErrors,
            isDismissed,
            showLiabilitySelector
        } = this.state

        const editableEvent = fields.type !== 'LIABILITY_MODIFIED'
        const minorEditableEvent = (fields.type && fields.type != 'CUSTOM')

        this.fieldColumns = [
            [
                [{
                    label: 'event name',
                    required: true,
                    type: 'text',
                    props: {
                        type: 'text',
                        disableEditable: blockEditTitle
                    },
                    key: 'title'
                }], [
                    {
                        label: 'Date of event',
                        required: true,
                        hideWhenEditing: minorEditableEvent || !editableEvent,
                        type: 'text',
                        props: {
                            type: 'date',
                            allowFuture: true
                        },
                        key: 'date'
                    }, editableEvent ? {
                        label: 'Repeat',
                        type: 'event-repeat',
                        hideWhenEditing: minorEditableEvent || !editableEvent,
                        hideIfEmpty: true,
                        hasMany: true,
                        props: {
                            keys: {
                                isRepeating: fields.isRepeating,
                                repeatNumber: fields.repeatNumber,
                                repeatUnit: fields.repeatUnit
                            }
                        }
                    } : {}
                ], [
                    editableEvent ? {
                        label: 'Notes',
                        type: 'textarea',
                        hideIfEmpty: true,
                        key: fields.noteText ? 'noteText' : 'description'
                    } : {
                            label: 'Modifications',
                            type: 'textarea',
                            key: 'modifiedValues',
                            hideWhenEditing: true,
                        }
                ], [{
                    label: 'Files',
                    type: 'files',
                    key: 'files',
                    hideIfEmpty: true,
                    props: {
                        keys: {
                            files: fields.files,
                            existingFiles: fields.existingFiles
                        }
                    }
                }]
            ]
        ]

        if(showLiabilitySelector && isEditing && !minorEditableEvent){
            this.fieldColumns[0].splice(1, 0, [{
                label: 'Liability',
                type: 'select',
                hideWhenEditing: minorEditableEvent || !editableEvent,
                props: {
                    values: this.getLiabilities()
                },
                key: 'liabilityId'
            }])
        }

        return (
            <div className="event">
                <div className="content-container">
                    <ModalForm 
                        disabled={!isEditing} 
                        errors={errors} 
                        handleChange={this.handleChange} 
                        fieldState={fields} 
                        columns={this.fieldColumns}
                    />
                </div>
                <div className="button-row">
                    <ValidationError 
                        error={hasErrors ? "There were errors in your submission." : null} 
                    />
                    <span>
                        {
                            isEditing ?
                                <Button 
                                    label={fields.id ? "Save event" : "Add event"} 
                                    level="high" 
                                    callback={this.handleSubmit} 
                                />
                                :
                                <Button 
                                    label="Edit event" 
                                    level="x-low" 
                                    callback={this.editEvent} 
                                />
                        }
                    </span>
                    {
                        (!isEditing && dismissableEvent(fields)) ?
                            <Button 
                                label={!isDismissed ? 'Dismiss' : 'Un-Dismiss'} 
                                level="high" 
                                callback={this.dismissEvent} 
                            />
                            :
                            null
                    }
                    <Button 
                        label={editableEvent ? "Cancel" : "Close"} 
                        level="low" 
                        callback={this.props.closeCallback} 
                    />
                </div>
            </div>
        )
    }
}

export default compose(
    graphql(UPSERT_EVENT, { name: 'upsertEvent' }),
    graphql(TOGGLE_DISMISS_EVENT, { name: 'toggleDismissEvent' }),
    graphql(INIT_DATA, {name: 'initData'}),
    graphql(CLOSE_MODAL, { name: 'closeModal' }),
)(ModalEvent)