import React, { Component } from 'react'
import moment from 'moment'
import { formatAddress } from 'utils/utils'

class QuickDetails extends Component {
    render(){
        let listData = []

        const { person, company } = this.props

        if(person){
            if(person.email){
                listData.push({
                    icon: 'message',
                    value: person.email
                })
            }

            if(person.phonePreference == 'Work'){
                if(person.phoneWork){
                    listData.push({
                        icon: 'phone',
                        value: person.phoneWork
                    })
                }else if(person.phoneMobile){
                    listData.push({
                        icon: 'phone',
                        value: person.phoneMobile
                    })
                }
            }else{
                if(person.phoneMobile){
                    listData.push({
                        icon: 'phone',
                        value: person.phoneMobile
                    })
                }else if(person.phoneWork){
                    listData.push({
                        icon: 'phone',
                        value: person.phoneWork
                    })
                }
            }

            if(person.dateOfBirth){
                listData.push({
                    icon: 'birthday',
                    value: moment(person.dateOfBirth).format('Do MMMM Y')
                })
            }

        }else{
            if(company.address && formatAddress(company.address)){
                listData.push({
                    icon: 'address',
                    value: formatAddress(company.address)
                })
            }

            if(company.email){
                listData.push({
                    icon: 'message',
                    value: company.email
                })
            }

            if(company.phoneWork){
                listData.push({
                    icon: 'phone',
                    value: company.phoneWork
                })
            }
        }

        return (
            <div className="content-box">
                <h3>
                    Quick details
                </h3>
                <ul className="widget-list">
                    {listData.map((item, index) => {
                        return (
                            <li key={index}>
                                <img src={`/images/icons/${item.icon}.png`} />
                                <span>           
                                    {item.value ? item.value : null}
                                </span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default QuickDetails