import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'

import { cloneDeep } from 'lodash'

class FileInput extends Component {
  componentDidMount(){
      console.log(this.props)
  }

    handleChange = value => {
        this.props.changeCallback({
            files: Array.from(value.files),
            existingFiles: this.props.keys.existingFiles
        })
    }

  removeFile = index => {
      let thisFiles = cloneDeep(this.props.keys.files)

      thisFiles.splice(index, 1)

      this.props.changeCallback({
          files: thisFiles,
          existingFiles: this.props.keys.existingFiles
      })
  }

  removeExistingFile = id => {
      let thisExistingFiles = cloneDeep(this.props.keys.existingFiles).filter(existingFile => {
          return existingFile.id !== id
      })

      this.props.changeCallback({
          files: this.props.keys.files,
          existingFiles: thisExistingFiles
      })
  }

  render(){
    const { 
        label, 
        error, 
        required, 
        keys, 
        isDisabled
    } = this.props

    let className = 'input-container files'

    if(isDisabled){
        className += ' disabled'
    }

    let files = keys.files
    let existingFiles = keys.existingFiles

    return (
      <div className={error ? "input-wrap error" : "input-wrap"}>
        <label>
          { label ? label : '\u00A0' }
          {required ?
            <em className="required">
              *
            </em>
            : null}
        </label>
        <div className={className}>
            <ul>
                {
                    existingFiles.map((file, index) => {
                        return (
                            <li className="file" key={index}>
                                <a href={ file.url } target="_blank">
                                    <img src="/images/icons/file.png" />
                                    <span>
                                        { file.filename }
                                    </span>
                                </a>
                                <button onClick={() => {this.removeExistingFile(file.id)}}>
                                    <img src="/images/icons/bin.png" />
                                </button>
                            </li>
                        )
                    })
                }
                {
                    files.map((file, index) => {
                        return (
                            <li className="file" key={index}>
                                <a href="javascript:void(0)">
                                    <img src="/images/icons/file.png" />
                                    <span>
                                        { file.name }
                                    </span>
                                </a>
                                <button onClick={() => {this.removeFile(index)}}>
                                    <img src="/images/icons/bin.png" />
                                </button>
                            </li>
                        )
                    })
                }
                {
                    !isDisabled ?
                    <li className="add">
                        <input type="file" multiple onChange={({target}) => {this.handleChange(target)}} />
                        <button className="add">
                            <img src="/images/icons/add-mini.png" />
                            <span>
                                Add new file...
                            </span>
                        </button>
                    </li>
                    : null
                }
                { (isDisabled && !files.length && !existingFiles.length) ? 
                    <span>
                        (No files)
                    </span>
                : null }
            </ul>
        </div>
        <ValidationError error={error} />
      </div>
    )
  }
}

export default FileInput
