import React, { Component } from 'react'

import { Link, Redirect } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'

class JoinBrokerage extends Component {
	state = {
		error: false,
		alertType: false,
		errors: {},
	}
	
	componentWillMount(){
		console.log(this.props)
	}

    handleSubmit = _ => {
        console.log('submitting!')

		try {
			this.props.callback(this.props.authorisedBrokerage, this.props.path, (response) => {
				if (response.length) {
					this.handleError(response[0].message)
				} else {
					this.handleError('Uh oh! A server error has occurred.', 'error')
				}
			}, () => {
				this.handleError('Details have been updated successfully.', 'success')
			});

		} catch (error) {
			console.log(error);
		}
    }
    
    render(){
        const {
            error,
            alertType
        } = this.state
        
        const {
			isLoading,
			authorisedBrokerage
		} = this.props
		
		if(!authorisedBrokerage){
			return <Redirect to="/auth" />
		}

        return (
			<div className="join-account-form" onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
				<MiniResponse type={alertType} message={error} />
				<Loader isLoading={isLoading} />
                <p>
                    <strong>{ authorisedBrokerage.name }</strong> has authorised you to join their brokerage.
                </p>
				<div className="button-row">
					<button className="button high" onClick={this.handleSubmit}>
						Join brokerage
					</button>
				</div>
				{!this.props.isEdit ?
					<div className="lower-row">
						<Link to="/auth/new-brokerage">
							&lsaquo; No, create a new brokerage.
						</Link>
					</div>
					: null
				}
			</div>
        )
    }
}

export default JoinBrokerage