import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'

import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'

import { cloneDeep } from 'lodash'

class EventRepeatInput extends Component {
  componentDidMount(){
    
  }

  handleChange = (value, key) => {
    this.props.changeCallback(value, key)
  }

  render(){
    let className = 'event-repeat-container'

    const { label, error, required, keys, placeholder } = this.props

    let repeatString

    if(keys.isRepeating){
        repeatString = 'Every '+keys.repeatNumber

        switch(keys.repeatUnit){
            case 'WEEKLY':
                repeatString += ' weeks'
                break
            case 'MONTHLY':
                repeatString += ' months'
                break
            case 'QUARTERLY':
                repeatString += ' quarters'
                break
            case 'YEARLY':
                repeatString += ' years'
                break
        }
    }else{
        repeatString = 'Not repeating'
    }

    return (
      <div className={error ? "input-wrap error" : "input-wrap"}>
        <label>
          { label ? label : '\u00A0' }
          {required ?
            <em className="required">
              *
            </em>
            : null}
        </label>
        {
            !this.props.isDisabled ?
            <div className={className}>
                <input checked={keys.isRepeating} type="checkbox" onChange={() => {this.handleChange(keys.isRepeating ? false : true, 'isRepeating')}} />
                <span>
                    Every
                </span>
                <TextInput value={keys.repeatNumber} changeCallback={value => {this.handleChange(value, 'repeatNumber')}} />
                <SelectInput value={keys.repeatUnit} changeCallback={value => {this.handleChange(value, 'repeatUnit')}} values={[
                    {
                        value: 'WEEKLY',
                        label: 'week(s)'
                    },{
                        value: 'MONTHLY',
                        label: 'month(s)'
                    },{
                        value: 'QUARTERLY',
                        label: 'quarter(s)'
                    },{
                        value: 'YEARLY',
                        label: 'year(s)'
                    }
                ]} /> 
            </div>
            :
            <span className="disabled-input">
                {
                    keys.isRepeating ?
                    <span>
                        { repeatString }
                    </span>
                    :
                    <span>
                        Not repeating
                    </span>
                }
            </span>
        }
        <ValidationError error={error} />
      </div>
    )
  }
}

export default EventRepeatInput
