import React, { Component } from 'react'

import { graphql, compose } from 'react-apollo'

import { INIT_DATA, UPDATE_BROKERAGE_MUTATION } from 'graphql/all'

import * as yup from 'yup'

import RegisterBrokerage from 'components/Auth/RegisterBrokerage/RegisterBrokerage'

class ModalBrokerage extends Component {
    componentWillMount() {
        //this.currentUser = this.props.initData.user
    }

    handleSubmit = (data, errorCallback, successCallback) => {
        this.props.setLoading(true)

        console.log(data)

        this.props.updateBrokerage({
            variables: {
                name: data.name,
                id: data.id,
                authorisedDomain: data.authorisedDomain,
                logo: data.imageFile ? data.imageFile : null
            }
        }).then(response => {
            console.log(response)
            this.props.setLoading(false)
            successCallback()
        }).catch(error => {
            console.log(error)
            console.log(error.graphQLErrors)

            if (error.graphQLErrors) {
                errorCallback(error.graphQLErrors)
            }

            this.props.setLoading(false)
        })

    }

    render() {
        return (
            <div className="modal-brokerage">
                <RegisterBrokerage callback={(data, path, errorCallback, successCallback) => { this.handleSubmit(data, errorCallback, successCallback) }} isEdit={true} data={this.props.object} />
            </div>
        )
    }
}

export default compose(
    graphql(INIT_DATA, { name: 'initData' }),
    graphql(UPDATE_BROKERAGE_MUTATION, { name: 'updateBrokerage' })
)(ModalBrokerage)