import gql from 'graphql-tag'

export const GET_MODAL_DATA = gql`
    query GET_MODAL_DATA {
        currentModal @client {
            operation,
            dataType,
            isVisible,
            object
        }
    }
` 

export const OPEN_MODAL = gql`
    mutation OPEN_MODAL (
        $operation: String!, 
        $dataType: String!, 
        $isVisible: Bool!,
        $object: Object
    ){
        openModal(
            operation: $operation, 
            dataType: $dataType,
            isVisible: $isVisible,
            object: $object
        ) @client
    }
`

export const CLOSE_MODAL = gql`
    mutation CLOSE_MODAL (
        $isVisible: Bool!
        $object: Object
    ){
        closeModal(
            isVisible: $isVisible,
            object: Object
        ) @client
    }
`