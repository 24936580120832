import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'

class Checkbox extends Component {
	constructor(props){
		super(props);
	}

	render() {
		return (
			<span className="checkbox">
				<input onChange={this.props.onChange} checked={this.props.isChecked} type="checkbox" />
				<em>
					<FontAwesome name="check" />
				</em>
			</span>
		);
	}
}

export default Checkbox;
