import React, { Component } from 'react'
import Button from 'components/Button/Button'
import { graphql, compose } from 'react-apollo'

import { getLiabilityName, getAssetName } from 'utils/utils'

import { DELETE_PERSON, DELETE_ASSET, DELETE_LIABILITY, DELETE_NOTE, DELETE_EVENT, DELETE_USER, DELETE_INVITATION, DELETE_COMPANY } from 'graphql/delete-graphql'
import { INIT_DATA } from 'graphql/user-graphql'

class ModalDelete extends Component {
    handleClick = () => {
        this.props.setLoading(true);

        const { object } = this.props

        // for bulk deletes
        let idArray = []

        try {
            switch (this.props.dataType) {
                case 'person':
                    object.forEach(person => {
                        idArray.push(person.id)
                    })

                    this.props.deletePerson({
                        variables: {
                            ids: idArray
                        },
                        update: (store, { data }) => {

                            const initDataQuery = store.readQuery({ query: INIT_DATA })
                            const { deletePerson } = data

                            deletePerson.forEach(deletedPerson => {
                                const personIndex = initDataQuery.user.people.findIndex(person => {
                                    return person.id === deletedPerson.id
                                })

                                if (personIndex !== -1) {
                                    initDataQuery.user.people.splice(personIndex, 1)
                                }
                            })

                            store.writeQuery({ query: INIT_DATA, data: initDataQuery })
                        }
                    }).then(response => {
                        this.successCallback()
                    })
                    break
                case 'company':
                    object.forEach(company => {
                        idArray.push(company.id)
                    })

                    this.props.deleteCompany({
                        variables: {
                            ids: idArray,
                        },
                        update: (store, { data }) => {

                            const initDataQuery = store.readQuery({ query: INIT_DATA })
                            const { deleteCompany } = data

                            deleteCompany.forEach(deletedCompany => {
                                const companyIndex = initDataQuery.user.companies.findIndex(company => {
                                    return company.id === deletedCompany.id
                                })

                                if (companyIndex !== -1) {
                                    initDataQuery.user.companies.splice(companyIndex, 1)
                                }
                            })

                            store.writeQuery({ query: INIT_DATA, data: initDataQuery })
                        }
                    }).then(response => {
                        this.successCallback()
                    })
                    break
                case 'liability':
                    object.forEach(liability => {
                        idArray.push(liability.id)
                    })

                    this.props.deleteLiability({
                        variables: {
                            ids: idArray,
                        },
                        update: (store, { data }) => {
                            console.log(data)
                        }
                    }).then(response => {
                        this.successCallback()
                    })
                    break
                case 'asset':
                    object.forEach(asset => {
                        idArray.push(asset.id)
                    })

                    this.props.deleteAsset({
                        variables: {
                            ids: idArray
                        }
                    }).then(response => {
                        this.successCallback()
                    })
                    break
                case 'note':
                    this.props.deleteNote({
                        variables: {
                            id: object.id
                        }
                    }).then(response => {
                        console.log(response)
                        this.successCallback()
                    })
                    break
                case 'event':
                    this.props.deleteEvent({
                        variables: {
                            id: object.id
                        }
                    }).then(response => {
                        console.log(response)
                        this.successCallback()
                    })
                    break
                case 'invitation':
                    this.props.deleteInvitation({
                        variables: {
                            id: object.id
                        },
                        refetchQueries: ['brokerData']
                    }).then(response => {
                        console.log(response)
                        this.successCallback()
                    })
                    break
                case 'user':
                    this.props.deleteUser({
                        variables: {
                            id: object.id
                        },
                        refetchQueries: ['brokerData']
                    }).then(response => {
                        console.log(response)
                        this.successCallback()
                    })
                    break
            }
        } catch (error) {
            this.props.setLoading(false);
            console.log(error);
        }
    }

    successCallback = () => {
        this.props.setLoading(false)
        this.props.closeCallback()
    }

    render() {
        const {
            object,
            closeCallback,
            dataType
        } = this.props

        let string = ''
        let liabilitiesToDelete = []
        let assetsToDelete = []

        if (object) {
            // Sometimes it's an array, sometimes not
            let normalisedObjectArray = Array.isArray(object) ? object : [object]

            let objectName = ''

            if(normalisedObjectArray[0].firstName){
                objectName = `${normalisedObjectArray[0].firstName} ${normalisedObjectArray[0].lastName}`
            }else if(normalisedObjectArray[0].name){
                objectName = normalisedObjectArray[0].name
            }else if(dataType == 'event'){
                objectName = 'this event'
            }

            string =  `Are you sure you want to delete ${objectName}? This action can't be undone.`

            normalisedObjectArray.forEach(deletedObject => {
                if(deletedObject.liabilities){
                    deletedObject.liabilities.forEach(liability => {
                        if(liability.percent == 100 && (liability.personRelation || liability.companyRelation)){
                            liabilitiesToDelete.push(liability.personRelation ? liability.personRelation : liability.companyRelation)
                        }
                    })
                }
    
                if(deletedObject.assets){
                    deletedObject.assets.forEach(asset => {
                        if(asset.percent == 100 && (asset.personRelation || asset.companyRelation)){
                            assetsToDelete.push(asset.personRelation ? asset.personRelation : asset.companyRelation)
                        }
                    })
                }
            })
        }

        const showWarning = (assetsToDelete.length || liabilitiesToDelete.length)

        return (
            <div className="modal-delete">
                <div className="content-container">
                    <p>
                        {string != '' ? string : null}
                    </p>
                    { showWarning ?
                        <div className="warning">
                            { assetsToDelete.length ? 
                                <div>
                                    <span>
                                        The following assets will also be deleted:
                                    </span>
                                    <ul>
                                        { assetsToDelete.map(asset => {
                                            return (
                                                <li key={asset.id}>
                                                    { getAssetName(asset) }
                                                </li>
                                            )
                                        }) }
                                    </ul>
                                </div>
                            : null }
                            { liabilitiesToDelete.length ?
                                <div>
                                    <span>
                                        The following liabilities will also be deleted:
                                    </span>
                                    <ul>
                                        { liabilitiesToDelete.map(liability => {
                                            return (
                                                <li key={liability.id}>
                                                    { getLiabilityName(liability) }
                                                </li>
                                            )
                                        }) }
                                    </ul>
                                </div>
                            : null}
                        </div>
                    : null }
                </div>
                <div className="button-row">
                    <Button label="Delete" level="high" callback={this.handleClick} />
                    <Button label="Cancel" level="low" callback={closeCallback} />
                </div>
            </div>
        )
    }
}

export default compose(
    graphql(DELETE_PERSON, { name: 'deletePerson' }),
    graphql(DELETE_COMPANY, { name: 'deleteCompany' }),
    graphql(DELETE_ASSET, { name: 'deleteAsset' }),
    graphql(DELETE_LIABILITY, { name: 'deleteLiability' }),
    graphql(DELETE_NOTE, { name: 'deleteNote' }),
    graphql(DELETE_EVENT, { name: 'deleteEvent' }),
    graphql(DELETE_USER, { name: 'deleteUser' }),
    graphql(DELETE_INVITATION, { name: 'deleteInvitation' }),
)(ModalDelete)

