import React, { Component } from 'react'
import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'

import DocumentTitle from 'react-document-title'

import ClientsSwitch from '../Clients/ClientsSwitch'

import Dashboard from '../Dashboard/Dashboard'
import Reporting from '../Reporting/Reporting'
import Loans from '../Loans/Loans'
import Lenders from '../Lenders/Lenders'
import Staff from '../Staff/Staff'

import Loader from 'components/Loader/Loader'

import { AUTH_TOKEN } from '../../constants'
import { graphql, Query } from 'react-apollo'
import { withApollo } from 'react-apollo'

import SwitchCSSTransitionGroup from 'switch-css-transition-group'
import { Route, Redirect } from "react-router-dom"

import { 
  INIT_DATA, 
  SET_REDIRECT_PATH,
  OPEN_MODAL
} from 'graphql/all'

import { isAdmin } from 'utils/utils'

class Main extends Component {

  state = {
    isShrunk: false
  }

  componentWillMount(){
    const authToken = localStorage.getItem(AUTH_TOKEN)

    if(!authToken){
      this.props.client.mutate({
          mutation: SET_REDIRECT_PATH,
          variables: {
              path: this.props.location.pathname
          }
      }).then(data => {
        this.props.history.push('/auth')
      })
    }
  }

  componentWillUpdate = nextProps => {
    if(!this.props.data.user && nextProps.data.user){
      const user = nextProps.data.user

      if(!user.hasOnboarded){
        setTimeout(() => {
          this.props.client.mutate({
              mutation: OPEN_MODAL,
              variables: {
                  operation: 'onboard',
                  dataType: 'broker',
                  isVisible: true,
                  object: null
              }
          })
        },300)
      }
    }
  }

  shrinkSidebar = () => {
    this.setState({
        isShrunk: !this.state.isShrunk
    })
  }

  render(){
    return (
      <Query query={INIT_DATA} notifyOnNetworkStatusChange={true}>
        {({data, loading, error, fetchMore}) => {          
          if(error){
            console.log(error)
            console.log(error.graphQLErrors)
            this.props.history.push('/auth')

            return <div>Error</div>
          }

          if(loading){
            return <Loader showText={true} isLoading={true} />
          }

          const { user } = data

          if(!user.brokerage){
            return (
              <Redirect to="/auth" />
            )
          }

          this.routes = [
            {
              path: "/dashboard",
              label: "Dashboard",
              component: Dashboard
            },{
              path: "/clients",
              label: "Clients",
              component: ClientsSwitch
            },{
              path: "/reporting",
              label: "Reporting",
              component: Reporting
            }
          ]

          if(isAdmin(this.props.client)){
            if(user.brokerage.isMasterAccount){
              this.routes.splice(2, 0, {
                path: "/loans",
                label: "Loans",
                component: Loans
              })
            }

            this.routes.splice(3, 0, {
              path: "/lenders",
              label: "Lenders",
              component: Lenders
            })

            this.routes.push({
              path: '/staff',
              label: "Staff",
              component: Staff
            })
          }

          return (
            <div className="main-container">
              <div className="load-cover"></div>
              <Header user={user} history={this.props.history} />
              <Sidebar shrinkSidebar={this.shrinkSidebar} routes={this.routes} isShrunk={this.state.isShrunk}/>
              <div className={this.state.isShrunk ? 'page-content shrunk' : 'page-content'}>
                <SwitchCSSTransitionGroup location={this.props.location} transitionName="slide" transitionEnterTimeout={300} transitionLeaveTimeout={300}>
                  { this.routes.map((route, i) => {
                    return (
                        <Route key={i} path={route.path} render={(routeProps) => {
                          const Component = route.component;

                          return (
                            <DocumentTitle title={route.label+' - Zamm'}>
                              <Component {...routeProps} data={user} />
                            </DocumentTitle>
                          )
                        }} />
                    )
                  })}
                  <Redirect from={"/"} exact to={this.routes[0].path} />
                </SwitchCSSTransitionGroup>
              </div>
            </div>
          )
        }}
    </Query>
    )
  }
}

export default withApollo(graphql(INIT_DATA)(Main))
