import { forIn, forInRight, cloneDeep } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import moment from 'moment'

import { INIT_DATA } from 'graphql/user-graphql'

export const getCurrentUser = client => {
    const { user } = client.readQuery({query: INIT_DATA})

    return user
}

export const isAdmin = client => {
    const { user } = client.readQuery({query: INIT_DATA})

    let isAdmin = false

    user.brokerage.admins.forEach(admin => {
        if(admin.id === user.id){
            isAdmin = true
        }
    })

    return isAdmin
}

export const isUserAdmin = (client, otherUser) => {
    if(otherUser.__typename == 'User'){
        const { user } = client.readQuery({query: INIT_DATA})

        let isAdmin = false
    
        user.brokerage.admins.forEach(admin => {
            if(admin.id === otherUser.id){
                isAdmin = true
            }
        })
    
        return isAdmin
    }else if(otherUser.__typename == 'Invitation'){
        return otherUser.isAdmin
    }
}

export const isUserCurrentUser = (client, otherUser) => {
    const { user } = client.readQuery({query: INIT_DATA})

    return user.id === otherUser.id
}

export const stripTypenamesFromMutations = data => {
    const clonedData = cloneDeep(data)

    const keysToRemove = [
        'createdAt',
        'updatedAt',
        'deletedAt',
        '__typename'
    ]

    function removeMeta(obj) {
        forIn(obj, (prop, key) => {
            if(keysToRemove.indexOf(key) !== -1){
                delete obj[key]
            }else if(typeof(obj[key]) === 'object'){
                removeMeta(obj[key])
            }
        })
    }

    removeMeta(clonedData)

    return clonedData;
}

export const removeSoftDeletedItems = data => {
    const clonedData = cloneDeep(data)

    function eachRecursive(obj, parent, parentKey){
        for (var k in obj){
            if (typeof obj[k] == "object" && obj[k] !== null){
                forInRight(obj, (prop, key) => {
                    if(prop && prop.deletedAt){
                        if(Array.isArray(obj)){
                            obj.splice(key,1)
                        }else{
                            delete obj.key
                        }
                    }
                })

                eachRecursive(obj[k], obj, k);
            }
        }
    }

    eachRecursive(clonedData, null)

    return clonedData
}

export const capitaliseFirstLetter = data => {
    if(data){
        return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
    }

    return null
}

export const getEmailDomain = email => {
    if(email){
        return email.replace(/.*@/, "")
    }else{
        return null
    }
}

export const getClientDisplayName = (client, firstOnly) => {
    if(!client){
        return ''
    }
    
    if(client.name){
        return client.name
    }
    
    let nameString = client.preferredName ? client.preferredName : client.firstName

    if(!nameString){
        return null
    }

    if(!firstOnly){
        nameString += ' '+client.lastName
    }

    return nameString
}

export const convertRepeatingEventsToUpcoming = eventList => {
    let clonedEventList = cloneDeep(eventList)

    clonedEventList.forEach(event => {
        if(event.isRepeating && event.date && event.repeatNumber && event.repeatUnit){
            let startDate = moment(event.date)
        
            // if dismissDate, check if it's before that, otherwise just new moment
            let dismissedUntil = event.repeatingDateDismissed ? moment(event.repeatingDateDismissed) : moment()

            if(!startDate.isAfter(dismissedUntil)){

                let hasFoundNextEvent = false
                let comparedDate = cloneDeep(startDate)

                while(!hasFoundNextEvent){
                    switch(event.repeatUnit){
                        case 'WEEKLY':
                            comparedDate.add(event.repeatNumber, 'weeks')
                            break
                        case 'MONTHLY':
                            comparedDate.add(event.repeatNumber, 'months')
                            break
                        case 'QUARTERLY':
                            comparedDate.add((event.repeatNumber * 3), 'months')
                            break
                        case 'YEARLY':
                            comparedDate.add(event.repeatNumber, 'years')
                            break
                    }

                    if(comparedDate.isAfter(dismissedUntil)){
                        hasFoundNextEvent = true

                        event.date = comparedDate.toDate()
                    }
                }
            }
        }
    })

    return clonedEventList
}

export const numberWithCommas = (x) => {
    if(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }else{
        return null
    }
}

export const generateEvents = (liabilities, people = [], companies = [], selectedValue = null, type = null, futureOnly = null, disableEventClientCheck = false, showCustom = true) => {
    let unformattedEventList = []

    let eventList = []
    let liabilityList = []

    liabilities.forEach(liability => {
        if(!liability){
            return 
        }

        let events = removeSoftDeletedItems(liability.events)

        if(!events.length){
            return
        }
        
        const loanName = liability.name ? liability.name : liability.loan.name

        liabilityList.push({
            value: liability.id,
            label: `${loanName} (${events.length})`
        })

        if(selectedValue !== liability.id && selectedValue !== 'all'){
            return 
        }

        events.forEach(event => {
            let clonedEvent = cloneDeep(event)
            let hasClients = false

            if(futureOnly && !moment(event.date).isAfter()){
                return
            }

            if(!showCustom && event.type == 'CUSTOM'){
                return
            }

            clonedEvent.loanName = loanName
            clonedEvent.assets = liability.assets
            clonedEvent.people = liability.people
            clonedEvent.companies = liability.companies

            if(!disableEventClientCheck){
                clonedEvent.people.forEach(applicant => {
                    if(applicant.person){
                        hasClients = true
                    }
                })

                clonedEvent.companies.forEach(applicant => {
                    if(applicant.company){
                        hasClients = true
                    }
                })
            }

            if(hasClients || disableEventClientCheck){
                unformattedEventList.push(clonedEvent)
            }
        })
    })

    if(selectedValue === 'all' && type === 'overview'){
        let clients = []

        clients = clients.concat(people)
        clients = clients.concat(companies)

        removeSoftDeletedItems(clients).forEach(client => {
            if(client && client.events){
                client.events.forEach(event => {
                    let clonedEvent = cloneDeep(event)

                    if(client.__typename == 'Person'){
                        clonedEvent.people = [client]
                    }else{
                        clonedEvent.companies = [client]
                    }

                    unformattedEventList.push(event)
                })
            }
        })
    }

    unformattedEventList = convertRepeatingEventsToUpcoming(unformattedEventList)

    unformattedEventList.sort((a,b) => {
        return new Date(a.date) - new Date(b.date)
    })

    unformattedEventList.forEach(event => {
        const eventDate = moment(event.date)

        if(futureOnly && !eventDate.isAfter()){
            return
        }

        if(event.type == 'BIRTHDAY' && type == 'overview'){
            if(!event.person || !event.person.notifyOfBirthday){
                return
            }
        }

        if(!showCustom && event.type == 'CUSTOM'){
            return 
        }

        let eventObject = {
            loanName: event.loanName,
            formattedDate: eventDate.format('Do MMMM, YYYY'),
            isInFuture: eventDate.isAfter(),
            assets: event.assets,
            date: eventDate,
            eventObject: event
        }

        if(event.isSystem){
            eventObject.addedBy = "Zamm"
        }else{
            eventObject.addedBy = event.addedBy ? getClientDisplayName(event.addedBy) : null
        }

        eventObject.title = getTitleFromEvent(event)

        eventList.push(eventObject)
    })

    return {
        events: eventList,
        liabilities: liabilityList
    }
}

export const getTitleFromEvent = event => {
    let title = event.title

    switch(event.type){
        case 'LOAN_START':
            title = "Loan term commences"
            break
        case 'LOAN_END':
            title = "Loan term ends"
            break
        case 'INTEREST_FREE_END':
            title = "Interest-only period ends"
            break
        case 'ANNIVERSARY':
            const yearsBetween = (event.liability && event.liability.startDate) ? moment(event.date).diff(moment(event.liability.startDate), 'years') : null

            title = yearsBetween ? `${yearsBetween}-year loan anniversary` : `0-year loan anniversary`
            break
        case 'FIXED_TERM_START':
            title = "Fixed-rate period starts"
            break
        case 'FIXED_TERM_END':
            title = "Fixed-rate period ends"
            break
        case 'INTRO_RATE_END':
            title = "Intro rate ends"
            break
        case 'LIABILITY_MODIFIED':
            title = "Liability details modified"
            break
        case 'BIRTHDAY':
            title = `${ getClientDisplayName(event.person, true) }'s birthday`
            break
    }

    if(!title){
        console.log(event)
    }

    return title
}

export const getLiabilityObjectsFromRelations = liabilities => {
    let liabilityObjects = []

    liabilities.forEach(liability => {
        let liabilityObject = liability.personRelation ? liability.personRelation : liability.companyRelation

        if (liabilityObject) {
            liabilityObjects.push(liabilityObject)
        }
    })

    return liabilityObjects
}

export const currentFixedRatePeriod = liability => {
    // defaults to 'no', I guess.
    if(!liability || !liability.startDate || !liability.fixedRatePeriods.length){
        return false
    }

    let returnedFixedRatePeriod = null

    liability.fixedRatePeriods.forEach(fixedRatePeriod => {
        if(!fixedRatePeriod.term){
            return 
        }

        const startMoment = moment(fixedRatePeriod.startDate)

        const endMoment = cloneDeep(startMoment).add(fixedRatePeriod.term, 'years')

        if(startMoment.isBefore() && moment().isBefore(endMoment)){
            returnedFixedRatePeriod = fixedRatePeriod
        }
    })

    // if the fixed-term ends in the future, it's currently fixed.
    return returnedFixedRatePeriod
}

export const getCurrentEffectiveInterestRate = (liability, loan = null) => {
    let loanObject = loan ? loan : liability.loan

    const settlementRate = liability.settlementRate

    // IF there's a fixed-rate period, we'll need to check that we've got data for when it started, otherwise it's void.

    let purpose = liability.financePurpose
    let isInterestOnly = false
    let interestRateGroup = null

    const startMoment = liability.startDate ? moment(liability.startDate) : null

    if(liability.interestOnlyTerm && startMoment){
        const endOfInterestOnly = startMoment.add(liability.interestOnlyTerm, 'years')

        isInterestOnly = endOfInterestOnly.isAfter()
    }

    if(!liability.startDate){
        return null
    }

    if(loanObject){
        switch(purpose){
            case 'OWNEROCCUPIER':
                if(isInterestOnly){
                    interestRateGroup = loanObject.interestRatesOOIO
                }else{
                    interestRateGroup = loanObject.interestRatesOOPI
                }
            break
            case 'INVESTMENT':
                if(isInterestOnly){
                    interestRateGroup = loanObject.interestRatesIVIO
                }else{
                    interestRateGroup = loanObject.interestRatesIVPI
                }
            break
        }

        if(!interestRateGroup.length){
            return null
        }
    }

    let currentRate

    const fixedRatePeriod = currentFixedRatePeriod(liability)

    if(fixedRatePeriod || !loanObject){
        let fixedRateStartPeriod = moment(fixedRatePeriod.startDate)

        if(fixedRatePeriod && fixedRatePeriod.customRate){
            currentRate = fixedRatePeriod.customRate
        }else if(loanObject){
            interestRateGroup.forEach((rate, index) => {
                let rateCreationMoment = moment(rate.effectiveDate)

                if(fixedRateStartPeriod.isAfter(rateCreationMoment)){
                    let isLastRate = index == (interestRateGroup.length - 1) ? true : false

                    // if this liability was made after this rate AND it's the last rate - that's our fixed rate.
                    if(isLastRate){
                        currentRate = rate.rate
                    }else{
                        let nextRate = interestRateGroup[(index + 1)]

                        // if the next rate was made after this liability, we're at our last one.
                        if(moment(nextRate.effectiveDate).isAfter(fixedRateStartPeriod)){
                            currentRate = rate.rate 
                        }
                    }
                }
            })
        }
    }else{
        currentRate = interestRateGroup[(interestRateGroup.length - 1)].rate
    }

    if(startMoment && liability.introRateYears && liability.introRatePercent /* && !fixedRatePeriod */){
        const isInIntroRate = moment().isBefore(startMoment.add(liability.introRateYears, 'years'))

        if(isInIntroRate){
            const rateMultiplier = (100 - liability.introRatePercent)/100

            currentRate *= rateMultiplier
        }
    }

    if(settlementRate){
        currentRate = settlementRate
    }

    if(currentRate){
        if(!fixedRatePeriod && !settlementRate){
            currentRate -= liability.discountPercent
        }

        return (Math.round(currentRate * 100) / 100).toFixed(2)
    }else{
        return null
    }
}

export const formatAddress = (address, forAutocomplete = null) => {
    let addressString = ''

    if(!forAutocomplete){
        if(address.level){
            addressString += 'Level '+address.level+', '
        }

        if(address.building){
            addressString += 'Building '+address.building+', '
        }

        if(address.unitNumber){
            addressString += 'Unit '+address.unitNumber+', '
        }
    }

    if(address.streetNumber){
        addressString += address.streetNumber+' '
    }

    if(address.streetName){
        addressString += address.streetName+', '
    }

    if(address.suburb){
        addressString += address.suburb+' '
    }

    if(forAutocomplete){
        if(address.state){
            addressString += address.state+' '
        }

        if(address.country){
            addressString += address.country+' '
        }
    }

    if(address.postcode){
        addressString += address.postcode
    }

    if(addressString){
        return addressString
    }else{
        return null
    }
}

export const getAssetName = (asset, prioritiseAddress = null) => {
    let addressLabel = null

    if(prioritiseAddress){
        if (formatAddress(asset.address)) {
            addressLabel = formatAddress(asset.address)
        } else if (asset.name) {
            addressLabel = asset.name
        }
    }else{
        if (asset.name) {
            addressLabel = asset.name
        } else if (formatAddress(asset.address)) {
            addressLabel = formatAddress(asset.address)
        }
    }

    return addressLabel
}

export const getLiabilityName = liability => {
    if(liability.name){
        return liability.name
    }

    if(liability.loan){
        return liability.loan.name
    }

    return '(Empty liability)'
}

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const pluraliseObject = name => {
    switch(name){
        case 'liability':
            return 'liabilities'
        case 'asset':
            return 'assets'
        case 'person':
            return 'people'
        case 'company':
            return 'companies'
    }
}

export const hasOwnershipError = fields => {
    let hasValidOwnership = true

    fields.people.forEach(person => {
        if(!person.id){
            hasValidOwnership = false
        }
    })

    fields.companies.forEach(company => {
        if(!company.id){
            hasValidOwnership = false
        }
    })

    if(!hasValidOwnership){
        return `You've left an ownership field blank.`
    }

    let percentIncrementer = 0

    fields.people.forEach(person => {
        if(person.percent){
            percentIncrementer += parseInt(person.percent)
        }
    })

    fields.companies.forEach(company => {
        if(company.percent){
            percentIncrementer += parseInt(company.percent)
        }
    })

    if(percentIncrementer !== 100){
        return `Your applicants don't add up to 100%`
    }

    return false
}

export const dismissableEvent = event => {
    if(!event || !event.type){
        return false
    }

    switch(event.type){
        case 'LIABILITY_MODIFIED':
            return false
        default:
            return true
    }
}