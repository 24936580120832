import gql from 'graphql-tag'
import { EventFragment } from './fragments-graphql'

export const UPSERT_EVENT = gql`
    mutation UPSERT_EVENT (
        $payload: UpsertEventPayload
    ){
        upsertEvent(
            payload: $payload
        ){
            person {
                id
                events {
                    ...EventFragment
                }
                liabilities {
                    id
                    personRelation {
                        id
                        events {
                            ...EventFragment
                        }
                    }
                }
            }
            company {
                id
                events {
                    ...EventFragment
                }
                liabilities {
                    id
                    companyRelation {
                        id
                        events {
                            ...EventFragment
                        }
                    }
                }
            }
        }
    }
    ${EventFragment}
`

export const TOGGLE_DISMISS_EVENT = gql`
    mutation TOGGLE_DISMISS_EVENT (
        $payload: IdReferencePayLoad
    ){
        toggleDismissEvent(
            payload: $payload
        ){
            id
            isDismissed
            repeatingDateDismissed
        }
    }
`