import React, { Component } from 'react'

import { CSSTransition } from "react-transition-group"

import { graphql, compose } from 'react-apollo'

import Button from 'components/Button/Button'

import { formatAddress } from 'utils/utils'

class ModalMiniAddress extends Component {
    state = {
        fields: {
            firstName: '',
            lastName: '',
        },
        errors: []
    }

    fieldColumns = [[[
        {
            label: 'First name',
            required: true,
            type: 'text',
            props: { type: 'text' },
            key: 'firstName'   
        },
        {
            label: 'Last name',
            required: true,
            type: 'text',
            props: { type: 'text' },
            key: 'lastName'   
        },
    ]]]

    render(){
        const {
            miniAddressData
        } = this.props

        const spouse = miniAddressData.spouse ? miniAddressData.spouse : null

        return (
            <CSSTransition in={this.props.isVisible ? true : false} mountOnEnter unmountOnExit classNames="mini-modal" timeout={{ enter: 1, exit: 300 }}>
                <div className="modal-mini modal-mini-address">
                    { spouse &&
                        <div>
                            <h3>
                                Auto-fill address from { miniAddressData.spouse.firstName }?
                            </h3>
                            <p>
                                { spouse.gender == 'MALE' ? 'He' : 'She' } lives at { formatAddress(spouse.address) }.
                            </p>
                            <Button label="Yes" level="high" callback={() => {this.props.callback(true)}} />
                            <Button label="Cancel" level="low" callback={() => {this.props.callback(false)}} />
                        </div>
                    }
                </div>
            </CSSTransition>
        )
    }
}

export default ModalMiniAddress
  