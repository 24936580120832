import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'

class SwitchInput extends Component {
    componentDidMount(){
        setTimeout(() => {
            if(!this.props.value && this.props.value !== false){
                this.props.changeCallback(this.props.values[0].value);
            }
        },2);
    }

  render(){
    let className = 'switch-container ';
    
    return (
        <div className={this.props.error ? "input-wrap error" : "input-wrap"}>
        {
          this.props.label ?
          <label>
            { this.props.label }
            {
              this.props.required ?
              <em className="required">
                *
              </em>
              : null
            }
          </label>
          : null
        }
        <div className={className}>
            <ul>
                {
                    this.props.values.map((value, index) => {
                        return (
                            <li key={index}>
                                <button className={value.value == this.props.value ? 'active' : null } onClick={() => {this.props.changeCallback(value.value)}}>
                                    { value.label }
                                </button>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        <ValidationError error={this.props.error} />
      </div>
    )
  }
}

export default SwitchInput
