import { InMemoryCache } from 'apollo-cache-inmemory'
import { withClientState } from 'apollo-link-state'

import {
  openModal,
  closeModal,
  setRedirectPath,
  clearRedirectPath,
  setSortingPref,
} from './actions'

export const cache = new InMemoryCache();

export const defaults = {
  currentModal: {
    operation: null,
    dataType: null,
    isVisible: false,
    object: null,
    data: {
      __typename: 'ModalStatusData'
    },
    __typename: 'ModalStatus'
  },
  redirectPath: {
    path: null,
    __typename: 'RedirectPath'
  },
  sortingPrefs: {
    asset: {
      column: null,
      direction: null,
      __typename: null
    },
    liability: {
      column: null,
      direction: null,
      __typename: null
    },
    reporting: {
      column: null,
      direction: null,
      __typename: null
    },
    people: {
      column: null,
      direction: null,
      __typename: null
    },
    companies: {
      column: null,
      direction: null,
      __typename: null
    },
    __typename: 'SortingPrefs'
  }
}

export const resolvers = {
  Mutation: {
    openModal,
    closeModal,
    setRedirectPath,
    clearRedirectPath,
    setSortingPref,
  },
}