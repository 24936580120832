import React, { Component } from 'react'
import { withRouter } from 'react-router'

import { cloneDeep } from 'lodash'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

import { graphql, Query, compose } from 'react-apollo'
import { OPEN_MODAL } from 'graphql/modal-graphql'

import Timeline from 'components/Timeline/Timeline'
import DetailsTable from 'components/DetailsTable/DetailsTable'

import Button from 'components/Button/Button'

import Countdown from './Widgets/Countdown'
import Figures from './Widgets/Figures'

import { Link } from 'react-router-dom'

import {
    numberWithCommas,
    getCurrentEffectiveInterestRate,
    getAssetName,
    currentFixedRatePeriod,
    getClientDisplayName
} from 'utils/utils'
import moment from 'moment'

class SingleLiability extends Component {

    tableData = [
        {
            title: "Liability Details",
            data: [
                [
                    {
                        label: 'Liability Name',
                        key: 'name',
                    }, {
                        label: 'Applicant(s)',
                        key: 'applicants',
                    }
                ], [
                    {
                        label: 'Linked Asset(s)',
                        key: 'asset',
                    }
                ]
            ]
        },
        {
            title: "Interest Details",
            data: [
                [
                    {
                        label: 'Interest Rate Type',
                        key: 'type',
                    }, {
                        label: 'Interest-Only Term',
                        key: 'interestOnlyTerm',
                    }, {
                        label: 'Loan Term',
                        key: 'loanTerm',
                    }
                ], [
                    {
                        label: 'Creditor/Lender',
                        key: 'lender',
                    }, {
                        label: 'Loan product',
                        key: 'loanProduct'
                    }
                ]
            ]
        },
        {
            title: "Account Details",
            data: [
                [
                    {
                        label: 'Finance Purpose',
                        key: 'financePurpose',
                    }, {
                        label: 'Amount',
                        key: 'amount',
                    },
                    {
                        label: 'Repayments',
                        key: 'repaymentFrequency',
                    },
                    {
                        label: 'Account Name',
                        key: 'bankAccountName',
                    },
                    {
                        label: 'BSB/Account number',
                        key: 'bankAccountNumber',
                    },
                    {
                        label: 'Offset BSB/Account number',
                        key: 'offsetAccountNumber'
                    }
                ], [
                    {
                        label: 'Loan Purpose',
                        key: 'loan_purpose',
                    }, {
                        label: 'Initial Balance',
                        key: 'initialBalance',
                    },
                    {
                        label: 'Interest Rate Discount',
                        key: 'interest_discount',
                    },
                ]
            ]
        }
    ]

    tableDataGetter = key => {
        const liabilityObject = this.props.liability

        let response = ''

        switch (key) {
            case 'name':
                if (liabilityObject.name) {
                    response = liabilityObject.name
                } else {
                    response = '(No name)'
                }
                break
            case 'applicants':
                if (liabilityObject.people.length || liabilityObject.companies.length) {
                    response = (
                        <ul className="table-list">
                            {liabilityObject.people.map((applicant, index) => {
                                if (!applicant.person) {
                                    return
                                }

                                return (
                                    <li key={index}>
                                        <Link to={"/clients/people/" + applicant.person.id}>
                                            <img src="/images/icons/person.png" />
                                            { getClientDisplayName(applicant.person) }
                                        </Link>
                                    </li>
                                )
                            })}
                            {liabilityObject.companies.map((applicant, index) => {
                                if (!applicant.company) {
                                    return
                                }

                                return (
                                    <li key={index}>
                                        <Link to={"/clients/companies/" + applicant.company.id}>
                                            <img src="/images/icons/company.png" />
                                            {applicant.company.name}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    )
                } else {
                    response = '(No applicants)'
                }

                break
            case 'asset':
                if (liabilityObject.assets.length) {
                    response = (
                        <ul className="table-list">
                            {
                                liabilityObject.assets.map((asset, index) => {
                                    let thisUrl = this.props.location.pathname

                                    thisUrl = thisUrl.split("liabilities")[0]

                                    if (getAssetName(asset)) {
                                        return (
                                            <li key={index}>
                                                <Link to={thisUrl + "assets"}>
                                                    <img src="/images/icons/asset.png" />
                                                    {getAssetName(asset)}
                                                </Link>
                                            </li>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </ul>
                    )
                } else {
                    response = '(No assets)'
                }
                break
            case 'type':
                response = currentFixedRatePeriod(liabilityObject) ? 'Fixed interest rate' : 'Variable interest rate'
                break
            case 'interestOnlyTerm':
                if (liabilityObject.interestOnlyTerm) {
                    response = liabilityObject.interestOnlyTerm + ' years'
                } else {
                    response = '(No interest-only term)'
                }
                break
            case 'loanTerm':
                if (liabilityObject.loanTerm) {
                    response = liabilityObject.loanTerm + ' years'
                } else {
                    response = '(No loan term)'
                }
                break
            case 'lender':
                if (liabilityObject.loan) {
                    response = liabilityObject.loan.lender.name
                } else {
                    response = '(No lender)'
                }
                break
            case 'loanProduct':
                if (liabilityObject.loan) {
                    response = liabilityObject.loan.name
                } else {
                    response = '(No loan)'
                }
                break
            case 'financePurpose':
                if (liabilityObject.financePurpose) {
                    response = liabilityObject.financePurpose == 'INVESTMENT' ? 'Investment' : 'Owner-occupier'
                }
                break
            case 'amount':
                if (liabilityObject.amount) {
                    response = '$' + numberWithCommas(liabilityObject.amount)
                } else {
                    response = '(No amount)'
                }
                break
            case 'repaymentFrequency':
                if (liabilityObject.repaymentAmount && liabilityObject.repaymentFrequency) {
                    response = '$' + numberWithCommas(liabilityObject.repaymentAmount)

                    switch (liabilityObject.repaymentFrequency) {
                        case 'WEEKLY':
                            response += '/week'
                            break
                        case 'MONTHLY':
                            response += '/month'
                            break
                        case 'QUARTERLY':
                            response += '/quarter'
                            break
                        case 'YEARLY':
                            response += '/year'
                            break
                    }
                } else {
                    response = '(No repayments)'
                }

                break
            case 'bankAccountName':
                if (liabilityObject.bankAccountName) {
                    response = liabilityObject.bankAccountName
                } else {
                    response = '(No bank account)'
                }
                break
            case 'bankAccountNumber':
                if (liabilityObject.bankAccountNumber) {
                    response = ''

                    if(liabilityObject.bankAccountBsb){
                        response += '('+liabilityObject.bankAccountBsb+') '
                    }

                    if(liabilityObject.bankAccountNumber){
                        response += liabilityObject.bankAccountNumber
                    }
                } else {
                    response = '(No account number)'
                }
                break
            case 'offsetAccountNumber':
                if (liabilityObject.offsetAccountNumber) {
                    response = ''

                    if(liabilityObject.offsetAccountBsb){
                        response += '('+liabilityObject.offsetAccountBsb+') '
                    }

                    if(liabilityObject.offsetAccountNumber){
                        response += liabilityObject.offsetAccountNumber
                    }
                } else {
                    response = '(No account number)'
                }
                break
            case 'loan_purpose':
                if (liabilityObject.loan) {
                    response = liabilityObject.loan.name
                }
                break
            case 'initialBalance':
                if (liabilityObject.initialBalance) {
                    response = '$' + numberWithCommas(liabilityObject.initialBalance)
                } else {
                    response = '$0'
                }
                break
            case 'interest_discount':
                if (liabilityObject.discountPercent) {
                    response = liabilityObject.discountPercent + '%'
                } else {
                    response = '0%'
                }
                break
            case 'account_bsb':
                if (liabilityObject.bankAccountBsb) {
                    response = liabilityObject.bankAccountBsb
                } else {
                    response = '(No BSB)'
                }
                break
        }

        return response
    }

    generateFigures = () => {
        let figures = []

        const {
            liability
        } = this.props

        if (liability.amount) {
            figures.push({
                title: "Loan amount",
                data: '$' + numberWithCommas(liability.amount)
            })
        }

        const interestRate = getCurrentEffectiveInterestRate(liability)

        if (interestRate) {
            figures.push({
                title: liability.settlementRate ? "Interest rate at settlement" : "Effective interest rate",
                subtitle: liability.settlementRate ? `Last known interest rate as at ${ moment(liability.updatedAt).format('Do MMMM, Y')  }` : null,
                data: interestRate + '%',
                showFixedRatePeriod: true,
                fixedRatePeriod: currentFixedRatePeriod(liability)
            })
        }

        return figures
    }

    render() {
        const {
            liability,
            breadcrumbs,
            person,
            company,
            openModal
        } = this.props

        const _breadcrumbs = cloneDeep(this.props.breadcrumbs)

        if(!liability){
            return (
                <div>
                    <div className="heading-section">
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <h1>
                            Liability missing
                        </h1>
                    </div>
                    <div className="main-content-container">
                        <div className="main-column">
                            <div className="content-box">
                                <p>
                                    Looks like this liability can't be found. Perhaps go back, and try again?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )   
        }else{
            _breadcrumbs.push({
                link: null,
                label: liability.name ? liability.name : loan.name
            })
        }

        const loan = liability.loan

        const figures = this.generateFigures()

        return (
            <div>
                <div className="heading-section">
                    <Breadcrumbs breadcrumbs={_breadcrumbs} />
                    <h1>
                        {liability.name ? liability.name : loan.name}
                    </h1>
                    <div className="controls">
                        <Button label="Edit liability" callback={() => {
                            let liabilityObject = cloneDeep(liability)

                            if (person) {
                                liabilityObject.personId = person.id
                            } else {
                                liabilityObject.companyId = company.id
                            }

                            openModal({
                                variables: {
                                    operation: 'edit',
                                    dataType: 'liability',
                                    isVisible: true,
                                    object: liabilityObject
                                }
                            })
                        }} />
                    </div>
                </div>
                <div className="main-content-container">
                    <div className="main-column">
                        <div className="content-box">
                            <Timeline 
                                type="single" 
                                person={person} 
                                company={company} 
                                liability={liability} 
                                liabilities={[liability]} 
                            />
                            <DetailsTable 
                                data={this.tableData} 
                                getter={this.tableDataGetter} 
                            />
                        </div>
                    </div>
                    <div className="side-column">
                        <div className="content-box">
                            <Countdown liability={liability} />
                            {figures.map((figure, index) => {
                                return (
                                    <Figures 
                                        key={index} 
                                        {...figure}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(compose(graphql(OPEN_MODAL, { name: 'openModal' }))(SingleLiability))