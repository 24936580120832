import React, { Component } from 'react'
import TextInput from 'components/Inputs/TextInput'
import { cloneDeep } from 'lodash'
import { withRouter } from 'react-router'

import Button from 'components/Button/Button'

import Table from 'components/Table/Table'

import { Mutation } from 'react-apollo'

import { OPEN_MODAL } from 'graphql/modal-graphql'

import { graphql, compose } from 'react-apollo'

import Loader from 'components/Loader/Loader'

class List extends Component {
	state = {
		searchDisplayValue: '',
		searchValue: '',
		renderList: (this.props.data && (this.props.data.type == 'person' || this.props.data.type == 'company')) ? false : true
	}

	searchChangeDebouncer = null

	componentDidMount() {
		setTimeout(_ => {
			window.requestAnimationFrame(_ => {
				this.setState({
					renderList: true
				})
			})
		},1000)
	}

	handleChange = value => {
		this.setState({
			searchDisplayValue: value
		})

		clearTimeout(this.searchChangeDebouncer)

		this.searchChangeDebouncer = setTimeout(_ => {
			this.setState({
				searchValue: value.toLowerCase()
			})
		}, 500)
	}

	hiddenBySearch = item => {
		const {
			data
		} = this.props

		const {
			searchValue
		} = this.state

		if (data.hiddenBySearch) {
			return data.hiddenBySearch(item, searchValue)
		} else {
			return false
		}
	}

	render() {
		const { 
			rows, 
			modalObject, 
			getter, 
			columns, 
			changeSortingCallback, 
			sorting, 
			type,
		} = this.props.data

		const {
			renderList,
			searchDisplayValue,
			searchValue
		} = this.state

		let searchString = ''

		switch (type) {
			case 'liability':
				searchString = 'Search liabilities...'
				break
			case 'asset':
				searchString = 'Search assets...'
				break
			case 'person':
				searchString = 'Search people...'
				break
			case 'company':
				searchString = 'Search companies...'
				break
		}

		return (
			<div>
				<div className="search-button-container">
					<TextInput 
						value={searchDisplayValue} 
						changeCallback={this.handleChange} 
						type="search" 
						placeholder={searchString} 
					/>
					<Mutation mutation={OPEN_MODAL}>
						{openModal => (
							<Button label={`Add ${type}`} callback={() => {
								openModal({
									variables: {
										operation: 'add',
										dataType: type,
										isVisible: true,
										object: modalObject
									}
								})
							}} />
						)}
					</Mutation>
				</div>
				<Loader 
					isLoading={!renderList} 
					showText={true}
				/>
				{ renderList ?
					<Table 
						dataType={type} 
						changeSortingCallback={changeSortingCallback} 
						sorting={sorting} 
						searchValue={searchValue} 
						searchDisplayValue={searchDisplayValue}
						hiddenBySearch={this.hiddenBySearch} 
						columns={columns} 
						data={rows} 
						getter={getter} 
						hideValuesUntilSearch={type === 'company' || type === 'person'}
					/> 
					:
					<div className="table-placeholder" />
				}
			</div>
		)
	}
}

export default withRouter(compose(graphql(OPEN_MODAL, { name: 'openModal' }))(List))