import React, { Component } from 'react'
import Select from 'react-select'
import Button from 'components/Button/Button'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import Loader from 'components/Loader/Loader'

import { Link } from 'react-router-dom'

import Table from 'components/Table/Table'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import html2canvas from 'html2canvas'

import { 
	getCurrentEffectiveInterestRate, 
	capitaliseFirstLetter, 
	removeSoftDeletedItems, 
	isAdmin,
	getCurrentUser,
	getClientDisplayName,
	exportToExcel,
	currentFixedRatePeriod
} from 'utils'

import { 
	graphql, 
	compose, 
	withApollo 
} from 'react-apollo'

import { 
	GET_LENDERS, 
	GET_REPORTING_LIABILITIES, 
	GET_REPORTING_PEOPLE, 
	GET_REPORTING_COMPANIES, 
	SET_SORTING_PREF,
	GET_SORTING_PREF
} from 'graphql/all'

import { numberWithCommas } from '../../utils/utils'

import SwitchInput from 'components/Inputs/SwitchInput'

import * as jsPDF from 'jspdf'
import 'jspdf-autotable'

window.html2canvas = html2canvas

class Reporting extends Component {
	state = {
		filterOptionType: 'liabilities',
		filterOptions: [],
		isLoading: false,
		tableData: [],
		showResultsForAll: true,
		liabilitySorting: {
			column: 'lender',
			direction: 'desc'
		},
		clientSorting: {
			column: 'name',
			direction: 'desc'
		}
	}

	// for file exports - <table> will generate the data for us
	generatedTableData = []
	formattedTableData = []

	componentWillMount = () => {

		const existingFilterOptions = localStorage.getItem('reportingFilterOptions') ? JSON.parse(localStorage.getItem('reportingFilterOptions')) : null
		const existingFilterOptionType = localStorage.getItem('reportingFilterOptionType')

		if(existingFilterOptions && existingFilterOptionType){
			this.setState({
				filterOptions: existingFilterOptions,
				filterOptionType: existingFilterOptionType
			})
		}

		this.props.setSortingPref({
			variables: {
				path: 'reporting',
				column: 'name',
				direction: 'desc',
				__typename: 'sortingPrefs'
			}
		})

		setTimeout(_ => {
			this.queryResults()
		},1)
	}

	componentWillUpdate() {
		this.generatedTableData = []
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.filterOptions !== prevState.filterOptions || this.state.filterOptionType !== prevState.filterOptionType){
			localStorage.setItem('reportingFilterOptions', JSON.stringify(this.state.filterOptions))
			localStorage.setItem('reportingFilterOptionType', this.state.filterOptionType)
		}
	}

	getSelectValues = (key, clauseIndex) => {
		switch (key) {
			case 'filterOptionsType':
				return [
					{
						label: 'liabilities',
						value: 'liabilities'
					}, {
						label: 'clients',
						value: 'clients'
					}
				]
			case 'filterOptions':
				return this.getAvailableOptionTypes(clauseIndex)
			case 'operator':
				return [
					{
						label: 'less than',
						value: 'lt'
					},{
						label: 'greater than',
						value: 'gt'
					}, {
						label: 'equal to',
						value: 'eq'
					}, {
						label: 'not equal to',
						value: 'neq'
					}
				]
			case 'date-operator':
				return [
					{
						label: 'before',
						value: 'lt'
					},{
						label: 'on',
						value: 'eq'
					},{
						label: 'after',
						value: 'gt'
					}
				]
			case 'lender':
				return this.getLenders()
			case 'purpose':
				return [
					{
						label: 'owner-occupier',
						value: 'OWNEROCCUPIER'
					}, {
						label: 'investment',
						value: 'INVESTMENT'
					}
				]
			case 'isFixed':
				return [
					{
						label: 'fixed-rate',
						value: true
					}, {
						label: 'variable',
						value: false
					}
				]
			case 'isInterestOnly':
				return [
					{
						label: 'principal & interest',
						value: false
					}, {
						label: 'interest-only',
						value: true
					}
				]
			case 'state':
				return [
					{
						label: 'New South Wales',
						value: 'NSW'
					},
					{
						label: 'Victoria',
						value: 'VIC'
					},
					{
						label: 'South Australia',
						value: 'SA'
					},
					{
						label: 'Queensland',
						value: 'QLD'
					},
					{
						label: 'Western Australia',
						value: 'WA'
					},
					{
						label: 'Northern Territory',
						value: 'NT'
					},
					{
						label: 'Australian Capital Territory',
						value: 'ACT'
					},
					{
						label: 'Tasmania',
						value: 'TAS'
					}
				]
			case 'isInvestor':
				return [
					{
						label: 'true',
						value: true
					},
					{
						label: 'false',
						value: false
					}
				]
		}
	}

	getLenders = () => {
		let lenders = []

		const allLiabilities = this.props.getLiabilities.reportingLiabilities
		const allLenders = this.props.getLenders.lenders

		if (lenders && allLenders && allLiabilities) {
			allLenders.forEach(lender => {
				const lenderIfInLiability = allLiabilities.find(loopedLiability => {
					if(loopedLiability.loan){
						return lender.loans.find(loopedLoan => {
							return loopedLoan.id == loopedLiability.loan.id
						})
					}
				})

				if(lenderIfInLiability){
					lenders.push({
						label: lender.name,
						value: lender.id
					})
				}
			})
		} else {
			lenders = [{
				label: '(Loading...)',
				value: undefined
			}]
		}

		return lenders
	}

	getAvailableOptionTypes = (indexIfAny = null) => {
		let filterOptions = []

		this.filterOptions[this.state.filterOptionType].forEach(option => {
			let isOptionInOtherClause = false

			this.state.filterOptions.forEach((stateOption, index) => {
				if (stateOption.option.key === option.key) {
					// if no indices are exempt, or the current index is NOT this option's index, it's in use.
					if (indexIfAny === null || indexIfAny !== index) {
						isOptionInOtherClause = true
					}
				}
			})

			if (!isOptionInOtherClause) {
				filterOptions.push({
					label: option.label,
					value: option.key
				})
			}
		})

		return filterOptions

	}

	canAddClause = () => {
		let selectedOptionsCount = this.state.filterOptions.length
		let totalOptionsCount = this.filterOptions[this.state.filterOptionType].length

		if (totalOptionsCount > selectedOptionsCount) {
			return true
		} else {
			return false
		}
	}

	addClause = () => {
		let clonedOptions = cloneDeep(this.state.filterOptions)

		let nextAvailableOptionType = this.getAvailableOptionTypes()[0]

		if (nextAvailableOptionType) {
			clonedOptions.push({
				option: this.filterOptions[this.state.filterOptionType].find(option => {
					return option.key == nextAvailableOptionType.value
				}),
				operator: 'lt',
				value: null
			})

			this.setState({
				filterOptions: clonedOptions
			})
		}
	}

	removeClause = index => {
		let clonedOptions = cloneDeep(this.state.filterOptions)

		clonedOptions.splice(index, 1)

		this.setState({
			filterOptions: clonedOptions
		}, () => {
			this.queryResults()
		})
	}

	changeFilterOptionType = value => {
		this.setState({
			filterOptionType: value.value,
			filterOptions: [],
			tableData: []
		}, () => {
			this.queryResults()
		})
	}

	changeClauseType = (value, index) => {
		let clonedOptions = cloneDeep(this.state.filterOptions)

		let newOption = this.filterOptions[this.state.filterOptionType].find(option => {
			return option.key === value.value
		})

		clonedOptions[index] = {
			option: newOption,
			operator: 'lt',
			value: null
		}

		this.setState({
			filterOptions: clonedOptions
		}, () => {
			this.queryResults()
		})
	}

	changeClauseOperator = (value, index) => {
		let clonedOptions = cloneDeep(this.state.filterOptions)

		clonedOptions[index].operator = value.value

		this.setState({
			filterOptions: clonedOptions
		}, () => {
			this.queryResults()
		})
	}

	handleChange = (type, value, index) => {
		if(type == 'showResultsForAll'){
			this.setState({
				showResultsForAll: value
			}, () => {
				this.queryResults()
			})
		}else{
			let clonedOptions = cloneDeep(this.state.filterOptions)

			clonedOptions[index].value = value

			this.setState({
				filterOptions: clonedOptions
			}, () => {
				this.queryResults()
			})
		}
	}

	checkValidity = index => {
		let option = this.state.filterOptions[index]

		if (option.value !== null && typeof (option.value) !== 'undefined') {
			return true
		} else {
			return false
		}
	}

	removeInvalidLiabilities = liabilities => {
		return liabilities.filter(liability => {
			let hasClients = false

			if (liability.people.length) {
				liability.people.forEach(applicant => {
					if (applicant.person) {
						hasClients = true
						return
					}
				})
			}

			if (liability.companies.length) {
				liability.companies.forEach(applicant => {
					if (applicant.company) {
						hasClients = true
						return
					}
				})
			}

			return hasClients
		})
	}

	removeOtherBrokerItems = (items, type) => {
		const thisUser = getCurrentUser(this.props.client)

		return items.filter(item => {
			return item.broker.id == thisUser.id
		})
	}

	queryResults = async () => {
		let queryData = []

		const {
			filterOptions,
			filterOptionType,
			showResultsForAll
		} = this.state

		const {
			client
		} = this.props

		filterOptions.forEach(option => {
			if (option.value || option.value === false) {
				queryData.push({
					key: option.option.key,
					operator: option.option.hasOperators ? option.operator : null,
					value: option.value
				})
			}
		})

		if (filterOptionType == 'liabilities') {
			this.setState({
				isLoading: true
			})

			client.query({
				query: GET_REPORTING_LIABILITIES
			}).then(data => {
				let liabilities = this.removeInvalidLiabilities(removeSoftDeletedItems(data.data.reportingLiabilities))

				if(!showResultsForAll){
					liabilities = this.removeOtherBrokerItems(liabilities, 'liabilities')
				}

				queryData.forEach(clause => {
					switch (clause.key) {
						case 'lender':
							liabilities = liabilities.filter(liability => {
								return liability.loan && liability.loan.lender.id === clause.value
							})
							break
						case 'purpose':
							liabilities = liabilities.filter(liability => {
								return liability.financePurpose == clause.value
							})
							break
						case 'isFixed':
							liabilities = liabilities.filter(liability => {
								const isFixed = currentFixedRatePeriod(liability) ? true : false
								return isFixed === clause.value
							})
							break
						case 'isInterestOnly':
							liabilities = liabilities.filter(liability => {
								let isCurrentlyInterestOnly = false

								if (liability.interestOnlyTerm) {
									// I guess it's technically IO, if there's a term but no start date.
									if (!liability.startDate) {
										isCurrentlyInterestOnly = true
									} else {
										let startDate = moment(liability.startDate)
										startDate.add(liability.interestOnlyTerm, 'years')

										if (startDate.isAfter()) {
											isCurrentlyInterestOnly = true
										}
									}
								}

								return clause.value === isCurrentlyInterestOnly
							})
							break
						case 'interestRate':
							liabilities = liabilities.filter(liability => {
								let interestRate = getCurrentEffectiveInterestRate(liability)
								let rateValue = parseFloat(clause.value)

								if (!interestRate || !rateValue) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return interestRate < rateValue
										case 'gt':
											return interestRate > rateValue
										case 'eq':
											return interestRate == rateValue
										case 'neq':
											return interestRate != rateValue
									}
									return false
								}
							})
							break

						case 'fixedRate':
							let selectedDate = clause.value
			
							liabilities = liabilities.filter(liability => {
								let hasEarlier = false
								let hasLater = false
								let hasSame = false

								liability.fixedRatePeriods.forEach(fixedRatePeriod => {
									let startMoment = moment(fixedRatePeriod.startDate)

									let endMoment = moment(fixedRatePeriod.term, 'years')

									let endDate = startMoment.add(endMoment)
									let currentMoment = moment()

									// old fixed-rate periods will be ignored
									if(endDate.isAfter(currentMoment)){
										if(!hasEarlier){
											hasEarlier = endDate.isBefore(selectedDate)
										}

										if(!hasLater){
											hasLater = endDate.isAfter(selectedDate)
										}

										if(!hasSame){
											hasSame = endDate.isSame(selectedDate)
										}
									}
								})

								if (!selectedDate) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return hasEarlier
										case 'gt':
											return hasLater
										case 'eq':
											return hasSame
									}
	
									return false
								}
							})

							break
						case 'discount':
							liabilities = liabilities.filter(liability => {
								let discountValue = parseFloat(clause.value)
								let discountPercent = liability.discountPercent

								if (!discountValue) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return !discountPercent || discountPercent < discountValue
										case 'gt':
											return discountPercent > discountValue
										case 'eq':
											return discountPercent == discountValue
										case 'neq':
											return discountPercent != discountValue
									}

									return false
								}
							})

							break
						case 'amount':
							liabilities = liabilities.filter(liability => {
								let amountValue = parseFloat(clause.value)
								let amount = liability.amount

								if (!amount) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return amount < amountValue
										case 'gt':
											return amount > amountValue
										case 'eq':
											return amount == amountValue
										case 'neq':
											return amount != amountValue
									}

									return false
								}
							})

							break
						case 'startDate':
							liabilities = liabilities.filter(liability => {
								let date = clause.value ? clause.value : null
								let startDate = liability.startDate ? moment(liability.startDate).startOf('day') : null

								if (!startDate) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return startDate.isBefore(date)
										case 'gt':
											return startDate.isAfter(date)
										case 'eq':
											return startDate.isSame(date)
										case 'neq':
											return !startDate.isSame(date)
									}

									return false
								}
							})
							break
						case 'endDate':
							liabilities = liabilities.filter(liability => {
								let date = clause.value ? clause.value : null
								let endDate = (liability.startDate && liability.loanTerm) ? moment(liability.startDate).startOf('day').add(liability.loanTerm, 'years') : null

								if (!endDate) {
									return false
								} else {
									switch (clause.operator) {
										case 'lt':
											return endDate.isBefore(date)
										case 'gt':
											return endDate.isAfter(date)
										case 'eq':
											return endDate.isSame(date)
										case 'neq':
											return !endDate.isSame(date)
									}

									return false
								}
							})

							break
					}
				})

				this.setState({
					isLoading: false,
					tableData: liabilities
				})

			}).catch(error => {
				console.log(error)
			})
		} else if (this.state.filterOptionType == 'clients') {
			this.setState({
				isLoading: true
			})

			let allClients = []

			await this.props.client.query({
				query: GET_REPORTING_PEOPLE
			}).then(data => {
				let people = removeSoftDeletedItems(data.data.getPeople)

				allClients = allClients.concat(people)
			})

			await this.props.client.query({
				query: GET_REPORTING_COMPANIES
			}).then(data => {
				let companies = removeSoftDeletedItems(data.data.getCompanies)

				allClients = allClients.concat(companies)
			})

			if(!this.state.showResultsForAll){
				allClients = this.removeOtherBrokerItems(allClients, 'clients')
			}

			let search

			queryData.forEach(clause => {
				switch (clause.key) {
					case 'suburb':
						search = clause.value.toLowerCase()

						allClients = allClients.filter(client => {
							if (!client.address || !client.address.suburb) {
								return false
							}

							return client.address.suburb.toLowerCase().includes(search)
						})
						break
					case 'state':
						allClients = allClients.filter(client => {
							if (!client.address || !client.address.state) {
								return false
							}

							return client.address.state === clause.value
						})
						break
					case 'age':
						allClients = allClients.filter(client => {
							if (client.__typename == 'Company' || !client.dateOfBirth) {
								return false
							}

							let age = moment().diff(moment(client.dateOfBirth), 'years')

							switch (clause.operator) {
								case 'lt':
									return age < clause.value
								case 'gt':
									return age > clause.value
								case 'eq':
									return age == clause.value
								case 'neq':
									return age != clause.value
							}

							return false
						})
						break
					case 'isInvestor':
						allClients = allClients.filter(client => {
							let isInvestor = false

							client.assets.forEach(assetOwnership => {
								if (client.__typename == 'Person') {
									if (assetOwnership.personRelation && assetOwnership.personRelation.isInvestment) {
										isInvestor = true
									}
								} else if (client.__typename == 'Company') {
									if (assetOwnership.companyRelation && assetOwnership.companyRelation.isInvestment) {
										isInvestor = true
									}
								}
							})

							return clause.value == isInvestor
						})
						break
				}
			})

			this.setState({
				isLoading: false,
				tableData: allClients
			})

		}
	}

	getRowData = (key, item, type) => {
		let returnObject = {}

		switch (key) {
			case 'name':
				let url = null

				if (item.people.length) {
					item.people.forEach(personRelation => {
						if(personRelation.person && !url){
							url = '/clients/people/' + personRelation.person.id	
						}
					})
				} else if (item.companies.length) {
					item.companies.forEach(companyRelation => {
						if(companyRelation.company && !url){
							url = '/clients/companies/' + companyRelation.company.id
						}
					})
				}

				if (url) {
					returnObject.label = (
						<Link to={url + '/liabilities/' + item.id}>
							{item.name ? item.name : '(No name)'}
						</Link>
					)
				}else{
					returnObject.label = '(No name)'
				}
				break
			case 'lender':
				if (item.loan) {
					returnObject.label = item.loan.lender.name
				} else {
					returnObject.label = '(None)'
					returnObject.empty = true
				}
				break
			case 'loan':
				if (item.loan) {
					returnObject.label = item.loan.name
				} else {
					returnObject.label = '(None)'
					returnObject.empty = true
				}
				break
			case 'interestRate':
				let interestRate = getCurrentEffectiveInterestRate(item)

				if (interestRate) {
					returnObject.label = interestRate + '%'
				} else {
					returnObject.label = '(None)'
					returnObject.empty = true
				}
				break
			case 'isFixed':
				let isFixed = currentFixedRatePeriod(item)

				if (isFixed) {
					returnObject.label = 'Fixed'
				} else {
					returnObject.label = 'Variable'
				}
				break
			case 'discount':
				if (item.discountPercent) {
					returnObject.label = item.discountPercent + '%'
				} else {
					returnObject.label = '0%'
					returnObject.empty = true
				}
				break
			case 'financePurpose':
				returnObject.label = capitaliseFirstLetter(item.financePurpose)
				break
			case 'initialBalance':
				if (item.initialBalance) {
					returnObject.label = '$' + numberWithCommas(item.initialBalance)
				} else {
					returnObject.label = '$0'
					returnObject.empty = true
				}
				break
			case 'applicants':
				returnObject.label = (
					<span>
						{item.people.map(applicant => {
							if (applicant.person) {
								return (
									<Link key={applicant.id} className="table-client" to={'/clients/people/' + applicant.person.id}>
										<img src="/images/icons/person.png" />
										{ getClientDisplayName(applicant.person) }
									</Link>
								)
							}
						})}
						{item.companies.map(applicant => {
							if (applicant.company) {
								return (
									<Link key={applicant.id} className="table-client" to={'/clients/companies/' + applicant.company.id}>
										<img src="/images/icons/company.png" />
										{applicant.company.name}
									</Link>
								)
							}
						})}
					</span>
				)
				break
			case 'clientName':
				if (item.__typename == 'Person') {
					returnObject.label = (
						<Link to={'/clients/people/' + item.id}>
							{ getClientDisplayName(item) ? getClientDisplayName(item) : '(No name)'}
						</Link>
					)
				} else if (item.__typename == 'Company') {
					returnObject.label = (
						<Link to={'/clients/companies/' + item.id}>
							{item.name ? item.name : '(No name)'}
						</Link>
					)
				} else {
					returnObject.label = ''
				}
				break
			case 'email':
				if (item.email) {
					returnObject.label = (
						<a href={'mailto:' + item.email}>
							{item.email}
						</a>
					)
				} else {
					returnObject.label = '(No email)'
					returnObject.empty = true
				}
				break
			case 'suburb':
				if (item.address && item.address.suburb) {
					returnObject.label = item.address.suburb
				} else {
					returnObject.label = '(No address)'
					returnObject.empty = true
				}
				break
			case 'state':
				if (item.address && item.address.state) {
					returnObject.label = item.address.state
				} else {
					returnObject.label = '(No state)'
					returnObject.empty = true
				}
				break
			case 'age':
				if (item.__typename == 'Company') {
					returnObject.label = 'N/A'
					returnObject.empty = true
				} else {
					if (item.dateOfBirth) {
						let age = moment().diff(moment(item.dateOfBirth), 'years')
						returnObject.label = age
					} else {
						returnObject.label = '(None)'
						returnObject.empty = true
					}
				}
				break
			case 'isInvestor':
				let isInvestor = false

				item.assets.forEach(assetOwnership => {
					if (item.__typename == 'Person') {
						if (assetOwnership.personRelation && assetOwnership.personRelation.isInvestment) {
							isInvestor = true
						}
					} else if (item.__typename == 'Company') {
						if (assetOwnership.companyRelation && assetOwnership.companyRelation.isInvestment) {
							isInvestor = true
						}
					}
				})

				returnObject.label = isInvestor ? 'True' : 'False'
				break
			case 'type':
				returnObject.label = item.__typename
				break
			case 'repaymentType':
				let isCurrentlyInterestOnly = false

				if (item.interestOnlyTerm) {
					// I guess it's technically IO, if there's a term but no start date.
					if (!item.startDate) {
						isCurrentlyInterestOnly = true
					} else {
						let startDate = moment(item.startDate)
						startDate.add(item.interestOnlyTerm, 'years')

						if (startDate.isAfter()) {
							isCurrentlyInterestOnly = true
						}
					}
				}

				returnObject.label = isCurrentlyInterestOnly ? 'Interest-only' : 'Principal & interest'
				break
			default:
				returnObject.label = 'Test'
		}

		return returnObject
	}

	getColumns = () => {
		let columns = []
		switch (this.state.filterOptionType) {
			case 'liabilities':
				columns = [
					{
						label: 'Name',
						type: 'value',
						key: 'name',
						canSort: true
					},
					{
						label: 'Lender',
						type: 'value',
						key: 'lender',
						canSort: true
					}, {
						label: 'Loan',
						type: 'value',
						key: 'loan',
						canSort: true
					}, {
						label: 'Effective interest rate',
						type: 'value',
						key: 'interestRate',
						canSort: true
					}, {
						label: 'Type',
						type: 'value',
						key: 'isFixed',
						canSort: true
					}, {
						label: 'Repayment type',
						type: 'value',
						key: 'repaymentType',
						canSort: false,
						isHidden: true
					}, {
						label: 'Discount',
						type: 'value',
						key: 'discount',
						canSort: true
					}, {
						label: 'Purpose',
						type: 'value',
						key: 'financePurpose',
						canSort: true
					}, {
						label: 'Initial balance',
						type: 'value',
						key: 'initialBalance',
						canSort: true
					}, {
						label: 'Applicant(s)',
						type: 'value',
						key: 'applicants',
						canSort: true
					}
				]
				break
			case 'clients':
				columns = [
					{
						label: 'Name',
						type: 'value',
						key: 'clientName',
						canSort: true
					},
					{
						label: 'Type',
						type: 'value',
						key: 'type',
						canSort: true
					},
					{
						label: 'Contact email',
						type: 'value',
						key: 'email',
						canSort: true
					},
					{
						label: 'Suburb',
						type: 'value',
						key: 'suburb',
						canSort: true
					},
					{
						label: 'State',
						type: 'value',
						key: 'state',
						canSort: true
					},
					{
						label: 'Age',
						type: 'value',
						key: 'age',
						canSort: true
					},
					{
						label: 'Has investment?',
						type: 'value',
						key: 'isInvestor',
						canSort: true
					}
				]
		}

		return columns
	}

	changeSorting = (type, key) => {
		const _sortingPrefs = this.props.sortingPrefs && this.props.sortingPrefs.sortingPrefs ? this.props.sortingPrefs.sortingPrefs : { column: 'name', direction: 'desc' }
		let sortingObject = cloneDeep(this.props.sortingPrefs.sortingPrefs.reporting)

		if (sortingObject.column == key) {
			sortingObject.direction = sortingObject.direction == 'desc' ? 'asc' : 'desc'
		} else {
			sortingObject = {
				column: key,
				direction: 'desc'
			}
		}

		//cause its done down here
		this.props.setSortingPref({
			variables: {
				path: 'reporting',
				...sortingObject
			}
		})
	}

	getComparedRelations = (a, b, direction) => {
		let firstObject = a
		let secondObject = b

		if (direction == 'asc') {
			firstObject = b
			secondObject = a
		}

		return {
			a: firstObject,
			b: secondObject
		}
	}

	sort = (rows, type = null) => {
		let clonedRows = cloneDeep(rows)
		let sortingType = this.props.sortingPrefs.sortingPrefs.reporting

		switch (sortingType.column) {
			case 'name':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aName = relations.a.name
					let bName = relations.b.name

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'lender':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aName = relations.a.loan ? relations.a.loan.lender.name.toLowerCase() : null
					let bName = relations.b.loan ? relations.b.loan.lender.name.toLowerCase() : null

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'loan':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aName = relations.a.loan ? relations.a.loan.name.toLowerCase() : null
					let bName = relations.b.loan ? relations.b.loan.name.toLowerCase() : null

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'interestRate':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aRate = getCurrentEffectiveInterestRate(relations.a)
					let bRate = getCurrentEffectiveInterestRate(relations.b)

					if (aRate < bRate) return -1
					if (aRate > bRate) return 1
					return 0
				})
				break
			case 'isFixed':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aFixed = relations.a.isFixed
					let bFixed = relations.b.isFixed

					if (aFixed < bFixed) return -1
					if (aFixed > bFixed) return 1
					return 0
				})
				break
			case 'discount':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aDiscount = relations.a.discountPercent
					let bDiscount = relations.b.discountPercent

					if (aDiscount < bDiscount) return -1
					if (aDiscount > bDiscount) return 1
					return 0
				})
				break
			case 'amount':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aAmount = relations.a.amount
					let bAmount = relations.b.amount

					if (aAmount < bAmount) return -1
					if (aAmount > bAmount) return 1
					return 0
				})
				break
			case 'financePurpose':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aPurpose = relations.a.amount
					let bPurpose = relations.b.amount

					if (aPurpose < bPurpose) return -1
					if (aPurpose > bPurpose) return 1
					return 0
				})
				break
			case 'initialBalance':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aBalance = relations.a.initialBalance
					let bBalance = relations.b.initialBalance

					if (aBalance < bBalance) return -1
					if (aBalance > bBalance) return 1
					return 0
				})
				break
			case 'clientName':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aName = relations.a.name ? relations.a.name.toLowerCase() : getClientDisplayName(relations.a, true).toLowerCase()
					let bName = relations.b.name ? relations.b.name.toLowerCase() : getClientDisplayName(relations.b, true).toLowerCase()

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'type':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aType = relations.a.__typename
					let bType = relations.b.__typename

					if (aType < bType) return -1
					if (aType > bType) return 1
					return 0
				})
				break
			case 'email':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aEmail = relations.a.email
					let bEmail = relations.b.email

					if (aEmail < bEmail) return -1
					if (aEmail > bEmail) return 1
					return 0
				})
				break
			case 'suburb':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aSuburb = (relations.a.address && relations.a.address.suburb) ? relations.a.address.suburb : ''
					let bSuburb = (relations.b.address && relations.b.address.suburb) ? relations.b.address.suburb : ''

					if (aSuburb < bSuburb) return -1
					if (aSuburb > bSuburb) return 1
					return 0
				})
				break
			case 'state':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aState = (relations.a.address && relations.a.address.state) ? relations.a.address.state : ''
					let bState = (relations.b.address && relations.b.address.state) ? relations.b.address.state : ''

					if (aState < bState) return -1
					if (aState > bState) return 1
					return 0
				})
				break
			case 'age':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aAge = relations.a.dateOfBirth ? moment().diff(moment(relations.a.dateOfBirth), 'years') : 0
					let bAge = relations.b.dateOfBirth ? moment().diff(moment(relations.b.dateOfBirth), 'years') : 0

					if (aAge < bAge) return -1
					if (aAge > bAge) return 1
					return 0
				})
				break
			case 'isInvestor':
				clonedRows.sort((a, b) => {
					const relations = this.getComparedRelations(a, b, sortingType.direction)

					let aIsInvestor = false 
					let bIsInvestor = false 

					relations.a.assets.forEach(assetOwnership => {
						if (relations.a.__typename == 'Person') {
							if (assetOwnership.personRelation && assetOwnership.personRelation.isInvestment) {
								aIsInvestor = true
							}
						} else if (relations.a.__typename == 'Company') {
							if (assetOwnership.companyRelation && assetOwnership.companyRelation.isInvestment) {
								aIsInvestor = true
							}
						}
					})

					relations.b.assets.forEach(assetOwnership => {
						if (relations.b.__typename == 'Person') {
							if (assetOwnership.personRelation && assetOwnership.personRelation.isInvestment) {
								bIsInvestor = true
							}
						} else if (relations.b.__typename == 'Company') {
							if (assetOwnership.companyRelation && assetOwnership.companyRelation.isInvestment) {
								bIsInvestor = true
							}
						}
					})

					if (aIsInvestor < bIsInvestor) return -1
					if (aIsInvestor > bIsInvestor) return 1
					return 0
				})
				break

		}

		return clonedRows
	}

	generateExportData = () => {
		let columnData = this.getColumns()

		let formattedTableData = [...this.generatedTableData]

		formattedTableData.forEach((row, rowIndex) => {
			row.forEach((column, columnIndex) => {
				if (typeof (column) == 'object') {
					if (column.type == 'span') {
						let value = ''

						// kinda gross, but just navigating react elements to pull out strings
						column.props.children.forEach(childElement => {
							childElement.forEach(childChildElement => {
								if (childChildElement && childChildElement.props.children) {
									childChildElement.props.children.forEach(childChildChildElement => {
										if (typeof (childChildChildElement) == 'string') {
											if (value != '') {
												value += ', '
											}

											value += childChildChildElement
										}
									})
								}

							})
						})

						formattedTableData[rowIndex][columnIndex] = value
					} else if (column.props && column.props.children) {
						formattedTableData[rowIndex][columnIndex] = column.props.children
					}
				}
			})
		})

		let tableHeadData = []

		columnData.forEach(column => {
			tableHeadData.push(column.label)
		})

		formattedTableData.unshift(tableHeadData)

		this.formattedTableData = formattedTableData
		return formattedTableData
	}

	getFilterOptionsAsString = _ => {
		const {
			filterOptionType,
			filterOptions
		} = this.state

		let response = ''

		if(!filterOptions || !filterOptions.length){
			response = 'Showing all '+filterOptionType
		}else{
			response = 'Showing '+filterOptionType

			filterOptions.forEach((optionGroup, index) => {
				const {
					option
				} = optionGroup

				if(!index){
					response += ', where '
				}else{
					response += ' and '
				}

				const format = optionGroup.option.input.format
				const type = optionGroup.option.input.type

				response += option.label+' is '

				if(!option.hasOperators){
					if(type == 'text'){
						response += optionGroup.value
					}else{
						const selectValues = this.getSelectValues(option.key)

						const value = selectValues.find(selectValue => {
							return selectValue.value === optionGroup.value
						})

						if(value && value.label){
							response += value.label
						}else{
							console.log('no label!')
							console.log(value)
						}
					}
				}else{

					const selectValues = this.getSelectValues(type == 'date' ? 'date-operator' : 'operator')

					const value = selectValues.find(selectValue => {
						return selectValue.value == optionGroup.operator
					})

					const valueString = type == 'date' ? optionGroup.value.format('Do MMMM, YYYY') : optionGroup.value

					response += value.label+' '

					if(format == 'money'){
						response += '$'
					}
					
					response += valueString

					if(format == 'percent'){
						response += '%'
					}
				}
			})
		}

		return response
	}

	exportData = format => {
		let title = ''

		switch (this.state.filterOptionType) {
			case 'liabilities':
				title = 'Zamm liability report, '
				break
			case 'clients':
				title = 'Zamm client report, '
				break
		}

		title += moment().format('D MMMM Y')

		const criteriaString = this.getFilterOptionsAsString()

		this.generateExportData()

		switch (format) {
			case 'csv':
				exportToExcel(this.formattedTableData, title, criteriaString)
				break
			case 'pdf':
				let columns = this.formattedTableData[0]
				let rows = cloneDeep(this.formattedTableData)

				rows.shift()

				rows.forEach((row,index) => {
					row.forEach((column, columnIndex) => {
						let newString = null

						switch(column){
							case 'Principal & interest':
								newString = 'P&I'
								break
							case 'Interest-only':
								newString = 'IO'
								break
							case 'Investment':
								newString = 'INV'
								break
							case 'Owner-occupier':
								newString = 'OO'
								break
						}

						if(newString){
							rows[index][columnIndex] = newString
						}
					})
				})

				let doc = new jsPDF('l','pt')

				doc.setFontSize(14)

				const lines = doc.splitTextToSize(criteriaString, 800)

				const startY = 45 + (lines.length * 20)

				doc.text(lines, 40, 50)

				doc.autoTable(columns, rows, { startY })
				doc.save(title+'.pdf')

				break
		}
	}

	clearFilters = _ => {
		this.setState({
			filterOptions: []
		}, _ => {
			this.queryResults()
		})
	}

	render() {
		const {
			sortingPrefs,
			getLiabilities
		} = this.props

		const {
			filterOptionType,
			showResultsForAll,
			filterOptions,
			isLoading,
			tableData
		} = this.state

		this.filterOptions = {
			liabilities: [
				{
					key: 'lender',
					label: 'lender',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				},
				{
					key: 'purpose',
					label: 'purpose',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				},
				{
					key: 'isFixed',
					label: 'loan type',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				},
				{
					key: 'isInterestOnly',
					label: 'repayment',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				},
				{
					key: 'interestRate',
					label: 'interest rate',
					hasOperators: true,
					input: {
						type: 'number',
						format: 'percent'
					}
				},
				{
					key: 'fixedRate',
					label: 'fixed rate end',
					hasOperators: true,
					input: {
						type: 'date'
					}
				},
				{
					key: 'discount',
					label: 'discount',
					hasOperators: true,
					input: {
						type: 'number',
						format: 'percent'
					}
				},
				{
					key: 'amount',
					label: 'amount',
					hasOperators: true,
					input: {
						type: 'number',
						format: 'money'
					}
				},
				{
					key: 'endDate',
					label: 'end date',
					hasOperators: true,
					input: {
						type: 'date'
					}
				},
				{
					key: 'startDate',
					label: 'start date',
					hasOperators: true,
					input: {
						type: 'date',
					}
				}
			],
			clients: [
				{
					key: 'suburb',
					label: 'suburb',
					hasOperators: false,
					input: {
						type: 'text',
					}
				},
				{
					key: 'state',
					label: 'state',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				},
				{
					key: 'age',
					label: 'age',
					hasOperators: true,
					input: {
						type: 'number',
					}
				},
				{
					key: 'isInvestor',
					label: 'has investments',
					hasOperators: false,
					input: {
						type: 'select',
						options: []
					}
				}
			]
		}

		let typeSelectValue = {
			label: filterOptionType,
			value: filterOptionType
		}

        let switchOptions = [
            {
                label: 'All brokers',
                value: true
            },{
                label: 'My book only',
                value: false
            }
		]

		return (
			<div className="reporting">
				<div className="heading-section">
					<h1>
						Reporting
					</h1>
					<div className="controls">
						{ isAdmin(this.props.client) ?
							<SwitchInput 
								changeCallback={value => {this.handleChange('showResultsForAll', value)}} 
								values={switchOptions} 
								value={showResultsForAll}  
							/>
							: null
						}
						<Button 
							disabled={!filterOptions.length} 
							label="Clear filters" 
							callback={this.clearFilters} 
						/>
						<Button 
							label="Export to Excel" 
							callback={() => { this.exportData('csv') }} 
						/>
						<Button 
							label="Export to PDF" 
							callback={() => { this.exportData('pdf') }} 
						/>
					</div>
				</div>
				<div className="main-content-container">
					<div className="content-box">
						<div className="reporting-filter-container">
							<span>
								Show me:
							</span>
							<Select 
								className="select" 
								onChange={this.changeFilterOptionType} 
								value={typeSelectValue} 
								options={this.getSelectValues('filterOptionsType')} 
							/>
							{filterOptions.map((option, index) => {
								let keyValue = {
									label: option.option.label,
									value: option.option.key
								}

								let operatorString = 'operator'
								let operatorValue = null
								let operatorOptions = null

								if(option.option.input.type == 'date'){
									operatorString = 'date-operator'
								}

								if (option.option.hasOperators) {
									operatorOptions = this.getSelectValues(operatorString)

									operatorOptions.forEach(operatorOption => {
										if (operatorOption.value === option.operator) {
											operatorValue = operatorOption
										}
									})
								}

								let valueComponent = null

								switch (option.option.input.type) {
									case 'select':
										const values = this.getSelectValues(option.option.key)

										const selectedValue = (option.value || option.value === false) ? values.find(value => {
											return value.value === option.value
										}) : undefined

										valueComponent = (
											<Select 
												className="select" 
												onChange={value => { this.handleChange(option.option.input.type, value.value, index) }} 
												value={typeof selectedValue !== 'undefined' ? selectedValue : null} 
												options={values} 
											/>
										)
										break
									case 'number':
										valueComponent = (
											<div 
												className={"number-input " + option.option.input.format}>
												<input 
													type="number" 
													min="0" 
													onChange={e => { this.handleChange(option.option.input.type, e.target.value, index) }} 
													value={option.value ? option.value : ''} 
												/>
											</div>
										)
										break
									case 'text':
										valueComponent = (
											<div className="text-input">
												<input 
													type="text" 
													onChange={e => { this.handleChange(option.option.input.type, e.target.value, index) }} 
													value={option.value ? option.value : ''} 
												/>
											</div>
										)
										break
									case 'date':
										valueComponent = (
											<DatePicker 
												selected={option.value} 
												onChange={value => { this.handleChange(option.option.input, value, index) }} 
											/>
										)
										break
								}

								let isValid = this.checkValidity(index)

								let className = 'clause '

								if (!isValid) {
									className += 'invalid'
								}

								return (
									<div key={option.option.key} className={className} style={{
										zIndex: filterOptions.length - index
									}}>
										<button className="remove-clause" onClick={() => { this.removeClause(index) }}>
											&times;
										</button>
										<span>
											{index == 0 ? 'where' : 'and '}
										</span>
										<Select 
											className="select" 
											onChange={value => { this.changeClauseType(value, index) }} 
											value={keyValue} 
											options={this.getSelectValues('filterOptions', index)} 
										/>
										<span>
											is
										</span>
										{option.option.hasOperators ?
											<Select 
												className="select" 
												onChange={value => { this.changeClauseOperator(value, index) }} 
												value={operatorValue} 
												options={this.getSelectValues(operatorString)} 
											/>
											:
											null
										}
										{valueComponent}
									</div>
								)
							})}
							{
								this.canAddClause() ?
									<Button 
										icon="plus" 
										level="x-low" 
										label={`ADD`} 
										callback={this.addClause} 
									/>
									: null
							}
						</div>
						<Table 
							generatedTableData={this.generatedTableData} 
							isLoading={isLoading} 
							changeSortingCallback={this.changeSorting} 
							sorting={(sortingPrefs && sortingPrefs.sortingPrefs) ? sortingPrefs.sortingPrefs.reporting : {
								column: 'name',
								direction: 'desc'
							}} 
							columns={this.getColumns()} 
							data={this.sort(tableData)} 
							getter={this.getRowData} 
							shouldPaginate={false}
						/>
						{
							(isLoading || getLiabilities.loading) ?
								<div className="table-loader-container">
									<Loader isLoading={true} showText={true} />
								</div>
								: null
						}
					</div>
				</div>
			</div>
		)

	}

}

export default withApollo(compose(
	graphql(GET_LENDERS, { name: 'getLenders' }),
	graphql(GET_REPORTING_LIABILITIES, { name: 'getLiabilities' }),
	graphql(GET_REPORTING_PEOPLE, { name: 'getPeople' }),
	graphql(GET_REPORTING_COMPANIES, { name: 'getCompanies' }),
	graphql(GET_SORTING_PREF, { name: 'sortingPrefs'}),
	graphql(SET_SORTING_PREF, { name: 'setSortingPref'}),
)(Reporting))
