import React, { Component } from 'react'
import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import { withApollo } from 'react-apollo'
import { GET_LENDERS } from 'graphql/loans-graphql'
import { isEqual, cloneDeep } from 'lodash'
import FontAwesome from 'react-fontawesome'

import { removeSoftDeletedItems } from 'utils/utils'

class RatesOverview extends Component {
    state = {
        hoveredColumnIndex: null,
        search: '',
        sorting: null,
        chartData: {
            data: [],
            max: 0
        },
        lenders: null,
        scrollLeft: 0
    }

    chartsRef = []
    chartContainerRef = []
    slideValue = 150

    componentWillMount(){
        this.props.client.query({
            query: GET_LENDERS
        }).then(data => {
            this.setState({
                lenders: removeSoftDeletedItems(data.data.lenders)
            }, () => {
                this.generateChartData(this.state)
            })
        })
    }

    componentWillUpdate = (nextProps, nextState) => {
        if(!isEqual(this.state, nextState)){
            this.generateChartData(nextState)
        }
    }

    generateChartData = state => {
        let chartData = []
        let chartMax = 0

        if(!state.lenders){
            return []
        }

        state.lenders.forEach(lender => {

            if(!lender.loans.length){
                return
            }

            let lowercaseName = lender.name.toLowerCase()
            let lowercaseSearch = state.search.toLowerCase()

            if(!lowercaseName.includes(lowercaseSearch)){
                return
            }

            let lenderObject = {
                name: lender.name,
                id: lender.id,
                loans: []
            }

            let ratesKeys = [
                {
                    key: 'interestRatesIVIO',
                    suffix: 'Investment (Interest-only)'
                },
                {
                    key: 'interestRatesIVPI',
                    suffix: 'Investment (Principal & interest)'
                },
                {
                    key: 'interestRatesOOIO',
                    suffix: 'Owner-occupier (Interest-only)'
                },
                {
                    key: 'interestRatesOOPI',
                    suffix: 'Owner-occupier (Principal & interest)'
                },
            ]

            lender.loans.forEach(loan => {
                ratesKeys.forEach(rateKey => {
                    let loanRates = loan[rateKey.key]

                    if(loanRates.length){
                        let lastRate = loanRates[(loanRates.length - 1)].rate

                        if(!lastRate){
                            return
                        }

                        if(lastRate > chartMax){
                            chartMax = lastRate
                        }

                        let loanObject = {
                            name: loan.name+' '+rateKey.suffix,
                            id: loan.id+rateKey.suffix,
                            rate: lastRate
                        }
        
                        lenderObject.loans.push(loanObject)
                    }
                })
            })

            if(state.sorting =='high-low') {
                lenderObject.loans.sort((a,b) => {
                    return b.rate - a.rate
                })
            }else{
                lenderObject.loans.sort((a,b) => {
                    return a.rate - b.rate
                })
            }

            chartData.push(lenderObject)

        })

        this.setState({
            chartData: {
                data: chartData,
                max: chartMax
            }
        })
    }

    handleChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
            scrollLeft: 0
        })
    }

    doScroll = direction => {
        if(direction == 'right'){
            let containerEndPoint = this.chartContainerRef[0].getBoundingClientRect().width
            let itemsEndPoint = this.chartsRef[0].getBoundingClientRect().width - this.state.scrollLeft

            let spaceToRight = itemsEndPoint - containerEndPoint

            if(spaceToRight > 0){
                this.setState({
                    scrollLeft: spaceToRight > this.slideValue ? this.state.scrollLeft + this.slideValue : this.state.scrollLeft + spaceToRight
                })
            }
        }else{
            if(this.state.scrollLeft > 0){
                this.setState({
                    scrollLeft: this.state.scrollLeft > this.slideValue ? this.state.scrollLeft - this.slideValue : 0
                })
            }
        }
    }

    disabledCheck = direction => {
        if(!this.chartsRef[0]){
            return false
        }

        if(direction == 'left'){
            return this.state.scrollLeft == 0
        }else{
            let containerEndPoint = this.chartContainerRef[0].getBoundingClientRect().width
            let itemsEndPoint = (this.chartsRef[0].getBoundingClientRect().width + 20) - this.state.scrollLeft

            let spaceToRight = itemsEndPoint - containerEndPoint

            return !spaceToRight
        }
    }

    render(){
        let sortingOptions = [
            {
                label: 'Highest to lowest',
                value: 'high-low'
            },{
                label: 'Lowest to highest',
                value: 'low-high'
            }
        ]

        let switchOptions = [
            {
                label: 'Variable',
                value: false
            },{
                label: 'Fixed',
                value: true
            }
        ]

        // prolly gonna move this, but for now we're making a bar chart.
        let increments = []

        if(this.state.chartData.max){
            for(var i = 0; i < (Math.ceil(this.state.chartData.max) + 1); i++){
                increments.push('')
            }
        }

        let chartHeight = 300

        let rateCharts = [0, 1]

        return (
            <div className="dashboard-widget rates-overview">
                <div className="heading">
                    <h3>
                        Interest rates
                    </h3>
                    <div className="filter">
                        <TextInput changeCallback={value => {this.handleChange('search', value)}} value={this.state.search} type="search" />
                        <SelectInput changeCallback={value => {this.handleChange('sorting', value)}} values={sortingOptions} value={this.state.sorting} />
                        <div className="input-wrap">
                            <button className="scroll-controls" onClick={_ => {this.doScroll('left')}} disabled={this.disabledCheck('left')}>
                                <FontAwesome name="arrow-left" />
                            </button>
                            <button className="scroll-controls" onClick={_ => {this.doScroll('right')}} disabled={this.disabledCheck('right')}>
                                <FontAwesome name="arrow-right" />
                            </button>
                        </div>
                    </div>
                    <div className="bar-chart">
                        <ul className="increments">
                            {
                                increments.map((increment, index) => {
                                    let height = chartHeight/increments.length

                                    return (
                                        <li key={index} style={{height: height+'px'}}>
                                            {
                                                index == 0 ?
                                                <span>
                                                    { increments.length }
                                                </span>
                                                 : null
                                            }
                                            <span>
                                                { (increments.length - 1) - index }
                                            </span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        { rateCharts.map(chartIndex => {
                            let isGhosted = chartIndex ? false : true
                            let className = 'lender-groups'

                            if(isGhosted){
                                className += ' ghosted'
                            }

                            return (
                                <ul ref={ref => {this.chartContainerRef[chartIndex] = ref}} className={className} key={chartIndex}>
                                    <div style={{ transform: 'translateX(-'+this.state.scrollLeft+'px)' }} ref={ref => {this.chartsRef[chartIndex] = ref}}>
                                        {this.state.chartData.data.map((lender, index) => {
                                            return (
                                                <li key={index}>
                                                    <ul className="bar-container">
                                                        {lender.loans.map((loan, loanIndex) => {
                                                            let rateHeight = (chartHeight/(this.state.chartData.max + 1)) * loan.rate
            
                                                            return (
                                                                <li 
                                                                    key={loan.id} 
                                                                    onMouseEnter={_ => {
                                                                        this.setState({
                                                                            hoveredColumnIndex: index+'.'+loanIndex
                                                                        })
                                                                    }}
                                                                    onMouseLeave={_ => {
                                                                        this.setState({
                                                                            hoveredColumnIndex: null
                                                                        })
                                                                    }}
                                                                    className={
                                                                        this.state.hoveredColumnIndex === index+'.'+loanIndex ? 'hovered' : null
                                                                    }
                                                                >
                                                                    <div style={{height: rateHeight+'px'}}>
                                                                        { isGhosted ?
                                                                            <div className="hover-display">
                                                                                <span>
                                                                                    { loan.rate }%
                                                                                </span>
                                                                                <span>
                                                                                    { lender.name }
                                                                                </span>
                                                                                <span>
                                                                                    { loan.name }
                                                                                </span>
                                                                            </div>
                                                                        : null }
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <span>
                                                        { lender.name }
                                                    </span>
                                                </li>
                                            )
                                        })}
                                    </div>
                                </ul>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(RatesOverview)