import React, { Component } from 'react'

import Button from 'components/Button/Button'

import { graphql, compose } from 'react-apollo'

import { withApollo } from 'react-apollo'

import { UPSERT_COMPANY, INIT_DATA, OPEN_MODAL } from 'graphql/all'

import ModalMiniNew from '../ModalTypes/ModalMiniNew'

import ModalForm from '../ModalComponents/ModalForm'
import ValidationError from 'components/Errors/ValidationError'
import * as yup from 'yup'

import { companyTypeData, industryData } from 'utils/enums'

import { cloneDeep } from 'lodash'

import { 
    stripTypenamesFromMutations, 
    removeSoftDeletedItems,
    getClientDisplayName
} from 'utils/utils'

class ModalCompany extends Component {
    state = {
        fields: {
            id: null,
            name: '',
            tradingName: '',
            type: '',
            abn: '',
            acn: '',
            address: {},
            registrationDate: null,
            industry: '',
            phoneWork: '',
            website: '',
            email: '',
            contact: null,
            isContactExistingPerson: true,
            externalContactName: '',
            externalContactPhone: '',
            externalContactEmail: '',
            referrer: '',
            actingOnTrust: false,
            trustName: '',
            people: [],

        },
        hasErrors: false,
        addPersonModalVisible: false,
        errors: {}
    }

    addPersonModalType = null

    componentWillMount() {
        if(this.props.object){
            this.setState({
                fields: {
                    ...this.state.fields,
                    ...this.props.object
                }
            })
        }
        // add all people/companies - then seed the current user/company if not already there
        this.props.client.query({
            query: INIT_DATA
        }).then(data => {
            const allPeople = removeSoftDeletedItems(data.data.user.people)

            this.setState({
                allPeople
            },() => {
    
            })
        })
       
    }

    getValue = type => {
        let responseObject = null

        switch(type){
            case 'people':
                if(this.state.allPeople){
                    if(!this.state.allPeople.length){
                        responseObject = [{
                            label: 'No people',
                            value: undefined,
                            hide: true
                        }]
                    }else{
                        responseObject = []

                        console.log(this.state)

                        this.state.allPeople.forEach(person => {
                            responseObject.push({
                                label: getClientDisplayName(person),
                                value: person.id,
                                isDisabled: this.state.fields.people.find(loopedPerson => {
                                    return loopedPerson.id == person.id
                                })
                            })
                        })

                        responseObject.sort((a, b) => {
                            if(a.label < b.label) return -1
                            if(a.label > b.label) return 1
                            return 0
                        })

                        responseObject.unshift({
                            label: '(Create new person)',
                            value: 'create'
                        })

                        responseObject.unshift({
                            label: '(Select person)',
                            value: undefined
                        })
                    }
                }     
            break
        }

        if(!responseObject){
            return [{
                label: 'Loading...',
                value: undefined
            }]
        }else{
            return responseObject
        }
    }

    handleChange = (key, value) => {
        if(key === 'people' && value.length && value[0].id === 'create'){
            this.addPersonModalType = key

            return this.setState({
                addPersonModalVisible: true
            })
        }

        this.setState({
            fields: {
                ...this.state.fields,
                [key]: value
            }
        })
    }

    handleError = error => {
        const errors = error.inner


        let newErrorState = {}

        errors.forEach(error => {
            newErrorState[error.path] = error.message
        })

        this.setState({
            hasErrors: true,
            errors: newErrorState
        }, () => {
            console.log(this.state)
        })
    }
    
    handleSubmit = async (openNextModal = false) => {
        const {
            history,
            isLoading,
            setLoading
        } = this.props

        if(isLoading){
            return 
        }
        
        const validationSchema = yup.object().shape({
            name: yup.string().required('Please enter Company name.'),
            externalContactEmail: yup.string().nullable().email("That doesn't look like a valid email."),
            email: yup.string().nullable().email("That doesn't look like a valid email.")
        });

        try {
            await validationSchema.validate({ ...this.state.fields }, { abortEarly: false})
        } catch (error) {
            return this.handleError(error)
        }

        setLoading(true)

        this.setState({
            hasErrors: false
        })

        const fields = stripTypenamesFromMutations(this.state.fields)

        delete fields.events
        delete fields.notes
        delete fields.broker

        if(fields.liabilities){
            delete fields.liabilities
        }

        if(fields.assets){
            delete fields.assets
        }

        if(fields.contact && fields.contact.id){
            fields.contact = fields.contact.id
        }

        if(fields.referrer && fields.referrer.id){
            fields.referrer = fields.referrer.id
        }

        let people = []

        fields.people.forEach(person => {
            people.push({
                id: person.id
            })
        })

        fields.people = people

        try{
            await this.props.upsertCompany({
                variables: {
                    payload: {
                        ...fields
                    },
                },
                update: (store, { data }) => {
                    const initDataQuery = store.readQuery({query: INIT_DATA})
                    const { upsertCompany } = data

                    if(!initDataQuery.user.companies.find(company => {
                        return company.id == upsertCompany.id
                    })){
                        initDataQuery.user.companies.push(upsertCompany)
                        store.writeQuery({query: INIT_DATA, data: initDataQuery})
                    }
                },
            }).then(data => {
                const companyData = data.data.upsertCompany

                this.props.handleSuccess(() => {
                    if(openNextModal){
                        this.doOpenNextModal(companyData)
                    }else{
                        history.push(`/clients/companies/${ companyData.id }`)
                    }
                })
            })
        }catch(error){
            console.log(error)
            this.props.setLoading(false)
        }
    }

    doOpenNextModal = company => {
        setTimeout(() => {
            this.props.openModal({variables: {
            operation: 'add',
            dataType: 'asset',
            isVisible: true,
            object: {
                personId: null,
                companyId: company.id
            }
            }})
        },300)
    }

    addPersonModalCallback = data => {
        if(!data){
            return this.setState({
                addPersonModalVisible: false
            })
        }

        if(data.data.upsertPerson){
            const { allPeople } = cloneDeep(this.state)

            allPeople.push(data.data.upsertPerson)

            const personId = data.data.upsertPerson.id

            const people = this.state.people ? [...this.state.people] : []

            people.push({
                id: data.data.upsertPerson.id
            })

            return this.setState({
                fields: {
                    ...this.state.fields,
                    people
                },
                allPeople,
                addPersonModalVisible: false
            })
        }
    }

    render(){
        const {
            addPersonModalVisible
        } = this.state

        this.fieldColumns = [
            [
                [{
                    label: 'Company Name',
                    type: 'text',
                    required: true,
                     props: { type: 'text' },
                    key: 'name'
                }],[{
                    label: 'Trading Name',
                    type: 'text',
                     props: { type: 'text' },
                    key: 'tradingName'
                },{
                    label: 'Type',
                    type: 'select',
                    props: {
                        values: companyTypeData.companyTypes
                    },
                    key: 'type'
                }],[{
                    label: 'ABN',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'abn'
                }],[
                    {
                        label: 'ACN',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'acn'
                    }],
                    [{
                        label: 'Primary Address',
                        type: 'address',
                        props:{ canOffPlan: false },
                        key: 'address'
                    }
                ],[{
                    label: 'Registration Date',
                    type:'text',
                     props: { type: 'date' },
                    key: 'registrationDate',
                    value: null
                }],
                [{
                    label: 'People',
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'select', 
                                props: {
                                    values: this.getValue('people')
                                },
                                key: 'id',
                            }
                        ],
                        buttonLabel: 'Add person'
                    },
                    key: 'people'
                }],
                [{
                    label: 'Industry',
                    type: 'select',
                    props: {
                        values: industryData.industries
                    },
                    key: 'industry'
                }]
                ],[
                [{
                    label: 'Phone Number',
                    type: 'text',
                     props: { type: 'text' },
                    
                    key: 'phoneWork'
                }],
                [{
                    label: 'Website',
                    type: 'text',
                     props: { type: 'text' },
                    
                    key: 'website'
                }],
                [{
                    label: 'Company email',
                    type: 'text',
                     props: { type: 'email' },
                    key: 'email'
                }],
                [{
                    label: 'Point of Contact',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: true,
                                label: 'Existing Client'
                            },{
                                value: false,
                                label: 'Other'
                            },
                         
                        ]
                    },
                    key: 'isContactExistingPerson'
                }],
                [{
                    label: 'Contact',
                    type: 'select', 
                    props: {
                        values: this.getValue('people')
                    },
                    key: 'contact',
                    isHidden: !this.state.fields.isContactExistingPerson
                }],
                [{
                    label: 'Contact full name',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'externalContactName',
                    isHidden: this.state.fields.isContactExistingPerson
                }],
                [{
                    label: 'Contact phone Number',
                    type: 'text',
                     props: { type: 'text' },
                   
                    key: 'externalContactPhone',
                    isHidden: this.state.fields.isContactExistingPerson
                }],
                [{
                    label: 'Contact email',
                    type: 'text',
                     props: { type: 'email' },
                    key: 'externalContactEmail',
                    isHidden: this.state.fields.isContactExistingPerson
                }],
                [{
                    label: 'Referred By',
                    type: 'select',
                    props: {
                        values: this.getValue('people')
                    },
                    key: 'referrer'
                }],
                [{
                    label: 'Acting On Trust',
                    type: 'checkbox',
                    props: { type: 'checkbox' },
                    key: 'actingOnTrust'
                }],
                [{
                    label: 'Trust Name',
                    type: 'text',
                     props: { type: 'text' },
                    key: 'trustName'
                }],
            ]
        ]

        console.log(addPersonModalVisible)

        return (
            <div className="wide">
                <ModalMiniNew
                    isVisible={addPersonModalVisible} 
                    callback={data => {this.addPersonModalCallback(data, this.addPersonModalType)}} 
                />
                <div className="content-container">
                    <ModalForm errors={this.state.errors} handleChange={this.handleChange} fieldState={this.state.fields} columns={this.fieldColumns} />
                </div>
                <div className="button-row">
                    <ValidationError error={this.state.hasErrors ? "There were errors in your submission." : null} />
                    { (!this.props.object || !this.props.object.id) ?
                        <Button icon="arrow-right" label="Add asset" level="high" callback={() => {this.handleSubmit(true)}} />
                        : null
                    }
                    <Button label="Save &amp; exit" level="high" callback={() => {this.handleSubmit(false)}} />
                    <Button label="Cancel" level="low" callback={this.props.closeCallback} />
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(UPSERT_COMPANY, {name: 'upsertCompany'}),
    graphql(OPEN_MODAL, {name: 'openModal'})
) (ModalCompany)
