import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import Button from 'components/Button/Button'
import TableRow from './TableRow'

import { OPEN_MODAL } from 'graphql/modal-graphql'

import { withApollo } from 'react-apollo'

import { clone, cloneDeep, chunk } from 'lodash'

import { pluraliseObject } from 'utils/utils'

class Table extends Component {
    state = {
        openMenu: null,
        selectedRows: [],
        pageIndex: 0
    }

    rowNodeRefs = []
    rows = []
    pageCount = 1

    shouldComponentUpdate = nextProps => {
        if(this.props.searchDisplayValue != nextProps.searchDisplayValue){
            return false
        }

        return true
    }

    componentWillMount(){
        document.addEventListener('click', this.handleClickOutside, false)

        this.processRows()
    }
  
    componentWillUnmount(){
      document.removeEventListener('click', this.handleClickOutside, false)
    }

    componentWillUpdate = (nextProps, nextState) => {
        this.processRows(nextProps, nextState)
    }

    processRows = (props = this.props, state = this.state) => {
        const {
            data,
            shouldPaginate = true,
            hiddenBySearch,
            hideValuesUntilSearch,
            searchValue
        } = props

        const {
            pageIndex
        } = state

        if(!data || !data.length || (hideValuesUntilSearch && !searchValue)){
            return this.rows = []
        }

        const pageLength = 30

        const searchFilteredRows = data.filter(row => {
            return !hiddenBySearch || !hiddenBySearch(row)
        })

        const pages = chunk(searchFilteredRows, shouldPaginate ? pageLength : 9999)

        const resetPages = ((pageIndex && !pages[pageIndex]) || !shouldPaginate || searchFilteredRows.length < pageLength)

        if(resetPages && this.state.pageIndex){
            this.pageCount = 1

            this.setState({
                pageIndex: 0
            })

            return this.rows = pages[0]
        }else{
            this.rows = pages[pageIndex] || []
            this.pageCount = pages.length

            return
        }
    }

    handleClickOutside = e => {
      if(this.state.openMenu !== null && this.rowNodeRefs.length && !this.rowNodeRefs[this.state.openMenu].contains(e.target)){
        this.toggleMenu(null);
      }
    }

    selectRow = row => {
        let selectedRows = [...this.state.selectedRows]

        const selectedRowIndex = selectedRows.findIndex(selectedRow => {
            return selectedRow.id === row.id
        })

        if(selectedRowIndex === -1){
            selectedRows.push(row)
        }else{
            selectedRows.splice(selectedRowIndex, 1)
        }

        this.setState({
            selectedRows
        })
    }

    toggleMenu = (index) => {
        if(this.state.openMenu === index){
            this.setState({
                openMenu: null
            })
        }else{
            this.setState({
                openMenu: index
            })
        }
    }

    bulkDelete = () => {
        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'delete',
                dataType: this.props.dataType,
                isVisible: true,
                object: this.state.selectedRows
            }
        })

        this.setState({
            selectedRows: []
        })
    }

    setPage = pageIndex => {
        this.setState({
            pageIndex
        })
    }

    render(){
        const { 
            columns, 
            getter, 
            className, 
            dataType,
            onlyShow,
            rowInputRefs = [],
            sorting,
            headingChangeCallback,
            addRowCallback,
            removeRowCallback,
            isLoading,
            changeSortingCallback,
            generatedTableData,
            hideValuesUntilSearch,
            searchValue
        } = this.props

        const {
            openMenu,
            selectedRows,
            pageIndex
        } = this.state

        let pluralisedDataType = pluraliseObject(dataType)

        let headingIndexIterator = -1
        let childIterator = -1

        const waitingForInput = (hideValuesUntilSearch && !searchValue)

        return (
            <div>
                <table className={className ? "list-table "+className : "list-table"}>
                    {onlyShow !== 'body' ?
                        <thead>
                            <tr>
                                {columns.map((column, index) => {
                                    if(column.isHidden ){
                                        return null
                                    }
                                    
                                    let isSortingByColumn = sorting && sorting.column == column.key ? true : false 

                                    let className = ''

                                    if(column.canSort){
                                        className += ' sortable'
                                    }

                                    if(isSortingByColumn){
                                        className += ' sortingBy'
                                    }

                                    return (
                                        <td className={className} key={index} onClick={() => column.canSort ? changeSortingCallback(dataType, column.key) : null}>
                                            { column.label }
                                            {
                                                isSortingByColumn ?
                                                <span className="sorting">
                                                    <FontAwesome name={sorting.direction == 'desc' ? 'angle-down' : 'angle-up'} />
                                                </span>
                                                : null
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        </thead>
                    : null }
                    {(onlyShow !== 'head' && !isLoading) ? 
                        <tbody>
                            {this.rows.map((item, rowIndex) => {
                                if(!item){
                                    return null
                                }
        
                                if(item.isHeading){
                                    headingIndexIterator++
                                    childIterator = -1
        
                                    let thisHeadingIndex = clone(headingIndexIterator)
        
                                    return (
                                        <tr key={rowIndex} className="heading-row">
                                            <td colSpan={columns.length}>
                                                <input 
                                                    ref={ref => rowInputRefs[rowIndex] = ref} 
                                                    type="text" 
                                                    value={item.name} 
                                                    onChange={e => {headingChangeCallback(thisHeadingIndex, e.target.value)}} 
                                                    placeholder="Lender name" 
                                                />
                                                <Button 
                                                    icon="plus" 
                                                    level="x-low" 
                                                    label="Add loan" 
                                                    callback={_ => addRowCallback(thisHeadingIndex)} 
                                                />
                                                <div className="mini-actions">
                                                    <button onClick={_ => { removeRowCallback(thisHeadingIndex) }}>
                                                        <FontAwesome name="trash" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
        
                                childIterator++
        
                                let thisHeadingIndex = clone(headingIndexIterator)
                                let thisChildIndex = clone(childIterator)

                                if(generatedTableData){
                                    generatedTableData.push([])
                                }

                                const key = item.id ? item.id : rowIndex
        
                                return (
                                    <TableRow 
                                        key={key} 
                                        columns={columns}
                                        generatedTableData={generatedTableData}
                                        selectedRows={selectedRows}
                                        selectRow={this.selectRow}
                                        openMenu={openMenu}
                                        toggleMenu={this.toggleMenu}
                                        rowIndex={rowIndex}
                                        getter={getter}
                                        item={item}
                                        childIterator={childIterator}
                                        thisHeadingIndex={thisHeadingIndex}
                                        thisChildIndex={thisChildIndex}
                                        rowNodeRefs={this.rowNodeRefs}
                                    />
                                )
                            })}
                            { waitingForInput ?
                                <tr>
                                    <td className="empty awaiting-input" colSpan={columns.length}>
                                        <p>
                                            Enter a search term to get started.
                                        </p>
                                    </td>
                                </tr>
                            :
                                !this.rows.length ?
                                    <tr>
                                        <td className="empty" colSpan={columns.length}>
                                            <p>
                                                No data
                                            </p>
                                        </td>
                                    </tr>
                                :
                                null
                            }
                        </tbody>
                    : null }
                    { this.state.isLoading ?
                        <tbody>
                            <tr>
                                <td className="empty" colSpan={columns.length}>
                                    <p>
                                        Loading...
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    : null }
                    { (this.pageCount && this.pageCount > 1) ?
                        <tbody>
                            <tr>
                                <td colSpan={999} className="table-paginator">
                                    <ul className="paginator">
                                        <li>
                                            <button
                                                className={!pageIndex ? 'disabled' : null}
                                                onClick={_ => {
                                                    if(pageIndex){
                                                        this.setPage(pageIndex - 1)
                                                    }
                                                }}
                                            >
                                                ‹
                                            </button>
                                        </li>
                                        { Array.from(Array(this.pageCount), (_, i) => {
                                            return (
                                                <li key={i}>
                                                    <button 
                                                        className={pageIndex == i ? 'active' : null}
                                                        onClick={_ => this.setPage(i)}
                                                    >
                                                        { i + 1 }
                                                    </button>
                                                </li>
                                            )
                                        })}
                                        <li>
                                            <button
                                                className={((pageIndex + 1) == this.pageCount) ? 'disabled' : null}
                                                onClick={_ => {
                                                    if(pageIndex < (this.pageCount - 1)){
                                                        this.setPage(pageIndex + 1)
                                                    }
                                                }}
                                            >
                                                ›
                                            </button>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    : null }
                </table>
                <div className={selectedRows.length > 0 ? "bulk-table-delete visible" : "bulk-table-delete"}>
                    <button onClick={this.bulkDelete}>
                        <FontAwesome name="trash" />
                        <span>
                            Delete { selectedRows.length } { selectedRows.length == 1 ? dataType : pluralisedDataType }
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}

export default withApollo(Table)