import React, { Component } from 'react'
import Timeline from 'components/Timeline/Timeline'
import DetailsTable from 'components/DetailsTable/DetailsTable'
import { capitaliseFirstLetter, removeSoftDeletedItems, formatAddress } from 'utils/utils'
import moment from 'moment'
import Notes from 'components/Notes/Notes'
import { Link } from 'react-router-dom'

class Overview extends Component{
    constructor(props){
        super(props)

        if (this.props.data.person != undefined) {

            this.tableData = [
                {
                    title: "General info",
                    data: [
                        [
                            {
                                label: 'Current address',
                                key: 'address',
                            },{
                                label: 'Gender',
                                key: 'gender',
                            },{
                                label: 'Marital status',
                                key: 'maritalStatus',
                            },{
                                label: 'Dependants',
                                key: 'dependants'
                            }
                        ],[
                            {
                                label: 'Mobile phone number',
                                key: 'phoneMobile',
                            },{
                                label: 'Email address',
                                key: 'email',
                            },{
                                label: 'Date of birth',
                                key: 'dateOfBirth',
                            }
                        ]
                    ]
                }
            ]
        } else {
            this.tableData = [
                {
                    title: "Company Details",
                    data: [
                        [
                            {
                                label: 'Trading Name',
                                key: 'tradingName',
                            },{
                                label: 'Address',
                                key: 'address',
                            },{
                                label: 'Contact Number',
                                key: 'contactNumber',
                            },
                            {
                                label: 'Registration Date',
                                key: 'registrationDate',
                            }

                        ],[,{
                                label: 'Company Email',
                                key: 'email',
                            },{
                                label: 'Website',
                                key: 'website',
                            },{
                                label: 'Representative',
                                key: 'representative',
                            }
                        ]
                    ]
                },
                {
                    title: "Account Details",
                    data: [
                        [
                            {
                                label: 'Type',
                                key: 'type',
                            },{
                                label: 'ABN',
                                key: 'abn',
                            },{
                                label: 'ACN',
                                key: 'acn',
                            },
                            {
                                label: 'Industry',
                                key: 'industry',
                            },
                            {
                                label: 'Number of Directors/Partners',
                                key: 'partners',
                            }                            

                        ],[
                            {
                                label: 'People',
                                key: 'people',
                            },{
                                label: 'Referred By',
                                key: 'referredBy',
                            },{
                                label: 'Acting on Behalf of Trust',
                                key: 'actingOnTrust',
                            },{
                                label: 'Trust Name',
                                key: 'trustName',
                            }
                        ]
                    ]
                }
            ]

        }
    }

    tableDataGetter = key => {
        let response

        if (this.props.data.person) {
            const { person } = this.props.data

            switch(key){
                case 'address':
                    response = formatAddress(person.address) ? formatAddress(person.address) : '(No address)'
                    break
                case 'gender':
                    response = capitaliseFirstLetter(person.gender)
                    break
                case 'maritalStatus':
                    response = capitaliseFirstLetter(person.maritalStatus)
                    break
                case 'phoneMobile':
                    response = person.phoneMobile
                    break
                case 'email':
                    response = person.email
                    break
                case 'dateOfBirth':
                    if(person.dateOfBirth){
                        const dateOfBirthMoment = moment(person.dateOfBirth)
                        response = dateOfBirthMoment.format('Do MMMM Y')

                        const age = moment().diff(dateOfBirthMoment, 'years')

                        response += ' ('+age+' years old)'
                    }
                    break
                case 'dependants':
                    if(person.dependants && person.dependants.length){
                        response = (
                            <ul className="table-list">
                                { person.dependants.map(dependant => {
                                    return (
                                        <li key={dependant.id}>
                                            <span>
                                                { dependant.name } { dependant.yearOfBirth ? `(Born ${dependant.yearOfBirth})` : null }
                                            </span>
                                        </li>
                                    )
                                }) }
                            </ul>
                        )
                    }else{
                        response = '(No dependants)'
                    }
                    break
            }
        } else {
            switch(key){
                case 'tradingName':
                    if(this.props.data.company.tradingName){
                        response = this.props.data.company.tradingName
                    }else{
                        response = '(No trading name)'
                    }
                    break
                case 'address':
                    response = formatAddress(this.props.data.company.address) ? formatAddress(this.props.data.company.address) : '(No address)'
                    break
                case 'contactNumber':
                    if(this.props.data.company.phoneWork){
                        response = this.props.data.company.phoneWork
                    }else{
                        response = '(No contact number)'
                    }
                    break
                case 'registrationDate':
                    response = moment(this.props.data.company.createdAt).format('Do MMMM Y')
                    break
                case 'email':
                    if(this.props.data.company.email){
                        response = this.props.data.company.email
                    }else{
                        response = '(No email)'
                    }
                    break
                case 'website':
                    if(this.props.data.company.website){
                        response = this.props.data.company.website
                    }else{
                        response = '(No website)'
                    }
                    break
                case 'representative':
                    if(this.props.data.company.contact){
                        let contact = this.props.data.company.contact

                        response = (
                            <Link to={'/clients/people/'+contact.id}>
                                { contact.firstName+' '+contact.lastName }
                            </Link>
                        )
                    }else if(this.props.data.company.externalContactName && this.props.data.company.externalContactEmail){
                        response = (
                            <a href={"mailto:"+this.props.data.company.externalContactEmail}>
                                { this.props.data.company.externalContactName }
                            </a>
                        )
                    }else{
                        response = '(No external contact name)'
                    }
                    break
                case 'type':
                    response = capitaliseFirstLetter(this.props.data.company.type)
                    break
                case 'abn':
                    if(this.props.data.company.abn){
                        response = this.props.data.company.abn
                    }else{
                        response = '(No ABN)'
                    }
                    break
                case 'acn':
                    if(this.props.data.company.acn){
                        response = this.props.data.company.acn
                    }else{
                        response = '(No ACN)'
                    }
                    break
                case 'industry':
                    response = capitaliseFirstLetter((this.props.data.company.industry).replace(/_/g, ' '))
                    break
                case 'referredBy':
                    let referrer = this.props.data.company.referrer

                    if(referrer){
                        response = (
                            <Link to={'/clients/people/'+referrer.id}>
                                { referrer.firstName+' '+referrer.lastName }
                            </Link>
                        )
                    }else{
                        response = '(No referrer)'
                    }
                    break
                case 'actingOnTrust':
                    if(this.props.data.company.actingOnTrust){
                        response = this.props.data.company.actingOnTrust
                    }else{
                        response = 'No'
                    }
                    break
                case 'trustName':
                    if(this.props.data.company.trustName){
                        response = this.props.data.company.trustName
                    }else{
                        response = 'None'
                    }
                    break
            }

        }

        return response
    }

    render(){
        const { data } = this.props

        let liabilities = []

        data.liabilities.forEach(liability => {
            if(liability.personRelation){
                liabilities.push(liability.personRelation)
            }else{
                liabilities.push(liability.companyRelation)
            }
        })

        let notes
        let clientType
        let clientId

        if(data.person){
            notes = data.person.notes ? data.person.notes : null
            clientType = 'person'
            clientId = data.person.id
        }else{
            notes = data.company.notes ? data.company.notes : null
            clientType = 'company'
            clientId = data.company.id
        }

        console.log(notes)

        notes = removeSoftDeletedItems(notes)
        
        return (
            <div>
                <Timeline 
                    person={data.person} 
                    company={data.company} 
                    type="overview" 
                    liabilities={liabilities} 
                />
                <Notes 
                    notes={notes} 
                    events={data.person ? removeSoftDeletedItems(data.person.events) : removeSoftDeletedItems(data.company.events)} 
                    clientType={clientType} 
                    clientId={clientId} 
                />
                <DetailsTable 
                    data={this.tableData} 
                    getter={this.tableDataGetter} 
                />
            </div>
        )
    }
}

export default Overview