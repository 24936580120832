import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'

export default class EmptyAlert extends Component {
	render() {
		const {
			title,
			body
		} = this.props

		return (
			<div className="empty-alert-big">
				<FontAwesome name="exclamation-triangle" />
				<h2>
					{ title }
				</h2>
				<p>
					{ body }
				</p>
			</div>
		)
	}
}
