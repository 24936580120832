import React, { Component } from 'react'
import Scroll from 'react-scroll'

import { cloneDeep, forIn } from 'lodash'

import Button from 'components/Button/Button'

import { graphql, compose } from 'react-apollo'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'

import {
    UPSERT_COMPANY_LIABILITY,
    UPSERT_PERSON_LIABILITY,
    GET_LENDERS,
    INIT_DATA,
    GET_PERSON,
    GET_COMPANY,
    GET_ASSETS,
    OPEN_MODAL
} from 'graphql/all'

import {
    stripTypenamesFromMutations,
    removeSoftDeletedItems,
    getAssetName,
    getCurrentEffectiveInterestRate,
    getClientDisplayName,
    hasOwnershipError
} from 'utils/utils'

import ModalForm from '../ModalComponents/ModalForm'
import ValidationError from 'components/Errors/ValidationError'
import * as yup from 'yup'

class ModalLiability extends Component {
    state = {
        fields: {
            id: null,
            name: '',
            people: [],
            companies: [],
            assets: [],
            loan: null,
            lender: null,
            loanTerm: null,
            startDate: null,
            interestOnlyTerm: null,
            financePurpose: '',
            amount: null,
            initialBalance: null,
            introRateYears: null,
            introRatePercent: null,
            repaymentAmount: null,
            repaymentFrequency: 'MONTHLY',
            discountPercent: null,
            bankAccountName: '',
            bankAccountBsb: null,
            bankAccountNumber: null,
            offsetAccountNumber: null,
            offsetAccountBsb: null,
            fixedRatePeriods: []
        },
        genericError: '',
        errors: {},
        miniConfirmModalVisible: false,
        miniConfirmData: {}
    }

    skipFirstEqualise = false // for if we add 0% spouse, etc. Don't make it 50-50
    openNextModalIntent = null
    scroll = Scroll.animateScroll

    componentWillMount() {
        const {
            object,
            client
        } = this.props

        // fill in data from object
        if (object) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    ...object
                }
            })
        }

        // add all lenders/loans
        client.query({
            query: GET_LENDERS
        }).then(data => {
            const lenders = removeSoftDeletedItems(data.data.lenders)

            const { brokerage } = this.props.initData.user

            this.setState({
                allLenders: lenders.filter(lender => {
                    return brokerage.selectedLenders.find(selectedLender => {
                        return selectedLender.id == lender.id
                    })
                }).sort((a, b) => {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                    return 0
                }),
            }, () => {
                this.syncLendersAndLoans('loan')
            })
        })

        client.query({
            query: GET_ASSETS,
            fetchPolicy: 'network-only'
        }).then(data => {
            const assets = removeSoftDeletedItems(data.data.assets)

            this.setState({
                allAssets: assets,
            })
        })

        // add all people/companies - then seed the current user/company if not already there
        client.query({
            query: INIT_DATA
        }).then(data => {
            const allPeople = removeSoftDeletedItems(data.data.user.people)
            const allCompanies = removeSoftDeletedItems(data.data.user.companies)

            this.setState({
                allPeople,
                allCompanies
            }, () => {
                // set up applicants
                let applicantKeys = {
                    people: [],
                    companies: []
                }

                let currentClientKey
                let currentClientId

                if (this.props.object.personId) {
                    currentClientId = this.props.object.personId
                    currentClientKey = 'people'
                } else if (this.props.object.companyId) {
                    currentClientKey = 'companies'
                    currentClientId = this.props.object.companyId
                }

                let shouldSeedCurrentClient = true

                forIn(applicantKeys, (array, key) => {
                    this.state.fields[key].forEach(node => {
                        // check if applicant->person or applicant->company has this user's ID
                        let subKey
                        if (key == 'people') {
                            subKey = 'person'
                        } else {
                            subKey = 'company'
                        }

                        // occasionally null. 
                        if (node[subKey]) {
                            applicantKeys[key].push({
                                id: node[subKey].id,
                                percent: node.percent
                            })

                            if (node[subKey].id === currentClientId) {
                                shouldSeedCurrentClient = false
                            }
                        }
                    })
                })

                if (shouldSeedCurrentClient) {
                    let ownershipArray = [{
                        id: currentClientId,
                        percent: 100
                    }]

                    if (currentClientKey == 'people') {
                        const currentPerson = allPeople.find(person => {
                            return person.id === currentClientId
                        })

                        if (currentPerson && currentPerson.spouse) {
                            this.skipFirstEqualise = true

                            ownershipArray.push({
                                id: currentPerson.spouse.id,
                                percent: 0
                            })
                        }
                    }

                    this.setState({
                        ...this.state,
                        fields: {
                            ...this.state.fields,
                            [currentClientKey]: ownershipArray
                        }
                    })
                } else {
                    let fields = cloneDeep(this.state.fields)

                    forIn(applicantKeys, (array, key) => {
                        fields[key] = array
                    })

                    this.setState({
                        fields
                    })
                }
            })
        })
    }

    componentWillUpdate = (nextProps, nextState) => {
        const fields = this.state.fields
        const newFields = nextState.fields

        if (fields.people.length != newFields.people.length && !this.skipFirstEqualise) {
            this.skipFirstEqualise = false

            const totalPeople = newFields.people.length
            const percentEach = Math.round(100 / newFields.people.length)
            const remainder = 100 - (percentEach * totalPeople)

            let newPeople = cloneDeep(newFields.people)

            newPeople.forEach((person, index) => {
                newPeople[index].percent = percentEach

                if (!index) {
                    person.percent += remainder
                }
            })

            this.setState({
                fields: {
                    ...nextState.fields,
                    people: newPeople
                }
            })
        }
    }

    getValue = type => {
        let responseObject = null

        switch (type) {
            case 'assets':
                responseObject = [{
                    label: '(Select asset)',
                    value: undefined
                }]

                if (this.state.allAssets && this.props.object) {
                    responseObject = []

                    const { personId, companyId } = this.props.object

                    this.state.allAssets.forEach(asset => {
                        let isClientAsset = false

                        if (personId && asset.people.length) {
                            asset.people.forEach(personRelation => {
                                if (personRelation.person && personRelation.person.id == personId) {
                                    isClientAsset = true
                                }
                            })
                        } else if (companyId && asset.companies.length) {
                            asset.companies.forEach(companyRelation => {
                                if (companyRelation.company && companyRelation.company.id == companyId) {
                                    isClientAsset = true
                                }
                            })
                        }

                        if (isClientAsset && getAssetName(asset)) {
                            responseObject.push({
                                label: getAssetName(asset, true),
                                value: asset.id,
                                isDisabled: this.state.fields.assets.find(loopedAsset => {
                                    return loopedAsset.id == asset.id
                                })
                            })
                        }
                    })
                }

                break
            case 'lenders':
                if (this.state.allLenders) {
                    responseObject = []

                    this.state.allLenders.forEach(lender => {
                        responseObject.push({
                            label: lender.name,
                            value: lender.id
                        })
                    })
                }
                break
            case 'loans':
                if (this.state.fields.lender) {
                    responseObject = []

                    this.state.allLenders.forEach(lender => {
                        if (lender.id === this.state.fields.lender.id) {
                            lender.loans.forEach(loan => {
                                responseObject.push({
                                    label: loan.name,
                                    value: loan.id
                                })
                            })
                        }
                    })
                }
                break
            case 'people':
                if (this.state.allPeople) {
                    if (!this.state.allPeople.length) {
                        responseObject = [{
                            label: 'No people',
                            value: undefined,
                            hide: true
                        }]
                    } else {
                        responseObject = []

                        this.state.allPeople.forEach(person => {
                            responseObject.push({
                                label: getClientDisplayName(person),
                                value: person.id,
                                isDisabled: this.state.fields.people.find(loopedPerson => {
                                    return loopedPerson.id == person.id
                                })
                            })
                        })

                        responseObject.sort((a, b) => {
                            if (a.label < b.label) return -1
                            if (a.label > b.label) return 1
                            return 0
                        })

                        responseObject.unshift({
                            label: '(Select person)',
                            value: undefined
                        })
                    }
                }
                break
            case 'companies':
                if (this.state.allCompanies) {
                    if (!this.state.allCompanies.length) {
                        responseObject = [{
                            label: 'No companies',
                            value: undefined,
                            hide: true
                        }]
                    } else {
                        responseObject = [{
                            label: '(Select company)',
                            value: undefined
                        }]

                        this.state.allCompanies.forEach(company => {
                            responseObject.push({
                                label: company.name,
                                value: company.id,
                                isDisabled: this.state.fields.companies.find(loopedCompany => {
                                    return loopedCompany.id == company.id
                                })
                            })
                        })
                    }
                }
                break
            case 'rates':
                let interestRate = null

                if (this.state.fields.settlementRate) {
                    interestRate = this.state.fields.settlementRate
                } else {
                    if (this.state.fields.lender && this.state.fields.loan) {
                        this.state.allLenders.forEach(lender => {
                            if (lender.id === this.state.fields.lender.id) {
                                lender.loans.forEach(loan => {
                                    if (loan.id === this.state.fields.loan.id) {
                                        interestRate = getCurrentEffectiveInterestRate(this.state.fields, loan)
                                    }
                                })
                            }
                        })
                    }
                }

                if (interestRate || interestRate === 0) {
                    responseObject = interestRate + '%'
                } else {
                    responseObject = 'N/A'
                }

                break
            case 'fixedRatePeriods':
                responseObject = []

                break
            case 'firstApplicant':
                if (this.props.object) {
                    if (this.props.object.personId && this.state.allPeople) {
                        responseObject = this.state.allPeople.find(person => {
                            return person.id === this.props.object.personId
                        })
                    } else if (this.props.object.companyId && this.state.allCompanies) {
                        responseObject = this.state.allCompanies.find(company => {
                            return company.id === this.props.object.companyId
                        })
                    }
                }
                break
        }

        if (!responseObject) {
            return [{
                label: 'Loading...',
                value: undefined
            }]
        } else {
            return responseObject
        }
    }

    handleChange = (key, value) => {
        const {
            object
        } = this.props

        if (typeof (value) === 'undefined' || !object) {
            return
        }

        let parsedValue = value
        let isChangeValid = true

        switch (key) {
            case 'loanTerm':
            case 'interestOnlyTerm':
                parsedValue = parseInt(parsedValue.replace(/[^0-9]/g, ""))
                break
            case 'initialBalance':
            case 'repaymentAmount':
            case 'amount':
                if (parsedValue) {
                    parsedValue = parsedValue
                }
            case 'discountPercent':
                if (parsedValue === '') {
                    parsedValue = null
                } else if (typeof parsedValue === 'string') {
                    //parsedValue = parseFloat(parsedValue.replace(/[^0-9.,]/g, ""))
                }
                break
            case 'introRateYears':
            case 'introRatePercent':
                if (parsedValue === '') {
                    parsedValue = null
                } else if (typeof parsedValue === 'string') {
                    //parsedValue = parseInt(parsedValue.replace(/[^0-9.,]/g, ""))
                }
                break
            case 'people':
            case 'companies':
                let currentClientId
                let currentClientKey

                if (object.personId) {
                    currentClientId = object.personId
                    currentClientKey = 'people'
                } else if (object.companyId) {
                    currentClientKey = 'company'
                    currentClientId = object.companyId
                }

                // e.g if current client is person and people is changing. don't let 'em remove the person!
                if (key == currentClientKey) {
                    isChangeValid = false

                    value.forEach(row => {
                        if (row.id === currentClientId) {
                            isChangeValid = true
                        }
                    })
                }

                if (!isChangeValid) {
                    break
                }

                parsedValue = []

                value.forEach(row => {
                    let percent = row.percent

                    if (typeof (row.percent) == 'string') {
                        percent = parseFloat(row.percent.replace(/[^0-9.]/g, ""))
                    } else if (!row.percent) {
                        percent = null
                    }

                    let thisParsedValue = {
                        id: row.id,
                        percent: percent
                    }

                    parsedValue.push(thisParsedValue)
                })

                break
            case 'loan':
                // select box returns a string
                if (typeof (value) === 'string') {
                    parsedValue = {
                        id: value
                    }
                }
                /*

                // pre-fill type, interest-only term, and current interest rate
                if(this.state.allLenders){
                    let loanObject = null 
                    this.state.allLenders.forEach(lender => {
                        lender.loans.forEach(loan => {
                            if(loan.id === value){
                                loanObject = loan
                            }
                        })
                    })
                }
                */
                break
            case 'lender':
                if (typeof (value) === 'string') {
                    parsedValue = {
                        id: value
                    }
                }

                this.syncLendersAndLoans('lender', parsedValue)
                break
        }

        if (isChangeValid) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [key]: parsedValue,
                }
            }, () => {
                this.checkLoanValidity()
            })
        }

    }

    checkLoanValidity = _ => {
        const {
            fields,
            allLenders
        } = this.state

        let loanObject

        if (fields.loan) {
            allLenders.forEach(lender => {
                lender.loans.forEach(loan => {
                    if (loan.id == fields.loan.id) {
                        loanObject = loan
                    }
                })
            })

            let typeString = ''

            if (fields.financePurpose == 'INVESTMENT') {
                typeString += 'IV'
            } else {
                typeString += 'OO'
            }

            if (fields.interestOnlyTerm) {
                typeString += 'IO'
            } else {
                typeString += 'PI'
            }

            let hasValidInterestRate = false

            // Test if this type of loan is supported by the selected product
            if (loanObject && loanObject['interestRates' + typeString] && loanObject['interestRates' + typeString].length && loanObject['interestRates' + typeString][(loanObject['interestRates' + typeString].length - 1)].rate) {
                hasValidInterestRate = true
            }

            if (hasValidInterestRate) {
                this.clearError('loan')
            } else {
                let errorString = "This loan doesn't support "

                switch (typeString) {
                    case 'IVIO':
                        errorString += "IO investment "
                        break
                    case 'IVPI':
                        errorString += "P&I investment "
                        break
                    case 'OOIO':
                        errorString += "IO owner-occupier "
                        break
                    case 'OOPI':
                        errorString += "P&I owner-occupier "
                        break
                }

                errorString += 'loans.'

                this.handleError({
                    inner: [{
                        path: 'loan',
                        message: errorString
                    }]
                })
            }
        }

    }

    syncLendersAndLoans = (changedKey, value = null) => {
        if (!value) {
            value = this.state.fields[changedKey]
        }

        if (value) {
            // on load, change lender to loan's parent 
            if (changedKey === 'loan') {
                let loanLenderId = null

                this.state.allLenders.forEach(lender => {
                    lender.loans.forEach(loan => {
                        if (loan.id === value.id) {
                            loanLenderId = lender.id
                        }
                    })
                })

                this.setState({
                    ...this.state,
                    fields: {
                        ...this.state.fields,
                        lender: {
                            id: loanLenderId
                        }
                    }
                })
            }
        }

    }

    handleError = error => {
        const errors = error.inner

        let newErrorState = {}

        errors.forEach(error => {
            newErrorState[error.path] = error.message
        })

        this.setState({
            genericError: 'There were errors in your submission',
            errors: newErrorState
        })
    }

    clearError = (path = null) => {
        if (!path) {
            this.setState({
                genericError: null,
                errors: {}
            })
        } else {
            let newErrorState = cloneDeep(this.state.errors)

            delete newErrorState[path]

            let newState = {
                errors: newErrorState
            }

            if (!Object.keys(newErrorState).length) {
                newState.genericError = null
            }

            this.setState(newState)
        }
    }

    isRepeaterFieldPinned = (value, allValues) => {
        // just pin it if there's only one.
        return allValues.length === 1

        /*
        if(value.id && this.props.object){
            if(this.props.object.personId){
                if(value.id === this.props.object.personId){
                    return true
                }
            }else if(this.props.object.companyId){
                if(value.id === this.props.object.companyId){
                    return true
                }
            }
        }

        return false */
    }

    removeNonPayloadData = fields => {
        let tempFields = cloneDeep(fields)

        delete tempFields.events
        delete tempFields.company
        delete tempFields.person

        let assets = []

        tempFields.assets && tempFields.assets.forEach(asset => {
            assets.push({
                id: asset.id
            })
        })

        tempFields.assets = assets

        if (tempFields.loan) {
            delete tempFields.loan.interestRatesOOPI
            delete tempFields.loan.interestRatesOOIO
            delete tempFields.loan.interestRatesIVPI
            delete tempFields.loan.interestRatesIVIO
            delete tempFields.loan.isFixed
            delete tempFields.loan.lender
        }

        if (tempFields.notes) {
            delete tempFields.notes
        }

        const fixedRatePeriods = []

        tempFields.fixedRatePeriods && tempFields.fixedRatePeriods.forEach(fixedRatePeriod => {
            if (fixedRatePeriod.startDate && fixedRatePeriod.term && fixedRatePeriod.customRate) {
                fixedRatePeriods.push(fixedRatePeriod)
            }
        })

        tempFields.fixedRatePeriods = fixedRatePeriods

        if (!tempFields.introRateYears) {
            tempFields.introRateYears = null
        }

        if (!tempFields.introRatePercent) {
            tempFields.introRatePercent = null
        }

        const floats = ['amount', 'initialBalance', 'repaymentAmount', 'discountPercent', 'settlementRate']
        const ints = ['introRatePercent', 'introRateYears']

        floats.forEach(float => {
            if (tempFields[float] && typeof tempFields[float] == 'string') {
                tempFields[float] = parseFloat(tempFields[float].replace(/[^0-9.,]/g, ""))
            }
        })

        ints.forEach(int => {
            if (tempFields[int] && typeof tempFields[int] === 'string') {
                tempFields[int] = parseInt(tempFields[int].replace(/[^0-9.,]/g, ""))
            }
        })

        tempFields.fixedRatePeriods.forEach(period => {
            if (period.term && typeof period.term === 'string') {
                period.term = parseInt(period.term)
            }
        })

        if(!tempFields.settlementRate){
            tempFields.settlementRate = null
        }

        return tempFields
    }

    handleSubmit = async openNextModal => {
        const {
            object,
            isLoading,
            setLoading,
            history,
            handleSuccess,
        } = this.props

        if (isLoading) {
            return
        }

        const validationSchema = yup.object().shape({
            name: yup.string().required('Please enter a name.'),
        })

        try {
            await validationSchema.validate({ ...this.state.fields }, { abortEarly: false })
        } catch (error) {
            return this.handleError(error)
        }

        this.openNextModalIntent = openNextModal

        setLoading(true)

        this.setState({
            genericError: null
        })

        let fields = stripTypenamesFromMutations(this.state.fields)
        fields = this.removeNonPayloadData(fields)

        const ownershipError = hasOwnershipError(fields)

        if (ownershipError) {
            return this.setState({
                genericError: ownershipError
            })
        }

        if (!fields.initialBalance) {
            fields.initialBalance = null
        }

        fields.fixedRatePeriods.forEach((fixedRatePeriod, index) => {
            if (!fixedRatePeriod.startDate) {
                fields.fixedRatePeriods[index].startDate = null
            }
        })

        const functionName = fields.personId ? 'upsertPersonLiability' : 'upsertCompanyLiability'

        let refetchQueriesArray = []

        if (object) {
            if (object.personId) {
                refetchQueriesArray = [{
                    query: GET_PERSON,
                    variables: {
                        id: object.personId
                    }
                }]
            } else if (object.companyId) {
                refetchQueriesArray = [{
                    query: GET_COMPANY,
                    variables: {
                        id: object.companyId
                    }
                }]
            }
        }

        try {
            await this.props[functionName]({
                variables: {
                    payload: {
                        ...fields
                    },
                },
                refetchQueries: _ => refetchQueriesArray
            }).then(response => {
                let url = null
                let client = null
                let clientType = null
                let liabilityId = null

                const data = response.data

                if (data.upsertPersonLiability) {
                    client = data.upsertPersonLiability
                    clientType = 'people'
                    liabilityId = client.liabilities[(client.liabilities.length - 1)].personRelation.id
                } else if (data.upsertCompanyLiability) {
                    client = data.upsertCompanyLiability
                    clientType = 'companies'
                    liabilityId = client.liabilities[(client.liabilities.length - 1)].companyRelation.id
                }

                url = `/clients/${clientType}/${client.id}/liabilities/${liabilityId}`

                history.push(url)

                handleSuccess(() => { })
            })
        } catch (error) {
            //const clientErrors = error.graphQLErrors;

            console.log(error)
            setLoading(false)
        }
    }

    render() {
        const {
            object,
            closeCallback
        } = this.props

        const {
            fields,
            errors,
            genericError
        } = this.state

        this.fieldColumns = [
            [
                [{
                    label: 'Name',
                    required: true,
                    type: 'text',
                    props: { type: 'text' },
                    key: 'name'
                }],
                [{
                    label: 'Commencement date',
                    type: 'text',
                    props: {
                        type: 'date',
                        allowFuture: true
                    },
                    key: 'startDate'
                }],
                [{
                    label: 'Finance purpose',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: 'INVESTMENT',
                                label: 'Investment'
                            }, {
                                value: 'OWNEROCCUPIER',
                                label: 'Owner-occupier'
                            }
                        ]
                    },
                    key: 'financePurpose'
                }],
                [{
                    label: 'Lender',
                    type: 'select',
                    props: {
                        values: this.getValue('lenders')
                    },
                    key: 'lender'
                }], [{
                    label: 'Loan product',
                    type: 'select',
                    props: {
                        values: this.getValue('loans')
                    },
                    key: 'loan',
                }], [
                    {
                        label: 'Loan term (years)',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'loanTerm'
                    },
                    {
                        label: 'Interest-only term (years)',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'interestOnlyTerm'
                    }
                ],
                [
                    {
                        label: 'Interest rate discount',
                        type: 'text',
                        props: { type: 'percent' },
                        key: 'discountPercent'
                    },
                    {
                        label: 'Effective interest rate',
                        type: 'rate',
                        key: 'rate',
                        props: {
                            value: this.getValue('rates')
                        }
                    }
                ],
                [{
                    label: "Fixed-rate periods",
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'text',
                                props: {
                                    type: 'date',
                                    allowFuture: true,
                                },
                                key: 'startDate'
                            }, {
                                type: 'text',
                                key: 'term',
                                placeholder: 'Years',
                            },
                            {
                                type: 'text',
                                props: {
                                    type: 'percent'
                                },
                                key: 'customRate',
                                placeholder: 'Fixed rate'
                            }
                        ],
                        buttonLabel: 'Add fixed-rate period',
                    },
                    key: 'fixedRatePeriods'
                }], [{
                    label: 'Settlement rate',
                    type: 'text',
                    props: { type: 'percent' },
                    key: 'settlementRate',
                }],
                [
                    {
                        label: 'Intro rate',
                        type: 'text',
                        props: {
                            placeholder: 'Years',
                        },
                        key: 'introRateYears'
                    }, {
                        label: '',
                        type: 'text',
                        props: {
                            type: 'percent',
                            placeholder: 'Discount',
                        },
                        key: 'introRatePercent'
                    }
                ]
            ],
            [[{
                label: 'Linked assets',
                type: 'repeater',
                props: {
                    inputs: [
                        {
                            type: 'select',
                            props: {
                                values: this.getValue('assets'),
                                noDefault: true
                            },
                            key: 'id',
                        }
                    ],
                    buttonLabel: 'Add asset'
                },
                key: 'assets'
            }], [{
                label: (!object || (!object.personId && !object.companyId)) ? 'Applicants - people' : 'Applicants',
                isHidden: object && object.companyId ? true : false,
                type: 'repeater',
                props: {
                    inputs: [
                        {
                            type: 'select',
                            props: {
                                values: this.getValue('people')
                            },
                            key: 'id',
                        }, {
                            type: 'text',
                            key: 'percent',
                            placeholder: '',
                            props: {
                                type: 'percent'
                            }
                        }
                    ],
                    buttonLabel: 'Add person',
                    isRepeaterFieldPinned: this.isRepeaterFieldPinned
                },
                key: 'people'
            }], [{
                label: (!object || (!object.personId && !object.companyId)) ? 'Applicants - companies' : 'Applicants',
                isHidden: object && object.personId ? true : false,
                type: 'repeater',
                props: {
                    inputs: [
                        {
                            type: 'select',
                            props: {
                                values: this.getValue('companies')
                            },
                            key: 'id',
                        }, {
                            type: 'text',
                            key: 'percent',
                            placeholder: '',
                            props: {
                                type: 'percent'
                            }
                        }
                    ],
                    buttonLabel: 'Add company',
                    isRepeaterFieldPinned: this.isRepeaterFieldPinned
                },
                key: 'companies'
            }],
            [
                {
                    label: 'Current balance (last known)',
                    type: 'text',
                    props: { type: 'money' },
                    key: 'amount'
                }, {
                    label: 'Original loan limit',
                    type: 'text',
                    props: { type: 'money' },
                    key: 'initialBalance'
                }
            ], [
                {
                    label: 'Repayments',
                    type: 'text',
                    props: { type: 'money' },
                    key: 'repaymentAmount',
                }, {
                    label: '',
                    type: 'select',
                    props: {
                        values: [
                            {
                                value: 'FORTNIGHTLY',
                                label: 'fortnightly'
                            },
                            {
                                value: 'MONTHLY',
                                label: 'monthly'
                            },
                            {
                                value: 'WEEKLY',
                                label: 'weekly'
                            }
                        ]
                    },
                    key: 'repaymentFrequency',
                }
            ], [{
                label: 'Account name',
                type: 'text',
                props: {
                    type: 'text',
                    canPrefillFrom: this.getValue('firstApplicant')
                },
                key: 'bankAccountName',
            }], [
                {
                    label: 'BSB',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'bankAccountBsb'
                }, {
                    label: 'Account number',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'bankAccountNumber'
                }
            ], [
                {
                    label: 'Offset BSB',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'offsetAccountBsb'
                }, {
                    label: 'Offset account number',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'offsetAccountNumber'
                }
            ]
            ]
        ]

        return (
            <div className="wide">
                <div className="content-container">
                    <ModalForm
                        errors={errors}
                        handleChange={this.handleChange}
                        fieldState={fields}
                        columns={this.fieldColumns}
                    />
                </div>
                <div className="button-row">
                    <ValidationError
                        error={genericError ? genericError : null}
                    />
                    <Button
                        label="Save liability"
                        level="high"
                        callback={() => { this.handleSubmit(false) }}
                    />
                    <Button
                        label="Cancel"
                        level="low"
                        callback={closeCallback}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    withApollo,
    graphql(UPSERT_PERSON_LIABILITY, { name: 'upsertPersonLiability' }),
    graphql(UPSERT_COMPANY_LIABILITY, { name: 'upsertCompanyLiability' }),
    graphql(OPEN_MODAL, { name: 'openModal' }),
    graphql(INIT_DATA, { name: 'initData' })
)(ModalLiability))