import React, { Component } from 'react'

class DetailsTable extends Component {
    render(){
        const { data, getter } = this.props

        return (
            <div className="details-table-container">
                {
                    data.map((row, rowIndex) => {
                        return (
                            <div className="details-table" key={rowIndex}>
                                <h3>
                                    { row.title }
                                </h3>
                                <div className="details-table-tables">
                                    {
                                        row.data.map((column, columnIndex) => {
                                            return (
                                                <table key={columnIndex}>
                                                    <tbody>
                                                        {
                                                            column.map((item, itemIndex) => {
                                                                return (
                                                                    <tr key={itemIndex}>
                                                                        <td>
                                                                            <label>
                                                                                { item.label }
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                { getter(item.key) }
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )

                    })
                }
            </div>
        )
    }
}

export default DetailsTable