import gql from 'graphql-tag'
import { 
    PersonFragment, 
    CompanyFragment, 
    AssetFragment, 
    EventFragment, 
    EventFragmentTwo, 
    LiabilityFragment,
    AddressFragment
} from './fragments-graphql'

export const UPSERT_PERSON = gql`
    mutation UPSERT_PERSON (
        $payload: UpsertPersonPayload
    ){
        upsertPerson(
            payload: $payload
        ){
            ...PersonFragment
            liabilities {
                id
            }
            assets {
                id
                percent
                personRelation {
                    id
                    name
                    address {
                        ...AddressFragment
                    }
                }
            }
        }
    }
    ${PersonFragment}
    ${AddressFragment}
`

export const GET_PERSON = gql`
    query GET_PERSON (
        $id: ID!
    ) {
        person (
            id: $id
        ){
            ...PersonFragment
            liabilities {
                id
                percent
                deletedAt
                personRelation {
                    ...LiabilityFragment
                }
                companyRelation {
                    ...LiabilityFragment
                }
            }
            assets {
                id
                percent
                deletedAt
                personRelation {
                    ...AssetFragment
                }
                companyRelation {
                    ...AssetFragment
                } 
            }
        }
    }
    ${PersonFragment}
    ${LiabilityFragment}
    ${AssetFragment}
`

export const UPDATE_USER = gql`
    mutation UPDATE_USER (
        $payload: UpdateUserPayload
    ){
        updateUser(
            payload: $payload
        ){
            ...UserFragment
        }
    }
    ${PersonFragment}
`

export const GET_COMPANY = gql`
    query GET_COMPANY (
        $id: ID!
    ) {
        company (
            id: $id
        ){
            ...CompanyFragment
            events {
                ...EventFragment
            }
            liabilities {
                id
                percent
                deletedAt
                personRelation {
                    ...LiabilityFragment
                }
                companyRelation {
                    ...LiabilityFragment
                }
            }
            assets {
                id
                percent
                deletedAt
                personRelation {
                    ...AssetFragment
                }
                companyRelation {
                    ...AssetFragment
                } 
            }
        }
    }
    ${CompanyFragment}
    ${LiabilityFragment}
    ${AssetFragment}
    ${EventFragment}
`

export const UPSERT_COMPANY = gql`
    mutation UPSERT_COMPANY(
        $payload: UpsertCompanyPayload
    )  {
        upsertCompany(
            payload: $payload
        ){
            ...CompanyFragment
            events {
                ...EventFragment
            }
            liabilities {
                id
            }
            assets {
              id
              percent
              companyRelation {
                  id
                  name
                  address {
                      ...AddressFragment
                  }
              }
            }
        }
    } 
    ${CompanyFragment}
    ${EventFragment}
    ${AddressFragment}
`

export const UPSERT_PERSON_ASSET = gql`
    mutation UPSERT_PERSON_ASSET (
      $payload: UpsertAssetPayload
    ){
        upsertPersonAsset (
            payload: $payload
        ){
            id
            assets {
                id
                percent
                deletedAt
                personRelation {
                    ...AssetFragment
                }
                companyRelation {
                    ...AssetFragment
                } 
            }
        }
    }
    ${AssetFragment}
`

export const UPSERT_COMPANY_ASSET = gql`
    mutation UPSERT_COMPANY_ASSET (
      $payload: UpsertAssetPayload
    ){
        upsertCompanyAsset (
            payload: $payload
        ){
            id
            assets {
                id
                percent
                deletedAt
                personRelation {
                    ...AssetFragment
                }
                companyRelation {
                    ...AssetFragment
                } 
            }
        }
    }
    ${AssetFragment}
`

export const UPSERT_PERSON_LIABILITY = gql`
    mutation UPSERT_PERSON_LIABILITY (
        $payload: UpsertLiabilityPayload
    ){
        upsertPersonLiability (
            payload: $payload
        ){
            ...PersonFragment
            liabilities {
                id
                percent
                deletedAt
                personRelation {
                    ...LiabilityFragment
                }
                companyRelation {
                    ...LiabilityFragment
                }
            }
        }
    }
    ${PersonFragment}
    ${EventFragment}
    ${LiabilityFragment}
`

export const UPSERT_COMPANY_LIABILITY = gql`
    mutation UPSERT_COMPANY_LIABILITY (
        $payload: UpsertLiabilityPayload
    ){
        upsertCompanyLiability (
            payload: $payload
        ){
            ...CompanyFragment
            events {
                ...EventFragmentTwo
            }
            liabilities {
                id
                percent
                deletedAt
                personRelation {
                    ...LiabilityFragment
                }
                companyRelation {
                    ...LiabilityFragment
                }
            }
        }
    }
    ${CompanyFragment}
    ${EventFragmentTwo}
    ${LiabilityFragment}
`

export const CREATE_NOTE = gql`
    mutation CREATE_NOTE (
        $payload: CreateNotePayload
    ) {
        createNote (
            payload: $payload
        ) {
            person {
                id
                notes {
                    id
                    text
                    createdAt
                    updatedAt
                    deletedAt
                    author {
                        id
                        firstName
                        lastName
                    }
                    editedBy {
                        id
                        firstName
                        lastName
                    }
                }
            }
            company {
                id
                notes {
                    id
                    text
                    createdAt
                    updatedAt
                    deletedAt
                    author {
                        id
                        firstName
                        lastName
                    }
                    editedBy {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`

export const UPDATE_NOTE = gql`
    mutation UPDATE_NOTE (
        $payload: UpdateNotePayload
    ) {
        updateNote (
            payload: $payload
        ) {
            person {
                id
                notes {
                    id
                    text
                    createdAt
                    updatedAt
                    deletedAt
                }
            }
            company {
                id
                notes {
                    id
                    text
                    createdAt
                    updatedAt
                    deletedAt
                }
            }
        }
    }
`