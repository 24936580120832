import React, { Component } from 'react'
import { cloneDeep, pull } from 'lodash'
import TextInput from 'components/Inputs/TextInput'
import SelectInput from 'components/Inputs/SelectInput'
import ValidationError from 'components/Errors/ValidationError'
import CheckboxInput from 'components/Inputs/CheckboxInput'

class RepeaterInput extends Component {
    state = {
        tempRows: [],
        focusedRow: 0
    }

    componentWillMount(){
        this.createRowIfNone(this.props);
    }

    componentWillUpdate = (nextProps) => {
        this.createRowIfNone(nextProps);
    }

    createRowIfNone = (props) => {
        if((!props.value || !props.value.length) && !this.state.tempRows.length){
            let firstRow = this.generateEmptyRow();

            this.setState({
                tempRows: [firstRow]
            })
        }else if(props.value && props.value.length && this.state.tempRows.length){
            this.setState({
                tempRows: []
            })
        }
    }

    generateEmptyRow = () => {
        let row = {}

        this.props.inputs.forEach(input => {
            row[input.key] = '';
        });

        return row;
    }

    handleChange = (key, rowIndex, value) => {
        let rows = cloneDeep(this.props.value);

        if(!rows.length){
            rows = this.state.tempRows;
        }

        rows[rowIndex][key] = value;

        this.props.changeCallback(rows);
    }

    removeRow = (index) => {
        let rows = cloneDeep(this.props.value);

        rows.splice(index, 1);

        this.props.changeCallback(rows);
    }

    addRow = () => {
        let rows = cloneDeep(this.props.value);
        let newRow = this.generateEmptyRow();

        rows.push(newRow);
        this.props.changeCallback(rows)
    }

    focusRow = index => {
        this.setState({
            focusedRow: index
        })
    }

    render(){
        const {
            value,
            inputs,
            error,
            label,
            required,
            subLabel,
            isRepeaterFieldPinned,
            buttonLabel
        } = this.props

        const {
            tempRows
        } = this.state

        let repeaterRows = cloneDeep(value)

        if(this.state.tempRows.length && (!repeaterRows || !repeaterRows.length)){
            if(repeaterRows){
                repeaterRows.push(tempRows[0])
            }else{
                repeaterRows = [tempRows[0]]
            }
        }
        
        let shouldHideInput = false

        inputs.forEach(input => {
            if(input.props && input.props.values && input.props.values.length){
                if(input.props.values[0].hide){
                    shouldHideInput = true
                }
            }
        })

        if(shouldHideInput){
            return null
        }

        let className = 'repeater-container'

        className += ' input-count-'+inputs.length

        return (
            <div className={error ? "input-wrap error" : "input-wrap"}>
                {
                    label ?
                    <label>
                        { label }
                        { required ?
                            <em className="required">*</em>
                            : null
                        }
                    </label>
                    : null
                }
                {
                    subLabel ?
                    <span className="subtitle">
                        { subLabel }
                    </span>
                    : null
                }
                <div className={className}>
                    <ul>
                        {
                            repeaterRows.map((row, rowIndex) => {
                                let isFieldPinned = isRepeaterFieldPinned ? isRepeaterFieldPinned(repeaterRows[rowIndex], value) : false

                                return (
                                    <li 
                                        style={{zIndex: this.state.focusedRow == rowIndex ? 9 : 1 }} 
                                        key={rowIndex} 
                                        onClick={() => {this.focusRow(rowIndex)}} 
                                        onFocus={() => {this.focusRow(rowIndex)}}
                                    >
                                        <span>
                                            { (rowIndex + 1) }.
                                        </span>
                                        <div className="inputs">
                                        {
                                            inputs.map((input, inputIndex) => {
                                                let Component = null;
    
                                                switch(input.type){
                                                    case 'text':
                                                        Component = TextInput
                                                        break
                                                    case 'select':
                                                        Component = SelectInput
                                                        break
                                                    case 'checkbox':
                                                        Component = CheckboxInput
                                                        break
                                                }

                                                let disabled = false

                                                if(input.props && input.props.disabled && !disabled){
                                                    disabled = input.props.disabled(repeaterRows[rowIndex])
                                                }

                                                return (
                                                    <Component 
                                                        {...input.props} 
                                                        key={inputIndex} 
                                                        disabled={disabled} 
                                                        placeholder={input.placeholder} 
                                                        changeCallback={value => {
                                                            this.handleChange(input.key, rowIndex ,value)
                                                        }} 
                                                        value={repeaterRows[rowIndex][input.key]} 
                                                    />
                                                )
                                            })
                                        }
                                        </div>
                                        {
                                            !isFieldPinned ?
                                            <button className="remove" onClick={() => {this.removeRow(rowIndex)}}>
                                                &times;
                                            </button>
                                            : null
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <button className="button x-low add" onClick={this.addRow}>
                        { buttonLabel }
                    </button>
                    <ValidationError error={error} />
                </div>
            </div>
        )
    }
}

export default RepeaterInput