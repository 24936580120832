import React, { Component } from 'react'
import moment from 'moment'

import { cloneDeep } from 'lodash'
import { getAssetName } from 'utils/utils'

class UpcomingEvents extends Component {
    render() {
        let eventsList = cloneDeep(this.props.data.events)

        let futureEvents = []

        eventsList.forEach(event => {
            if (event.date.isAfter()) {
                futureEvents.push(event)
            }
        })

        if (futureEvents.length > 3) {
            futureEvents.length = 3
        }

        return (
            <div className="content-box">
                <h3>
                    Upcoming events
                </h3>
                {futureEvents.length ? 
                    <ul className="upcoming-events-list">
                        {
                            futureEvents.map((event, index) => {
                                return (
                                    <li key={index}>
                                        <div className="date-container">
                                            <span>
                                                {event.date.format('DD')}
                                            </span>
                                            <span>
                                                {event.date.format('MMM Y')}
                                            </span>
                                        </div>
                                        <div className="meta-container">
                                            <p>
                                                {event.title}
                                            </p>
                                            <span>
                                                {
                                                    event.addedBy ?
                                                        <span>
                                                            Added by: {event.addedBy}
                                                        </span>
                                                        : null
                                                }
                                                {
                                                    (event.assets && event.assets.length) ?
                                                        <span>
                                                            {event.assets.map(asset => {
                                                                return getAssetName(asset) ? getAssetName(asset) : '(Empty asset)'
                                                            })}
                                                        </span>
                                                        : null
                                                }
                                                <span>
                                                    {event.loanName ? event.loanName : null}
                                                </span>
                                            </span>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    :
                    <span>
                        There are no upcoming events for this client.
                    </span>
                }
            </div>
        )
    }
}

export default UpcomingEvents