import React, { Component } from 'react'

import ModalFormRow from './ModalFormRow'

class ModalForm extends Component {
    state = {
        showContents: false,
        focusedRow: 0
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                showContents: true
            })
        },1)
    }

    focusRow = index => {
        this.setState({
            focusedRow: index
        })
    }

    render(){
        const {
            showContents,
            focusedRow
        } = this.state

        const {
            disabled,
            columns,
            errors,
            fieldState,
            handleChange
        } = this.props

        if(!showContents){
            return null;
        }

        const isDisabled = !!disabled
        
        return (
            <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <ul className="form-columns">
                    {
                        columns.map((column, index) => {
                            return (
                                <li key={index}>
                                    <ul className="column-fields">
                                    {
                                        column.map((fieldArray, index) => {
                                            const values = {} 
                                            
                                            fieldArray.forEach(field => {
                                                values[field.key] = fieldState[field.key]
                                            })

                                            return (
                                                <ModalFormRow
                                                    key={index}
                                                    className={fieldArray.length > 1 ? 'halves' : null}
                                                    focusRow={this.focusRow}
                                                    focusedRow={focusedRow}
                                                    fieldArray={fieldArray}
                                                    isFirst={!index}
                                                    isDisabled={isDisabled}
                                                    handleChange={handleChange}
                                                    values={values}
                                                    errors={errors}
                                                    index={index}
                                                />
                                            )
                                        })
                                    }
                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>
            </form>
        )
    }
}

export default ModalForm