import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import * as yup from 'yup'

class Login extends Component {
  state = {
    email: '',
    password: '',
    error: false,
    response: null,
    isLoading: false
  }

  componentWillMount(){
    console.log(this.props)
    
    if(this.props.notify){
      this.handleResponse(this.props.notify, 'success', false)
    }
  }

  handleSubmit = async () => {
    let loginSchema = yup.object().shape({
      password: yup.string().required('Please enter a password.'),
    	email: yup.string().email("That doesn't look like a valid email.").required('Please enter an email address.'),
    });

    try {
    	await loginSchema.validate({ ...this.state })
    } catch (error) {
    	return this.handleResponse(error.message)
    }

    this.props.callback(this.state, this.props.path, (response) => {
      if(response.length){
        this.handleResponse(response[0].message);
      }else{
        this.handleResponse('Uh oh! A server error has occurred.');
      }
    })
  }

  handleResponse = (message, type = 'error', autoDismiss = true) => {
    this.setState({
      response: {
        message,
        type
      }
    })

    if(autoDismiss){
      setTimeout(() => {
        this.setState({
          response: null
        })
      },3000)
    }
  }

  render(){
    return (
        <div onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
          <MiniResponse message={this.state.response ? this.state.response.message : null} type={this.state.response ? this.state.response.type : null} />
          <Loader isLoading={this.props.isLoading} />
          <input placeholder="Email address" type="email" onChange={e => this.setState({email: e.target.value})} />
          <input placeholder="Password" type="password" onChange={e => this.setState({password: e.target.value})} />
          <button className="button high" onClick={this.handleSubmit}>
            Log in
          </button>
          <div className="lower-row">
            <Link to="/auth/forgot-password">
              Forgot your password?
            </Link>
            <Link to="/auth/register">
              Register a new account
            </Link>
          </div>
        </div>
    )
  }
}

export default Login;
