import React, { Component } from 'react'
import { forIn } from 'lodash'
import moment from 'moment'
import EmptyAlert from 'components/Alerts/EmptyAlert'
import { getClientDisplayName } from 'utils'
import { Link } from 'react-router-dom'

export default class MostRecent extends Component {
    render() {
        const {
            user
        } = this.props

        let recentClients = []

        forIn(user.mostRecentClients, (date, id) => {            
            let client = null

            client = user.people.find(person => {
                return person.id === id
            })

            if(!client){
                client = user.companies.find(company => {
                    return company.id === id
                })
            }

            if(!client){
                return 
            }

            const clientData = {
                date,
                client
            }

            recentClients.push(clientData)
        })


        recentClients = recentClients.sort((a,b) => {
            return b.date.localeCompare(a.date)
        })

        return (
            <div className="dashboard-widget most-recent">
                <h3>
                    Recent clients
                </h3>
                { recentClients.length ?
                    <ul>
                        { recentClients.map((clientData, index) => {
                            if(index > 15){
                                return null
                            }

                            const { client } = clientData

                            const name = getClientDisplayName(client)

                            let type 
                            let link 
    
                            if(client.__typename == 'Person'){
                                type = 'person'
                                link = '/clients/people/'+client.id
                            }else{
                                type = 'company'
                                link = '/clients/companies/'+client.id
                            }

                            const openedMoment = moment(clientData.date)

                            let dateString = 'Last opened '

                            if(openedMoment.isSame(moment(), 'day')){
                                dateString += 'today'
                            }else{
                                dateString += openedMoment.format('Do MMMM')
                            }

                            dateString += ' at '+openedMoment.format('h:mm a')
    
                            return (
                                <li key={client.id}>
                                    <Link to={link}>
                                        <img src={`/images/icons/${type}.png`} />
                                        <h3>
                                            { name }
                                        </h3>
                                        <span>
                                            { dateString }
                                        </span>
                                    </Link>
                                </li>
                            )
                        }) }
                    </ul>
                :
                    <EmptyAlert
                        title="Nothing to show just yet"
                        body="We'll show your most recent clients here."
                    />
                }
            </div>
        )
    }
}
