import React, { Component } from 'react'

import Table from 'components/Table/Table'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'

import { 
    INIT_DATA, 
    OPEN_MODAL, 
    TOGGLE_ADMIN,
    RESEND_INVITATION
} from 'graphql/all'
import { withApollo } from 'react-apollo'

import { removeSoftDeletedItems, isUserAdmin, isUserCurrentUser } from 'utils/utils'
import { graphql, Query, compose } from 'react-apollo'

import FontAwesome from 'react-fontawesome'

class Staff extends Component {
    state = {
        isLoading: false,
    }

    getClientCount = userId => {
        const { user } = this.props.initData

        let clientCount = 0

        user.people.forEach(person => {
            if(person.broker.id === userId){
                clientCount++
            }
        })

        user.companies.forEach(company => {
            if(company.broker.id === userId){
                clientCount++
            }
        })

        return clientCount

    }

    getTableValue = (key, item) => {
        let label = ''
        let empty = false

        if (item[key]) {
            label = item[key]
        } else {
            switch (key) {
                case 'firstName':
                    label = '(No first name)'
                    empty = true
                    break
                case 'lastName':
                    label = '(No last name)'
                    empty = true
                    break
                case 'clientCount':
                    label = this.getClientCount(item.id)
                    break
            }
        }

        if (key == 'isActive') {
            if (item.__typename == 'User' || item.isComplete) {
                label = (
                    <FontAwesome name="check" />
                )
            } else {
                label = (
                    <FontAwesome name="times" />
                )
            }
        }

        if (key == 'isAdmin') {
            if (isUserAdmin(this.props.client, item)) {
                label = (
                    <FontAwesome name="check" />
                )
            } else {
                label = (
                    <FontAwesome name="times" />
                )
            }
        }

        return {
            label,
            empty
        }
    }

    openInviteModal = () => {
        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'invite',
                dataType: null,
                isVisible: true,
                object: null
            }
        })
    }

    showCheck = (item, type) => {
        let shouldShow = false

        switch (type) {
            case 'edit':
                if (item.__typename == 'User') {
                    shouldShow = true
                }
                break
            case 'reassignClients':
                if(this.getClientCount(item.id)){
                    shouldShow = true
                }
                break
            case 'deleteUser':
                if (item.__typename == 'User' && !isUserCurrentUser(this.props.client, item)) {
                    shouldShow = true
                }
                break
            case 'setAdmin':
                if (!isUserAdmin(this.props.client, item) && (!item.__typename == 'User' || !isUserCurrentUser(this.props.client, item))) {
                    shouldShow = true
                }
                break
            case 'rescindAdmin':
                if (isUserAdmin(this.props.client, item) &&  (!item.__typename == 'User' || !isUserCurrentUser(this.props.client, item))) {
                    shouldShow = true
                }
                break
            case 'resendInvitation':
                if(item.__typename == 'Invitation'){
                    shouldShow = true
                }
                break
            case 'deleteInvitation':
                if (item.__typename == 'Invitation') {
                    shouldShow = true
                }
                break
        }

        return shouldShow
    }

    render() {
        let columnClass = "main-column full-width"

        if (this.state.isLoading) {
            columnClass += " loading"
        }

        const brokerage = removeSoftDeletedItems(this.props.data.brokerage)

        let rows = brokerage.users.concat(brokerage.invitations.filter(invitation => {
            return !invitation.isComplete
        }))

        this.tableData = {
            rows,
            columns: [
                {
                    label: 'First name',
                    type: 'value',
                    key: 'firstName'
                },
                {
                    label: 'Last name',
                    type: 'value',
                    key: 'lastName'
                },
                {
                    label: 'Email',
                    type: 'value',
                    key: 'email'
                },
                {
                    label: 'Number of clients',
                    type: 'value',
                    key: 'clientCount'
                },
                {
                    label: 'Is active?',
                    type: 'value',
                    key: 'isActive'
                },
                {
                    label: 'Is admin?',
                    type: 'value',
                    key: 'isAdmin'
                },
                {
                    label: '',
                    type: 'actions',
                    actions: [
                        {
                            label: 'Reassign clients',
                            key: 'reassignClients',
                            showCheck: this.showCheck,
                            callback: (person) => {
                                this.props.openModal({
                                    variables: {
                                        operation: 'reassign',
                                        dataType: null,
                                        isVisible: true,
                                        object: person
                                    }
                                })
                            }
                        }, {
                            label: 'Make admin',
                            key: 'setAdmin',
                            showCheck: this.showCheck,
                            callback: async (person) => {
                                await this.props.toggleAdmin({
                                    variables: {
                                        userId: person.id
                                    }
                                })
                            }
                        }, {
                            label: 'Rescind admin',
                            key: 'rescindAdmin',
                            showCheck: this.showCheck,
                            callback: async (person) => {
                                await this.props.toggleAdmin({
                                    variables: {
                                        userId: person.id
                                    }
                                })
                            }
                        }, {
                            label: 'Edit staff member',
                            key: 'edit',
                            showCheck: this.showCheck,
                            callback: (person) => {
                                this.props.openModal({
                                  variables: {
                                    operation: 'edit',
                                    dataType: 'user',
                                    isVisible: true,
                                    object: person
                                }})
                            }
                        }, {
                            label: 'Re-send invitation',
                            key: 'resendInvitation',
                            showCheck: this.showCheck,
                            callback: async invitation => {
                                await this.props.resendInvitation({
                                    variables: {
                                        payload: invitation.id
                                    }
                                }).then(response => {
                                    console.log(response)
                                })
                            }
                        },{
                            label: 'Delete staff member',
                            key: 'deleteUser',
                            showCheck: this.showCheck,
                            callback: (person) => {
                                this.props.openModal({
                                    variables: {
                                        operation: 'delete',
                                        dataType: 'user',
                                        isVisible: true,
                                        object: person
                                    }
                                })
                            }
                        }, {
                            label: 'Delete staff member',
                            key: 'deleteInvitation',
                            showCheck: this.showCheck,
                            callback: (person) => {
                                this.props.openModal({
                                    variables: {
                                        operation: 'delete',
                                        dataType: 'invitation',
                                        isVisible: true,
                                        object: person
                                    }
                                })
                            }
                        }
                    ]
                }
            ]
        }

        return (
            <div>
                <div className="heading-section">
                    <h1>
                        Staff
                    </h1>
                    <p>
                        Manage your staff here.
                    </p>
                </div>
                <div className="main-content-container">
                    {
                        this.state.isLoading ?
                            <Loader isLoading={true} showText={true} />
                            : null
                    }
                    <div className={columnClass}>
                        <div className="content-box">
                            <div className="search-button-container buttons-only">
                                <Button label={`Invite staff members`} level="high" callback={this.openInviteModal} />
                            </div>
                            <Table columns={this.tableData.columns} data={this.tableData.rows} getter={this.getTableValue} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(compose(
    graphql(OPEN_MODAL, { name: 'openModal' }),
    graphql(TOGGLE_ADMIN, { name: 'toggleAdmin' }),
    graphql(RESEND_INVITATION, { name: 'resendInvitation' }),
    graphql(INIT_DATA, { name: 'initData' }),
)(Staff))