import gql from 'graphql-tag'

export const DELETE_PERSON = gql`
    mutation DELETE_PERSON (
        $ids: [ID!]!
    ){
        deletePerson(
            ids: $ids
        ){
            id
            deletedAt
        }
    }
`

export const DELETE_COMPANY = gql`
    mutation DELETE_COMPANY (
        $ids: [ID!]!
    ){
        deleteCompany(
            ids: $ids
        ){
            id
            deletedAt
        }
    }
`

export const DELETE_ASSET = gql`
    mutation DELETE_ASSET (
        $ids: [ID!]!
    ){
        deleteAsset(
            ids: $ids
        ){
            id
            deletedAt
        }
    }
`

export const DELETE_LIABILITY = gql`
    mutation DELETE_LIABILITY (
        $ids: [ID!]!
    ){
        deleteLiability(
            ids: $ids
        ){
            id
            deletedAt
            personRelation {
                id
                deletedAt
            }
            companyRelation {
                id
                deletedAt
            }
        }
    }
`

export const DELETE_NOTE = gql`
    mutation DELETE_NOTE (
        $id: ID!
    ) {
        deleteNote (
            id: $id
        ) {
            id
            deletedAt
        }
    }
`

export const DELETE_EVENT = gql`
    mutation DELETE_EVENT (
        $id: ID!
    ) {
        deleteEvent (
            id: $id
        ) {
            id
            deletedAt
        }
    }
`
export const DELETE_USER = gql`
    mutation DELETE_USER (
        $id: ID!
    ){
        deleteUser(
            id: $id
        ){
            id
            deletedAt
        }
    }
`

export const DELETE_INVITATION = gql`
    mutation DELETE_INVITATION (
        $id: ID!
    ){
        deleteInvitation(
            id: $id
        ){
            id
            deletedAt
        }
    }
`