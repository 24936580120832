import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'
import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-client-preset'
import { createUploadLink } from 'apollo-upload-client'

import { AUTH_TOKEN } from './constants'
import { resolvers, defaults, cache} from './state/state'

import './styles/app.css'

const uploadLink = createUploadLink({ 
  uri: window.location.href.includes('localhost') ? 'http://localhost:4000' : 'https://api.zamm.com.au'
})

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  const authorizationHeader = token ? `Bearer ${token}` : null

  operation.setContext({
    headers: {
      authorization: authorizationHeader
    }
  })

  return forward(operation)
})

const httpLinkWithAuthToken = middlewareAuthLink.concat(uploadLink)

export const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache,
  resolvers
})

cache.writeData({
  data: defaults
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
  , document.getElementById('root'))
//registerServiceWorker()