import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import { cloneDeep } from 'lodash'
import Button from 'components/Button/Button'

import { withApollo } from 'react-apollo'

import { OPEN_MODAL } from 'graphql/modal-graphql'
import { compose } from 'react-apollo'

import { generateEvents } from 'utils/utils'

import SelectInput from 'components/Inputs/SelectInput'
import CheckboxInput from 'components/Inputs/CheckboxInput'

class Timeline extends Component {
    state = {
        selectedValue: null,
        showItems: false,
        showingCustom: true,
        scrollLeft: 0,
        scrollTop: 0,
        toggleValue: 0,
        blockTransitions: false
    }

    eventList = []
    liabilityList = []
    eventRefs = []

    componentDidMount = () => {
        this.setState({
            selectedValue: this.liabilityList[0].value
        })

        setTimeout(() => {
            this.setState({
                showItems: true
            })
        }, 150)

        setTimeout(_ => {
            this.scrollToNextActiveEvent('x')
        },300)
    }

    handleChange = value => {
        this.setState({
            selectedValue: value,
            scrollLeft: 0
        })
    }

    formatData = () => {
        const { liabilities, person, company } = this.props

        const generateEventResult = generateEvents(liabilities, [person], [company], this.state.selectedValue, this.props.type, null, null, this.state.showingCustom)

        this.eventList = generateEventResult.events
        this.liabilityList = generateEventResult.liabilities
    }

    addEvent = () => {
        const { person, company, liability } = this.props

        let modalObject = {
            personId: person ? person.id : null,
            companyId: company ? company.id : null,
            liabilityId: liability ? liability.id : null
        }

        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'add',
                dataType: 'event',
                isVisible: true,
                object: modalObject
            }
        })
    }

    getValidEventRefs = () => {
        let validEventRefs = this.eventRefs ? this.eventRefs.filter(eventRef => {
            return eventRef
        }) : []

        return validEventRefs
    }

    canScroll = () => {
        // sometimes they're null?
        let validEventRefs = this.getValidEventRefs()

        if (!validEventRefs.length) {
            return false
        }

        let offsets = []

        validEventRefs.forEach(ref => {
            if (ref) {
                offsets.push(ref.offsetLeft)
            }
        })

        let eventEndPoint = offsets[offsets.length - 1] + validEventRefs[validEventRefs.length - 1].getBoundingClientRect().width
        let viewportWidth = this.timelineRef.getBoundingClientRect().width

        if (eventEndPoint > viewportWidth) {
            return true
        } else {
            return false
        }
    }

    scrollEvents = dir => {
        const validEventRefs = this.getValidEventRefs()

        if (!validEventRefs.length) {
            return
        }

        let offsets = []

        validEventRefs.forEach(ref => {
            offsets.push(ref.offsetLeft)
        })

        let eventEndPoint = offsets[offsets.length - 1] + validEventRefs[validEventRefs.length - 1].getBoundingClientRect().width
        let viewportWidth = this.timelineRef.getBoundingClientRect().width

        if (dir === 'right') {
            // if the furthest right point is still outside the events box
            if (eventEndPoint - this.state.scrollLeft > viewportWidth) {
                let nextPointLeft

                offsets.forEach((offset, index) => {
                    if (offset === this.state.scrollLeft) {
                        nextPointLeft = offsets[(index + 1)]
                    }
                })

                this.setState({
                    scrollLeft: nextPointLeft
                })
            }
        } else {
            if (this.state.scrollLeft > 0) {
                let previousPointLeft

                offsets.forEach((offset, index) => {
                    if (offset === this.state.scrollLeft) {
                        previousPointLeft = offsets[(index - 1)]
                    }
                })

                this.setState({
                    scrollLeft: previousPointLeft
                })
            }
        }
    }

    scrollToNextActiveEvent = axis => {
        const nextActiveEventIndex = this.eventList.findIndex(event => {
            return event.isInFuture && !event.eventObject.isDismissed
        })

        if(!nextActiveEventIndex){
            return
        }

        const validEventRefs = this.getValidEventRefs()

        if(!validEventRefs.length){
            return
        }

        if(axis == 'x'){
            if(this.canScroll()){
                const scrollLeft = validEventRefs[nextActiveEventIndex].offsetLeft

                this.setState({
                    scrollLeft
                })
            }
        }else{
            const scrollTop = validEventRefs[nextActiveEventIndex].offsetTop

            this.timelineRef.scrollTop = (scrollTop - 80)
        }
    }

    editEvent = event => {
        const { person, company } = this.props

        let eventObject = cloneDeep(event)

        if (person) {
            eventObject.personId = person.id
        } else if (company) {
            eventObject.companyId = company.id
        }

        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'edit',
                dataType: 'event',
                isVisible: true,
                object: eventObject
            }
        })
    }

    deleteEvent = event => {
        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'delete',
                dataType: 'event',
                isVisible: true,
                object: event
            }
        })
    }

    toggleSwitch = () => {
        this.setState({
            blockTransitions: true
        }, _ => {
            window.requestAnimationFrame(_ => {
                this.setState({
                    toggleValue: !this.state.toggleValue
                }, _ => {
                    setTimeout(_ => {
                        if(!this.state.toggleValue){
                            this.scrollToNextActiveEvent('x')
                        }else{
                            this.scrollToNextActiveEvent('y')
                        }
                    },300)

                    setTimeout(_ => {
                        this.setState({
                            blockTransitions: false
                        })
                    },500)
                })
            })
        })
    }

    render() {
        const {
            toggleValue,
            blockTransitions,
            showItems
        } = this.state

        this.formatData()

        this.liabilityList.unshift({
            value: 'all',
            label: 'All liabilities'
        })

        let selectedValue = this.state.selectedValue

        if (!this.eventRefs.length) {
            this.eventRefs = []
        }

        let timelineClass = 'timeline-main no-padding'

        if(toggleValue){
            timelineClass += ' list-view'
        }

        if(blockTransitions){
            timelineClass += ' block-transitions'
        }

        return (
            <div className="timeline">
                <div className="heading-container">
                    <h3>
                        {this.props.type == 'overview' ? "Master event timeline" : "Event timeline"}
                    </h3>
                    <div className="custom-filter">
                        <CheckboxInput changeCallback={value => {
                            this.setState({
                                showingCustom: !this.state.showingCustom
                            })
                        }} value={this.state.showingCustom ? true : false} label={"Show custom events"}/>
                    </div>
                    {this.eventList.length ?
                        <div className={toggleValue == 0 ? "mini-switch" : "mini-switch list-view"} onClick={this.toggleSwitch}>
                            <label>
                                Timeline
                            </label>
                            <div>
                                <i></i>
                            </div>
                            <label>
                                List
                            </label>
                        </div>
                        :
                    null}
                </div>
                <div className={timelineClass}>
                    <div className={!this.eventList.length ? 'empty' : null}>
                        <div>
                            {this.eventList.length ?
                                <ul style={toggleValue == 0 ? { transform: `translateX(-${this.state.scrollLeft}px)` } : {}} ref={ref => { this.timelineRef = ref }} className={showItems ? "events visible" : "events"}>
                                    {
                                        this.eventList.map((event, index) => {
                                            let shouldAlert = !event.isInFuture && !event.eventObject.isDismissed

                                            let className = !event.isInFuture ? 'past' : null

                                            if(event.eventObject.isDismissed){
                                                className += ' dismissed'
                                            }

                                            const numberOfFiles = event.eventObject.files.length

                                            return (
                                                <li ref={ref => { this.eventRefs[index] = ref }} className={className} key={index}>
                                                    <FontAwesome name={shouldAlert ? 'exclamation' : "check"} />
                                                    <div style={{ transitionDelay: (index / 10) + 's' }}>
                                                        <p>
                                                            <strong className={shouldAlert ? 'alert' : null}>
                                                                {event.formattedDate}
                                                            </strong>
                                                            {event.title}
                                                        </p>
                                                        <span>
                                                            {toggleValue == 1 ? '(' : null}Added by {event.addedBy ? event.addedBy : '(nobody)'}{toggleValue == 1 ? ')' : null}
                                                            { numberOfFiles ? ` • ${numberOfFiles} file${ numberOfFiles > 1 ? 's' : '' }` : null }
                                                        </span>
                                                        <div className="event-controls">
                                                            <button onClick={() => { this.editEvent(event.eventObject) }}>
                                                                <FontAwesome name="eye" />
                                                                <span>
                                                                    View
                                                                </span>
                                                            </button>
                                                            {
                                                                event.eventObject.type !== 'LIABILITY_MODIFIED' ?
                                                                <button onClick={() => { this.deleteEvent(event.eventObject) }}>
                                                                    <FontAwesome name="trash" />
                                                                    <span>
                                                                        Delete
                                                                    </span>
                                                                </button>
                                                                : null
                                                            }
                                                        </div>
                                                        {event.loanName && this.props.type == 'overview' ?
                                                            <div className="event-loan">
                                                                { this.state.toggleValue == 1 ? 'From ' : null }
                                                                {event.loanName}
                                                            </div>
                                                            : null}
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                :
                                <div className="empty-alert">
                                    <h2>
                                        No events yet.
                                    </h2>
                                    <p>
                                        These'll add automatically when you have some valid liability data.
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="controls-row">
                            {
                                this.props.type == 'overview' ?
                                    <div className="filter">
                                        <SelectInput changeCallback={this.handleChange} label={'Show:'} value={selectedValue} values={this.liabilityList} />
                                    </div>
                                    : null
                            }
                            <div className="buttons">
                                <Button label="Add custom event" callback={this.addEvent} />
                                {this.canScroll() && this.state.toggleValue == 0 ?
                                    <div>
                                        <button onClick={() => { this.scrollEvents('left') }}>
                                            <FontAwesome name="arrow-left" />
                                        </button>
                                        <button onClick={() => { this.scrollEvents('right') }}>
                                            <FontAwesome name="arrow-right" />
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(withApollo)(Timeline)