import React, { Component } from 'react'
import { CSSTransition } from "react-transition-group"

import { GET_MODAL_DATA, OPEN_MODAL, CLOSE_MODAL } from 'graphql/modal-graphql'
import { graphql, Query, Mutation, compose } from 'react-apollo'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import Loader from 'components/Loader/Loader'

import ModalDelete from './ModalTypes/ModalDelete'
import ModalPerson from './ModalTypes/ModalPerson'
import ModalCompany from './ModalTypes/ModalCompany'
import ModalAsset from './ModalTypes/ModalAsset'
import ModalLiability from './ModalTypes/ModalLiability'
import ModalEvent from './ModalTypes/ModalEvent'
import ModalInvite from './ModalTypes/ModalInvite'
import ModalProfile from './ModalTypes/ModalProfile'
import ModalBrokerage from './ModalTypes/ModalBrokerage'
import ModalError from './ModalTypes/ModalError'
import ModalNotify from './ModalTypes/ModalNotify'
import ModalClientAllocation from './ModalTypes/ModalClientAllocation'
import ModalOnboarding from './ModalTypes/ModalOnboarding'

import { pluraliseObject } from 'utils/utils'

class Modal extends Component {
    state = {
        isLoading: false,
        showSuccess: false,
        miniModalVisible: false
    }

    closeModal = () => {
        this.props.closeModal()
    }

    setLoading = (isLoading) => {
        this.setState({
            isLoading
        })
    }

    handleSuccess = (callback = null) => {
        this.setState({
            isLoading: false,
            showSuccess: true
        })

        setTimeout(() => {
            this.setState({
                showSuccess: false
            })

            this.props.closeModal()

            if(callback){
                callback()
            }
        },1000)
    }

    componentDidMount(){
      document.addEventListener("keydown", this.handleKeyPress, false)
    }

    componentWillUnmount(){
      document.removeEventListener("keydown", this.handleKeyPress, false)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(!this.targetElement && document.querySelector('#modal')){
            this.targetElement = document.querySelector('#modal')
        }

        const newModalData = this.props.getModalData.currentModal
        const oldModalData = prevProps.getModalData.currentModal

        if(!newModalData){
            return
        }

        if(newModalData.object && !oldModalData.object){
            disableBodyScroll(this.targetElement)
        }else if(!newModalData.object && oldModalData.object){
            setTimeout(() => {
                enableBodyScroll(this.targetElement)
    
                clearAllBodyScrollLocks()
            },300)
        }
    }

    handleKeyPress = e => {
        if(e.key == 'Escape'){
            this.closeModal()
        }
    }

    render() {
        return (
            <Query query={GET_MODAL_DATA}>
                {({ data }) => {
                    const currentModal = data.currentModal

                    let Component
                    let title

                    if(!currentModal){
                        return null
                    }

                    switch(currentModal.operation){
                        case 'delete':
                            if(currentModal.object && currentModal.object.length > 1){
                                title = `Delete ${currentModal.object.length} ${pluraliseObject(currentModal.dataType)}?`
                            }else{
                                title = `Delete this ${currentModal.dataType}?`
                            }

                            Component = ModalDelete
                            break
                        case 'invite':
                            title = 'Invite staff members'
                            Component = ModalInvite
                            break
                        case 'error':
                            title = "Something's wrong."
                            Component = ModalError
                            break
                        case 'reassign':
                            if(currentModal.object){
                                title = `Reassign ${currentModal.object.firstName}'s clients`
                            }else{
                                title = null
                            }

                            Component = ModalClientAllocation
                            break
                        case 'notify':
                            title = `Success!`
                            Component = ModalNotify
                            break
                        case 'onboard':
                            title = 'Getting started'
                            Component = ModalOnboarding
                            break
                        default:
                            switch (currentModal.dataType) {
                                case 'person':
                                    Component = ModalPerson

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new person'
                                            break;
                                        case 'edit':
                                            title = 'Edit person'
                                            break;
                                    }
                                    break
                                case 'user':
                                    Component = ModalProfile

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new person'
                                            break
                                        case 'edit':
                                            title = 'Edit user'
                                            break
                                    }
                                    break    
                                case 'company':
                                    Component = ModalCompany;

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new company'
                                            break
                                        case 'edit':
                                            title = 'Edit company'
                                            break
                                    }
                                    break
                                case 'asset':
                                    Component = ModalAsset;

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new asset'
                                            break
                                        case 'edit':
                                            title = 'Edit asset'
                                            break
                                    }
                                    break
                                case 'liability':
                                    Component = ModalLiability

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new liability'
                                            break
                                        case 'edit':
                                            title = 'Edit liability'
                                            break
                                    }
                                    break
                                case 'event':
                                    Component = ModalEvent

                                    switch (currentModal.operation) {
                                        case 'add':
                                            title = 'Add a new event'
                                            break
                                        case 'edit':
                                            title = 'Edit event'
                                            break
                                    }
                                    break
                                case 'brokerage':
                                    Component = ModalBrokerage
                                    title = 'Edit brokerage'
                                    break
                            }

                    }

                    let className = currentModal.operation+'-'+currentModal.dataType


                    return (
                        <CSSTransition in={currentModal.isVisible ? true : false} mountOnEnter unmountOnExit classNames="modal" timeout={{ enter: 1, exit: 300 }}>
                            <div id="modal" className={"modal-container "+className} data-type={currentModal.dataType}>
                                <div onClick={e => e.stopPropagation()}>
                                    <Loader isLoading={this.state.isLoading} showText={true} />
                                    <Loader isSuccess={true} isLoading={this.state.showSuccess} showText={true} />
                                    <h2>
                                        {title}
                                        <button className="close-modal" onClick={this.closeModal}>
                                            &times;
                                        </button>
                                    </h2>
                                    {Component ?
                                        <Component 
                                            setLoading={this.setLoading} 
                                            dataType={currentModal.dataType} 
                                            object={currentModal.object} 
                                            closeCallback={this.closeModal} 
                                            handleSuccess={this.handleSuccess} 
                                            isLoading={this.state.isLoading}
                                        />
                                    : null}
                                </div>
                            </div>
                        </CSSTransition>
                    )
                }}
            </Query>
        )
    }
}

export default compose(
    graphql(OPEN_MODAL, { name: 'openModal' }),
    graphql(GET_MODAL_DATA, { name: 'getModalData' }),
    graphql(CLOSE_MODAL, { name: 'closeModal' }),
)(Modal)