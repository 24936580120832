import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Button from 'components/Button/Button'

class Sidebar extends Component {

  state = {
    selectedTab: 1
  }

  render(){
    const { isShrunk } = this.props

    return (
      <aside className={isShrunk ? 'shrunk' : null}>
        <ul className="menu-items">
          {
            this.props.routes.map((route, i) => {
              return (
                <li key={i} className={this.state.selectedTab === i ? 'selected' : null}>
                  <NavLink to={route.path}>
                    <img src={`/images/icons/${route.label.toLowerCase()}.png`} />
                    <span>
                      { route.label }
                    </span>
                  </NavLink>
                </li>
              )
            })
          }
        </ul>
        { /*<Button 
          className="shrink"
          label={isShrunk ? 'Grow' : 'Shrink'} 
          icon={isShrunk ? "angle-right" : "angle-left"} 
          iconOnRight={isShrunk}
          callback={this.props.shrinkSidebar} 
        /> */ }
      </aside>
    )
  }
}

export default Sidebar;
