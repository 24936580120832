import gql from 'graphql-tag'

import { 
  LiabilityFragment, 
  EventFragmentFour, 
  PersonFragment, 
  CompanyFragment,
  AddressFragment
} from './fragments-graphql'

export const INIT_DATA = gql`
  query brokerData{
    user {
      id
      brokerage {
        id
        name
        slug
        authorisedDomain
        isMasterAccount
        logo {
          id
          url
        }
        selectedLenders {
          id
        }
        users {
          id
          firstName
          lastName
          email
        }
        invitations {
          id
          email
          isComplete
          isAdmin
          brokerage {
            id
            authorisedDomain
            logo {
              id
              url
            }
          }
          inviter {
            id
          }
        }
        admins {
          id
        }
      }
      activated
      firstName
      lastName
      email
      hasOnboarded
      mostRecentClients
      people {
        ...PersonFragment
        events {
          ...EventFragmentFour
        }
        liabilities {
          id
          percent
          personRelation {
            id
            name
            events {
              id
              createdAt
              updatedAt
              deletedAt
              title
              type
              date
            }
          }
        }
        assets {
          id
          percent
          personRelation {
            id
            name
            address {
              ...AddressFragment
            }
          }
        }
      }
      companies {
        ...CompanyFragment
        events {
          ...EventFragmentFour
        }
        liabilities {
          id
          percent
          companyRelation {
            id
            name
            events {
              id
              createdAt
              updatedAt
              deletedAt
              title
              type
              date
            }
          }
        }
        assets {
          id
          percent
          companyRelation {
            id
            name
            address {
              ...AddressFragment
            }
          }
        }
      }
    }
  }
  ${PersonFragment}
  ${CompanyFragment}
  ${EventFragmentFour}
  ${AddressFragment}
`

export const TOGGLE_SELECTED_LENDERS = gql`
mutation toggleSelectedLenders(
  $payload: ID!
) {
  toggleSelectedLenders(
    payload: $payload
  ) {
    id
    selectedLenders {
      id
    }
  }
}
`

export const DASHBOARD_DATA = gql`
  query dashboardData{
    dashboardData {
      id
      mostRecentClients
      liabilities {
        ...LiabilityFragment
      }
    }
  }
  ${LiabilityFragment}
`

export const POST_INVITES = gql`
  mutation postInvites(
    $emails: [String!]!
  ) {
    postInvites(
      emails: $emails
    ) {
      invitations {
        id
        createdAt
        deletedAt
        email
        isComplete
        isAdmin
        brokerage {
          id
          authorisedDomain
          logo {
            id
            url
          }
          name
        }
        inviter {
          id
          firstName
          lastName
        }
      }
      takenEmails
    }
  }
`

export const RESEND_INVITATION = gql`
 mutation resendInvitation(
   $payload: ID!
 ) {
   resendInvitation (
     payload: $payload
   )
 }
`

export const TOGGLE_ADMIN = gql`
  mutation toggleAdmin(
    $userId: ID!
  ) {
    toggleAdmin(
      userId: $userId
    ) {
      id
      admins {
        id
      }
      invitations {
        id
        isAdmin
      }
    }
  }
`

export const REASSIGN_CLIENTS = gql`
  mutation reassignClients(
    $payload: [UpdatedBrokerPayload!]!
  ) {
    reassignClients(
      payload: $payload
    ) {
      people {
        id
        broker {
          id
        }
      }
      companies {
        id
        broker {
          id
        }
      }
    }
  }
`

export const RESEND_CONFIRMATION = gql`
  mutation resendConfirmation(
    $nothing: String
  ){
    resendConfirmation (
      nothing: $nothing
    )
  }
`

export const SET_SORTING_PREF = gql`
  mutation SET_SORTING_PREF (
    $path: String!
    $column: String!
    $direction: String!
  ) {
    setSortingPref(
      path: $path
      column: $column
      direction: $direction
    ) @client
  }
`

export const GET_SORTING_PREF = gql`
    query GET_SORTING_PREF {
      sortingPrefs @client {
        asset {
          column
          direction
        }
        liability {
          column
          direction
        }
        reporting {
          column
          direction
        }
        people {
          column
          direction
        }
        companies {
          column
          direction
        }
        companies {
          column
          direction
        }
      }
    }
`