import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Connections extends Component {
    render(){
        let connections = []

        if(this.props.company){
            connections = [
                ...this.props.company.people
            ]
        }else if(this.props.person){
            connections = [
                ...this.props.person.companies
            ]

            if(this.props.person.spouse){
                connections.push({
                    ...this.props.person.spouse,
                    relation: 'Spouse'
                })
            }
        }

        if(!connections.length){
            return null
        }
        
        return (
            <div className="content-box">
                <h3>
                    Connections
                </h3>
                <ul className="widget-list has-link">
                    {connections.map((connection, index) => {
                        let type 
                        let value
                        let link 

                        if(connection.__typename == 'Person'){
                            type = 'person'
                            value = connection.firstName+' '+connection.lastName
                            link = '/clients/people/'+connection.id
                        }else{
                            type = 'company'
                            value = connection.name
                            link = '/clients/companies/'+connection.id
                        }

                        return (
                            <li key={index}>
                                <Link to={link}>
                                    <img src={`/images/icons/${type}.png`} />
                                    <span>
                                        { value }
                                        { connection.relation ? 
                                            <span>
                                                ({ connection.relation })
                                            </span>
                                            :
                                            null 
                                        }
                                    </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default Connections