import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'

class CheckboxInput extends Component {
  handleChange = _ => {
    this.props.changeCallback(this.props.value ? false : true)
  }

  render(){
    const { label, error, value } = this.props

    return (
      <div className={error ? "input-wrap checkbox error" : "checkbox input-wrap"}>
        <label>
            <input 
              onChange={this.handleChange} 
              checked={value ? true : false} 
              type="checkbox" 
            />
            <span 
              dangerouslySetInnerHTML={{ __html: label ? label : '\u00A0' }} 
            />
        </label>
        <ValidationError error={error} />
      </div>
    )
  }
}

export default CheckboxInput
