import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import { Redirect } from "react-router-dom"
import * as yup from 'yup'
import ValidationError from 'components/Errors/ValidationError'

import { 
	withApollo, 
	compose,
	graphql
} from 'react-apollo'

import { 
	getCurrentUser, 
	getEmailDomain 
} from 'utils/utils'

import { IS_BROKERAGE_DOMAIN_AVAILABLE } from 'graphql/all'

import CheckboxInput from 'components/Inputs/CheckboxInput'

class RegisterBrokerage extends Component {
	state = {
		id: null,
		name: '',
		authorisedDomain: '',
		error: false,
		alertType: false,
		errors: {},
		isDomainAvailable: false,
		imageUrl: null,
		imageFile: null
	}

	componentWillMount = () => {
		if(this.props.data){
			const brokerage = this.props.data

			console.log(brokerage)

			this.setState({
				id: brokerage.id,
				name: brokerage.name,
				authorisedDomain: brokerage.authorisedDomain,
				imageUrl: brokerage.logo ? brokerage.logo.url : null
			})

			this.props.client.query({
				query: IS_BROKERAGE_DOMAIN_AVAILABLE,
				variables: {
					id: this.props.data.id
				}
			}).then(response => {
				this.setState({
					isDomainAvailable: response.data.isBrokerageDomainAvailable
				})
			}).catch(error => {
				console.log(error)
			})
		}
	}

	handleSubmit = async () => {
		const validationSchema = yup.object().shape({
			name: yup.string().required('Please enter Brokerage name.'),
		});

		try {
			await validationSchema.validate({ ...this.state }, { abortEarly: false })
		} catch (error) {
			return this.handleError(error)
		}
		
		console.log(this.state)

		try {
			this.props.callback(this.state, this.props.path, (response) => {
				console.log(response)
				if (response && response.length) {
					this.handleError(response[0].message);
				} else {
					this.handleError('Uh oh! A server error has occurred.');
				}
			}, () => {
				this.handleError('Brokerage has been updated successfully.', 'success')
			});
		} catch (error) {
			console.log(error)
		}
	}

	handleError = (error, type = null) => {
		if(error.inner){
			const errors = error.inner

			let newErrorState = {}

			errors.forEach(error => {
				newErrorState[error.path] = error.message
			})

			this.setState({
				hasErrors: true,
				errors: newErrorState,
				error: type ? error : null,
				alertType: type
			})
		}else{
			this.setState({
				error: error,
				alertType: type
			})
		}
	}

	isVowel = letter => {
		let comparisonLetter = letter.toLowerCase()

		switch(comparisonLetter){
			case 'a':
			case 'e':
			case 'i':
			case 'o':
			case 'u':
				return true
			default:
				return false
		}
	}

	handleFileChange = e => {
		let allFiles = [...e.target.files]

		if(!allFiles.length){
			return 
		}

		let file = allFiles[0]

		let reader = new FileReader()
		let result 

		if(file){
			reader.onloadend = () => {
				result = {
					object: file,
					previewUrl: reader.result
				}

				this.setState({
					imageUrl: result.previewUrl,
					imageFile: file
				})
			}

			reader.readAsDataURL(file)
		}

		e.target.value = null
	}

	render() {
		const {
			user,
			isEdit,
			isUniqueDomain,
			client
		} = this.props

		const {
			imageUrl
		} = this.state

		if (!user && !isEdit) {
			return (
				<Redirect to="/auth/register" />
			)
		}

		let currentUser
		let domain = null

		if(!isEdit){
			currentUser = user
		}else{
			currentUser = getCurrentUser(client)
		}

		if(isUniqueDomain || this.state.isDomainAvailable){
			domain = getEmailDomain(currentUser.email)
		}

		return (
			<div className="brokerage-form" onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
				<MiniResponse type={this.state.alertType} message={this.state.error} />
				<Loader isLoading={this.props.isLoading} />
				{!this.props.isEdit ?
					<p>
						You're almost there, {user.firstName}!<br />
						Create a new brokerage below.
					</p>
					: null
				}
				<div className="input-halves">
					<div className="input-wrap image">
						<div className="image-container">
							<input type="file" onChange={this.handleFileChange} />
							<img src={imageUrl ? imageUrl : "/images/image-empty.jpg"} />
						</div>
					</div>
					<div className="input-wrap">
						{ this.props.isEdit ?
							<label>
								Brokerage name
							</label>
							: null
						}
						<input placeholder="Brokerage name" value={this.state.name ? this.state.name : ''} type="text" onChange={e => this.setState({ name: e.target.value })} />
						<ValidationError error={this.state.errors['name'] ? this.state.errors['name'] : null} />
						{ domain ? 
							<CheckboxInput changeCallback={value => {
								this.setState({
									authorisedDomain: value ? domain : null
								})
							}} value={this.state.authorisedDomain ? true : false} label={`Anyone with ${this.isVowel(domain.charAt(0)) ? 'an' : 'a'} <strong>${domain}</strong> email can join this brokerage.`}/>
						: null }
					</div>
				</div>
				<div className="button-row">
					<button className="button high" onClick={this.handleSubmit}>
						{ this.props.isEdit ? 'Save' : 'Register' } brokerage
					</button>
				</div>
				{!this.props.isEdit ?
					<div className="lower-row">
						<Link to="/auth/register">
							&lsaquo; Back to account registration
						</Link>
					</div>
					: null
				}
			</div>
		)
	}
}

export default withApollo(RegisterBrokerage)