import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import * as yup from 'yup'

class ForgotPassword extends Component {
    state = {
        email: '',
    }

    handleSubmit = async () => {
        let loginSchema = yup.object().shape({
            email: yup.string().email("That doesn't look like a valid email.").required('Please enter an email address.'),
        })

        try {
            await loginSchema.validate({ ...this.state })
        } catch (error) {
            return this.handleError(error.message, 'error')
        }

        this.props.callback(this.state, this.props.path, (response) => {
            if (response.length) {
                this.handleError(response[0].message, 'error');
            } else {
                this.handleError('Uh oh! A server error has occurred.', 'error');
            }
        }, () => {
            this.handleError("A password reset request has been sent to your email.", 'success')
        })
    }

    handleError = (message, alertType) => {
        this.setState({
            error: message,
            alertType
        })

        setTimeout(() => {
            this.setState({
                error: null
            })
        }, 3000);
    }

    render() {
        return (
            <div onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
                <MiniResponse type={this.state.alertType} message={this.state.error} />
                <Loader isLoading={this.props.isLoading} />
                <input placeholder="Enter your email address" type="email" onChange={e => this.setState({ email: e.target.value })} />
                <button className="button high" onClick={this.handleSubmit}>
                    Reset password
                </button>
                <div className="lower-row">
                    <Link to="/auth/login">
                        &lsaquo; Back to login
                    </Link>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
