import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import PopMenu from 'components/PopMenu/PopMenu'
import SwitchInput from 'components/Inputs/SwitchInput'

export default class TableRow extends Component {
	shouldComponentUpdate = nextProps => {
		// if we're opening a menu and it's not for us, don't re-render.
		if(this.props.openMenu !== null || nextProps.openMenu !== null){
			if(this.props.openMenu != this.props.rowIndex && nextProps.openMenu != nextProps.rowIndex){
				return false
			}
		}

		const changedKeys = []

		Object.keys(this.props).forEach(key => {
			if(this.props[key] != nextProps[key]){
				changedKeys.push(key)
			}
		}) 

		const {
			item
		} = this.props

		if(changedKeys.length == 1 && changedKeys.indexOf('selectedRows') !== -1){
			const isItemInProps = this.props.selectedRows.find(row => {
				return row.id == item.id
			})

			const isItemInNextProps = nextProps.selectedRows.find(row => {
				return row.id == item.id
			})

			if(!isItemInProps && !isItemInNextProps){
				return false
			}
		}

		return true
	}

	render() {
		const {
			columns,
			selectedRows,
			generatedTableData,
			selectRow,
			openMenu,
			rowIndex,
			item,
			thisChildIndex,
			thisHeadingIndex,
			getter,
			toggleMenu,
			childIterator,
			rowNodeRefs
		} = this.props

		return (
			<tr>
				{columns.map((column, columnIndex) => {
					const linkData = getter(column.key, item, column.type)

					if(!linkData){
						return null
					}

					switch(column.type){

						case 'checkbox':
							return (
								<td key={columnIndex} index={columnIndex}>
									<input 
										type="checkbox" 
										checked={selectedRows.indexOf(item) !== -1} 
										onChange={e => {selectRow(item)}} 
									/>
								</td>
							)
						case 'value':
							if(generatedTableData){
								generatedTableData[childIterator].push(linkData.label)
							}

							if(column.isHidden){
								return null
							}

							return (
								<td key={columnIndex} index={columnIndex}>
									<span className={linkData.empty ? 'empty' : null}>
										{
											linkData.href ? 
											<a 
												href={linkData.href} 
												onClick={(e) => {
													e.preventDefault()
													if(linkData.callback){
														linkData.callback(item, linkData.href)
													}
												}}
											>
												{ linkData.label }
											</a>
											:
											<span onClick={() => linkData.callback ? linkData.callback(item) : null}>
												{ linkData.label }
											</span>
										}
									</span>
								</td>
							)
						case 'input':
							let inputStatus = linkData.check(false, item, column.key, linkData.value)

							return (
								<td key={columnIndex} index={columnIndex}>
									<span className={inputStatus}>
										<input 
											type="text" 
											value={(linkData.value || linkData.value === 0) ? linkData.value : ''} 
											placeholder={column.placeholder ? column.placeholder : null} 
											onChange={e => {linkData.handleChange(thisHeadingIndex, thisChildIndex, column.key, e.target.value)}} 
										/>
									</span>
								</td>
							)
						case 'switch':
							return (
								<td key={columnIndex} index={columnIndex}>
									<span>
										<SwitchInput 
											changeCallback={value => linkData.handleChange(thisHeadingIndex, thisChildIndex, column.key,value)} 
											{ ...column.props } 
											value={linkData.value} 
										/>
									</span>
								</td>
							)
						case 'actions':
							return (
								<td key={columnIndex} index={columnIndex}>
									<div 
										className="actions" 
										ref={node => rowNodeRefs[rowIndex] = node}
									>
										<button onClick={() => {toggleMenu(rowIndex)}}>
											Actions
											<FontAwesome name="angle-down" />
										</button>
										<PopMenu 
											callbackObject={item} 
											closeCallback={() => {toggleMenu(null)}} 
											items={column.actions} 
											isOpen={openMenu === rowIndex} 
										/>
									</div>
								</td>
							)
						case 'mini-actions':
							return (
								<td key={columnIndex} index={columnIndex}>
									<div className="mini-actions">
										<button 
											onClick={() => { linkData.callback(thisHeadingIndex, thisChildIndex) }}
										>
											<FontAwesome name="trash" />
										</button>
									</div>
								</td>
							)
					}
				})}
			</tr>
		)
	}
}
