import React, { Component } from 'react'
import { cloneDeep } from 'lodash'

import { CSSTransition } from "react-transition-group"

import { graphql, compose } from 'react-apollo'
import { UPSERT_PERSON, INIT_DATA } from 'graphql/all'

import ModalForm from '../ModalComponents/ModalForm'
import Button from 'components/Button/Button'

import { stripTypenamesFromMutations } from 'utils/utils'
import * as yup from 'yup'

class ModalMiniNew extends Component {
    state = {
        fields: {
            firstName: '',
            lastName: '',
        },
        errors: []
    }

    fieldColumns = [[[
        {
            label: 'First name',
            required: true,
            type: 'text',
            props: { type: 'text' },
            key: 'firstName'   
        },
        {
            label: 'Last name',
            required: true,
            type: 'text',
            props: { type: 'text' },
            key: 'lastName'   
        },
    ]]]

    componentDidUpdate = prevProps => {
        if(prevProps.isVisible && !this.props.isVisible){
            this.setState({
                fields: {
                    firstName: '',
                    lastName: ''
                }
            })
        }
    }

    handleChange = (key, value) => {
        let { fields } = cloneDeep(this.state)
        
        fields[key] = value

        this.setState({
            fields
        })
    }

    handleSubmit = async (openNextModal) => {
        const validationSchema = yup.object().shape({
            firstName: yup.string().required('Please enter a first name.'),
            lastName: yup.string().required('Please enter a last name'),
        });

        try {
            await validationSchema.validate({ ...this.state.fields }, { abortEarly: false})
        } catch (error) {
            return this.handleError(error)
        }

        this.setState({
            hasErrors: false
        })

        let fields = stripTypenamesFromMutations(this.state.fields)

        try{
            await this.props.upsertPerson({
                variables: {
                    payload: {
                        ...fields
                    },
                },
                update: (store, { data }) => {
                    const initDataQuery = store.readQuery({query: INIT_DATA})
                    const { upsertPerson } = data

                    initDataQuery.user.people.push(upsertPerson)

                    store.writeQuery({query: INIT_DATA, data: initDataQuery})
                },
            }).then(data => {
                this.props.callback(data)
            })
        }catch(error){
            const clientErrors = error.graphQLErrors
            
            console.log(error)
            console.log(clientErrors)
        }
    }

    render(){
        return (
            <CSSTransition 
                in={this.props.isVisible ? true : false} 
                mountOnEnter 
                unmountOnExit 
                classNames="mini-modal" 
                timeout={{ enter: 1, exit: 300 }}
            >
                <div className="modal-mini">
                    <div>
                        <h3>
                            Create a new person
                        </h3>
                        <p>
                            More details can be added when this submission is complete.
                        </p>
                        <ModalForm 
                            errors={this.state.errors} 
                            handleChange={this.handleChange} 
                            fieldState={this.state.fields} 
                            columns={this.fieldColumns} 
                        />
                        <Button 
                            label="Save person" 
                            level="high" 
                            callback={() => {this.handleSubmit()}} 
                        />
                        <Button 
                            label="Cancel" 
                            level="low" 
                            callback={() => {this.props.callback(false)}} 
                        />
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default compose(
    graphql(UPSERT_PERSON, {name: 'upsertPerson'}),
    graphql(INIT_DATA, {name: 'initData'})
  ) (ModalMiniNew)
  