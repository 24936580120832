import React from 'react'
import FontAwesome from 'react-fontawesome'

const ValidationError = ({error}) => (
    <div className={error ? 'validation-error visible' : 'validation-error'}>
      <FontAwesome name="exclamation-triangle" />
      { error }
    </div>
)

export default ValidationError;
