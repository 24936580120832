import gql from 'graphql-tag'

import { UserFragment } from './fragments-graphql'

export const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION (
    $email: String!, 
    $password: String!
  ) {
    login (
      email: $email, 
      password: $password
    ) {
      token
      user {
        firstName
        lastName
        brokerage {
          id
          name
          slug
          logo {
            id
            url
          }
        }
      }
    }
  }
`


export const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION (
    $id: ID
    $email: String!, 
    $password: String!, 
    $firstName: String!, 
    $lastName: String!
    $invitationId: ID
  ) {
    signup(
      id: $id,
      email: $email, 
      password: $password, 
      firstName: $firstName, 
      lastName: $lastName
      invitationId: $invitationId
    ) {
      token
      isUniqueDomain
      authorisedBrokerage{
        id
        name
        logo {
          id
          url
        }
      }
      user {
        id
        firstName
        lastName
        email
        brokerage {
          id
          name
          slug
          logo {
            id
            url
          }
        }
      }
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation UPDATE_USER_MUTATION (
    $payload: UpdateUserPayload
  ) {
      updateUserMutation(
        payload: $payload
      ){
        ...UserFragment
      }
    }
    ${UserFragment}
`

export const SET_ONBOARDED = gql`
    mutation SET_ONBOARDED{
      setOnboarded {
        ...UserFragment
      }
    }
    ${UserFragment}
`

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UPDATE_PASSWORD_MUTATION (
    $payload: UpdatePasswordPayload
  ) {
      updatePasswordMutation(
        payload: $payload
      ){
        id
      }
    }
`

export const SIGNUP_BROKERAGE_MUTATION = gql`
  mutation SIGNUP_BROKERAGE_MUTATION (
    $name: String!
    $authorisedDomain: String
    $logo: Upload
  ) {
    signupBrokerage (
      name: $name
      authorisedDomain: $authorisedDomain
      logo: $logo
    ) {
      id
      name
      logo {
        id
        url
      }
      authorisedDomain
      slug
      users {
        id
        brokerage {
          id
        }
      }
    }
  }
`

export const UPDATE_BROKERAGE_MUTATION = gql`
  mutation UPDATE_BROKERAGE_MUTATION (
    $name: String!
    $id: ID!
    $authorisedDomain: String
    $logo: Upload
  ) {
    updateBrokerage (
      name: $name
      id: $id
      authorisedDomain: $authorisedDomain
      logo: $logo
    ) {
      id
      name
      authorisedDomain
      logo {
        id
        url
      }
    }
  }
`

export const IS_BROKERAGE_DOMAIN_AVAILABLE = gql`
  query IS_BROKERAGE_DOMAIN_AVAILABLE (
    $id: ID!
  ) {
    isBrokerageDomainAvailable (
      id: $id
    )
  }
`

export const JOIN_AUTHORISED_BROKERAGE = gql`
  mutation JOIN_AUTHORISED_BROKERAGE (
    $id: ID!
  ) {
    joinAuthorisedBrokerage (
      id: $id
    ) {
		id
		name
		authorisedDomain
    slug
    logo {
      id
      url
    }
		users {
		    id
        brokerage {
          id
        }
	  	}
    }
  }
`

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation FORGOT_PASSWORD_MUTATION (
    $email: String!
  ) {
    forgotPassword (
      email: $email
    ) {
     success 
    }
  }
`

export const NEW_PASSWORD_MUTATION = gql`
  mutation NEW_PASSWORD_MUTATION (
    $id: ID!
    $password: String!
  ) {
    newPassword (
      id: $id
      password: $password
    ) {
      success
    }
  }
`

export const REQUEST_ACTION_DATA = gql`
  query REQUEST_ACTION_DATA (
    $id: ID!
    $type: String!
  ) {
    actionData (
      id: $id
      type: $type
    ) {
      type
      invitation {
        id
        email
        isComplete
        isAdmin
        brokerage {
          id
          name
          logo {
            id
            url
          }
        }
        inviter {
          id
        }
      }
    }
  }
`

export const GET_ACTION_DATA = gql`
  query GET_ACTION_DATA {
      action @client {
          type,
          object,
          error
      }
  }
`

export const SET_REDIRECT_PATH = gql`
  mutation SET_REDIRECT_PATH (
    $path: String!
  ) {
    setRedirectPath(
      path: $path
    ) @client
  }
`

export const GET_REDIRECT_PATH = gql`
  query GET_REDIRECT_PATH {
    redirectPath @client {
      path
    }
  }
`

export const CLEAR_REDIRECT_PATH = gql`
  mutation CLEAR_REDIRECT_PATH (
    $fakeDataCauseApolloIsSilly: String
  ) {
    clearRedirectPath(
      fakeDataCauseApolloIsSilly: $fakeDataCauseApolloIsSilly
    ) @client
  }
`