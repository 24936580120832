import gql from 'graphql-tag'

import { AssetFragment, LiabilityFragment } from './fragments-graphql'

export const GET_ASSETS = gql`
  query getAssetData{
    assets {
        ...AssetFragment
    }
  }
  ${AssetFragment}
`

export const GET_LIABILITIES = gql`
  query getLiabilityData{
    liabilities {
        ...LiabilityFragment
    }
  }
  ${LiabilityFragment}
`