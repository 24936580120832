import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'

class Button extends Component {
    render(){
        const { level, disabled, tooltip, callback, icon, iconOnRight, label, className } = this.props

        let buttonClass = 'button ';

        buttonClass += level ? level : '';

        if(disabled){
            buttonClass += ' disabled'
        }

        if(tooltip){
            buttonClass += ' has-tooltip'
        }

        if(className){
            buttonClass += ' '+className
        }

        return (
            <button onClick={disabled ? null : callback} className={buttonClass}>
                { (icon && !iconOnRight) && <FontAwesome name={icon} /> }
                { label }
                { (icon && iconOnRight) && <FontAwesome name={icon} /> }
                { tooltip &&
                    <span className="tooltip">
                        { tooltip }
                    </span>
                }
            </button>
        )
    }
}

export default Button;