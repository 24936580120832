export const industryData = {
	"industries": [
    {
      "label": "Accounting",
      "value": "ACCOUNTING"
    },
    {
      "label": "Airlines/Aviation",
      "value": "AIRLINES_AVIATION"
    },
    {
      "label": "Alternative Dispute Resolution",
      "value": "ALTERNATIVE_DISPUTE_RESOLUTION"
    },
    {
      "label": "Alternative Medicine",
      "value": "ALTERNATIVE_MEDICINE"
    },
    {
      "label": "Animation",
      "value": "ANIMATION"
    },
        {
      "label": "Apparel Fashion",
      "value": "APPAREL_FASHION"
    },
    {
      "label": "Architecture Planning",
      "value": "ARCHITECTURE_PLANNING"
    },
    {
      "label": "Arts Crafts",
      "value": "ARTS_CRAFTS"
    },
    {
      "label": "Automotive",
      "value": "AUTOMOTIVE"
    },
    {
      "label": "Aviation Aerospace",
      "value": "AVIATION_AEROSPACE"
    },
    {
      "label": "Banking",
      "value": "BANKING"
    },
    {
      "label": "Biotechnology",
      "value": "BIOTECHNOLOGY"
    },
    {
      "label": "Broadcast Media",
      "value": "BROADCAST_MEDIA"
    },
    {
      "label": "Building Materials",
      "value": "BUILDING_MATERIALS"
    },
    {
      "label": "Building Materials",
      "value": "BUILDING_MATERIALS"
    },
    {
      "label": "Business Supplies Equipment",
      "value": "BUSINESS_SUPPLIES_EQUIPMENT"
    },
    {
      "label": "Capital Markets",
      "value": "CAPITAL_MARKETS"
    },
    {
      "label": "Chemicals",
      "value": "CHEMICALS"
    },
    {
      "label": "Civic Social Organisation",
      "value": "CIVIC_SOCIAL_ORGANIZATION"
    },
    {
      "label": "Civil Engineering",
      "value": "CIVIL_ENGINEERING"
    },
    {
      "label": "Commercial Real Estate",
      "value": "COMMERCIAL_REAL_ESTATE"
    },
    {
      "label": "Computer Network Security",
      "value": "COMPUTER_NETWORK_SECURITY"
    },
    {
      "label": "Computer Games",
      "value": "COMPUTER_GAMES"
    },
    {
      "label": "Computer Hardware",
      "value": "COMPUTER_HARDWARE"
    },
    {
      "label": "Computer Networking",
      "value": "COMPUTER_NETWORKING"
    },
    {
      "label": "Computer Software",
      "value": "COMPUTER_SOFTWARE"
    },
    {
      "label": "Construction",
      "value": "CONSTRUCTION"
    },
    {
      "label": "Consumer Electronics",
      "value": "CONSUMER_ELECTRONICS"
    },
    {
      "label": "Consumer Goods",
      "value": "CONSUMER_GOODS"
    },
    {
      "label": "Consumer Services",
      "value": "CONSUMER_SERVICES"
    },
    {
      "label": "Cosmetics",
      "value": "COSMETICS"
    },
    {
      "label": "Dairy",
      "value": "DAIRY"
    },
    {
      "label": "Defense Space",
      "value": "DEFENSE_SPACE"
    },
    {
      "label": "Design",
      "value": "DESIGN"
    },
    {
      "label": "Education Management",
      "value": "EDUCATION_MANAGEMENT"
    },
    {
      "label": "E-Learning",
      "value": "ELEARNING"
    },
    {
      "label": "Electrical Electronic Manufacturing",
      "value": "ELECTRICAL_ELECTRONIC_MANUFACTURING"
    },
    {
      "label": "Entertainment",
      "value": "ENTERTAINMENT"
    },
    {
      "label": "Environmental Services",
      "value": "ENVIRONMENTAL_SERVICES"
    },
    {
      "label": "Events Services",
      "value": "EVENTS_SERVICES"
    },
    {
      "label": "Executive Office",
      "value": "EXECUTIVE_OFFICE"
    },
    {
      "label": "Facilities Services",
      "value": "FACILITIES_SERVICES"
    },
    {
      "label": "Farming",
      "value": "FARMING"
    },
    {
      "label": "Financial Services",
      "value": "FINANCIAL_SERVICES"
    },
    {
      "label": "Fine Art",
      "value": "FINE_ART"
    },
    {
      "label": "Fishery",
      "value": "FISHERY"
    },
    {
      "label": "Food Beverages",
      "value": "FOOD_BEVERAGES"
    },
    {
      "label": "Food Production",
      "value": "FOOD_PRODUCTION"
    },
    {
      "label": "Fundraising",
      "value": "FUNDRAISING"
    },
    {
      "label": "Furniture",
      "value": "FURNITURE"
    },
    {
      "label": "Gambling Casinos",
      "value": "GAMBLING_CASINOS"
    },
    {
      "label": "Glass Ceramics Concrete",
      "value": "GLASS_CERAMICS_CONCRETE"
    },
    {
      "label": "Government Administration",
      "value": "GOVERNMENT_ADMINISTRATION"
    },
    {
      "label": "Government Relations",
      "value": "GOVERNMENT_RELATIONS"
    },
    {
      "label": "Graphic Design",
      "value": "GRAPHIC_DESIGN"
    },
    {
      "label": "Health Wellness Fitness",
      "value": "HEALTH_WELLNESS_FITNESS"
    },
    {
      "label": "Higher Education",
      "value": "HIGHER_EDUCATION"
    },
    {
      "label": "Hospital Health Care",
      "value": "HOSPITAL_HEALTH_CARE"
    },
    {
      "label": "Hospitality",
      "value": "HOSPITALITY"
    },
   	{
      "label": "Human Resources",
      "value": "HUMAN_RESOURCES"
    },
    {
      "label": "Import Export",
      "value": "IMPORT_EXPORT"
    },
    {
      "label": "Individual Family Services",
      "value": "INDIVIDUAL_FAMILY_SERVICES"
    },
    {
      "label": "Industrial Automation",
      "value": "INDUSTRIAL_AUTOMATION"
    },
    {
      "label": "Information Technology Services",
      "value": "INFORMATION_TECHNOLOGY_SERVICES"
    },
    {
      "label": "Insurance",
      "value": "INSURANCE"
    },
    {
      "label": "International Affairs",
      "value": "INTERNATIONAL_AFFAIRS"
    },
    {
      "label": "International Trade Development",
      "value": "INTERNATIONAL_TRADE_DEVELOPMENT"
    },
    {
      "label": "Internet",
      "value": "INTERNET"
    },
    {
      "label": "Investment Banking Venture",
      "value": "INVESTMENT_BANKING_VENTURE"
    },
    {
      "label": "Investment Management",
      "value": "INVESTMENT_MANAGEMENT"
    },
    {
      "label": "Judiciary",
      "value": "JUDICIARY"
    },
    {
      "label": "Law Enforcement",
      "value": "LAW_ENFORCEMENT"
    },
    {
      "label": "Law Practice",
      "value": "LAW_PRACTICE"
    },
    {
      "label": "Legal Services",
      "value": "LEGAL_SERVICES"
    },
    {
      "label": "Legislative Office",
      "value": "LEGISLATIVE_OFFICE"
    },
    {
      "label": "Leisure Travel",
      "value": "LEISURE_TRAVEL"
    },
    {
      "label": "Libraries",
      "value": "LIBRARIES"
    },
    {
      "label": "Logistics Supply Chain",
      "value": "LOGISTICS_SUPPLY_CHAIN"
    },
   	{
      "label": "Luxury Goods Jewellery",
      "value": "LUXURY_GOODS_JEWELRY"
    },
   	{
      "label": "Machinery",
      "value": "MACHINERY"
    },
    {
      "label": "Management Consulting",
      "value": "MANAGEMENT_CONSULTING"
    },
    {
      "label": "Maritime",
      "value": "MARITIME"
    },
    {
      "label": "Marketing Advertising",
      "value": "MARKETING_ADVERTISING"
    },
    {
      "label": "Market Research",
      "value": "MARKET_RESEARCH"
    },
    {
      "label": "Mechanical or Industrial Engineering",
      "value": "MECHANICAL_OR_INDUSTRIAL_ENGINEERING"
    },
    {
      "label": "Media Production",
      "value": "MEDIA_PRODUCTION"
    },
    {
      "label": "Medical Device",
      "value": "MEDICAL_DEVICE"
    },
    {
      "label": "Medical Practice",
      "value": "MEDICAL_PRACTICE"
    },
    {
      "label": "Mental Health Care",
      "value": "MENTAL_HEALTH_CARE"
    },
    {
      "label": "Military",
      "value": "MILITARY"
    },
    {
      "label": "Mining Metals",
      "value": "MINING_METALS"
    },
    {
      "label": "Motion Pictures Film",
      "value": "MOTION_PICTURES_FILM"
    },
    {
      "label": "Museums Insitutions",
      "value": "MUSEUMS_INSTITUTIONS"
    },
    {
      "label": "Music",
      "value": "MUSIC"
    },
    {
      "label": "Nanotechnology",
      "value": "NANOTECHNOLOGY"
    },
    {
      "label": "Newspapers",
      "value": "NEWSPAPERS"
    },
   	{
      "label": "Nonprofit Organisation Management",
      "value": "NONPROFIT_ORGANIZATION_MANAGEMENT"
    },
    {
      "label": "Oil Energy",
      "value": "OIL_ENERGY"
    },
    {
      "label": "Oil Energy",
      "value": "OIL_ENERGY"
    },
    {
      "label": "Online Publishing",
      "value": "ONLINE_PUBLISHING"
    },
   	{
      "label": "Outsourcing Offshoring",
      "value": "OUTSOURCING_OFFSHORING"
    },
    {
      "label": "Package Freight Delivery",
      "value": "PACKAGE_FREIGHT_DELIVERY"
    },
    {
      "label": "Packaging Containers",
      "value": "PACKAGING_CONTAINERS"
    },
    {
      "label": "Paper Forest Products",
      "value": "PAPER_FOREST_PRODUCTS"
    },
    {
      "label": "Peforming Arts",
      "value": "PERFORMING_ARTS"
    },
    {
      "label": "Pharmaceuticals",
      "value": "PHARMACEUTICALS"
    },
    {
      "label": "Philanthropy",
      "value": "PHILANTHROPY"
    },
    {
      "label": "Photography",
      "value": "PHOTOGRAPHY"
    },
    {
      "label": "Plastics",
      "value": "PLASTICS"
    },
    {
      "label": "Political Organisation",
      "value": "POLITICAL_ORGANIZATION"
    },
   	{
      "label": "Primary Secondary",
      "value": "PRIMARY_SECONDARY"
    },
    {
      "label": "Printing",
      "value": "PRINTING"
    },
    {
      "label": "Professional Trading",
      "value": "PROFESSIONAL_TRAINING"
    },
    {
      "label": "Program Development",
      "value": "PROGRAM_DEVELOPMENT"
    },
    {
      "label": "Public Policy",
      "value": "PUBLIC_POLICY"
    },
    {
      "label": "Public Relations",
      "value": "PUBLIC_RELATIONS"
    },
    {
      "label": "Public Safety",
      "value": "PUBLIC_SAFETY"
    },
    {
      "label": "Publishing",
      "value": "PUBLISHING"
    },
   	{
      "label": "Railroad Manufacture",
      "value": "RAILROAD_MANUFACTURE"
    },
    {
      "label": "Ranching",
      "value": "RANCHING"
    },
    {
      "label": "Real Estate",
      "value": "REAL_ESTATE"
    },
    {
      "label": "Recreational",
      "value": "RECREATIONAL"
    },
    {
      "label": "Facilities Services",
      "value": "FACILITIES_SERVICES"
    },
    {
      "label": "Religious Institutions",
      "value": "RELIGIOUS_INSTITUTIONS"
    },
    {
      "label": "Renewables Environment",
      "value": "RENEWABLES_ENVIRONMENT"
    },
    {
      "label": "Research",
      "value": "RESEARCH"
    },
    {
      "label": "Restaraunts",
      "value": "RESTAURANTS"
    },
    {
      "label": "Retail",
      "value": "RETAIL"
    },
    {
      "label": "Security Investigations",
      "value": "SECURITY_INVESTIGATIONS"
    },
    {
      "label": "Semiconductors",
      "value": "SEMICONDUCTORS"
    },
    {
      "label": "Shipbuilding",
      "value": "SHIPBUILDING"
    },
    {
      "label": "Sporting Goods",
      "value": "SPORTING_GOODS"
    },
    {
      "label": "Sports",
      "value": "SPORTS"
    },
    {
      "label": "Staffing Recruiting",
      "value": "STAFFING_RECRUITING"
    },
    {
      "label": "Supermarkets",
      "value": "SUPERMARKETS"
    },
    {
      "label": "Telecommunications",
      "value": "TELECOMMUNICATIONS"
    },
    {
      "label": "Textiles",
      "value": "TEXTILES"
    },
    {
      "label": "Think Tanks",
      "value": "THINK_TANKS"
    },
    {
      "label": "Tobacco",
      "value": "TOBACCO"
    },
    {
      "label": "Translation Localization",
      "value": "TRANSLATION_LOCALIZATION"
    },
    {
      "label": "Transportation Trucking Railroad",
      "value": "TRANSPORTATION_TRUCKING_RAILROAD"
    },
    {
      "label": "Utilities",
      "value": "UTILITIES"
    },
    {
      "label": "Venture Capital",
      "value": "VENTURE_CAPITAL"
    },
    {
      "label": "Veterinary",
      "value": "VETERINARY"
    },
    {
      "label": "Warehousing",
      "value": "WAREHOUSING"
    },
    {
      "label": "Wholesale",
      "value": "WHOLESALE"
    },
    {
      "label": "Wine Spirits",
      "value": "WINE_SPIRITS"
    },
    {
      "label": "Wireless",
      "value": "WIRELESS"
    },
    {
      "label": "Writing Editing",
      "value": "WRITING_EDITING"
    }
 	]
}


export const companyTypeData = {
		"companyTypes": [
    {
      "label": "Incorporated",
      "value": "INCORPORATED"
    },
        {
      "label": "Pty Ltd",
      "value": "PTYLTD"
    },
  ]

}