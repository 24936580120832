import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'
import DateInput from './DateInput'

import { numberWithCommas } from 'utils/utils'

import moment from 'moment'
import { cloneDeep } from 'lodash'

class TextInput extends Component {
	state = {
		tempValue: ''
	}

	componentDidMount() {
		if (this.inputRef && this.props.isFirst) {
			this.inputRef.focus()
		}

		if (this.props.value === null) {
			//this.props.changeCallback('')
		}
	}

	componentWillUpdate = nextProps => {
		if(!this.props.disabled && nextProps.disabled){
			this.props.changeCallback('')
		}
	}

	handleDateChange = date => {
		if (!date) {
			return this.props.changeCallback(null)
		}

		let dateObject = date.toDate()

		return this.props.changeCallback(dateObject)
	}

	handleChange = value => {
		let returnedValue = value

		switch (this.props.type) {
			case 'money':
				returnedValue = returnedValue.replace(/[^0-9.]/g, "")
				returnedValue = returnedValue.replace(',', '')
				//returnedValue = parseFloat(returnedValue)
				break
			case 'percent':
				returnedValue = returnedValue.replace(/[^0-9.]/g, "")
				break
		}

		this.props.changeCallback(returnedValue)
	}

	prefillFromParent = _ => {
		const name = this.getPrefillName()

		this.props.changeCallback(name)
	}

	getPrefillName = _ => {
		let prefillName = null

		const {
			canPrefillFrom
		} = this.props

		if(canPrefillFrom){
			if(canPrefillFrom.name){
				prefillName = canPrefillFrom.name
			}else if(canPrefillFrom.firstName && canPrefillFrom.lastName){
				prefillName = `${canPrefillFrom.firstName} ${canPrefillFrom.lastName}`
			}
		}

		return prefillName
	}

	isValidDate = date => {
		if(date){
			const dateMoment = moment(date)

			return dateMoment.isValid()
		}else{
			return false
		}
	}

	render() {
		let className = 'input-container '

		const {
			type,
			label,
			error,
			required,
			value,
			placeholder,
			autoFocus,
			disableEditable,
			isDisabled,
			disabled,
			allowFuture,
			canPrefillFrom
		} = this.props

		let formattedValue = cloneDeep(value)

		switch (type) {
			case 'search':
				className += 'search has-after'
				break;
			case 'date':
				className += 'date has-after'

				if (formattedValue) {
					formattedValue = moment(formattedValue)
				}
				break;
			case 'money':
				className += 'money has-before'
				break
			case 'percent':
				className += 'percent has-after'
				break
		}

		let displayedValue = ''

		if (value || value === 0) {
			displayedValue = value

			if (type == 'money') {
				displayedValue = numberWithCommas(displayedValue)
			}
		}

		if(type === 'date' && this.isValidDate(displayedValue)){
			className += ' valid-date'
		}

		let prefillValue = this.getPrefillName()

		return (
			<div className={error ? "input-wrap error" : "input-wrap"}>
				<label>
					{label ? label : '\u00A0'}
					{(required && !isDisabled && !disableEditable) ?
						<em className="required">
							*
            			</em>
					: null}
				</label>
				{
					(!isDisabled && !disableEditable) ?
						<div className={className}>
							{
								type !== 'date' ?
									<input
										ref={ref => this.inputRef = ref}
										autoFocus={autoFocus ? autoFocus : null}
										onChange={e => { this.handleChange(e.target.value) }}
										value={displayedValue}
										placeholder={placeholder}
										type="text"
									/>
									:
									<DateInput
										value={formattedValue}
										onChange={this.handleDateChange}
										disabled={disabled}
									/>
							}
						</div>
						:
						<span
							className="disabled-input"
							dangerouslySetInnerHTML={{ __html: type == 'date' ? formattedValue.format('Do MMMM, Y') : displayedValue }}
						/>
				}
				<ValidationError
					error={error}
				/>
				{canPrefillFrom ?
					<span
						className="address-prefill-sub"
						onClick={this.prefillFromParent}
					>
						Pre-fill from {prefillValue}?
          			</span>
					: null
				}
			</div>
		)
	}
}

export default TextInput
