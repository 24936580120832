import React, { Component } from 'react'
import Table from 'components/Table/Table'
import Button from 'components/Button/Button'
import { withApollo } from 'react-apollo'
import Loader from 'components/Loader/Loader'
import { cloneDeep, isEqual } from 'lodash'
import moment from 'moment'

import { graphql, compose } from 'react-apollo'
import { stripTypenamesFromMutations, removeSoftDeletedItems } from 'utils/utils'

import { GET_LENDERS, UPSERT_LENDERS } from 'graphql/loans-graphql'

class Loans extends Component {
    tableColumns = [
        {
            label: '',
            type: 'input',
            key: 'name',
            placeholder: 'Loan name'
        }, {
            label: 'Current rate OO-P&I (%)',
            type: 'input',
            key: 'interestRatesOOPI'
        }, {
            label: 'Current rate OO-IO (%)',
            type: 'input',
            key: 'interestRatesOOIO'
        }, {
            label: 'Current rate INV-P&I (%)',
            type: 'input',
            key: 'interestRatesIVPI'
        }, {
            label: 'Current rate INV-IO (%)',
            type: 'input',
            key: 'interestRatesIVIO'
        },
        {
            label: 'Updated At',
            type: 'value',
            key: 'updatedAt'
        }, {
            label: '',
            type: 'mini-actions',
            key: 'actions'
        }
    ]

    rowInputRefs = [];

    state = {
        isLoading: true,
        allValid: true
    }

    componentWillMount() {
        this.props.client.query({
            query: GET_LENDERS,
            fetchPolicy: 'network-only'
        }).then(data => {
            const lenders = removeSoftDeletedItems(data.data.lenders)

            this.setState({
                isLoading: false,
                lenders: cloneDeep(lenders),
                initialLenders: lenders
            })
        })
    }

    generateRowData = () => {
        let rowsArray = [];

        this.state.lenders.forEach(lender => {
            rowsArray.push({
                id: lender.id ? lender.id : '',
                name: lender.name,
                isHeading: true
            })

            lender.loans.forEach(loan => {
                rowsArray.push({
                    ...loan
                })
            })
        })

        return rowsArray
    }

    addLender = () => {
        const newLenderObject = {
            id: '',
            name: '',
            loans: []
        }

        let { lenders } = cloneDeep(this.state)

        lenders.push(newLenderObject)

        this.setState({
            lenders
        }, () => {
            let lastRowIndex = this.rowInputRefs.length - 1
            this.rowInputRefs[lastRowIndex].focus()
        })
    }

    removeLender = index => {
        let { lenders } = cloneDeep(this.state)

        lenders.splice(index, 1)

        this.setState({
            lenders
        })

        this.handleChange(null, null, null, null)
    }

    addLoan = (lenderIndex) => {
        const newLoanObject = {
            id: '',
            name: '',
            deletedAt: null,
            interestRatesOOPI: [],
            interestRatesOOIO: [],
            interestRatesIVPI: [],
            interestRatesIVIO: [],
        }

        let { lenders } = cloneDeep(this.state)

        lenders[lenderIndex].loans.push(newLoanObject)

        this.setState({
            lenders
        })
    }

    removeLoan = (lenderIndex, loanIndex) => {
        let { lenders } = cloneDeep(this.state)

        lenders[lenderIndex].loans.splice(loanIndex, 1)

        this.setState({
            lenders
        })
    }

    handleHeadingChange = (index, value) => {
        let { lenders } = cloneDeep(this.state)

        lenders[index].name = value;

        this.setState({
            lenders
        })
    }

    handleChange = (lenderIndex, loanIndex, key, value) => {
        // setting this anywhere else causes an infinite loop, but we want the handleCheck to set the validity.
        this.setState({
            allValid: true
        })

        if (lenderIndex === null) {
            return
        }

        let { lenders } = cloneDeep(this.state)

        let loanObject = lenders[lenderIndex].loans[loanIndex]
        let parsedValue = value

        if (!key.toLowerCase().includes('interestrate')) {
            loanObject[key] = parsedValue
        } else {
            let parsedValue = value.replace(/[^0-9.]/g, "")
            // if no interest rates, or the last one has an ID, create a new rate
            if (!loanObject[key].length || loanObject[key][(loanObject[key].length - 1)].id) {
                loanObject[key].push({
                    id: '',
                    rate: parseFloat(parsedValue)
                })
            } else {
                loanObject[key][(loanObject[key].length - 1)].rate = parsedValue
            }
        }

        this.setState({
            lenders
        })
    }

    handleCheck = (isHeading, item, key, value) => {
        let isChanged = false
        let isValid = true

        if (!value && value !== 0) {
            /*if(this.state.allValid){
                this.setState({
                    allValid: false
                })
            }

            return 'invalid' */
        }

        // if it's got an ID, check if it's changed
        if (item.id) {
            this.state.initialLenders.forEach(lender => {
                if (!isHeading) {
                    lender.loans.forEach(loan => {
                        if (loan.id == item.id) {
                            if (typeof (loan[key]) == 'object' && isEqual(loan[key], item[key])) {
                                return null
                            }

                            if (loan[key] !== item[key]) {
                                isChanged = true
                            }
                        }
                    })
                } else {
                    if (lender[key] !== item[key]) {
                        isChanged = true
                    }
                }
            })
        }

        if (isChanged) {
            return 'changed'
        } else {
            return null
        }
    }

    resetChanges = () => {
        this.setState({
            lenders: cloneDeep(this.state.initialLenders),
        })
    }

    getTableValue = (key, loan, type) => {
        let returnObject = {
            label: 'test',
            href: null,
            callback: () => { },
            value: '',
            handleChange: this.handleChange,
            check: this.handleCheck
        }

        switch (key) {
            case 'name':
                returnObject.value = loan.name
                break;
            case 'id':
                returnObject.label = loan.id ? loan.id : 'N/A'
                break;
            case 'interestRatesOOPI':
            case 'interestRatesOOIO':
            case 'interestRatesIVPI':
            case 'interestRatesIVIO':
                returnObject.value = loan[key].length ? loan[key][(loan[key].length - 1)].rate : ''
                break;
            case 'updatedAt':
                const keysToCheck = ['interestRatesOOPI', 'interestRatesOOIO', 'interestRatesIVPI', 'interestRatesIVIO']

                let latestDate = null

                keysToCheck.forEach(rateKey => {
                    const latestDateForKey = loan[rateKey] && loan[rateKey].length ? loan[rateKey][(loan[rateKey].length - 1)] : null

                    if(latestDateForKey){
                        if(!latestDate || latestDateForKey.createdAt > latestDate.createdAt){
                            latestDate = latestDateForKey
                        }
                    }
                })

                returnObject.label = latestDate ? moment(latestDate.createdAt).format('Do MMMM Y') : loan.updatedAt ? moment(loan.updatedAt).format('Do MMMM Y') : ''
                break;
            case 'actions':
                returnObject.callback = this.removeLoan
                break;
        }

        return returnObject;
    }

    handleSubmit = () => {
        this.setState({
            isLoading: true
        })

        const fields = stripTypenamesFromMutations(this.state.lenders)

        console.log(fields)

        this.props.client.mutate({
            mutation: UPSERT_LENDERS,
            variables: {
                payload: [
                    ...fields
                ]
            }
        }).then(data => {
            this.setState({
                isLoading: false
            })

            const lenders = removeSoftDeletedItems(data.data.upsertLenders)

            this.setState({
                isLoading: false,
                lenders: cloneDeep(lenders),
                initialLenders: lenders
            })
        }).catch(error => {
            this.setState({
                isLoading: false
            })

            console.log(error)
        })
    }

    render() {
        const {
            allValid,
            initialLenders,
            isLoading,
        } = this.state

        let rowData = []
        let pristine = false
        let canSave = true

        if (!isLoading) {
            rowData = this.generateRowData()
            pristine = isEqual(this.state.lenders, initialLenders)
        }

        if (!allValid || pristine) {
            canSave = false
        }

        let columnClass = "main-column full-width"

        if (isLoading) {
            columnClass += " loading"
        }

        return (
            <div>
                <div className="heading-section">
                    <h1>
                        Loans
                    </h1>
                    <p>
                        Edit all your loan products here. When editing an existing loan, only variable interest rates will affect your clients' existing liabilities.
                    </p>
                </div>
                <div className="main-content-container">
                    {
                        isLoading ?
                            <Loader 
                                isLoading={true} 
                                showText={true} 
                            />
                            : null
                    }
                    <div className={columnClass}>
                        <div className="content-box">
                            <div className="search-button-container buttons-only">
                                <Button 
                                    disabled={!canSave} 
                                    label={`Save all`} 
                                    callback={this.handleSubmit} 
                                />
                                <Button 
                                    disabled={pristine} 
                                    label={`Discard changes`} 
                                    level="low" 
                                    callback={this.resetChanges} 
                                />
                                <Button 
                                    label={`Add lender`} 
                                    level="x-low" 
                                    callback={this.addLender} 
                                />
                            </div>
                            <Table
                                className="loans-table"
                                rowInputRefs={this.rowInputRefs}
                                addRowCallback={this.addLoan}
                                removeRowCallback={this.removeLender}
                                headingChangeCallback={this.handleHeadingChange}
                                columns={this.tableColumns}
                                data={rowData}
                                getter={this.getTableValue}
                                shouldPaginate={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
)(Loans)