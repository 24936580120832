import React, { Component } from 'react'
import { CSSTransition } from "react-transition-group"

class HeaderMenu extends Component {
  render(){
    let callbackObject = null;

    if(this.props.callbackObject){
      callbackObject = this.props.callbackObject;
    }

    return (
      <div className="pop-menu-container">
        <CSSTransition in={this.props.isOpen ? true : false} unmountOnExit classNames="pop" timeout={{ enter: 1, exit: 200 }}>
          <div className="pop-menu" onClick={e => e.stopPropagation()}>
            <ul>
              {
                this.props.items.map((item, i) => {
                  let showItem = true

                  if(item.showCheck){
                    showItem = item.showCheck(callbackObject, item.key)
                  }

                  if(!showItem){
                    return null
                  }

                  return (
                    <li key={i}>
                      <button onClick={() => {
                        item.callback(callbackObject)
                        this.props.closeCallback()
                        }}>
                        { item.label }
                      </button>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default HeaderMenu
