import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import * as yup from 'yup'

import { getParameterByName } from 'utils/utils'

class NewPassword extends Component {
    state = {
        password: '',
        passwordConfirm: '',
        error: null
    }

    componentDidMount(){
        this.requestId = getParameterByName('requestId')

        if(!this.requestId){
            this.props.history.push('/auth/forgot-password')
        }

        console.log(this.props)
    }

    handleSubmit = async () => {
        if(!this.state.password || !this.state.passwordConfirm){
            return this.handleError("Please enter your new password in both fields.", 'error')
        }

        if(this.state.password !== this.state.passwordConfirm){
            return this.handleError("Those passwords aren't the same.", 'error')
        }

        this.props.callback({
            id: this.requestId,
            password: this.state.password   
        }, this.props.path, (response) => {
            console.log(response)

            if (response.length) {
                this.handleError(response[0].message, 'error')
            } else {
                this.handleError('Uh oh! A server error has occurred.', 'error')
            }
        }, () => {
            this.handleError("Your password has been reset successfully.",'success')
        })
    }

    handleError = (message, alertType) => {
        this.setState({
            error: message,
            alertType
        })

        setTimeout(() => {
            this.setState({
                error: null
            })
        }, 3000);
    }

    render() {
        return (
            <div onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
                <MiniResponse type={this.state.alertType} message={this.state.error} />
                <Loader isLoading={this.props.isLoading} />
                <input placeholder="Enter a new password" type="password" onChange={e => this.setState({ password: e.target.value })} />
                <input placeholder="Confirm new password" type="password" onChange={e => this.setState({ passwordConfirm: e.target.value })} />
                <button className="button high" onClick={this.handleSubmit}>
                    Reset password
                </button>
                <div className="lower-row">
                    <Link to="/auth/login">
                        &lsaquo; Back to login
                    </Link>
                </div>
            </div>
        )
    }
}

export default NewPassword
