import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { withApollo } from 'react-apollo'

import { REQUEST_ACTION_DATA, GET_ACTION_DATA } from 'graphql/all'

class DoEmailAction extends Component {
    componentWillMount(){
        const search = this.props.location.search.replace('?','')

        let actionData = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

        this.props.client.query({
            query: REQUEST_ACTION_DATA,
            variables: {
                id: actionData.id,
                type: actionData.type
            }
        }).then(data => {
            // invite and verify can both redirect to register, it'll bump you along if you're logged in.
            this.props.client.writeQuery({query: GET_ACTION_DATA, data: {
                action: {
                    type: actionData.type,
                    object: data.data.actionData.invitation,
                    error: null,
                    __typename: 'ActionDataPayload'
                }
            }})

            if(actionData.type == 'verify'){
                this.props.history.push('/auth/login')
            }else{
                this.props.history.push('/auth/register')
            }            
        }).catch(error => {
            console.log(error)

            let errorMessage = error.graphQLErrors ? error.graphQLErrors[0].message : 'An unknown error has occurred. Please try again.'

            this.props.client.writeQuery({query: GET_ACTION_DATA, data: {
                action: {
                    type: actionData.type,
                    object: null,
                    error: errorMessage,
                    __typename: 'ActionDataPayload'
                }
            }})

            this.props.history.push('/auth/register')
        })
    }

    render(){
        return (
            <div>
                One sec!
            </div>
        )
    }
}

export default withApollo(withRouter(DoEmailAction))