import gql from 'graphql-tag'
import { LiabilityFragment, PersonFragment, CompanyFragment } from './fragments-graphql'

export const GET_REPORTING_LIABILITIES = gql`
    query GET_REPORTING_LIABILITIES{
        reportingLiabilities{
            ...LiabilityFragment
            broker {
                id
            }
        }
    }
    ${LiabilityFragment}
`

export const GET_REPORTING_PEOPLE = gql`
    query GET_REPORTING_PEOPLE{
        getPeople{
            id
            firstName
            lastName
            email
            dateOfBirth
            notifyOfBirthday
            broker {
                id
            }
            address {
                id
                state
                suburb
            }
            assets {
                id
                personRelation {
                    id
                    isInvestment
                }
            }

        }
    }
`

export const GET_REPORTING_COMPANIES = gql`
    query GET_REPORTING_COMPANIES{
        getCompanies{
            id
            name
            email
            broker {
                id
            }
            address {
                id
                suburb
                state
            }
            assets {
                id
                companyRelation {
                    id
                    isInvestment
                }
            }
        }
    }
`