import React, { useState, useEffect } from 'react'
import moment from 'moment'

export default ({ onChange, value, disabled }) => {
    const groups = ['DD','MM','YY']
    const [inputValues, setInputValues] = useState(['','',''])

    useEffect(() => {
        if(value){
            const valueMoment = getMomentFromValues()

            if(!valueMoment || !value.isSame(valueMoment)){
                const valueString = value.format('DD-MM-YYYY')
                const valuesArray = valueString.split('-')

                setInputValues(valuesArray)
            }
        }
    }, [value])

    const handleBlur = () => {
        onChange(getMomentFromValues())
    }

    const getMomentFromValues = () => {
        let isAllValid = true
        let outputString = ''
        let formatString = ''

        groups.forEach((group, index) => {
            const valueString = inputValues[index]
            const valueInt = parseInt(valueString)

            if(!valueInt || isNaN(valueInt)){
                isAllValid = false
            }else{
                if(index){
                    outputString += '-'
                    formatString += '-'
                }

                if(valueString.length === 1){
                    outputString += '0'
                }

                if(index === 2){
                    formatString += 'YY'

                    if(valueString.length === 2){
                        outputString += valueInt < 30 ? '20' : '19'
                    }
                }

                outputString += valueString
                formatString += group
            }
        })
        
        return isAllValid ? moment(outputString, formatString) : null
    }

    const handleChange = (e, index) => {
        const value = e.target.value

        if(!isNaN(value)){
            const _inputValues = [...inputValues]

            _inputValues[index] = e.target.value

            setInputValues(_inputValues)
        }
    }

    return (
        <div className="date-input">
            { groups.map((group, index) => (
                <React.Fragment key={index}>
                    { index ? '/' : ''}
                    <input
                        type="text"
                        key={group}
                        placeholder={group}
                        onBlur={handleBlur}
                        onChange={e => handleChange(e, index)}
                        value={ inputValues[index] ? inputValues[index] : ''}
                        disabled={!!disabled}
                    />
                </React.Fragment>
            ))}
        </div>
    )
}