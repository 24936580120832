import React, { Component } from 'react'
import Button from 'components/Button/Button'
import { graphql, compose, withApollo } from 'react-apollo'
import Textarea from 'react-textarea-autosize'
import ValidationError from 'components/Errors/ValidationError'

import { cloneDeep } from 'lodash'

import { POST_INVITES, INIT_DATA } from 'graphql/all'

class ModalInvite extends Component {
    state = {
        inputValue: '',
        error: null,
        hasMounted: false
    }

    inputRef = null

    componentDidMount() {
        setTimeout(_ => {
            this.setState({
                hasMounted: true
            }, _ => {
                window.requestAnimationFrame(_ => {
                    this.inputRef.focus()
                })
            })
        },1)
    }

    handleClick = () => {
        let emails = this.parsedEmailAddresses(this.state.inputValue)

        this.props.setLoading(true)

        console.log(emails)

        if(emails.allValid){
            this.props.client.mutate({
                mutation: POST_INVITES,
                variables: {
                    emails: emails.validAddresses
                },
                update: (store, { data }) => {
                    const initData = store.readQuery({query: INIT_DATA})

                    let invitations = cloneDeep(initData.user.brokerage.invitations)

                    invitations = invitations.concat(data.postInvites.invitations)

                    initData.user.brokerage.invitations = invitations

                    store.writeQuery({query: INIT_DATA, data: initData})

                    const takenEmails = data.postInvites.takenEmails

                    if(takenEmails.length){
                        let errorString = ''

                        if(takenEmails.length == 1){
                            errorString = `"${takenEmails[0]}" already has an account on Zamm.`
                        }else{
                            takenEmails.forEach((email, index) => {
                                if(index == takenEmails.length - 1){
                                    errorString += ' & '
                                }

                                errorString += `"${email}"`

                                if(index < takenEmails.length - 2){
                                    errorString += ', '
                                }
                            })

                            errorString += ' already have accounts on Zamm.'
                        }

                        this.setState({
                            error: errorString
                        })
                    }
                },
            }).then(data => {
                this.props.setLoading(false)

                console.log(data)

                if(!data.data.postInvites.takenEmails.length){
                    this.props.handleSuccess()
                }
            }).catch(error => {
                console.log(error)
            })
        }else{
            this.props.setLoading(false)

            this.setState({
                error: `One of those addresses doesn't seem valid.`
            })
        }
    }

    successCallback = () => {
        this.props.setLoading(false)
        this.props.closeCallback()
    }

    handleChange = value => {
        this.setState({
            inputValue: value
        })
    }

    parsedEmailAddresses = (emails) => {
        const allAddresses = emails.replace(/\n/g,',').split(',').filter(email => {return email ? email : null;})
    
        if(!allAddresses.length){
            return {
                allValid: false,
                invalidAddresses: []
            }
        }
    
        let invalidAddresses = [];
    
        allAddresses.forEach((email) => {
            if(!this.emailIsValid(email)){
                invalidAddresses.push(email);
            }
        });
    
        if(invalidAddresses.length){
            return {
                allValid: false,
                invalidAddresses
            }
        }
    
        return {
            allValid: true,
            validAddresses: allAddresses
        }
    }

    emailIsValid = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase())
    }

    render(){
        const {
            hasMounted
        } = this.state

        return(
            <div className="modal-invite">
                <div className="content-container">
                    { hasMounted &&
                        <Textarea 
                            placeholder="Write each email address on a new line." 
                            onChange={e => {this.handleChange(e.target.value)}} 
                            value={this.state.inputValue} 
                            inputRef={ref => {this.inputRef = ref}}
                        />
                    }
                </div>
                <ValidationError error={this.state.error} />
                <div className="button-row">
                    <Button label="Invite users" level="high" callback={this.handleClick} />
                    <Button label="Cancel" level="low" callback={this.props.closeCallback} />
                </div>
            </div>
        )
    }
}

export default withApollo(ModalInvite)

  