import React, { Component } from 'react'

import { graphql, compose } from 'react-apollo'

import { UPDATE_USER_MUTATION } from 'graphql/auth-graphql'
import { UPDATE_PASSWORD_MUTATION } from 'graphql/auth-graphql'

import { INIT_DATA } from 'graphql/user-graphql'

import Register from 'components/Auth/Register/Register'

import Password from 'components/Password/Password'

class ModalProfile extends Component {
	componentWillMount(){
		this.currentUser = this.props.initData.user
	}

	handleSubmit = (data, type, errorCallback, successCallback) => {
		const {
			setLoading,
			updateUserMutation,
			updatePasswordMutation
		} = this.props

		setLoading(true)

		if (type == "user") {
			let requestData = {
				...data
			}

			delete requestData.invitationId
			delete requestData.error
			delete requestData.password
			delete requestData.errors

			updateUserMutation({
				variables: {
					payload: requestData
				}
			}).then(() => {
				setLoading(false)
				successCallback()
			}).catch(error => {
				console.log(error)
				console.log(error.graphQLErrors)

				if(error.graphQLErrors){
					errorCallback(error.graphQLErrors)
				}

				setLoading(false)
			})
		} else {
			if (data.newPassword != data.confirmPassword) {
				return errorCallback('New passwords aren\'t the same.')
			}

			updatePasswordMutation({
				variables: {
					payload: {
						id: data.id,
						password: data.password,
						newPassword: data.newPassword,
					}
				}
			}).then(() => {
				setLoading(false)
				successCallback()
			}).catch(error => {
				setLoading(false)
				const clientErrors = error.graphQLErrors

				errorCallback(clientErrors)
			})
		}

	}

	render() {
		const {
			object
		} = this.props

		return (
			<div className="modal-profile">
				<Register 
					callback={(data, path, errorCallback, successCallback) => { 
						this.handleSubmit(data, 'user', errorCallback, successCallback) 
					}} 
					isEdit={true} 
					data={object} 
				/>
				<Password 
					callback={(data, path, errorCallback, successCallback) => { 
						this.handleSubmit(data, 'password', errorCallback, successCallback) 
					}} 
					isEdit={true} 
					currentUser={this.currentUser} 
					data={object} 
				/>
			</div>
		)
	}
}

export default compose(
	graphql(UPDATE_USER_MUTATION, { name: 'updateUserMutation' }),
	graphql(UPDATE_PASSWORD_MUTATION, { name: 'updatePasswordMutation' }),
	graphql(INIT_DATA, { name: 'initData' })
)(ModalProfile)