import React, { Component } from 'react'

import EmptyAlert from 'components/Alerts/EmptyAlert'

import { 
    numberWithCommas,
    getClientDisplayName,
    getAssetName
} from 'utils/utils'

import { cloneDeep, forEachRight } from 'lodash'

class AssetAllocation extends Component {
    state = {
        assetLvrValues: {}
    }

    componentWillMount = () => {
        const lvrValuesObject = {}

        this.getLiabilityObjects().forEach(liability => {
            liability.assets.forEach(asset => {
                lvrValuesObject[asset.id] = 80
            })
        })

        this.setState({
            assetLvrValues: lvrValuesObject
        })
    }

    getLiabilityObjects = _ => {
        const liabilities = []

        this.props.data.liabilities.forEach(liabilityApplication => {
            let liability 

            if(liabilityApplication.companyRelation){
                liability = liabilityApplication.companyRelation
            }else if(liabilityApplication.personRelation){
                liability = liabilityApplication.personRelation
            }

            if(liability){
                liabilities.push(liability)
            }
        })

        return liabilities
    }

    handleLvrChange = (value, assetId) => {
        const assetLvrValues = cloneDeep(this.state.assetLvrValues)

        assetLvrValues[assetId] = value

        this.setState({
            assetLvrValues
        })
    }

    generateTableData = () => {
        let allAssets = []
        let relevantClients = []

        this.getLiabilityObjects().forEach(liability => {
            // add asset to allAssets
            liability.assets.forEach(loopedAsset => {
                if(!allAssets.find(asset => {
                    return asset.assetObject.id === loopedAsset.id
                })){
                    let lvrAllocated = this.state.assetLvrValues[loopedAsset.id]

                    allAssets.push({
                        assetObject: loopedAsset,
                        lvrAllocated
                    })
                }
            })

            // add liability to client in relevantClients
            let allClients = [
                ...liability.companies,
                ...liability.people
            ]

            allClients.sort((a, b) => {
                const aId = a.company ? a.company.id : a.person.id
                const bId = b.company ? b.company.id : b.person.id

                return aId - bId
            })

            let relationshipId = ''
            let relationshipName = ''

            allClients.forEach((clientApplicant, index) => {
                if(!clientApplicant.company && !clientApplicant.person){
                    return 
                }

                let name = ''

                if(clientApplicant.company){
                    relationshipId += '_company'+clientApplicant.company.id
                    name = clientApplicant.company.name
                }else if(clientApplicant.person){
                    relationshipId += '_person'+clientApplicant.person.id
                    name = getClientDisplayName(clientApplicant.person)
                }

                if(!index){
                    relationshipName += name
                }else if(index == (allClients.length - 1)){
                    relationshipName += ' & '+name
                }else{
                    relationshipName += ', '+name
                }
            })

            let relevantClientIndex = relevantClients.findIndex(relevantClient => {
                return relevantClient.id === relationshipId
            })
            
            if(relevantClientIndex == -1){
                relevantClients.push({
                    id: relationshipId,
                    name: relationshipName,
                    liabilityApplications: [{
                        liability
                    }]
                })
            }else{
                relevantClients[relevantClientIndex].liabilityApplications.push({
                    liability
                })
            }
        })

        return {
            assets: allAssets,
            clients: this.removeInvalidClientsAndLiabilities(relevantClients)
        }
    }

    removeInvalidClientsAndLiabilities = clients => {
        let clonedClients = cloneDeep(clients)

        forEachRight(clonedClients,(client, index) => {
            let clientHasAssets = false
    
            forEachRight(client.liabilityApplications,(liabilityApplication, liabilityApplicationIndex) => {
                if(liabilityApplication.liability.assets.length){
                    clientHasAssets = true
                }else{
                    client.liabilityApplications.splice(liabilityApplicationIndex, 1)
                }
            })

            if(!clientHasAssets){
                clonedClients.splice(index, 1)
            }
        })

        return clonedClients

    }

    getTotalLiabilitiesAmount = (clients, asset) => {
        let liabilitiesWithThisAsset = []

        clients.forEach(client => {
            client.liabilityApplications.forEach(liabilityApplication => {
                liabilityApplication.liability.assets.forEach((loopedAsset, loopedAssetIndex) => {
                    if(loopedAsset.id === asset.id){
                        liabilitiesWithThisAsset.push({
                            liability: liabilityApplication.liability,
                            index: loopedAssetIndex
                        })
                    }
                })
            })
        })

        let totalLiabilitiesAmount = 0
        let totalLiabilitiesAmountWhereAssetIsFirst = 0

        if(liabilitiesWithThisAsset.length){
            liabilitiesWithThisAsset.forEach(liability => {
                const amount = liability.liability.amount

                totalLiabilitiesAmount += amount

                if(!liability.index){
                    totalLiabilitiesAmountWhereAssetIsFirst += amount
                }
            })
        }

        return {
            totalAmount: totalLiabilitiesAmount,
            totalWhereAssetIsFirst: totalLiabilitiesAmountWhereAssetIsFirst
        }

    }

    render(){
        const tableData = this.generateTableData()

        let totalValue = 0
        let totalEquity = 0

        const {
            data
        } = this.props

        if(!tableData || (!tableData.assets.length && !tableData.clients.length)){
            let emptyTitle = 'Nothing to show here.'
            let emptyBody = `You don't have any assets or clients.`

            if(data){
                if(data.person){
                    emptyBody = `${data.person.firstName} doesn't have any assets or clients`
                }else if(data.company){
                    emptyBody = `${data.company.name} doesn't have any assets or clients`
                }
            }

            return (
                <EmptyAlert
                    title={emptyTitle}
                    body={emptyBody}
                />
            )
        }

        return (
            <div>
                <h3>
                    Asset Allocation
                </h3>
                <table className="allocation-table">
                    <thead>
                    <tr className="thead-row">
                            <td colSpan={2} />
                            {tableData.assets.map((assetData, index) => {
                                const asset = assetData.assetObject

                                if(asset.value){
                                    totalValue += asset.value
                                }

                                return (
                                    <td key={index}>
                                        { getAssetName(asset, true) }
                                        <span className="small">
                                            { asset.value ?
                                                '$'+numberWithCommas(asset.value)
                                                :
                                                '(No set value)'
                                            }
                                        </span>
                                    </td>
                                )
                            }) }
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="thead-row">
                            <td colSpan={2}>
                                LVR allocated (%)
                            </td>
                            {tableData.assets.map((asset, index) => {
                                return (
                                    <td key={index} className="input-cell">
                                        <input type="text" placeholder="LVR allocated (%)" value={asset.lvrAllocated} onChange={e => this.handleLvrChange(e.target.value, asset.assetObject.id)} />
                                    </td>
                                )
                            }) }
                        </tr>
                    </tbody>
                    {tableData.clients.map(client => {
                        return (
                            <tbody key={client.id}>
                                <tr>
                                    <td className="heading-row" colSpan={(tableData.assets.length + 2)}>
                                        { client.name }
                                    </td>
                                </tr>
                                {client.liabilityApplications.map((liabilityApplication, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                { liabilityApplication.liability.name }
                                                <span className="small">
                                                    { liabilityApplication.liability.loan ? liabilityApplication.liability.loan.lender.name+' '+liabilityApplication.liability.loan.name : null }
                                                </span>
                                            </td>
                                            <td>
                                                { numberWithCommas(liabilityApplication.liability.amount) ? '$'+numberWithCommas(liabilityApplication.liability.amount) : null }
                                            </td>
                                            {tableData.assets.map((assetData,index) => {
                                                const asset = assetData.assetObject
                                                const liability = liabilityApplication.liability
                                                
                                                let liabilityAssets = liability.assets

                                                const assetIndexFromLiability = liabilityAssets.findIndex(liabilityAsset => {
                                                    return liabilityAsset.id === asset.id
                                                })

                                                const doesLiabilityHaveAsset = assetIndexFromLiability !== -1

                                                let value = null

                                                if(doesLiabilityHaveAsset){
                                                    if(assetIndexFromLiability === 0){
                                                        value = '$'+numberWithCommas(liability.amount)
                                                    }else{
                                                        value = '$0'
                                                    }
                                                }

                                                return (
                                                    <td key={index} className={!doesLiabilityHaveAsset ? 'disabled' : null}>
                                                        <span>
                                                            { value }
                                                        </span>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        )
                    })}
                    <tbody>
                    <tr>
                        <td colSpan={2}>
                            LVR used (%):
                        </td>
                        {tableData.assets.map((assetData,index) => {
                            const asset = assetData.assetObject
                            const totalLiabilityValues = this.getTotalLiabilitiesAmount(tableData.clients, asset)

                            let value = ''

                            if(!asset.value || !totalLiabilityValues.totalAmount){
                                value = 'N/A'
                            }else if(totalLiabilityValues.totalWhereAssetIsFirst){
                                const percent = Math.round((totalLiabilityValues.totalWhereAssetIsFirst/asset.value) * 100)

                                value = percent+'%'
                            }else{
                                // it HAS values, but none for this asset
                                value = '0%'
                            }

                            return (
                                <td key={index}>
                                    <span>
                                        { value }
                                    </span>
                                </td>
                            )
                        })}
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Available equity ($):
                        </td>
                        {tableData.assets.map((assetData,index) => {
                            const asset = assetData.assetObject

                            const totalLiabilityValues = this.getTotalLiabilitiesAmount(tableData.clients, asset)
                            const assetValue = assetData.assetObject.value
                            const lvrAllocated = assetData.lvrAllocated

                            let value = ''
                            let normalisedValue = 0 // 'N/A' isn't an int, but for pos/neg turning red/green, we need to pretend.

                            if(totalLiabilityValues.totalWhereAssetIsFirst && assetValue && lvrAllocated){
                                const totalLiabilitiesValue = totalLiabilityValues.totalWhereAssetIsFirst
                                const equityAvailable = (assetValue * (lvrAllocated/100)) - totalLiabilitiesValue

                                if(equityAvailable){
                                    normalisedValue = equityAvailable
                                }

                                totalEquity += equityAvailable

                                value = equityAvailable ? '$'+numberWithCommas(Math.round(equityAvailable)) : '$0'
                            }else{
                                value = 'N/A'
                            }

                            let className = ''

                            if(normalisedValue > 0){
                                className = 'positive'
                            }else if(normalisedValue < 0){
                                className = 'negative'
                            }

                            return (
                                <td key={index}>
                                    <span className={className}>
                                        { value }
                                    </span>
                                </td>
                            )
                        })}
                    </tr>
                    </tbody>
                </table>
                <p>
                    Total asset market value: ${numberWithCommas(totalValue)}
                </p>
                <p>
                    Net equity available: { totalEquity ? '$'+numberWithCommas(Math.round(totalEquity)) : '$0' }
                </p>
            </div>
        )
    }
}

export default AssetAllocation