import React, { Component } from 'react'

import ModalMiniNew from '../ModalTypes/ModalMiniNew'
import ModalMiniAddress from '../ModalTypes/ModalMiniAddress'
import ModalMiniConfirm from '../ModalTypes/ModalMiniConfirm'

import { withRouter } from 'react-router'

import Scroll from 'react-scroll'

import Button from 'components/Button/Button'
import { cloneDeep } from 'lodash'

import { graphql, compose } from 'react-apollo'

import { UPSERT_PERSON, INIT_DATA, OPEN_MODAL } from 'graphql/all'

import { 
    stripTypenamesFromMutations, 
    removeSoftDeletedItems,
    getClientDisplayName,
    formatAddress
} from 'utils/utils'

import ModalForm from '../ModalComponents/ModalForm'
import ValidationError from 'components/Errors/ValidationError'
import * as yup from 'yup'

class ModalPerson extends Component {
    state = {
        fields: {
            id: null,
            title: '',
            firstName: '',
            middleName: '',
            lastName: '',
            preferredName: '',
            gender: '',
            maritalStatus: 'SINGLE',
            dependants: [],
            address: {},
            phonePreference: '',
            phoneWork: '',
            phoneMobile: '',
            email: '',
            dateOfBirth: null,
            notifyOfBirthday: false,
            actingOnTrust: false,
            trustName: '',
        },
        hasErrors: false,
        errors: {},
        miniModalVisible: false,
        miniAddressModalVisible: false,
        miniAddressData: {},
        miniConfirmModalVisible: false,
        miniConfirmData: {}
    }

    possibleDuplicate = null
    openNextModalIntent = null
    scroll = Scroll.animateScroll

    getValue = type => {
        let responseObject = null

        const {
            allPeople
        } = this.state

        const {
            object
        } = this.props

        switch(type){
            case 'people':
                if(allPeople){
                    if(!allPeople.length){
                        responseObject = [{
                            label: 'No people',
                            value: undefined,
                            hide: true
                        }]
                    }else{
                        responseObject = []

                        allPeople.forEach(person => {
                            // don't put me in if I'm the person
                            if(object && object.id && person.id == object.id){
                                return 
                            }

                            responseObject.push({
                                label: getClientDisplayName(person),
                                value: person.id
                            })
                        })

                        responseObject.sort((a, b) => {
                            if(a.label < b.label) return -1
                            if(a.label > b.label) return 1
                            return 0
                        })

                        responseObject.unshift({
                            label: '(Create new person)',
                            value: 'create'
                        })

                        responseObject.unshift({
                            label: '(Select person)',
                            value: undefined
                        })
                    }
                }     
            break
        }

        if(!responseObject){
            return [{
                label: 'Loading...',
                value: undefined
            }]
        }else{
            return responseObject
        }
    }

    componentWillMount() {
        const allPeople = removeSoftDeletedItems(this.props.initData.user.people)

        this.setState({
            allPeople
        })

        if(this.props.object){
            this.setState({
                fields: {
                    ...this.state.fields,
                    ...this.props.object
                }
            })
        }
    }

    handleChange = (key, value) => {
        if(key == 'spouse'){
            const spouse = this.props.initData.user.people.find(person => {
                return person.id == value
            })

            if(spouse && spouse.address && spouse.address.streetName){
                this.setState({
                    miniAddressModalVisible: true,
                    miniAddressData: {
                        spouse
                    }
                })
            }
        }

        if(key == 'spouse' && value == 'create'){
            this.setState({
                miniModalVisible: true
            })
        }else{
            this.setState({
                fields: {
                    ...this.state.fields,
                    [key]: value
                }
            })
        }

    }

    handleError = error => {
        const errors = error.inner

        let newErrorState = {}

        errors.forEach(error => {
            newErrorState[error.path] = error.message
        })

        this.setState({
            hasErrors: true,
            errors: newErrorState
        }, () => {
            console.log(this.state)
        })
    }

    hasPossibleDuplicate = _ => {
        const {
            fields,
            allPeople
        } = this.state

        let duplicatePerson = null

        if(!fields.id && fields.firstName && fields.lastName && allPeople){
            allPeople.forEach(person => {
                if(person.firstName == fields.firstName && person.lastName == fields.lastName){
                    duplicatePerson = person
                }
            })
        }

        return duplicatePerson
    }

    handleSubmit = async (openNextModal, skipDuplicateCheck = false) => {
        if(this.props.isLoading){
            return 
        }

        const {
            setLoading,
            upsertPerson,
            handleSuccess,
            history
        } = this.props

        if(!skipDuplicateCheck){
            this.possibleDuplicate = this.hasPossibleDuplicate()

            if(this.possibleDuplicate){
                this.openNextModalIntent = openNextModal

                this.scroll.scrollToTop({
                    duration: 300,
                    delay: 0,
                    smooth: "easeInOutQuint",
                    containerId: 'modal',
                })

                return this.setState({
                    miniConfirmModalVisible: true,
                    miniConfirmData: {
                        title: `Possible duplicate found`,
                        body: this.possibleDuplicate.firstName+' '+this.possibleDuplicate.lastName+' appears to exist already. Update them, or create a new person?'
                    }
                })
            }
        }
        
        const validationSchema = yup.object().shape({
            title: yup.string().required('Please enter a password.'),
            firstName: yup.string().required('Please enter a first name.'),
            lastName: yup.string().required('Please enter a last name'),
            email: yup.string().nullable().email("That doesn't look like a valid email.").required('Please enter an email address.'),
        });

        try {
            await validationSchema.validate({ ...this.state.fields }, { abortEarly: false})
        } catch (error) {
            return this.handleError(error)
        }

        setLoading(true)

        this.setState({
            hasErrors: false
        })

        const fields = stripTypenamesFromMutations(this.state.fields)

        delete fields.companies
        delete fields.events
        delete fields.notes
        delete fields.broker
        delete fields.liabilities
        delete fields.assets

        if(fields.spouse && fields.spouse.id){
            fields.spouse = fields.spouse.id
        }

        fields.dependants.forEach(dependant => {
            if(!dependant.yearOfBirth){
                delete dependant.yearOfBirth
            }
        })

        try{
            await upsertPerson({
                variables: {
                    payload: {
                        ...fields
                    },
                },
                update: (store, { data }) => {
                    const initDataQuery = store.readQuery({query: INIT_DATA})
                    const { upsertPerson } = data
                    
                    if(!initDataQuery.user.people.find(person => {
                        return person.id == upsertPerson.id
                    })){
                        initDataQuery.user.people.push(upsertPerson)

                        console.log(initDataQuery)
                        
                        store.writeQuery({query: INIT_DATA, data: initDataQuery})
                    }
                },
            }).then(data => {
                const personData = data.data.upsertPerson

                handleSuccess(() => {
                    if(openNextModal){
                        this.doOpenNextModal(personData)
                    }else{
                        history.push(`/clients/people/${ personData.id }`)
                    }
                })
            })
        }catch(error){
            const clientErrors = error.graphQLErrors

            setLoading(false)
        }
    }

    doOpenNextModal = person => {
        setTimeout(() => {
            this.props.openModal({variables: {
            operation: 'add',
            dataType: 'asset',
            isVisible: true,
            object: {
                personId: person.id,
                companyId: null
            }
            }})
        },300)
    }

    miniModalCallback = (data, type) => {
        let { allPeople, fields } = cloneDeep(this.state)

        if(data){
            if(type == 'person'){
                const person = data.data.upsertPerson

                allPeople.push(person)
                fields.spouse = person.id
            }else if(type == 'address'){
                fields.address = {
                    ...this.state.miniAddressData.spouse.address
                }
            }
        }

        this.setState({
            miniModalVisible: false,
            miniAddressModalVisible: false,
            allPeople,
            fields
        })
    }

    handleDuplicate = action => {
        if(!this.possibleDuplicate){
            return 
        }
        
        if(action == 'update'){
            this.setState({
                fields: {
                    ...this.state.fields,
                    id: this.possibleDuplicate.id
                }
            })
        }

        if(action !== 'close'){
            this.handleSubmit(this.openNextModalIntent, true)
        }

        // close no matter what
        this.setState({
            miniConfirmModalVisible: false
        }, _ => {
            setTimeout(_ => {
                this.setState({
                    miniConfirmModalData: null
                })
            },300)
        })

    }

    getPrefillAddress = _ => {
        let person = null

        if(this.state.fields.spouse){
            person = this.state.allPeople.find(person => {
                return person.id === this.state.fields.spouse.id  
            })
        }

        // only show if the person has a valid address.
        if(person && formatAddress(person.address)){
            return person
        }else{
            return false
        }
    }

    render(){
        this.fieldColumns = [
            [
                [{
                    label: 'Title',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: 'MR',
                                label: 'Mr'
                            },{
                                value: 'MRS',
                                label: 'Mrs'
                            },
                            {
                                value: 'MS',
                                label: 'Ms'
                            }
                        ]
                    },
                    key: 'title'
                }],[{
                    label: 'First name',
                    autoFocus: true,
                    required: true,
                    type: 'text',
                    props: { type: 'text' },
                    key: 'firstName'
                }],[{
                    label: 'Middle name',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'middleName'
                }],[{
                    label: 'Last name',
                    required: true,
                    type: 'text',
                    props: { type: 'text' },
                    key: 'lastName'
                }],[
                    {
                        label: 'Preferred name',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'preferredName'
                    },{
                    label: 'Gender',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: 'MALE',
                                label: 'Male'
                            },{
                                value: 'FEMALE',
                                label: 'Female'
                            }
                        ]
                    },
                    key: 'gender'
                }],[{
                    label: 'Current address',
                    type: 'address',
                    props: { 
                        canOffPlan: false,
                        canPrefillFrom: this.getPrefillAddress()
                    },
                    key: 'address'
                }]
            ],[
                [{
                    label: 'Date of birth',
                    type: 'text',
                    props: { type: 'date' },
                    key: 'dateOfBirth'
                }],[{
                    label: `Notify me of ${ getClientDisplayName(this.state.fields, true) ? getClientDisplayName(this.state.fields, true) : 'this person'}\'s birthday`,
                    type: 'checkbox',
                    key: 'notifyOfBirthday'
                }],[{
                    label: 'Marital status',
                    type: 'select',
                    props: {
                        values: [
                            {
                                value: 'SINGLE',
                                label: 'Single'
                            },
                            {
                                value: 'MARRIED',
                                label: 'Married'
                            },
                            {
                                value: 'DEFACTO',
                                label: 'Defacto'
                            },
                            {
                                value: 'DIVORCED',
                                label: 'Divorced'
                            }
                        ]
                    },
                    key: 'maritalStatus'
                }],   
                [{
                    label: 'Spouse',
                    type: 'select',
                    isHidden: this.state.fields.maritalStatus == 'SINGLE' ? true : false,
                    props: {
                        values: this.getValue('people')
                    },
                    key: 'spouse'
                }],
                [{
                    label: 'Dependants',
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'text', 
                                key: 'name',
                                placeholder: 'Name (optional)' 
                            },{
                                type: 'text', 
                                key: 'yearOfBirth',
                                placeholder: 'Year of birth' 
                            }
                        ],
                        buttonLabel: 'Add dependant'
                    },
                    key: 'dependants'
                }],
                [{
                    label: 'Preferred daytime contact',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: 'Mobile',
                                label: 'Mobile'
                            },{
                                value: 'Work',
                                label: 'Work'
                            }
                        ]
                    },
                    key: 'phonePreference'
                }],
                [
                   {
                        label: 'Mobile number',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'phoneMobile'
                    }
                ],[
                    {
                        label: 'Work number',
                        type: 'text',
                        props: { type: 'text' },
                        key: 'phoneWork'
                    }
                ],[{
                    label: 'Email address',
                    type: 'text',
                    required: true,
                    props: { type: 'email' },
                    key: 'email'
                }],
            ]
        ]

        const {
            miniModalVisible,
            miniAddressModalVisible,
            miniAddressData,
            miniConfirmModalVisible,
            miniConfirmData,
            fields,
            errors,
            hasErrors
        } = this.state

        const {
            object,
            closeCallback
        } = this.props

        return (
            <div className="wide">
                <ModalMiniConfirm
                    isVisible={miniConfirmModalVisible}
                    data={miniConfirmData}
                    handleClose={_ => {this.handleDuplicate('close')}}
                    buttons={[
                        {
                            label: 'Create new',
                            level: 'low',
                            callback: _ => { this.handleDuplicate('new') }
                        },
                        {
                            label: 'Overwrite existing',
                            level: 'high',
                            callback: _ => { this.handleDuplicate('update') }
                        }
                    ]}
                />
                <ModalMiniNew 
                    isVisible={miniModalVisible} 
                    callback={data => {this.miniModalCallback(data, 'person')}} 
                />
                <ModalMiniAddress 
                    isVisible={miniAddressModalVisible} 
                    miniAddressData={miniAddressData} 
                    callback={data => {this.miniModalCallback(data, 'address')}} 
                />
                <div className="content-container">
                    <ModalForm 
                        errors={errors} 
                        handleChange={this.handleChange}
                        fieldState={fields} 
                        columns={this.fieldColumns} 
                    />
                </div>
                <div className="button-row">
                    <ValidationError 
                        error={hasErrors ? "There were errors in your submission." : null} 
                    />
                    { (!object || !object.id) ?
                        <Button 
                            icon="arrow-right" 
                            label="Add asset" 
                            level="high" 
                            callback={_ => {this.handleSubmit(true)}}
                        />
                        : null
                    }
                    <Button 
                        label="Save &amp; exit" 
                        level="high" 
                        callback={_ => {this.handleSubmit(false)}} 
                    />
                    <Button 
                        label="Cancel" 
                        level="low" 
                        callback={closeCallback} 
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(compose(
    graphql(UPSERT_PERSON, {name: 'upsertPerson'}),
    graphql(OPEN_MODAL, {name: 'openModal'}),
    graphql(INIT_DATA, {name: 'initData'})
  ) (ModalPerson))