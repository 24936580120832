import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import * as yup from 'yup'
import ValidationError from 'components/Errors/ValidationError'
import PasswordInfo from './PasswordInfo'

import Checkbox from 'components/Checkbox/Checkbox'

import { cloneDeep } from 'lodash'

class Register extends Component {
	state = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		terms: false,
		error: false,
		errors: {},
		invitationId: null,
		isPasswordFocused: false,
		isPasswordValid: false
	}

	componentWillMount() {
		console.log('mounting')
		console.log(this.props)

		const user = this.props.data

		if (user) {
			this.setState({
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				invitationId: null,
				password: user.password ? user.password : ''
			})
		}else if(this.props.invitation){
			this.setState({
				email: this.props.invitation.email,
				invitationId: this.props.invitation.id
			})
		}
	}

	componentWillUpdate = nextProps => {
		if (!this.props.invitation && nextProps.invitation) {
			console.log(nextProps)

			this.setState({
				email: nextProps.invitation.email,
				invitationId: nextProps.invitation.id
			})
		}
	}

	formatSubmitData = () => {
		let data = {
			...this.state
		}

		delete data.terms
		delete data.isPasswordFocused
		delete data.isPasswordValid
		delete data.alertType
		delete data.error
		delete data.errors

		return data
	}

	handleSubmit = async () => {
		let validationSchema

		// this shouldn't need to duplicate, but you can't conditionally include validation constraints
		if(this.props.isEdit){
			validationSchema = yup.object().shape({
				firstName: yup.string().required('Please enter your first name.'),
				lastName: yup.string().required('Please enter your last name.'),
				email: yup.string().nullable().email("That doesn't look like a valid email.").required('Please enter an email address.'),
				password: !this.props.isEdit ? yup.string().required("Please enter a password.") : yup.string(),
			})
		}else{
			validationSchema = yup.object().shape({
				firstName: yup.string().required('Please enter your first name.'),
				lastName: yup.string().required('Please enter your last name.'),
				email: yup.string().nullable().email("That doesn't look like a valid email.").required('Please enter an email address.'),
				password: !this.props.isEdit ? yup.string().required("Please enter a password.") : yup.string(),
				terms: !this.props.isEdit ? yup.boolean().oneOf([true], 'Please accept our term of use and privacy policy.') : null
			})
		}

		try {
			await validationSchema.validate({ ...this.state }, { abortEarly: false })
		} catch (error) {
			console.log(error)
			return this.handleError(error)
		}

		if(!this.state.isPasswordValid && !this.props.isEdit){
			return this.handleError({
				inner: [{
					path: 'password',
					message: 'Please enter a stronger password.'
				}]
			})
		}

		try {
			this.props.callback(this.formatSubmitData(), this.props.path, (response) => {
				if (response.length) {
					this.handleError(response[0].message)
				} else {
					this.handleError('Uh oh! A server error has occurred.', 'error')
				}
			}, () => {
				this.handleError('Details have been updated successfully.', 'success')
			});

		} catch (error) {
			console.log(error);
		}

	}

	handleError = (error, type) => {
		let newErrorState = {}

		console.log(error)

		if (!type) {
			let firstError = ''

			if (error.inner) {
				const errors = error.inner

				errors.forEach((error, index) => {
					if(index == 0){
						firstError = error.message
					}

					newErrorState[error.path] = error.message
				})
			} else {
				if (typeof (error) == 'string') {
					// graphQL validation sucks.
					if (error.includes('A unique constraint')) {
						firstError = "This email address is already in use."

						newErrorState.email = firstError
					}
				}
			}

			if(!this.props.isEdit){
				this.setState({
					hasErrors: true,
					errors: newErrorState
				})
			}else{
				this.setState({
					alertType: 'error',
					error: firstError
				})
			}
		} else {
			this.setState({
				error,
				alertType: type
			})
		}
	}

	handleChange = (event, input) => {
		let value 

		if(input == 'terms'){
			value = event.target.checked
		}else{
			value = event.target.value
		}

		let currentState = cloneDeep(this.state)

		currentState[input] = value

		this.setState({
			...currentState,
		})
	}

	togglePasswordInfo = isVisible => {
		this.setState({
			isPasswordFocused: isVisible
		})
	}

	setPasswordValidity = isValid => {
		this.setState({
			isPasswordValid: isValid
		})
	}

	render() {
		return (
			<div onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
				<MiniResponse type={this.state.alertType} message={this.state.error} />
				<Loader isLoading={this.props.isLoading} />
				{
					this.props.invitation ?
						<p>
							Add in your details, and we'll add you to {this.props.invitation.brokerage.name}.
          				</p>
						: null
				}
				<div className="content-container">
					<div className="input-wrap">
						{this.props.isEdit ?
							<label>
								First name
            				</label>
							: null}
						<input placeholder="First name" type="text" value={this.state.firstName} onChange={e => this.setState({ firstName: e.target.value })} />
						<ValidationError error={this.state.errors['firstName'] ? this.state.errors['firstName'] : null} />
					</div>
					<div className="input-wrap">
						{this.props.isEdit ?
							<label>
								Last name
            				</label>
							: null}
						<input placeholder="Last name" type="text" value={this.state.lastName} onChange={e => this.setState({ lastName: e.target.value })} />
						<ValidationError error={this.state.errors['lastName'] ? this.state.errors['lastName'] : null} />
					</div>
					<div className="input-wrap">
						{this.props.isEdit ?
							<label>
								Email Address
            				</label>
							: null}
						<input placeholder="Email address" disabled={this.props.invitation ? true : false} type="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
						<ValidationError error={this.state.errors['email'] ? this.state.errors['email'] : null} />
					</div>
					{!this.props.isEdit ?
						<div className="input-wrap">
							{this.props.isEdit ?
								<label>
									Password
            					</label>
								: null}
							<input onFocus={() => {this.togglePasswordInfo(true)}} onBlur={() => {this.togglePasswordInfo(false)}} placeholder="Enter a password" type="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
							<ValidationError error={this.state.errors['password'] ? this.state.errors['password'] : null} />
							<PasswordInfo setValidity={this.setPasswordValidity} isVisible={this.state.isPasswordFocused} password={this.state.password} />
						</div>
						: null
					}
					{!this.props.isEdit ?
						<div className="input-wrap">
							<label className="domain-toggle" onClick={e => e.stopPropagation()}>
								<Checkbox isChecked={this.state.terms} onChange={event => {this.handleChange(event, 'terms')}} />
								<span>
									I have read and agree to the <a href="https://hassl.co/terms-of-use" target="_blank">terms of use</a> and <a href="https://hassl.co/privacy-policy">privacy policy</a>.
								</span>
							</label>
							<ValidationError error={this.state.errors['terms'] ? this.state.errors['terms'] : null} />
						</div>
						: null
					}
				</div>
				<div className="button-row">
					<button className="button high" onClick={this.handleSubmit}>
						{
							!this.props.isEdit ?
								"Register"
								: "Update Profile"
						}
					</button>
				</div>
				{
					this.props.isEdit != true ?
						<div className="lower-row">
							<Link to="/auth/login">
								&lsaquo; Back to login
                			</Link>
						</div>
						: null
				}
			</div>
		)
	}
}

export default Register