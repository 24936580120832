import React, { Component } from 'react'
import Table from 'components/Table/Table'
import Button from 'components/Button/Button'
import { withApollo } from 'react-apollo'
import Loader from 'components/Loader/Loader'
import { cloneDeep, isEqual } from 'lodash'

import { graphql, compose } from 'react-apollo'
import { stripTypenamesFromMutations, removeSoftDeletedItems } from 'utils/utils'

import { 
    GET_LENDERS, 
    INIT_DATA,
    TOGGLE_SELECTED_LENDERS
} from 'graphql/all'

class Lenders extends Component {
    toggleLender = lender => {
        const {
            initData,
            client
        } = this.props

        const brokerage = cloneDeep(initData.user.brokerage)

        const lenderIndex = brokerage.selectedLenders.findIndex(loopedLender => {
            return loopedLender.id === lender.id
        })

        brokerage.selectedLenders.splice(lenderIndex, 1)

        console.log('wtf')

        client.mutate({
            mutation: TOGGLE_SELECTED_LENDERS,
            variables: {
                payload: lender.id
            },
            optimisticResponse: {
                toggleSelectedLenders: {
                    ...brokerage,
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    render (){
        const {
            allLenders,
            initData
        } = this.props

        const {
            loading,
            lenders
        } = allLenders

        let selectedLenders = []

        if(initData.user){
            selectedLenders = initData.user.brokerage.selectedLenders
        }

        const validLenders = lenders ? removeSoftDeletedItems(lenders) : []

        let columnClass = "main-column full-width"

        if(loading){
            columnClass += " loading"
        }

        return (
            <div>
                <div className="heading-section">
                    <h1>
                        Lenders
                    </h1>
                    <p>
                        Choose the lenders you work with, and they'll be available for all your liabilities. 
                    </p>
                </div>
                <div className="main-content-container">
                    {
                        loading ?
                        <Loader isLoading={true} showText={true} />
                        : null
                    }
                    <div className={columnClass}>
                        <div className="content-box no-background">
                            <ul className="lender-grid">
                            {
                                validLenders.map(lender => {
                                    let isSelected = selectedLenders.find(selectedLender => {
                                        return selectedLender.id == lender.id
                                    })

                                    return (
                                        <li key={lender.id}>
                                            <button 
                                                onClick={_ => {this.toggleLender(lender)}} 
                                                className={isSelected ? 'selected' : null}
                                            >
                                                <img src={`/images/logos/${lender.slug}.jpg`} />
                                                <span>
                                                    { lender.name }
                                                </span>
                                            </button>
                                        </li>
                                    )
                                })
                            }
                            </ul>                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(compose(
    graphql(GET_LENDERS, { name: 'allLenders', fetchPolicy: 'network-only'}),
    graphql(INIT_DATA, {name: 'initData'}),
)(Lenders))