import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import Button from 'components/Button/Button'

import ContentEditable from 'react-contenteditable'

import { graphql, compose } from 'react-apollo'
import { withApollo } from 'react-apollo'
import { CREATE_NOTE, UPDATE_NOTE, OPEN_MODAL } from 'graphql/all'

import moment from 'moment'

import Scroll from 'react-scroll'

import { clone, cloneDeep } from 'lodash'

var scroll = Scroll.animateScroll

class Notes extends Component {
    state = {
        newNoteText: '',
        currentEditedNoteId: null,
        editedNoteText: null,
    }

    scrollChatToBottom = () => {
        scroll.scrollToBottom({
            duration: 300,
            delay: 1,
            smooth: "easeInOutQuint",
            containerId: 'notes-list',
        })
    }

    updateNote = _ => {
        this.props.client.mutate({
            mutation: UPDATE_NOTE,
            variables: {
                payload: {
                    id: this.state.currentEditedNoteId,
                    text: this.state.editedNoteText,
                    clientType: this.props.clientType,
                    clientId: this.props.clientId
                }
            },
        }).then(response => {
            this.setState({
                currentEditedNoteId: null,
                editedNoteText: null
            })
        }).catch(error => {
            console.log(error)
        })
    }

    handleSubmit = () => {
        const text = this.state.newNoteText

        setTimeout(() => {
            if(!text){
                return
            }

            this.props.client.mutate({
                mutation: CREATE_NOTE,
                variables: {
                    payload: {
                        clientType: this.props.clientType,
                        clientId: this.props.clientId,
                        text
                    }
                }
            }).then(data => {
                console.log(data)
                this.scrollChatToBottom()
            }).catch(error => {
                console.log(error)
            })

            this.setState({
                newNoteText: ''
            })
        }, 1)
    }

    componentWillMount(){
        this.updateNotesList()
    }

    componentDidMount(){
        let scrollHeight = this.el.scrollHeight;
        let height = this.el.clientHeight;
        let maxScrollTop = scrollHeight - height;
        this.el.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    deleteNote = note => {
        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'delete',
                dataType: 'note',
                isVisible: true,
                object: note
            }
        })
    }

    addToTimeline = note => {
        let modalObject = {
            note
        }

        if(this.props.clientType == 'person'){
            modalObject.personId = this.props.clientId
        }else{
            modalObject.companyId = this.props.clientId
        }

        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'add',
                dataType: 'event',
                isVisible: true,
                object: modalObject
            }
        })
    }

    updateNotesList = () => {
        this.notesList = cloneDeep(this.props.notes)

        this.notesList.sort((a, b) => {
            let aDate = new Date(a.createdAt)
            let bDate = new Date(b.createdAt)
      
            return bDate - aDate
        })
    }

    handleChange = (e, noteId) => {
        if(!noteId){
            this.setState({
                newNoteText: e.target.value
            })
        }else{
            this.setState({
                editedNoteText: e.target.value
            })
        }
    }

    render(){
        const {
            events
        } = this.props

        const {

        } = this.state

        this.updateNotesList()

        const notesListInReverse = this.notesList.reverse()

        const buttonOptions = [
            [
                {
                    icon: 'external-link',
                    tooltip: 'Add to timeline',
                    disabledTooltip: 'Already on timeline',
                    disabledCheck: note => {
                        let isNoteInTimeline = events.find(event => {
                            return event.note && event.note.id === note.id
                        })

                        return isNoteInTimeline ? true : false
                    },
                    callback: this.addToTimeline
                },{
                    icon: 'pencil',
                    tooltip: 'Edit note',
                    callback: note => {
                        this.setState({
                            currentEditedNoteId: note.id,
                            editedNoteText: note.text
                        })
                    }
                },{
                    icon: 'trash',
                    tooltip: 'Delete note',
                    callback: this.deleteNote
                }
            ],[
                {
                    icon: 'check',
                    callback: note => {
                        this.updateNote()
                    }
                },{
                    icon: 'ban',
                    callback: note => {
                        this.setState({
                            currentEditedNoteId: null,
                            editedNoteText: null
                        })
                    }
                }
            ]
        ]

        return (
            <div className="notes">
                <h3>
                    Notes
                </h3>
                <div className="notes-list" id="notes-list" ref={el => { this.el = el }}>
                    { notesListInReverse.map(note => {
                        if(!note.text){
                            return null
                        }

                        let authorText = null
                        let editedBy = null

                        if(note.author){
                            authorText = `• ${note.author.firstName} ${note.author.lastName}`
                        }

                        if(note.editedBy){
                            editedBy = `• Edited by ${note.editedBy.firstName} ${note.editedBy.lastName}`
                        }

                        let isBeingEdited = this.state.currentEditedNoteId === note.id

                        return (
                            <div key={note.id} className={isBeingEdited ? "note-container fixed" : "note-container"}>
                                <div>
                                    <div className={isBeingEdited ? "content editing" : "content"}>
                                        <ContentEditable 
                                            onKeyPress={e => e.key == 'Enter' ? this.handleSubmit() : null}
                                            onChange={e => {this.handleChange(e, note.id)}} 
                                            html={ isBeingEdited ? this.state.editedNoteText : note.text } 
                                            disabled={!isBeingEdited} 
                                        />
                                    </div>
                                    <div className={"note-controls"}>
                                        { buttonOptions.map((buttonGroup, groupIndex) => {
                                            let className

                                            if(groupIndex == 0){
                                                className = !isBeingEdited ? 'visible' : null
                                            }else{
                                                className = isBeingEdited ? 'visible' : null
                                            }

                                            return (
                                                <div className={className} key={groupIndex}>
                                                    { buttonGroup.map((button, buttonIndex) => {
                                                        let disabled = button.disabledCheck ? button.disabledCheck(note) : false

                                                        return (
                                                            <button onClick={() => {button.callback(note)}} disabled={disabled} className={button.tooltip ? 'has-tooltip' : null} key={buttonIndex}>
                                                                <FontAwesome name={button.icon} />
                                                                { button.tooltip ? 
                                                                    <span className="tooltip">
                                                                        { disabled ? button.disabledTooltip : button.tooltip }
                                                                    </span>
                                                                : null }
                                                            </button>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="meta">
                                    { moment(note.createdAt).format('h:mm a, D/M/Y') } { authorText } { editedBy }
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="notes-editor">
                    <ContentEditable 
                        placeholder="Enter a note here" 
                        onKeyPress={e => {
                            if(e.key == 'Enter'){
                                this.handleSubmit()
                                e.preventDefault()
                            }
                        }}
                        onChange={e => {this.handleChange(e, null)}} 
                        html={this.state.newNoteText} 
                    />
                    <Button disabled={this.state.newNoteText ? false : true} label="Save note" callback={this.handleSubmit} />
                </div>
                { /* <form onSubmit={e => e.preventDefault()}>
                    <textarea onChange={e => {this.handleChange(e.target.value)}} value={this.state.newNoteText} />
                    <div className="button-shelf">
                        {
                            !eventIfExisting ?
                            <button onClick={this.addToTimeline}>
                                <FontAwesome name="calendar-plus-o" />
                                Add to timeline
                            </button>
                            :
                            <button disabled onClick={() => {}}>
                                <FontAwesome name="check" />
                                Added to "{ eventIfExisting.title }"
                            </button>
                        }
                        <button disabled={this.notesList.length < 2} onClick={this.deleteNote}>
                            <FontAwesome name="trash" />
                            Delete note
                        </button>
                    </div>
                    </form> */ }
            </div>
        )
    }
}

export default compose(withApollo) (Notes)