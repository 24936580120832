import React, { Component } from 'react'

class RateDisplay extends Component {
    render() {
        let className = 'input-container ';

        const { type, label, error, required, value, placeholder } = this.props

        return (
            <div className={error ? "input-wrap error" : "input-wrap"}>
                <label>
                    {label}
                </label>
                <span className="disabled-input">
                    { value }
                </span>
            </div>
        )
    }
}

export default RateDisplay
