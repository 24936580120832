import React, { Component } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Switch, Route, Redirect } from "react-router-dom"
import { AUTH_TOKEN } from '../../constants'

import DocumentTitle from 'react-document-title'

import { SIGNUP_MUTATION, 
	LOGIN_MUTATION, 
	SIGNUP_BROKERAGE_MUTATION, 
	GET_ACTION_DATA, 
	GET_REDIRECT_PATH, 
	CLEAR_REDIRECT_PATH,
	FORGOT_PASSWORD_MUTATION,
	NEW_PASSWORD_MUTATION,
	JOIN_AUTHORISED_BROKERAGE
} from 'graphql/all'

import Login from './Login/Login'
import Register from './Register/Register'
import RegisterBrokerage from './RegisterBrokerage/RegisterBrokerage'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import NewPassword from './ForgotPassword/NewPassword'
import JoinBrokerage from './JoinBrokerage/JoinBrokerage'

class Auth extends Component {
	state = {
		isLoading: false,
		user: null,
		invitation: null,
		notify: null,
		authorisedBrokerage: null,
		redirectPath: '/',
		data: null
	}

	componentWillMount() {
		this.props.clearRedirectPath()

		let redirectPath = this.props.getRedirectPath.redirectPath.path

		if (redirectPath) {
			this.setState({
				redirectPath
			})
		}

		const authToken = localStorage.getItem(AUTH_TOKEN);

		if (authToken) {
			this.props.history.push('/')
		}
	}

	componentWillMount() {
		const { actionData } = this.props

		if(actionData.action){
			const action = actionData.action

			if(action.type == 'invite'){
				this.setState({
					invitation: action.object
				})
			}else if(action.type == 'verify'){
				this.setState({
					notify: "Your email has been verified! Please log in."
				})

				this.props.client.writeQuery({
					query: GET_ACTION_DATA, data: {
						action: {
							type: null,
							object: null,
							error: null,
							__typename: 'ActionDataPayload'
						}
					}
				})
			}
		}
	}

	handleLoading = (isLoading) => {
		this.setState({
			isLoading
		})
	}

	_confirm = async (data, type, response, successResponse) => {
		this.setState({
			isLoading: true
		})

		const { 
			id, 
			firstName, 
			lastName, 
			email, 
			password, 
			name, 
			invitationId, 
			authorisedDomain, 
			imageFile 
		} = data

		let success = true;

		let result
		let token
		let brokerage
		let user
		let isUniqueDomain
		let authorisedBrokerage

		try {
			switch (type) {
				case 'login':
					result = await this.props.loginMutation({
						variables: {
							email,
							password
						}
					})

					token = result.data.login.token
					user = result.data.login.user
					brokerage = result.data.login.user.brokerage
					this._saveUserData(token)
					break
				case 'register':
					// This is a little odd, cause if you click 'back' when registering a brokerage, it should keep your old deets.
					result = await this.props.signupMutation({
						variables: {
							id: (this.state.data && this.state.data.id) ? this.state.data.id: null,
							firstName,
							lastName,
							email,
							password,
							invitationId
						}
					})

					token = result.data.signup.token
					user = result.data.signup.user
					isUniqueDomain = result.data.signup.isUniqueDomain
					brokerage = user.brokerage
					authorisedBrokerage = result.data.signup.authorisedBrokerage

					// 'authorisedBrokerage' is null, unless appropriate
					this.setState({
						data: {
							id: user.id,
							firstName,
							lastName,
							email,
							password
						},
						authorisedBrokerage
					})

					this._saveUserData(token)

					break
				case 'new-brokerage':
					result = await this.props.signupBrokerageMutation({
						variables: {
							name,
							authorisedDomain,
							logo: imageFile ? imageFile : null
						}
					})

					brokerage = result.data.signupBrokerage

					break
				case 'join-brokerage':
					result = await this.props.joinBrokerMutation({
						variables: {
							id: data.id
						}
					})

					brokerage = result.data.joinAuthorisedBrokerage
					break
				case 'forgot-password':
					result = await this.props.forgotPasswordMutation({
						variables: {
							email
						}
					})

					// temp - just to stop redirection
					success = false
					successResponse()
					break
				case 'new-password':
					result = await this.props.newPasswordMutation({
						variables: {
							id,
							password
						}
					})

					// temp - just to stop redirection
					success = false
					successResponse()
					break
			}
		} catch (err) {
			console.log(err)

			const clientErrors = err.graphQLErrors;
			success = false;

			response(clientErrors);
		}

		this.setState({
			isLoading: false
		})

		if (success) {
			setTimeout(() => {
				if (!brokerage) {
					this.setState({
						user,
						isUniqueDomain
					}, () => {
						if(authorisedBrokerage){
							this.props.history.push('/auth/join-brokerage')
						}else{
							this.props.history.push('/auth/new-brokerage')
						}
					})
				} else {
					console.log(this.state.redirectPath)

					this.props.history.push(this.state.redirectPath)
				}
			}, 300)
		}
	}

	_saveUserData = token => {
		console.log('setting item');
		localStorage.setItem(AUTH_TOKEN, token)
	}

	routes = [
		{
			title: 'Log in',
			path: 'login',
			component: Login,
		}, {
			title: 'Register',
			path: 'register',
			component: Register,
		}, {
			title: 'Register brokerage',
			path: 'new-brokerage',
			component: RegisterBrokerage
		}, {
			title: 'Forgot password',
			path: 'forgot-password',
			component: ForgotPassword
		}, {
			title: 'Reset password',
			path: 'new-password',
			component: NewPassword
		}, {
			title: 'Join this brokerage?',
			path: 'join-brokerage',
			component: JoinBrokerage
		}
	];

	render() {
		const {
			data,
			notify,
			invitation,
			user,
			isLoading,
			isUniqueDomain,
			authorisedBrokerage
		} = this.state

		return (
			<div className="auth-container">
				<TransitionGroup>
					<CSSTransition key={this.props.location.key} className="test" classNames="zoom" timeout={300}>
						<Switch className="test2" location={this.props.location}>
							{
								this.routes.map((route, i) => {
									return (
										<Route key={i} exact path={"/auth/" + route.path} render={routeProps => {
											const Component = route.component

											return (
												<DocumentTitle title={route.title + ' - Zamm'}>
													<div className={'auth-section-'+route.path}>
														<img src={'/images/logo.png'} className="logo" />
														<h3>
															{route.title}
														</h3>
														<Component 
															{...routeProps} 
															data={data}
															notify={notify} 
															invitation={invitation} 
															user={user} 
															handleLoading={this.handleLoading} 
															isLoading={isLoading} 
															path={route.path} 
															callback={this._confirm} 
															isUniqueDomain={isUniqueDomain}
															authorisedBrokerage={authorisedBrokerage}
														/>
													</div>
												</DocumentTitle>
											)
										}} />
									)
								})
							}
							<Route exact path={"/auth/loading"} render={() => {
								return <span></span>
							}} />
							<Redirect from={"/auth/"} exact to={'/auth/login'} key='4' />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</div>
		)
	}
}

export default withApollo(compose(
	graphql(SIGNUP_MUTATION, { name: 'signupMutation' }),
	graphql(SIGNUP_BROKERAGE_MUTATION, { name: 'signupBrokerageMutation' }),
	graphql(LOGIN_MUTATION, { name: 'loginMutation' }),
	graphql(FORGOT_PASSWORD_MUTATION, { name: 'forgotPasswordMutation' }),
	graphql(NEW_PASSWORD_MUTATION, { name: 'newPasswordMutation' }),
	graphql(JOIN_AUTHORISED_BROKERAGE, { name: 'joinBrokerMutation'}),
	graphql(GET_REDIRECT_PATH, { name: 'getRedirectPath' }),
	graphql(CLEAR_REDIRECT_PATH, { name: 'clearRedirectPath' }),
	graphql(GET_ACTION_DATA, { name: 'actionData' })
)(Auth))

