import React, { Component } from 'react'
import { cloneDeep } from 'lodash'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import Loader from 'components/Loader/Loader'

import { numberWithCommas } from 'utils/utils'

class TotalsOverview extends Component {
    state = {
        isTotalVisible: false
    }

    colours = {
        green: '#77cd6c',
        paleGreen: '#def1db',
        blue: '#2896c9',
        aqua: '#6692db'
    }

    generateChartData = () => {
        let sortedLiabilities = cloneDeep(this.props.liabilities).filter(liability => {
            return liability.startDate ? true : false
        })

        sortedLiabilities.sort((a, b) => {
            let aDate = new Date(a.startDate)
            let bDate = new Date(b.startDate)

            return aDate - bDate;
        });

        let totalValue = 0

        let earliestDate
        let latestDate

        let data = []

        sortedLiabilities.forEach((liability, index) => {
            let thisDate = moment(liability.startDate).startOf('day')

            if(index == 0){
                earliestDate = thisDate
            }

            if(index == (sortedLiabilities.length - 1)){
                latestDate = thisDate
            }

            let shouldPush = true

            totalValue += liability.amount

            // check if previous was same day
            if(data.length > 0){
                let previousItem = data[(data.length - 1)]

                if(previousItem.x.getTime() == thisDate.toDate().getTime()){
                    data[(data.length - 1)].y += liability.amount
                    shouldPush = false
                }
            }

            if(shouldPush){
                data.push({
                    x: thisDate.toDate(),
                    y: Math.round(totalValue)
                })
            }
        })

        if(earliestDate && latestDate){
            let totalDays = latestDate.diff(earliestDate, 'days')

            if(totalDays < 20){
                let startDate = earliestDate.subtract((20 - totalDays), 'days')

                data.unshift({
                    x: startDate.toDate(),
                    y: 0
                })
            }
        }

        let chartData = {
            labels: [],
            datasets: [{
                fill: true,
                borderColor: this.colours.green,
                backgroundColor: this.colours.paleGreen,
                borderWidth: 1,
                pointRadius: 3,
                pointBackgroundColor: this.colours.green,
                data
            }]
        }

        return {
            data: chartData,
            total: Math.round(totalValue)
        } 
    }

    toggleTotal = () => {
        this.setState({
            isTotalVisible: !this.state.isTotalVisible
        })
    }

    render() {
        let chartData = this.generateChartData()

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            axes: {
                display: false
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: false,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                    }
                }],
                xAxes: [{
                    display: true,
                    gridLines: {
                        display: false
                    },
                    type: 'time',
                    time: {
                        minUnit: 'day',
                        unit: 'quarter',
                        displayFormats: {
                            month: 'MMM',
                            quarter: '[Q]Q YYYY'
                        }
                    },
                    ticks: {
                        beginAtZero: true,
                    },
                    afterBuildTicks: function(axis) {
                        axis.margins.left = '-5px';
                        axis.margins.right = '-5px';
                    },
                }]
            }
        }

        return (
            <div>
                <div className="dashboard-widget totals-overview">
                    <h3>
                        Loan book value
                    </h3>
                    <div className="chart-container">
                        <Loader isLoading={this.props.isLoading} showText={true} />
                        <div>
                            <Line data={chartData.data} options={options} width={330} height={250} />
                        </div>
                        <span className={this.state.isTotalVisible ? "total-container toggled" : "total-container"}>
                            <span>
                                { '$'+numberWithCommas(chartData.total) }
                            </span>
                            <i></i>
                        </span>
                    </div>
                    <div className={this.state.isTotalVisible ? "total-container toggled" : "total-container"}>
                        <h2>
                            { '$'+numberWithCommas(chartData.total) }
                        </h2>
                        <button className="h3" onClick={this.toggleTotal}>
                            + Click to reveal
                        </button>
                        <h3>
                            Current total value
                        </h3>
                    </div>
                </div>
                <div className="totals-mini-container">
                    <div>
                        <h3>
                            Loans
                        </h3>
                        <div>
                            <figure>
                                { this.props.liabilities.length }
                            </figure>
                        </div>
                    </div>
                    <div>
                        <h3>
                            Clients
                        </h3>
                        <div>
                            <figure>
                                { this.props.companies.length+this.props.people.length }
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TotalsOverview