import React, { Component } from 'react'

import { CSSTransition } from "react-transition-group"
import Button from 'components/Button/Button'

export default class ModalMiniConfirm extends Component {
	render() {
		const {
			isVisible,
			handleClose,
			data,
			buttons
		} = this.props

		return (
			<CSSTransition 
				in={isVisible ? true : false} 
				mountOnEnter 
				unmountOnExit 
				classNames="mini-modal" 
				timeout={{ enter: 1, exit: 300 }}
			>
                <div className="modal-mini modal-mini-confirm">
                    <div>
						<button 
							className="close-modal"
							onClick={handleClose}
						>
							&times;
						</button>
                        <h3>
                            { data ? data.title : '' }
                        </h3>
                        <p>
                            { data ? data.body : '' }
                        </p>
						{ buttons.map((button, index) => {
							return (
								<Button 
									key={index}
									{...button}
								/>

							)
						})}
                    </div>
                </div>
            </CSSTransition>
		)
	}
}
