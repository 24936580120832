import React, { Component } from 'react'
import { CSSTransition } from "react-transition-group"
import FontAwesome from 'react-fontawesome'

class Loader extends Component {
  types = ['folding', 'bars']

  render(){
    let type = this.types[0]

    const { isSuccess, isLoading, showText } = this.props

    let className = 'loader-container'

    if(isSuccess){
      className += ' success'
    }

    return (
      <div className={className}>
        <CSSTransition in={isLoading} unmountOnExit classNames="zoom" timeout={{ enter: 1, exit: 200 }}>
          <div className="loader">
            <div>
              {
                !isSuccess ? 
                <div className={type}>
                  <span className="sk-folding-cube">
                    <span className="sk-cube1 sk-cube"></span>
                    <span className="sk-cube2 sk-cube"></span>
                    <span className="sk-cube3 sk-cube"></span>
                    <span className="sk-cube4 sk-cube"></span>
                    <span className="sk-cube5 sk-cube"></span>
                  </span>
                </div>
                :
                <div className="success-container">
                  <FontAwesome name="check" />
                </div>
              }
              {
                showText ? 
                <span className="text">
                   { isSuccess ? 'Success!' : 'Loading' }
                </span>
                : null
              }
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default Loader
