import { 
  GET_MODAL_DATA, 
  GET_ACTION_DATA, 
  GET_REDIRECT_PATH,
  GET_SORTING_PREF
} from 'graphql/all'

import { defaults } from './state'

import { cloneDeep } from 'lodash'
import { client } from 'index'

export const openModal = (_, { operation, dataType, object }, { cache }) => {
    const modalQuery = cache.readQuery({
      query: GET_MODAL_DATA
    })

    const currentModal = cloneDeep(modalQuery.currentModal)
  
    currentModal.operation = operation
    currentModal.dataType = dataType
    currentModal.isVisible = true
  
    if(object){
      currentModal.object = object
    }
  
    cache.writeQuery({
      query: GET_MODAL_DATA,
      data: { currentModal }
    })
  
    return null
  }
  
  export const closeModal = (_, {}, { cache }) => {
    const modalQuery = cache.readQuery({
      query: GET_MODAL_DATA
    })

    const currentModal = cloneDeep(modalQuery.currentModal)
  
    currentModal.isVisible = false
    currentModal.object = null
  
    cache.writeQuery({
      query: GET_MODAL_DATA,
      data: { currentModal }
    })
  
    return null
  };

  export const doEmailAction = (_, { type, object }, { cache }) => {
    const actionDataQuery = cache.readQuery({
      query: GET_ACTION_DATA
    })

    const actionData = cloneDeep(actionDataQuery)

    actionData.type = type
    actionData.object = object

    cache.writeQuery({
      query: GET_ACTION_DATA,
      data: { actionData }
    })
  }

  export const setRedirectPath = (_, { path }, { cache }) => {
    let { redirectPath } = cache.readQuery({
      query: GET_REDIRECT_PATH
    })

    redirectPath.path = path

    cache.writeQuery({
      query: GET_REDIRECT_PATH,
      data: { redirectPath }
    })
  }

  export const clearRedirectPath = (_, { path }, { cache }) => {
    let { redirectPath } = cache.readQuery({
      query: GET_REDIRECT_PATH
    })

    redirectPath.path = null

    cache.writeQuery({
      query: GET_REDIRECT_PATH,
      data: { redirectPath }
    })
  }

  export const setSortingPref = (_, {path, column, direction}, { cache }) => {
    let sortingPrefs = {}

    try{
      const sortingPrefQuery = cache.readQuery({
        query: GET_SORTING_PREF
      })

      sortingPrefs = cloneDeep(sortingPrefQuery.sortingPrefs)
    }catch(e){
      sortingPrefs = defaults.sortingPrefs
    }

    sortingPrefs[path] = {
      column, 
      direction,
      __typename: "SortingPrefs"
    }

    client.writeQuery({
      query: GET_SORTING_PREF,
      data: { sortingPrefs }
    })

    return null
  }