import React, { Component } from 'react'
import TabSection from 'components/TabSection/TabSection'
import { withRouter } from 'react-router'
import { Switch, Route, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

import { 
    removeSoftDeletedItems, 
    generateEvents, 
    formatAddress, 
    numberWithCommas, 
    getLiabilityObjectsFromRelations,
    getCurrentEffectiveInterestRate,
    getClientDisplayName
} from 'utils/utils'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

import { graphql, Query, compose, withApollo } from 'react-apollo'

import {
    GET_PERSON,
    GET_COMPANY,
    OPEN_MODAL,
	SET_SORTING_PREF,
	GET_SORTING_PREF
} from 'graphql/all'

import Loader from 'components/Loader/Loader'
import Button from 'components/Button/Button'

import SingleLiability from './SingleLiability'

import Overview from './Tabs/Overview'
import List from 'components/List/List'
import AssetAllocation from 'components/AssetAllocation/AssetAllocation'

import { cloneDeep } from 'lodash'

import UpcomingEvents from './Widgets/UpcomingEvents'
import QuickDetails from './Widgets/QuickDetails'
import Connections from './Widgets/Connections'

class SingleClient extends Component {

    componentWillMount() {
        this.props.client.query({
            query: this.props.match.params.type == 'people' ? GET_PERSON : GET_COMPANY,
            variables: {
                id: this.props.match.params.id
            },
            fetchPolicy: 'network-only'
        })

        if(!this.props.sortingPrefs.sortingPrefs.liability.column){
            this.props.setSortingPref({
                variables: {
                    path: 'liability',
                    column: 'name',
                    direction: 'asc',
                }
            })
        }
        
        if(!this.props.sortingPrefs.sortingPrefs.asset.column){
            this.props.setSortingPref({
                variables: {
                    path: 'asset',
                    column: 'name',
                    direction: 'asc',
                }
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getRowData = (key, item, type) => {
        let returnObject = {}
        let object = null
        let objectType = null

        let baseUrl = this.props.match.url

        if (item.__typename == 'LiabilityApplicant') {
            objectType = 'liability'
            object = item.personRelation ? item.personRelation : item.companyRelation
            baseUrl += '/liabilities'
        } else if (item.__typename == "AssetOwner") {
            objectType = 'asset'
            object = item.personRelation ? item.personRelation : item.companyRelation
            baseUrl += '/assets'
        }

        if (!object) {
            return null
        }

        switch (key) {
            case 'name':
                returnObject.label = object.name ? object.name : null

                if (objectType == 'liability') {
                    returnObject.href = baseUrl + '/' + object.id
                    returnObject.callback = this.openItem

                    if(!returnObject.label){
                        returnObject.label = '(unnamed liability)'
                    }
                }else{
                    if(!returnObject.label){
                        returnObject.label = '(unnamed asset)'
                    }
                }
                break
            case 'loan':
                if (object.loan) {
                    returnObject.label = object.loan.name
                }
                break
            case 'contact':
                returnObject.label = 'test'
                break
            case 'address':
                returnObject.label = formatAddress(object.address) ? formatAddress(object.address) : '(No address)'
                break
            case 'value':
                if(object.value){
                    returnObject.label = '$'+numberWithCommas(object.value)
                }else{
                    returnObject.label = '(No value)'
                }
                break
            case 'usage':
                returnObject.label = object.isInvestment ? 'Investment' : 'Owner-occupier'
                break
            case 'effectiveRate':
                const effectiveRate = getCurrentEffectiveInterestRate(object)
                
                if(effectiveRate){
                    returnObject.label = effectiveRate
                }else{
                    returnObject.label = 'N/A'
                }
                break
            case 'amount':
                if(object.amount){
                    returnObject.label = '$'+numberWithCommas(object.amount)
                }else{
                    returnObject.label = '(No value)'
                }
                break
        }

        return returnObject
    }

    openItem = (item, href) => {
        this.props.history.push(href)
    }

    getLiabilityObjects = liabilities => {
        let liabilityObjects = []

        liabilities.forEach(liability => {
            let liabilityObject = liability.personRelation ? liability.personRelation : liability.companyRelation

            if (liabilityObject) {
                liabilityObjects.push(liabilityObject)
            }
        })

        return liabilityObjects
    }

    hiddenBySearch = (item, searchValue) => {
        let shouldHide = true

        let object = item.personRelation ? item.personRelation : item.companyRelation

        // has no person or company..?
        if (!object) {
            return true
        }

        if (item.__typename == 'AssetOwner') {
            if (object.name.toLowerCase().includes(searchValue)) {
                shouldHide = false
            }

            if (formatAddress(object.address) && formatAddress(object.address).toLowerCase().includes(searchValue)) {
                shouldHide = false
            }
        } else if (item.__typename == 'LiabilityApplicant') {
            if (object.name.toLowerCase().includes(searchValue)) {
                shouldHide = false
            }

            if (object.loan && object.loan.name.toLowerCase().includes(searchValue)) {
                shouldHide = false
            }

            object.assets.forEach(asset => {
                let addressString = formatAddress(asset.address)

                if (addressString && addressString.toLowerCase().includes(searchValue)) {
                    shouldHide = false
                }
            })
        }

        return shouldHide
    }

    removeItemsWithNoRelation = rows => {
        return rows.filter(row => {
            if(row.personRelation || row.companyRelation){
                return true
            }else{
                return false
            }
        })
    }

    getComparedRelations = (a, b, direction) => {
        let firstObject = a.personRelation ? a.personRelation : a.companyRelation
        let secondObject = b.personRelation ? b.personRelation : b.companyRelation

        if (direction == 'asc') {
            firstObject = b.personRelation ? b.personRelation : b.companyRelation
            secondObject = a.personRelation ? a.personRelation : a.companyRelation
        }

        return {
            a: firstObject,
            b: secondObject
        }
    }

    sort(rows, type) {
        let clonedRows = this.removeItemsWithNoRelation(cloneDeep(rows))

        let sortingType = type == 'assets' ? this.props.sortingPrefs.sortingPrefs.asset : this.props.sortingPrefs.sortingPrefs.liability

        switch (sortingType.column) {
            case 'name':
                clonedRows.sort((a, b) => {
                    const relations = this.getComparedRelations(a, b, sortingType.direction)

                    let aName = relations.a.name.toLowerCase()
                    let bName = relations.b.name.toLowerCase()

                    if (aName < bName) return -1
                    if (aName > bName) return 1
                    return 0
                })
                break
            case 'address':
                clonedRows.sort((a, b) => {
                    const relations = this.getComparedRelations(a, b, sortingType.direction)

                    let aAddress = formatAddress(relations.a.address)
                    let bAddress = formatAddress(relations.b.address)

                    if (aAddress < bAddress || !bAddress) return -1
                    if (aAddress > bAddress || !aAddress) return 1
                    return 0
                })

                break
            case 'state':
                clonedRows.sort((a, b) => {
                    const relations = this.getComparedRelations(a, b, sortingType.direction)

                    let aState = relations.a.address.state
                    let bState = relations.b.address.state

                    if (aState < bState || !bState) return -1
                    if (aState > bState || !aState) return 1
                    return 0
                })
                
                break
            case 'usage':
                clonedRows.sort((a, b) => {
                    const relations = this.getComparedRelations(a, b, sortingType.direction)

                    let aIsInvestment = relations.a.isInvestment
                    let bIsInvestment = relations.b.isInvestment

                    return bIsInvestment - aIsInvestment
                })

                break
            case 'loan':
                clonedRows.sort((a, b) => {
                    const relations = this.getComparedRelations(a, b, sortingType.direction)

                    let aLoan = relations.a.loan ? relations.a.loan.name : null
                    let bLoan = relations.b.loan ? relations.b.loan.name : null

                    if (aLoan < bLoan || !bLoan) return -1
                    if (aLoan > bLoan || !aLoan) return 1
                    return 0
                })

                break
            case 'percent':
                clonedRows.sort((a, b) => {
                    let firstObject = a
                    let secondObject = b
            
                    if (sortingType.direction == 'asc') {
                        firstObject = b
                        secondObject = a
                    }

                    return firstObject.percent - secondObject.percent
                })

                break
        }

        return clonedRows
    }

    changeSorting = (type, key) => {
        let sortingObject

        if (type == 'asset') {
            sortingObject = cloneDeep(this.props.sortingPrefs.sortingPrefs.asset)
        } else {
            sortingObject = cloneDeep(this.props.sortingPrefs.sortingPrefs.liability)
        }

        if (sortingObject.column == key) {
            sortingObject.direction = sortingObject.direction == 'desc' ? 'asc' : 'desc'
        } else {
            sortingObject = {
                column: key,
                direction: 'desc'
            }
        }

        this.props.setSortingPref({
            variables: {
                path: type,
                ...sortingObject
            }
        })
    }

    render() {
        const {
            match,
            openModal,
            sortingPrefs,
            location
        } = this.props

        const {
            type
        } = match.params

        return (
            <Query 
                query={type == 'people' ? GET_PERSON : GET_COMPANY} 
                variables={{ id: match.params.id }} 
                fetchPolicy={'network-only'}
            >
                {({ data, loading, error, fetchMore }) => {
                    if (error) {
                        console.log(error)
                        
                        openModal({
                            variables: {
                                operation: 'error',
                                dataType: null,
                                isVisible: true,
                                object: error.graphQLErrors[0].message
                            }
                        })

                        return <Redirect to="/clients" />
                    }

                    if (loading) {
                        return <Loader isLoading={true} showText={true} />
                    }

                    const { person, company } = data

                    let liabilities
                    let assets
                    let title

                    if (person) {
                        liabilities = person.liabilities
                        assets = person.assets
                        title = getClientDisplayName(person)
                    } else {
                        liabilities = company.liabilities
                        assets = company.assets
                        title = company.name
                    }

                    assets = removeSoftDeletedItems(assets)
                    liabilities = removeSoftDeletedItems(liabilities)

                    let breadcrumbs = [
                        {
                            link: '/clients',
                            label: 'Clients'
                        }, {
                            link: '/clients/'+type,
                            label: this.capitaliseFirstLetter(type)
                        }, {
                            link: match.url,
                            label: title
                        }
                    ]

                    this.tabs = [
                        {
                            label: "Overview",
                            component: Overview,
                            data: {
                                liabilities,
                                person,
                                company
                            }
                        }, {
                            label: "Assets",
                            component: List,
                            data: {
                                rows: this.sort(assets, 'assets'),
                                type: 'asset',
                                modalObject: {
                                    personId: person ? person.id : null,
                                    companyId: company ? company.id : null
                                },
                                getter: this.getRowData,
                                hiddenBySearch: this.hiddenBySearch,
                                sorting: sortingPrefs.sortingPrefs.asset,
                                changeSortingCallback: this.changeSorting,
                                columns: [
                                    {
                                        label: '',
                                        type: 'checkbox',
                                        key: 'isChecked'
                                    }, {
                                        label: 'Name',
                                        type: 'value',
                                        key: 'name',
                                        canSort: true
                                    }, {
                                        label: 'Address',
                                        type: 'value',
                                        key: 'address',
                                        canSort: true
                                    }, {
                                        label: 'Market value',
                                        type: 'value',
                                        key: 'value',
                                        canSort: true
                                    }, {
                                        label: 'Usage',
                                        type: 'value',
                                        key: 'usage',
                                        canSort: true
                                    }, {
                                        label: '',
                                        type: 'actions',
                                        actions: [
                                            {
                                                label: 'Edit asset',
                                                callback: asset => {
                                                    const assetObject = asset.personRelation ? asset.personRelation : asset.companyRelation

                                                    const modalObject = {
                                                        ...assetObject,
                                                        personId: person ? person.id : null,
                                                        companyId: company ? company.id : null
                                                    }

                                                    openModal({
                                                        variables: {
                                                            operation: 'edit',
                                                            dataType: 'asset',
                                                            isVisible: true,
                                                            object: modalObject
                                                        }
                                                    })
                                                }
                                            }, {
                                                label: 'Delete asset',
                                                callback: asset => {
                                                    openModal({
                                                        variables: {
                                                            operation: 'delete',
                                                            dataType: 'asset',
                                                            isVisible: true,
                                                            object: [asset]
                                                        }
                                                    })
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        }, {
                            label: "Liabilities",
                            component: List,
                            data: {
                                rows: this.sort(liabilities, 'liabilities'),
                                type: 'liability',
                                modalObject: {
                                    personId: person ? person.id : null,
                                    companyId: company ? company.id : null
                                },
                                getter: this.getRowData,
                                hiddenBySearch: this.hiddenBySearch,
                                sorting: sortingPrefs.sortingPrefs.liability,
                                changeSortingCallback: this.changeSorting,
                                columns: [
                                    {
                                        label: '',
                                        type: 'checkbox',
                                        key: 'isChecked'
                                    }, {
                                        label: 'Name',
                                        type: 'value',
                                        key: 'name',
                                        canSort: true
                                    }, {
                                        label: 'Loan',
                                        type: 'value',
                                        key: 'loan',
                                        canSort: true
                                    },{
                                        label: 'Amount',
                                        type: 'value',
                                        key: 'amount',
                                        canSort: true
                                    },{
                                        label: 'Effective rate',
                                        type: 'value',
                                        key: 'effectiveRate',
                                        canSort: true
                                    },{
                                        label: '',
                                        type: 'actions',
                                        actions: [
                                            {
                                                label: 'Edit liability',
                                                callback: liability => {
                                                    const liabilityObject = liability.personRelation ? liability.personRelation : liability.companyRelation

                                                    const modalObject = {
                                                        ...liabilityObject,
                                                        personId: person ? person.id : null,
                                                        companyId: company ? company.id : null
                                                    }

                                                    openModal({
                                                        variables: {
                                                            operation: 'edit',
                                                            dataType: 'liability',
                                                            isVisible: true,
                                                            object: modalObject
                                                        }
                                                    })
                                                }
                                            }, {
                                                label: 'Delete liability',
                                                callback: liability => {
                                                    openModal({
                                                        variables: {
                                                            operation: 'delete',
                                                            dataType: 'liability',
                                                            isVisible: true,
                                                            object: [liability]
                                                        }
                                                    })
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        }, {
                            label: "Allocation",
                            component: AssetAllocation,
                            data: {
                                liabilities,
                                person,
                                company
                            }
                        }
                    ]

                    let liabilityObjects = getLiabilityObjectsFromRelations(liabilities)

                    let upcomingEvents = generateEvents(liabilityObjects, [person], [company], 'all', 'overview')

                    return (
                        <DocumentTitle title={title + ' - Zamm'}>
                            <Switch location={location}>
                                <Route path={match.url + '/liabilities/:id'} render={routeProps => {
                                    let liabilityId = routeProps.match.params.id
                                    let thisLiability = liabilityObjects.find(liability => {
                                        return liability.id === liabilityId
                                    })

                                    return (
                                        <SingleLiability 
                                            person={person} 
                                            company={company} 
                                            liability={thisLiability} 
                                            breadcrumbs={breadcrumbs} 
                                            {...routeProps} 
                                        />
                                    )
                                }} />
                                <Route path={match.url} render={routeProps => {
                                    return (
                                        <div>
                                            <div className="heading-section">
                                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                                                <h1>
                                                    {title}
                                                </h1>
                                                <div className="controls">
                                                    <Button label="Edit client" callback={() => {
                                                        let dataType
                                                        let object

                                                        if (person) {
                                                            dataType = 'person'
                                                            object = person
                                                        } else {
                                                            dataType = 'company'
                                                            object = company
                                                        }

                                                        openModal({
                                                            variables: {
                                                                operation: 'edit',
                                                                isVisible: true,
                                                                object,
                                                                dataType,
                                                            }
                                                        })
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="main-content-container">
                                                <div className="main-column">
                                                    <TabSection tabs={this.tabs} />
                                                </div>
                                                <div className="side-column">
                                                    <UpcomingEvents data={upcomingEvents} />
                                                    <QuickDetails person={person} company={company} />
                                                    <Connections person={person} company={company} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }} />
                            </Switch>
                        </DocumentTitle>
                    )
                }}
            </Query>
        )
    }
}

export default withRouter(compose(
    graphql(OPEN_MODAL, { name: 'openModal' }),
    graphql(GET_SORTING_PREF, { name: 'sortingPrefs'}),
    graphql(SET_SORTING_PREF, { name: 'setSortingPref'}),
    withApollo
)(SingleClient))