import React, { Component } from 'react'

import { 
    getAssetName, 
    generateEvents,
    getClientDisplayName,
} from 'utils/utils'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import Table from 'components/Table/Table'
import { Link } from 'react-router-dom'
import { withApollo, compose, graphql } from 'react-apollo'
import Loader from 'components/Loader/Loader'
import SwitchInput from 'components/Inputs/SwitchInput'

import Button from 'components/Button/Button'

import { OPEN_MODAL } from 'graphql/modal-graphql'
import { cloneDeep } from 'lodash'

class EventsOverview extends Component {
    state = {
        daysToShow: 30
    }

    editEvent = event => {
        let eventObject = cloneDeep(event)

        if (event.person) {
            eventObject.personId = event.person.id
        } else if (event.company) {
            eventObject.companyId = event.company.id
        }

        this.props.client.mutate({
            mutation: OPEN_MODAL,
            variables: {
                operation: 'edit',
                dataType: 'event',
                isVisible: true,
                object: eventObject
            }
        })
    }

    openModal = asset => {
        this.props.openModal({
            variables: {
                operation: 'edit',
                dataType: 'asset',
                isVisible: true,
                object: asset
            }
        })
    }

    getRowData = (key, event) => {
        let response

        switch (key) {
            case 'client':
                let clients = []

                if (event.eventObject.people || event.eventObject.companies) {
                    let people = event.eventObject.people
                    let companies = event.eventObject.companies

                    let tempClients = people.concat(companies)

                    tempClients.forEach(client => {
                        if (client.__typename == 'LiabilityApplicant') {
                            clients.push(client.person ? client.person : client.company)
                        } else {
                            clients.push(client)
                        }
                    })
                } else {
                    if (event.eventObject.person) {
                        clients = [event.eventObject.person]
                    } else {
                        clients = [event.eventObject.company]
                    }
                }

                response = (
                    <ul className="table-list">
                        {
                            clients.map((client, index) => {
                                if(!client){
                                    return null
                                }

                                if (client.__typename == 'Person') {
                                    return (
                                        <li key={index}>
                                            <Link to={"/clients/people/" + client.id}>
                                                <img src="/images/icons/person.png" />
                                                { getClientDisplayName(client) }
                                            </Link>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={index}>
                                            <Link to={"/clients/companies/" + client.id}>
                                                <img src="/images/icons/company.png" />
                                                {client.name}
                                            </Link>
                                        </li>
                                    )
                                }
                            })
                        }
                    </ul>
                )
                break
            case 'details':
                response = (
                    <span>
                        <span>
                            {event.title}
                        </span>
                        {
                            event.assets ? event.assets.map((asset, index) => {
                                if (getAssetName(asset)) {
                                    return (
                                        <a key={index} onClick={e => { e.stopPropagation(); this.openModal(asset) }}>
                                            <img src="/images/icons/company.png" />
                                            { getAssetName(asset) }
                                        </a>
                                    )
                                } else {
                                    return null
                                }
                            }) : null
                        }
                    </span>
                )
                break
            case 'date':
                const today = moment().format()
                const week = moment().add(7, 'days')

                function isWithinAWeek(momentDate) {
                    return momentDate.isBefore(week)
                }
                function isAfterAWeek(momentDate) {
                    return momentDate.isAfter(week)
                }
                function isExpired(momentDate) {
                    return momentDate.isBefore(today)
                }

                let spanClass

                if (isWithinAWeek(event.date)) {
                    spanClass = 'within-week'
                } 
                if (isAfterAWeek(event.date)) {
                    spanClass = 'after-week'
                } 
                if (isExpired(event.date)) {
                    spanClass = 'expired'
                } 

                response = (
                    <span className={spanClass}>
                        <FontAwesome name="calendar-o" />
                        {event.formattedDate}
                    </span>
                )
                break
            case 'view':
                response = (
                    <span>
                        <Button label="View event" level="x-low" callback={() => {this.editEvent(event.eventObject)}} />
                    </span>
                )
                break
        }

        return {
            label: response
        }
    }

    handleChange = daysToShow => {
        this.setState({
            daysToShow
        })
    }

    filterDismissedEvents = events => {
        let eventsToReturn = []

        return events.filter(event => {
            let shouldInclude = true

            if(event.eventObject.isDismissed){
                shouldInclude = false
            }

            let daysBetween = event.date.diff(moment(), 'days')

            if(daysBetween > this.state.daysToShow){
                shouldInclude = false
            }

            return shouldInclude
        })
    }

    render() {
        const { 
            liabilities, 
            companies, 
            people, 
            isLoading 
        } = this.props

        const {
            daysToShow
        } = this.state

        let upcomingEvents = generateEvents(liabilities, people, companies, 'all', 'overview')

        this.tableData = {
            rows: this.filterDismissedEvents(upcomingEvents.events),
            columns: [
                {
                    label: 'Client',
                    type: 'value',
                    key: 'client'
                },
                {
                    label: 'Details',
                    type: 'value',
                    key: 'details'
                },
                {
                    label: 'Date',
                    type: 'value',
                    key: 'date'
                },
                {
                    label: '',
                    type: 'value',
                    key: 'view'
                }
            ]
        }

        let switchOptions = [
            {
                label: '30 days',
                value: 30
            },{
                label: '60 days',
                value: 60
            },{
                label: '90 days',
                value: 90
            }
        ]

        return (
            <div className="dashboard-widget events-overview">
                <div className="heading">
                    <h3>
                        Upcoming events
                    </h3>
                    <div className="filter">
                        <SwitchInput 
                            changeCallback={value => {this.handleChange(value)}} 
                            values={switchOptions} 
                            value={daysToShow}  
                        />
                    </div>
                </div>
                <div className="table-container">
                    <Loader 
                        isLoading={isLoading} 
                        showText={true} 
                    />
                    <Table 
                        onlyShow="head" 
                        columns={this.tableData.columns} 
                        data={this.tableData.rows} 
                        getter={this.getRowData} 
                        shouldPaginate={false}
                    />
                    <div className={isLoading ? "table-overflow-container loading" : "table-overflow-container"}>
                        <Table 
                            onlyShow="body" 
                            columns={this.tableData.columns} 
                            data={this.tableData.rows} 
                            getter={this.getRowData} 
                            shouldPaginate={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withApollo(compose(graphql(OPEN_MODAL, { name: 'openModal' }))(EventsOverview))