import React, { Component } from 'react'
import moment from 'moment'

class Figures extends Component {
    render() {
        const {
            title,
            data,
            fixedRatePeriod,
            showFixedRatePeriod,
            subtitle
        } = this.props

        const _subtitle = (() => {
            if (subtitle) return subtitle

            if (showFixedRatePeriod) {
                if (!fixedRatePeriod) {
                    return 'Variable'
                }

                return 'Fixed until ' + moment(fixedRatePeriod.startDate).add(fixedRatePeriod.term, 'years').format('MMMM Do YYYY')
            }

            return null
        })()

        return (
            <div className="figure-container">
                <h3>
                    {title}
                </h3>
                <span>
                    {data}
                </span>
                {_subtitle &&
                    <span className="interest-rate-type">
                        {_subtitle}
                    </span>
                }
            </div>
        )
    }
}

export default Figures