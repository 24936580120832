import React, { Component } from 'react'
import Scroll from 'react-scroll'

import Button from 'components/Button/Button'

import ModalMiniNew from '../ModalTypes/ModalMiniNew'
import ModalMiniConfirm from '../ModalTypes/ModalMiniConfirm'

import { cloneDeep, forIn } from 'lodash'

import { graphql, compose } from 'react-apollo'
import { withApollo } from 'react-apollo'

import { 
    UPSERT_PERSON_ASSET, 
    UPSERT_COMPANY_ASSET, 
    INIT_DATA, 
    GET_COMPANY,
    GET_PERSON,
    OPEN_MODAL, 
    GET_LIABILITIES 
} from 'graphql/all'

import { 
    stripTypenamesFromMutations, 
    removeSoftDeletedItems,
    getClientDisplayName,
    formatAddress,
    hasOwnershipError
} from 'utils/utils'

import ModalForm from '../ModalComponents/ModalForm'
import ValidationError from 'components/Errors/ValidationError'
import * as yup from 'yup'

class ModalAsset extends Component {

    state = {
        fields: {
            id: null,
            name: '',
            address: {},
            propertyType: 'FULLY_DETACHED_HOUSE',
            value: null,
            valueIsCertified: false,
            valuationDate: null,
            isInvestment: false,
            rentalIncomeValue: null,
            rentalIncomeFrequency: null,
            rentalHasAgent: false,
            rentalAgentContact: '',
            isUnencumbered: false,
            zoningType: 'RESIDENTIAL',
            people: [],
            companies: [],
            liabilities: []
        },
        hasErrors: false,
        errors: {},
        miniModalVisible: false,
        miniConfirmModalVisible: false,
        miniConfirmData: {}
    }

    skipFirstEqualise = false // for if we add 0% spouse, etc. Don't make it 50-50
    openNextModalIntent = null
    scroll = Scroll.animateScroll

    componentWillMount() {
        if(this.props.object){
            // special considerations need to be made for 'ownership' - can be person or company
            this.setState({
                fields: {
                    ...this.state.fields,
                    ...this.props.object
                }
            })
        }

        this.props.client.query({
            query: INIT_DATA
        }).then(data => {
            const allPeople = data.data.user.people
            const allCompanies = data.data.user.companies


            this.setState({
                allPeople,
                allCompanies
            },() => {
                // set up applicants
                let applicantKeys = {
                    people: [],
                    companies: []
                }

                let currentClientKey
                let currentClientId

                if(this.props.object.personId){
                    currentClientId = this.props.object.personId
                    currentClientKey = 'people'
                }else if(this.props.object.companyId){
                    currentClientKey = 'companies'
                    currentClientId = this.props.object.companyId
                }

                let shouldSeedCurrentClient = true

                forIn(applicantKeys, (array, key) => {
                    this.state.fields[key].forEach(node => {
                        // check if applicant->person or applicant->company has this user's ID
                        let subKey
                        if(key == 'people'){
                            subKey = 'person'
                        }else{
                            subKey = 'company'
                        }

                        if(!node[subKey]){
                            return
                        }

                        applicantKeys[key].push({
                            id: node[subKey].id,
                            percent: node.percent
                        })
    
                        if(node[subKey].id === currentClientId){
                            shouldSeedCurrentClient = false
                        }
                    })
                })

                let fields = cloneDeep(this.state.fields)

                if(shouldSeedCurrentClient){
                    //// Check for spouse, add zero percent
                    let ownershipArray = [{
                        id: currentClientId,
                        percent: 100
                    }]

                    if(currentClientKey == 'people'){
                        const currentPerson = allPeople.find(person => {
                            return person.id === currentClientId
                        })

                        if(currentPerson && currentPerson.spouse){
                            this.skipFirstEqualise = true

                            ownershipArray.push({
                                id: currentPerson.spouse.id,
                                percent: 0
                            })
                        }
                    }
                    ///

                    this.setState({
                        ...this.state,
                        fields: {
                            ...fields,
                            [currentClientKey]: ownershipArray
                        }
                    })
                }else{
                    forIn(applicantKeys, (array, key) => {
                        fields[key] = array
                    })

                    this.setState({
                        fields
                    })
                }
            })
        })

        this.props.client.query({
            query: GET_LIABILITIES,
            fetchPolicy: 'network-only'
        }).then(data => {
            const liabilities = removeSoftDeletedItems(data.data.liabilities)

            this.setState({
                allLiabilities: liabilities,
            })
        })
    }

    componentWillUpdate = (nextProps, nextState) => {
        const fields = this.state.fields
        const newFields = nextState.fields

        if(fields.people.length != newFields.people.length && !this.skipFirstEqualise){
            this.skipFirstEqualise = false

            const totalPeople = newFields.people.length 
            const percentEach = Math.round(100/newFields.people.length)
            const remainder = 100 - (percentEach * totalPeople)
            
            let newPeople = cloneDeep(newFields.people)

            newPeople.forEach((person, index) => {
                newPeople[index].percent = percentEach

                if(!index){
                    person.percent += remainder
                }
            })

            this.setState({
                fields: {
                    ...nextState.fields,
                    people: newPeople
                }
            })
        } 
    }

    getValue = type => {
        let responseObject = null

        switch(type){
            case 'people':
                if(this.state.allPeople){
                    if(!this.state.allPeople.length){
                        responseObject = [{
                            label: 'No people',
                            value: undefined,
                            hide: true
                        }]
                    }else{
                        responseObject = []
    
                        this.state.allPeople.forEach(person => {
                            responseObject.push({
                                label: getClientDisplayName(person),
                                value: person.id,
                                isDisabled: this.state.fields.people.find(loopedPerson => {
                                    return loopedPerson.id == person.id
                                })
                            })
                        })

                        responseObject.sort((a, b) => {
                            if(a.label < b.label) return -1
                            if(a.label > b.label) return 1
                            return 0
                        })

                        responseObject.unshift({
                            label: '(Create new person)',
                            value: 'create'
                        })

                        responseObject.unshift({
                            label: '(Select person)',
                            value: undefined
                        })
                    }
                }     
                break
            case 'companies':
                if(this.state.allCompanies){
                    if(!this.state.allCompanies.length){
                        responseObject = [{
                            label: 'No companies',
                            value: undefined,
                            hide: true
                        }]
                    }else{
                        responseObject = [{
                            label: '(Select company)',
                            value: undefined
                        }]
    
                        this.state.allCompanies.forEach(company => {
                            responseObject.push({
                                label: company.name,
                                value: company.id,
                                isDisabled: this.state.fields.companies.find(loopedCompany => {
                                    return loopedCompany.id == company.id
                                })
                            })
                        })
                    }
                }   
                break
                case 'liabilities':
                    if(this.state.allLiabilities && this.props.object){
                        responseObject = []

                        const { personId, companyId } = this.props.object
    
                        this.state.allLiabilities.forEach(liability => {
                            let isClientLiability = false

                            if(personId && liability.people.length){
                                liability.people.forEach(personRelation => {
                                    if(personRelation.person && personRelation.person.id == personId){
                                        isClientLiability = true
                                    }
                                })
                            }else if(companyId && liability.companies.length){
                                liability.companies.forEach(companyRelation => {
                                    if(companyRelation.company && companyRelation.company.id == companyId){
                                        isClientLiability = true
                                    }
                                })
                            }

                            if(isClientLiability){
                                responseObject.push({
                                    label: liability.name,
                                    value: liability.id,
                                    isDisabled: this.state.fields.liabilities.find(loopedLiability => {
                                        return loopedLiability.id == liability.id
                                    })
                                })
                            }
                        })
                    }

                    break
                case 'firstApplicant':
                    if(this.props.object){
                        if(this.props.object.personId && this.state.allPeople){
                            responseObject = this.state.allPeople.find(person => {
                                return person.id === this.props.object.personId
                            })
                        }else if(this.props.object.companyId && this.state.allCompanies){
                            responseObject = this.state.allCompanies.find(company => {
                                return company.id === this.props.object.companyId
                            })
                        }
                    }

                    if(responseObject && responseObject.address){
                        const hasAddress = formatAddress(responseObject.address)

                        if(!hasAddress){
                            return null
                        }
                    }else{
                        return null
                    }
                    break
        }

        if(!responseObject){
            return [{
                label: 'Loading...',
                value: undefined
            }]
        }else{
            return responseObject
        }
    }

    handleChange = (key, value) => {
        if(key == 'people' && value.find(selection => {
            return selection.id == 'create'
        })){
            this.setState({
                miniModalVisible: true
            })
        }else{
            this.setState({
                fields: {
                    ...this.state.fields,
                    [key]: value
                }
            })
        }
    }

    handleError = error => {
        const errors = error.inner

        let newErrorState = {}

        errors.forEach(error => {
            newErrorState[error.path] = error.message
        })

        this.setState({
            hasErrors: true,
            errors: newErrorState
        })
    }

    isRepeaterFieldPinned = (value, allValues) => {
        // just pin it if there's only one.
        return allValues.length === 1
    }

    removeNonPayloadData = fields => {
        fields = stripTypenamesFromMutations(fields)
        
        if(fields.company){
            delete fields.company
        }

        if(fields.person){
            delete fields.person
        }

        let liabilityIdReferences = []

        fields.liabilities.forEach(liability => {
            if(liability.id){
                liabilityIdReferences.push({
                    id: liability.id
                })
            }
        })

        fields.liabilities = liabilityIdReferences

        return fields
    }

    handleSubmit = async openNextModal => {
        const {
            isLoading,
            setLoading,
            object
        } = this.props

        const {
            fields
        } = this.state

        if(isLoading){
            return 
        }

        if(!fields.name && !fields.address.streetName){
            return this.setState({
                genericError: `Please enter a name or address`
            })
        }

        const ownershipError = hasOwnershipError(fields)

        if(ownershipError){
            return this.setState({
                genericError: ownershipError
            })
        }

        this.openNextModalIntent = openNextModal

        setLoading(true)

        this.setState({
            hasErrors: false
        })

        const functionName = fields.personId ? 'upsertPersonAsset' : 'upsertCompanyAsset'
        
        const fieldsToSubmit = this.removeNonPayloadData(fields)

        let refetchQueriesArray = []

        if(object){
            if(object.personId){
                refetchQueriesArray = [{
                    query: GET_PERSON,
                    variables: {
                        id: object.personId
                    }
                }]
            }else if(object.companyId){
                refetchQueriesArray = [{
                    query: GET_COMPANY,
                    variables: {
                        id: object.companyId
                    }
                }]
            }
        }

        try{
            await this.props[functionName]({
                variables: {
                    payload: {
                        ...fieldsToSubmit
                    }
                },
                update: (store, { data }) => {
                    console.log(data)
                },
                refetchQueries: _ => refetchQueriesArray
            }).then(data => {
                this.props.handleSuccess(() => {
                    console.log(data)

                    if(openNextModal){
                        this.doOpenNextModal(data.data)
                    }
                })
            })
        }catch(error){
            console.log(error)
            this.props.setLoading(false)
        }
    }

    getPropertyTypes = () => {
        return [
            {
                label: 'Apartment/Unit/Flat',
                value: 'APARTMENT_UNIT_FLAT'
            },
            {
                label: 'Bedsitter Bachelor',
                value: 'BEDSITTER_BACHELOR'
            },
            {
                label: 'Boarding House',
                value: 'BOARDING_HOUSE'
            },
            {
                label: 'Commercial',
                value: 'COMMERCIAL'
            },
            {
                label: 'Company Title Unit',
                value: 'COMPANY_TITLE_UNIT'
            },
            {
                label: 'Converted Commercial Property',
                value: 'CONVERTED_COMMERCIAL_PROPERTY'
            },
            {
                label: 'Converted Motel Units',
                value: 'CONVERTED_MOTEL_UNITS'
            },
            {
                label: 'Display Home',
                value: 'DISPLAY_HOME'
            },
            {
                label: 'Duplex',
                value: 'DUPLEX'
            },
            {
                label: 'Fully-detached House',
                value: 'FULLY_DETACHED_HOUSE'
            },
            {
                label: 'Hobby Farm',
                value: 'HOBBY_FARM'
            },
            {
                label: 'Industrial',
                value: 'INDUSTRIAL'
            },
            {
                label: 'Kit Home',
                value: 'KIT_HOME'
            },
            {
                label: 'Luxury House',
                value: 'LUXURY_HOUSE'
            },
            {
                label: 'New Strata Title Unit',
                value: 'NEW_STRATA_TITLE_UNIT'
            },
            {
                label: 'Nursing Home',
                value: 'NURSING_HOME'
            },
            {
                label: 'Relocatable Home',
                value: 'RELOCATABLE_HOME'
            },
            {
                label: 'Resort Unit',
                value: 'RESORT_UNIT'
            },
            {
                label: 'Retirement Unit',
                value: 'RETIREMENT_UNIT'
            },
            {
                label: 'Rural Lifestyle',
                value: 'RURAL_LIFESTYLE'
            },
            {
                label: 'Semi-detached House',
                value: 'SEMI_DETACHED_HOUSE'
            },
            {
                label: 'Serviced Apartment',
                value: 'SERVICED_APARTMENT'
            },
            {
                label: 'Snow Lease',
                value: 'SNOW_LEASE'
            },
            {
                label: 'Strata Title Unit',
                value: 'STRATA_TITLE_UNIT'
            },
            {
                label: 'Student Accommodation',
                value: 'STUDENT_ACCOMMODATION'
            },
            {
                label: 'Studio Warehouse Apartment',
                value: 'STUDIO_WAREHOUSE_APARTMENT'
            },
            {
                label: 'Terrace',
                value: 'TERRACE'
            },
            {
                label: 'Townhouse',
                value: 'TOWNHOUSE'
            },
            {
                label: 'Transportable Home',
                value: 'TRANSPORTABLE_HOME'
            },
            {
                label: 'Vacant Land',
                value: 'VACANT_LAND'
            },
            {
                label: 'Villa',
                value: 'VILLA'
            },
            {
                label: 'Warehouse Conversion',
                value: 'WAREHOUSE_CONVERSION'
            }, 
        ]
    }

    miniModalCallback = data => {
        let { allPeople, fields } = cloneDeep(this.state)

        if(data){
            const person = data.data.upsertPerson

            allPeople.push(person)

            fields.people[(fields.people.length - 1)].id = person.id
        }

        this.setState({
            miniModalVisible: false,
            allPeople,
            fields
        })
    }

    doOpenNextModal = data => {
        // we return the whole client, so just get the last asset.
        setTimeout(() => {
            const response = data.upsertPersonAsset ? data.upsertPersonAsset : data.upsertCompanyAsset
            const lastAssetRelation = response.assets[(response.assets.length - 1)]
            const asset = lastAssetRelation.personRelation ? lastAssetRelation.personRelation : lastAssetRelation.companyRelation

            let object = {
                personId: data.upsertPersonAsset ? data.upsertPersonAsset.id : null,
                companyId: data.upsertCompanyAsset ? data.upsertCompanyAsset.id : null,
                assets: [asset]
            }

            this.props.openModal({variables: {
                operation: 'add',
                dataType: 'liability',
                isVisible: true,
                object
            }})
        },300)
    }

    render(){
        const {
            object,
            closeCallback
        } = this.props

        const {
            fields,
            miniModalVisible,
            errors,
            genericError
        } = this.state

        const fieldColumns = [
            [
                [{
                    label: 'Asset name (optional)',
                    type: 'text',
                    props: { type: 'text' },
                    key: 'name'
                }],[{
                    label: 'Address',
                    type: 'address',
                    props: { 
                        canOffPlan: true,
                        canPrefillFrom: this.getValue('firstApplicant')
                    },
                    key: 'address'
                }],[{
                    label: 'Linked liabilities',
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'select', 
                                props: {
                                    values: this.getValue('liabilities'),
                                    noDefault: true
                                },
                                key: 'id',
                            }
                        ],
                        buttonLabel: 'Add liability'
                    },
                    key: 'liabilities'
                }],[{
                    label: (!object || (!object.personId && !object.companyId)) ? 'Ownership - people' : 'Ownership',
                    isHidden: object && object.companyId ? true : false,
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'select', 
                                props: {
                                    values: this.getValue('people'),
                                },
                                key: 'id',
                            },{
                                type: 'text', 
                                key: 'percent',
                                placeholder: '',
                                props: {
                                    type: 'percent'
                                }
                            }
                        ],
                        buttonLabel: 'Add owner',
                        isRepeaterFieldPinned: this.isRepeaterFieldPinned
                    },
                    key: 'people'
                }],[{
                    label: (!object || (!object.personId && !object.companyId)) ? 'Ownership - companies' : 'Ownership',
                    isHidden: object && object.personId ? true : false,
                    type: 'repeater',
                    props: {
                        inputs: [
                            {
                                type: 'select', 
                                props: {
                                    values: this.getValue('companies'),
                                },
                                key: 'id',
                            },{
                                type: 'text', 
                                key: 'percent',
                                placeholder: '',
                                props: {
                                    type: 'percent'
                                }
                            }
                        ],
                        buttonLabel: 'Add owner',
                        isRepeaterFieldPinned: this.isRepeaterFieldPinned
                    },
                    key: 'companies'
                }]
            ],[
                [
                    {
                        label: 'Zoning',
                        type: 'select',
                        props: {
                            values: [
                                {
                                    value: 'COMMERCIAL',
                                    label: 'Commercial'
                                },
                                {
                                    value: 'RESIDENTIAL',
                                    label: 'Residential'
                                }
                            ]
                        },
                        key: 'zoningType'
                    },
                    {
                        label: 'Property Type',
                        type: 'select',
                        props: {
                            values: this.getPropertyTypes()
                        },
                        key: 'propertyType'
                    },
                ],
                [{
                    label: 'Market value',
                    type: 'text',
                    props: { type: 'money' },
                    key: 'value'
                }],
                [{
                    label: 'Valuation date',
                    type: 'text',
                    props: { type: 'date' },
                    key: 'valuationDate'
                }],
                [{
                    label: 'Usage',
                    type: 'switch',
                    props: {
                        values: [
                            {
                                value: false,
                                label: 'Owner occupier'
                            },{
                                value: true,
                                label: 'Investment'
                            }
                        ]
                    },
                    key: 'isInvestment'
                }],
                [
                    {
                        label: 'Rental income',
                        isHidden: !fields.isInvestment,
                        type: 'text',
                        props: { type: 'money', placeholder: 'Gross amount' },
                        key: 'rentalIncomeValue'
                    },{
                        label: 'Frequency',
                        isHidden: !fields.isInvestment,
                        type: 'select',
                        props: {
                            values: [
                                {
                                    value: 'WEEKLY',
                                    label: 'Weekly'
                                },{
                                    value: 'MONTHLY',
                                    label: 'Monthly'
                                },{
                                    value: 'QUARTERLY',
                                    label: 'Quarterly'
                                },{
                                    value: 'YEARLY',
                                    label: 'Yearly'
                                }
                            ]
                        },
                        key: 'rentalIncomeFrequency'
                    }
                ],
            ]
        ]

        return (
            <div className="modal-person wide">
                <ModalMiniNew 
                    isVisible={miniModalVisible} 
                    callback={this.miniModalCallback} 
                />
                <div className="content-container">
                    <ModalForm 
                        errors={errors} 
                        handleChange={this.handleChange} 
                        fieldState={fields} 
                        columns={fieldColumns} 
                    />
                </div>
                <div className="button-row">
                    <ValidationError 
                        error={genericError ? genericError : null} 
                    />
                    { (!object || !object.id) ?
                        <Button 
                            icon="arrow-right" 
                            label="Add Liability" 
                            level="high" 
                            callback={() => {this.handleSubmit(true)}} 
                        />
                        : null
                    }
                    <Button 
                        label="Save asset" 
                        level="high" 
                        callback={() => {this.handleSubmit(false)}} 
                    />
                    <Button 
                        label="Cancel" 
                        level="low" 
                        callback={closeCallback} 
                    />
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(UPSERT_PERSON_ASSET, {name: 'upsertPersonAsset'}),
    graphql(UPSERT_COMPANY_ASSET, {name: 'upsertCompanyAsset'}),
    graphql(OPEN_MODAL, {name: 'openModal'})
) (ModalAsset)