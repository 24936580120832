import React, { Component } from 'react'
import TabSection from '../TabSection/TabSection'
import { withRouter } from 'react-router'

import List from 'components/List/List'

import { 
	getCurrentUser, 
	isAdmin, 
	generateEvents, 
	getLiabilityObjectsFromRelations 
} from 'utils/utils'

import { 
	OPEN_MODAL,
	SET_SORTING_PREF,
	GET_SORTING_PREF
} from 'graphql/all'

import { graphql, compose, withApollo } from 'react-apollo'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import FontAwesome from 'react-fontawesome'

class Clients extends Component {

	componentWillMount = () => {
		const {
			sortingPrefs,
			setSortingPref
		} = this.props

		if(!sortingPrefs.sortingPrefs || !sortingPrefs.sortingPrefs.people.column){
			this.props.setSortingPref({
				variables: {
					path: 'people',
					column: 'name',
					direction: 'desc',
					__typename: 'sortingPrefs'
				}
			})
		}

		if(!sortingPrefs.sortingPrefs || !sortingPrefs.sortingPrefs.companies.column){
			setSortingPref({
				variables: {
					path: 'companies',
					column: 'companyName',
					direction: 'desc',
					__typename: 'sortingPrefs'
				}
			})
		}
	}

	getNextEvent = item => {
		// putting this here, cause generateEvents expects an array of both/either
		let people = []
		let companies = []

		if (item.__typename == 'Person') {
			people = [item]
		} else {
			companies = [item]
		}

		let liabilityObjects = getLiabilityObjectsFromRelations(item.liabilities)

		let upcomingEvents = generateEvents(liabilityObjects, people, companies, 'all', 'overview', true, true)

		if (upcomingEvents.events.length) {
			return upcomingEvents.events[0]
		}
	}

	getRowData = (key, item, type) => {
		let returnObject = {}

		const baseUrl = item.__typename == 'Person' ? '/clients/people' : '/clients/companies'

		switch (key) {
			case 'name':
				returnObject.label = item.firstName + ' ' + item.lastName
				returnObject.href = baseUrl + '/' + item.id
				returnObject.callback = this.openClient
				break;
			case 'email':
				returnObject.label = item.email
				returnObject.href = null
				returnObject.callback = this.emailClient
				break;
			case 'nextEvent':
				let nextEvent = this.getNextEvent(item)

				if (nextEvent) {
					returnObject.label = (
						<span className="date">
							<FontAwesome name="calendar-o" />
							{moment(nextEvent.eventObject.date).format('Do MMMM, YYYY')}
						</span>
					)
				} else {
					returnObject.label = '(None)'
					returnObject.empty = true
				}

				break;
			case 'reference':

				break;
			case 'representative':
				if (item.contact) {
					returnObject.label = (
						<span>
							{item.contact.firstName + ' ' + item.contact.lastName}
							<a className="small" href={'mailto:' + item.contact.email} target="_blank">
								{item.contact.email}
							</a>
						</span>
					)
				} else if (item.externalContactName && item.externalContactEmail) {
					returnObject.label = (
						<span>
							{item.externalContactName}
							<a className="small" href={'mailto:' + item.externalContactEmail} target="_blank">
								{item.externalContactEmail}
							</a>
						</span>
					)
				}
				break
			case 'companyName':
				returnObject.label = item.name
				returnObject.href = baseUrl + '/' + item.id
				returnObject.callback = this.openClient
				break
			case 'broker':
				const currentUser = getCurrentUser(this.props.client)

				returnObject.label = item.broker.firstName + ' ' + item.broker.lastName

				if (currentUser.id === item.broker.id) {
					returnObject.label += ' (Me)'
				}
				break
		}

		return returnObject;
	}

	openClient = (client, href) => {
		this.props.history.push(href)
	}

	emailClient = client => {
		window.location.href = "mailto:" + client.email
	}

	hiddenBySearch = (item, searchValue) => {
		let shouldHide = true

		if (item.__typename == 'Person') {
			const personName = item.firstName+' '+item.lastName

			let stringToSearch = personName

			if(item.email){
				stringToSearch += ' '+item.email
			}

			if(item.preferredName){
				stringToSearch += ' '+item.preferredName
			}

			if (stringToSearch.toLowerCase().includes(searchValue)) {
				shouldHide = false
			}
		} else {
			if (item.name.toLowerCase().includes(searchValue)) {
				shouldHide = false
			}
		}

		return shouldHide
	}

	sort(rows, type) {
		let clonedRows = cloneDeep(rows)

		let sortingType = {
			column: 'name',
			direction: 'asc'
		}
		
		if(this.props.sortingPrefs && this.props.sortingPrefs.sortingPrefs){
			sortingType = type == 'people' ? this.props.sortingPrefs.sortingPrefs.people : this.props.sortingPrefs.sortingPrefs.companies
		}

		switch (sortingType.column) {
			case 'name':
				clonedRows.sort((a, b) => {
					let firstObject = a
					let secondObject = b

					if (sortingType.direction == 'asc') {
						firstObject = b
						secondObject = a
					}

					let aName = firstObject.firstName ? firstObject.firstName.toLowerCase() : ''
					let bName = secondObject.firstName ? secondObject.firstName.toLowerCase() : ''

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'email':
				clonedRows.sort((a, b) => {
					let firstObject = a
					let secondObject = b

					if (sortingType.direction == 'asc') {
						firstObject = b
						secondObject = a
					}

					if (firstObject.email < secondObject.email) return -1
					if (firstObject.email > secondObject.email) return 1
					return 0
				})
				break
			case 'companyName':
				clonedRows.sort((a, b) => {
					let firstObject = a
					let secondObject = b

					if (sortingType.direction == 'asc') {
						firstObject = b
						secondObject = a
					}

					let aName = firstObject.name.toLowerCase()
					let bName = secondObject.name.toLowerCase()

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				break
			case 'nextEvent':
				clonedRows.sort((a, b) => {
					let firstObject = a
					let secondObject = b

					if (sortingType.direction == 'asc') {
						firstObject = b
						secondObject = a
					}

					let firstObjectNextEvent = this.getNextEvent(firstObject)
					let secondObjectNextEvent = this.getNextEvent(secondObject)

					let firstDate = firstObjectNextEvent ? moment(firstObjectNextEvent.eventObject.date).toDate() : null
					let secondDate = secondObjectNextEvent ? moment(secondObjectNextEvent.eventObject.date).toDate() : null

					return firstDate - secondDate;
				})
				break
			case 'broker':
				clonedRows.sort((a, b) => {
					let firstObject = a
					let secondObject = b

					if (sortingType.direction == 'asc') {
						firstObject = b
						secondObject = a
					}

					let aName = firstObject.broker.firstName.toLowerCase()
					let bName = secondObject.broker.firstName.toLowerCase()

					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})

				break
		}

		return clonedRows
	}

	changeSorting = (type, key) => {
		const {
			setSortingPref,
			sortingPrefs
		} = this.props

		let sortingObject

		if (type == 'person') {
			sortingObject = cloneDeep(sortingPrefs.sortingPrefs.people)
		} else {
			sortingObject = cloneDeep(sortingPrefs.sortingPrefs.companies)
		}

		if (sortingObject.column == key) {
			sortingObject.direction = sortingObject.direction == 'desc' ? 'asc' : 'desc'
		} else {
			sortingObject = {
				column: key,
				direction: 'desc'
			}
		}

		const sortingPrefObject = {
			variables: {
				...sortingObject,
				path: type === 'person' ? 'people' : 'companies'
			}
		}

		setSortingPref(sortingPrefObject)
	}

	render() {
		const {
			sortingPrefs,
			data,
			openModal,
			client
		} = this.props

		const safeSortingPrefs = (sortingPrefs && sortingPrefs.sortingPrefs) ? sortingPrefs.sortingPrefs : {people: {}, companies: {}}

		this.tabs = [
			{
				label: "People",
				component: List,
				data: {
					rows: this.sort(data.people, 'people'),
					type: 'person',
					getter: this.getRowData,
					hiddenBySearch: this.hiddenBySearch,
					sorting: safeSortingPrefs.people,
					changeSortingCallback: this.changeSorting,
					columns: [
						{
							label: '',
							type: 'checkbox',
							key: 'isChecked'
						}, {
							label: 'Name',
							type: 'value',
							key: 'name',
							canSort: true
						}, {
							label: 'Email address',
							type: 'value',
							key: 'email',
							canSort: true
						}, {
							label: 'Next event',
							type: 'value',
							key: 'nextEvent',
							canSort: true
						}, {
							label: '',
							type: 'actions',
							actions: [
								{
									label: 'Edit person',
									callback: person => {
										openModal({
											variables: {
												operation: 'edit',
												dataType: 'person',
												isVisible: true,
												object: person
											}
										})
									}
								}, {
									label: 'Delete person',
									callback: person => {
										openModal({
											variables: {
												operation: 'delete',
												dataType: 'person',
												isVisible: true,
												object: [person]
											}
										})
									}
								}
							]
						}
					]
				}
			}, {
				label: "Companies",
				component: List,
				data: {
					rows: this.sort(data.companies, 'companies'),
					type: 'company',
					getter: this.getRowData,
					hiddenBySearch: this.hiddenBySearch,
					sorting: safeSortingPrefs.companies,
					changeSortingCallback: this.changeSorting,
					columns: [
						{
							label: '',
							type: 'checkbox',
							key: 'isChecked'
						}, {
							label: 'Name',
							type: 'value',
							key: 'companyName',
							canSort: true
						}, {
							label: 'Representative',
							type: 'value',
							key: 'representative',
							canSort: true
						}, {
							label: 'Next event',
							type: 'value',
							key: 'nextEvent',
							canSort: true
						}, {
							label: '',
							type: 'actions',
							actions: [
								{
									label: 'Edit company',
									callback: company => {
										openModal({
											variables: {
												operation: 'edit',
												dataType: 'company',
												isVisible: true,
												object: company
											}
										})
									}
								}, {
									label: 'Delete company',
									callback: company => {
										openModal({
											variables: {
												operation: 'delete',
												dataType: 'company',
												isVisible: true,
												object: [company]
											}
										})
									}
								}
							]
						}
					]
				}
			}
		]

		if (isAdmin(client)) {
			this.tabs.forEach(tab => {
				tab.data.columns.splice((tab.data.columns.length - 1), 0, {
					label: 'Broker',
					type: 'value',
					key: 'broker',
					canSort: true
				})
			})
		}

		return (
			<div>
				<div className="heading-section">
					<h1>
						Clients
					</h1>
				</div>
				<div className="main-content-container">
					<div className="main-column full-width">
						<TabSection tabs={this.tabs} />
					</div>
					<div className="side-column">

					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(withApollo(compose(
	graphql(OPEN_MODAL, { name: 'openModal' }),
	graphql(GET_SORTING_PREF, { name: 'sortingPrefs'}),
    graphql(SET_SORTING_PREF, { name: 'setSortingPref'})
)(Clients)))