import React, { Component } from 'react'
import Button from 'components/Button/Button'
import { graphql, compose, withApollo } from 'react-apollo'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Select from 'react-select'

import { cloneDeep } from 'lodash'

import { INIT_DATA, REASSIGN_CLIENTS } from 'graphql/all'

import { getClientDisplayName } from 'utils/utils'

class ModalClientAllocation extends Component {
    state = {
        clients: [],
        error: null,
        alertType: null
    }

    componentWillMount(){
        const { initData, object } = this.props

        let currentUser = object
        let allClients = initData.user.people.concat(initData.user.companies)

        let userClients = []

        allClients.forEach(client => {
            if(client.broker.id === currentUser.id){
                userClients.push(cloneDeep(client))
            }
        })

        this.setState({
            clients: userClients
        })
    }

    getClientBroker = client => {
        const { initData, object } = this.props

        let brokers = initData.user.brokerage.users

        let tempClient = this.state.clients.find(stateClient => {
            return stateClient.id === client.id
        })

        let selectedBroker = brokers.find(broker => {
            return broker.id === tempClient.broker.id
        })

        if(selectedBroker){
            return {
                label: selectedBroker.firstName+' '+selectedBroker.lastName,
                value: selectedBroker.id
            }
        }

    }

    changeBroker = (value, clientData) => {
        const brokerId = value.value

        let tempClients = cloneDeep(this.state.clients)

        if(clientData !== 'all'){
            const client = tempClients.find(_client => _client.id === clientData.id)
            client.broker.id = brokerId
        }else{
            tempClients.forEach(client => {
                client.broker.id = brokerId
            })
        }

        this.setState({
            clients: tempClients
        })
    }

    getBrokers = allowNone => {
        const { initData } = this.props

        let brokers = initData.user.brokerage.users

        let brokerOptions = []

        brokers.forEach(broker => {
            brokerOptions.push({
                label: broker.firstName+' '+broker.lastName,
                value: broker.id
            })
        })

        if(allowNone){
            brokerOptions.unshift({
                label: 'N/A',
                value: -1
            })
        }

        return brokerOptions
    }

    handleSubmit = () => {
        let formattedClients = []

        // only submit clients that've changed
        this.state.clients.forEach(client => {
            if(client.broker.id !== this.props.object.id){
                formattedClients.push({
                    id: client.id,
                    type: client.__typename,
                    brokerId: client.broker.id
                })
            }
        })

        let payload = formattedClients

        this.props.setLoading(true)

        this.props.reassignClients({
            variables: {
                payload: payload
            }
        }).then(data => {
            this.props.handleSuccess()
        }).catch(error => {
            this.props.setLoading(false)
            console.log(error)
        })
    }

	handleError = (message, alertType) => {
		this.setState({
			error: message,
			alertType
		})

		setTimeout(() => {
			this.setState({
				error: null,
				alertType
			})
		}, 3000)
    }
    
    getAllBroker = _ => {
        let firstBroker = null
        let isAllSameBroker = true

        this.state.clients.forEach(client => {
            const thisBroker = client.broker
            
            if(!firstBroker){
                firstBroker = thisBroker
            }else if(firstBroker.id !== thisBroker.id){
                isAllSameBroker = false
            }
        })

        if(isAllSameBroker){
            const brokerSelectValue = this.getBrokers().find(broker => {
                return broker.value === firstBroker.id
            })

            return brokerSelectValue
        }

        return null
    }

    render(){
        const {
            clients,
            alertType,
            error
        } = this.state

        const {
            closeCallback
        } = this.props

        const _clients = clients ? [...clients] : []

        _clients.sort((a,b) => {
            const aName = a.__typename == 'Person' ? getClientDisplayName(a) : a.name
            const bName = b.__typename == 'Person' ? getClientDisplayName(b) : b.name

            if(aName < bName) { 
                return -1 
            }
            if(aName > bName) { 
                return 1 
            }

            return 0
        })

        return(
            <div className="modal-client-allocation">
                <p>
                    We'll automatically notify staff of their new clients. 
                </p>
                <div className="content-container">
                    <MiniResponse 
                        type={alertType} 
                        message={error} 
                    />
                    <ul>
                        <li>
                            <span>
                                All clients
                            </span>
                            <Select
                                className="select"
                                onChange={value => {this.changeBroker(value, 'all')}}
                                value={this.getAllBroker()}
                                options={this.getBrokers(true)}
                            />
                        </li>
                        { _clients.map((client, index) => {
                            return (
                                <li key={client.id}>
                                    <span>
                                        { client.__typename == 'Person' ? getClientDisplayName(client) : client.name }
                                    </span>
                                    <Select 
                                        className="select" 
                                        onChange={value => { this.changeBroker(value, client) }} 
                                        value={this.getClientBroker(client)} 
                                        options={this.getBrokers(false)} 
                                    />
                                </li>
                            )
                        }) }
                    </ul>
                </div>
                <div className="button-row">
                    <Button 
                        label="Save" 
                        level="high" 
                        callback={this.handleSubmit} 
                    />
                    <Button 
                        label="Cancel" 
                        level="low" 
                        callback={closeCallback} 
                    />
                </div>
            </div>
        )
    }
}

export default withApollo(compose(
    graphql(INIT_DATA, { name: 'initData' }),
    graphql(REASSIGN_CLIENTS, { name: 'reassignClients' })
)(ModalClientAllocation))