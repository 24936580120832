import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MiniResponse from 'components/Errors/MiniResponse/MiniResponse'
import Loader from 'components/Loader/Loader'
import PasswordInfo from 'components/Auth/Register/PasswordInfo'

class Password extends Component {
	state = {
		id: null,
		password: '',
		newPassword: '',
		confirmPassword: '',
		error: false,
		alertType: null,
		isPasswordFocused: false,
		isPasswordValid: false
	}

	componentWillMount() {
		this.setState({
			id: this.props.data.id
		})
	}

	handleSubmit = () => {
		if (this.state.newPassword === this.state.confirmPassword && this.state.isPasswordValid) {
			this.props.callback(this.state, this.props.path, (response) => {
				if (response.length) {
					this.handleError(response[0].message)
				} else {
					this.handleError('Uh oh! A server error has occurred.', 'error')
				}
			}, () => {
				this.handleError('Password has been updated successfully.', 'success')
			})
		}else if(!this.state.newPassword){
			this.handleError('Please enter a new password', 'error')
		}else if(!this.state.confirmPassword){
			this.handleError('Please enter a confirmation password.', 'error')
		}else if(!this.state.isPasswordValid){
			this.handleError('Please enter a stronger password.', 'error')
		}else{
			this.handleError('Your new passwords aren\'t the same.', 'error')
		}
	}

	handleError = (message, alertType) => {
		this.setState({
			error: message,
			alertType
		})

		setTimeout(() => {
			this.setState({
				error: null,
				alertType
			})
		}, 3000);
	}

	togglePasswordInfo = isFocused => {
		this.setState({
			isPasswordFocused: isFocused
		})
	}

	setPasswordValidity = isValid => {
		this.setState({
			isPasswordValid: isValid
		})
	}

	render() {
		if (!this.props.data) {
			return null
		}

		let isOtherUser = this.props.data.id !== this.props.currentUser.id

		return (
			<div onKeyPress={e => e.key === 'Enter' ? this.handleSubmit() : null}>
				<MiniResponse type={this.state.alertType} message={this.state.error} />
				<Loader isLoading={this.props.isLoading} />
				<div className="content-container">
					<div className="input-wrap">
						<label>
							{!isOtherUser ?
								'Reset your password'
								:
								`Reset ${this.props.data.firstName}'s password`
							}
						</label>
						{!isOtherUser ?
							<input type="password" placeholder="Current password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
							: null}
					</div>
					<div className="input-wrap">
						<input onFocus={() => {this.togglePasswordInfo(true)}} onBlur={() => {this.togglePasswordInfo(false)}} type="password" placeholder="New password" value={this.state.newPassword} onChange={e => this.setState({ newPassword: e.target.value })} />
						<PasswordInfo setValidity={this.setPasswordValidity} isVisible={this.state.isPasswordFocused} password={this.state.newPassword} />
					</div>
					<div className="input-wrap">
						<input type="password" placeholder="Confirm password" value={this.state.confirmPassword} onChange={e => this.setState({ confirmPassword: e.target.value })} />
					</div>
				</div>
				<div className="button-row">
					{
						!this.props.isEdit ?
							<button className="button high" onClick={this.handleSubmit}>
								Register
              				</button>
							:
							<button className="button high" onClick={this.handleSubmit}>
								Reset Password
							</button>
					}
					{
						!this.props.isEdit ?
							<div className="lower-row">
								<Link to="/auth/login">
									&lsaquo; Back to login
                				</Link>
							</div>
							: null
					}
				</div>
			</div>
		)
	}
}

export default Password
