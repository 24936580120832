import React, { Component } from 'react'
import ValidationError from 'components/Errors/ValidationError'

import { cloneDeep } from 'lodash'

class TextareaInput extends Component {
  componentDidMount(){
    
  }

  handleChange = value => {
    let returnedValue = value

    this.props.changeCallback(returnedValue)
  }

  render(){
    let className = 'input-container ';

    const { label, error, required, value, placeholder } = this.props

    return (
      <div className={error ? "input-wrap error" : "input-wrap"}>
        <label>
          { label ? label : '\u00A0' }
          {(required && !this.props.isDisabled) ?
            <em className="required">
              *
            </em>
            : null}
        </label>
        {
          !this.props.isDisabled ?
            <div className={className}>
              <textarea onChange={e => {this.props.changeCallback(e.target.value)}} value={value} placeholder={placeholder} />
            </div>
            :
            <span className="disabled-input">
            { value ? value : '(No notes)' }
          </span>
        }
        <ValidationError error={error} />
      </div>
    )
  }
}

export default TextareaInput
