import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Breacrumbs extends Component {
    render(){
        const { breadcrumbs } = this.props 

        return (
            <span className="breadcrumbs">
                <ul>
                    {breadcrumbs.map((breadcrumb, index) => {
                        return (
                            <li key={index}>
                                {
                                    index > 0 ?
                                    <em>
                                        &rsaquo;
                                    </em>
                                    : null
                                }
                                {
                                    breadcrumb.link ?
                                    <Link to={breadcrumb.link}>
                                        { breadcrumb.label }
                                    </Link>
                                    :
                                    <span>
                                        { breadcrumb.label }
                                    </span>
                                }
                            </li>
                        )
                    })}
                </ul>
            </span>

        )
    }
}

export default Breacrumbs