import React, { Component } from 'react'
import Button from 'components/Button/Button'
import { graphql, compose } from 'react-apollo'

class ModalDelete extends Component {
    render(){
        console.log(this.props)

        return(
            <div className="modal-delete">
                <div className="content-container">
                    <p>
                        { this.props.object }
                    </p>
                </div>
                <div className="button-row">
                    <Button label="Okay" level="high" callback={this.props.closeCallback} />
                </div>
            </div>
        )
    }
}

export default ModalDelete

  