import React, { Component } from 'react'

import RatesOverview from './RatesOverview'
import EventsOverview from './EventsOverview'
import TotalsOverview from './TotalsOverview'
import MostRecent from './MostRecent'

import Button from 'components/Button/Button'

import { 
  graphql, 
  compose, 
  withApollo 
} from 'react-apollo'

import { OPEN_MODAL } from 'graphql/modal-graphql'

import { DASHBOARD_DATA, INIT_DATA } from 'graphql/all'

import { removeSoftDeletedItems } from 'utils/utils'

class Dashboard extends Component {
  state = {
    isVisible: false,
    liabilities: [],
    companies: [],
    people: [],
    isLoading: true
  }

  componentWillMount() {
    this.props.client.query({
      query: DASHBOARD_DATA,
      fetchPolicy: 'network-only'
    }).then(data => {
      let dashboardData = removeSoftDeletedItems(data.data.dashboardData)

      const { user } = this.props.initData

      this.setState({
        isLoading: false,
        liabilities: dashboardData.liabilities,
        companies: user.companies,
        people: user.people
      })
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: true
      })
    }, 10)
  }

  render() {
    const { 
      dashboardData, 
      initData,
      data,
      openModal
    } = this.props

    let isLoading = false

    if(dashboardData.loading || initData.loading){
      isLoading = true
    }

    let people = []
    let companies = []
    let liabilities = []

    if(!dashboardData.loading){
      people = initData.user.people
      companies = initData.user.companies
      liabilities = removeSoftDeletedItems(dashboardData.dashboardData).liabilities
    }

    return (
      <div className="dashboard">
        <div className="heading-section">
          <h1>
            Welcome, {data.firstName}.
          </h1>
          <div className="controls">
              <Button label={`Add person`} callback={() => {
                openModal({variables: {
                  operation: 'add',
                  dataType: 'person',
                  isVisible: true,
                  object: null
                }})
              }} />
              <Button label={`Add company`} callback={() => {
                openModal({variables: {
                  operation: 'add',
                  dataType: 'company',
                  isVisible: true,
                  object: null
                }})
              }} />
          </div>
        </div>
        {
          this.state.isVisible ?
            <div>
              <div className="split">
                <EventsOverview 
                  isLoading={isLoading} 
                  companies={companies} 
                  people={people} 
                  liabilities={liabilities} 
                />
                <TotalsOverview 
                  isLoading={isLoading} 
                  companies={companies} 
                  people={people} 
                  liabilities={liabilities} 
                />
              </div>
              <div className="split">
                <RatesOverview />
                <MostRecent 
                  user={data}
                />
              </div>
            </div>
            : null
        }
      </div>
    )
  }
}

export default withApollo(compose(
  graphql(OPEN_MODAL, {name: 'openModal'}),
  graphql(INIT_DATA, {name: 'initData'}),
  graphql(DASHBOARD_DATA, {name: 'dashboardData'}),
) (Dashboard))