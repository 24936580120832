import gql from 'graphql-tag'

export const GET_LENDERS = gql`
  query getLenderData{
    lenders {
      id
      name
      slug
      deletedAt
      loans {
        id
        name
        deletedAt
        updatedAt
        interestRatesOOPI {
            id
            createdAt
            rate
        },
        interestRatesOOIO {
            id
            createdAt
            rate
        },
        interestRatesIVPI {
            id
            createdAt
            rate
        },
        interestRatesIVIO {
            id
            createdAt
            rate
        },
      }
    }
  }
`

export const UPSERT_LENDERS = gql`
    mutation UPSERT_LENDERS (
        $payload: [UpsertLenderPayload!]!
    ){
        upsertLenders(
            payload: $payload
        ){
            id
            deletedAt
            name
            loans {
                id
                deletedAt
                name
                interestRatesOOPI {
                    id
                    createdAt
                    rate
                },
                interestRatesOOIO {
                    id
                    createdAt
                    rate
                },
                interestRatesIVPI {
                    id
                    createdAt
                    rate
                },
                interestRatesIVIO {
                    id
                    createdAt
                    rate
                },
            }
        }
    }
`