import React, { Component } from 'react'
import TextInput from './TextInput'
import PlacesAutocomplete from 'custom-modules/react-places-autocomplete-custom'
import { clone } from 'lodash'
import { 
    formatAddress,
    getClientDisplayName
} from 'utils/utils'

//import { countryData } from 'utils/countries-states'

class AddressInput extends Component {
    state = {
        autocompleteInput: ''
    }

    handleChange = (value, key) => {
        if(key !== 'autocomplete'){
            let currentAddress = clone(this.props.value)

            currentAddress[key] = value

            this.props.changeCallback(currentAddress)

        }else{
            this.setState({
                autocompleteInput: value
            })
        }
    }

    componentWillMount = () => {
        if(this.props.value){
            this.setState({
                autocompleteInput: formatAddress(this.props.value, true) ? formatAddress(this.props.value, true) : ''
            })
        }
    }

    componentWillUpdate = nextProps => {
        if(this.props.value.address != nextProps.value.address){
            this.setState({
                autocompleteInput: nextProps.value.address
            })
        }
    }

    prefillFromParent = _ => {
        const address = this.props.canPrefillFrom.address

        if(address && formatAddress(address)){
            this.props.changeCallback(address)

            this.setState({
                autocompleteInput: formatAddress(address)
            })
        }
    }

    handleSelect = (selection, placeId, suggestion) => {
        let currentAddress = clone(this.props.value)
        let newState 

        if(suggestion.terms.length == 4){
            let firstTerm = suggestion.terms[0].value

            const streetNumber = firstTerm.substring(0, firstTerm.indexOf(" ") + 1)
            const streetName = firstTerm.substring(firstTerm.indexOf(" ") + 1, firstTerm.length)

            newState = {
                streetNumber,
                streetName,
                suburb: suggestion.terms[1].value,
                state: suggestion.terms[2].value,
                country: suggestion.terms[3].value,
                postcode: suggestion.postcode
            }
        }else{
            newState = {
                streetNumber: suggestion.terms[0].value,
                streetName: suggestion.terms[1].value,
                suburb: suggestion.terms[2].value,
                state: suggestion.terms[3].value,
                country: suggestion.terms[4].value,
                postcode: suggestion.postcode
            }
        }

        let newAddress = {
            ...currentAddress,
            ...newState
        }

        this.setState({
            autocompleteInput: selection
        })
        
        this.props.changeCallback(newAddress)
    }

    render() {
        const { 
            label, 
            error, 
            required, 
            value, 
            canOffPlan,
            canPrefillFrom
        } = this.props

        const searchOptions = {
            types: ['address'],
            componentRestrictions: {country: "au"}
        }

        let prefillName = ''

        if(canPrefillFrom){
            if(canPrefillFrom.__typename == 'Person'){
                prefillName = getClientDisplayName(canPrefillFrom)
            }else if(canPrefillFrom.__typename == 'Company'){
                prefillName = canPrefillFrom.name
            }
        }

        const offPlanInputs = [
            {
                key: 'streetNumber',
                placeholder: '#'
            },{
                key: 'streetName',
                placeholder: 'Street name'
            },{
                key: 'suburb',
                placeholder: 'Suburb'
            },{
                key: 'state',
                placeholder: 'State'
            },{
                key: 'country',
                placeholder: 'Country'
            },{
                key: 'postcode',
                placeholder: 'Postcode'
            }
        ]

        return (
            <div className={error ? "input-wrap error" : "input-wrap"}>
                <label>
                    {label ? label : '\u00A0'}
                    {required ?
                        <em className="required">
                            *
                        </em>
                        : null
                    }
                </label>
                {
                    canOffPlan ? 
                    <label className="label-check">
                        <input 
                            type="checkbox" 
                            checked={value.offPlan ? value.offPlan : false} 
                            onChange={() => {this.handleChange(value.offPlan ? false : true, 'offPlan')}} 
                        />
                        <span>
                            Off-plan address?
                        </span>
                    </label>
                    : null
                }
                <div className="address-container">
                    {
                        value.offPlan ? 
                            <div className="thirds">
                                <TextInput 
                                    value={value.level ? value.level : null} 
                                    placeholder={"Level"} 
                                    changeCallback={value => {
                                        this.handleChange(value, 'level')
                                    }} 
                                />
                                <TextInput 
                                    value={value.building ? value.building : null} 
                                    placeholder={"Building"} 
                                    changeCallback={value => {
                                        this.handleChange(value, 'building')
                                    }} 
                                />
                                <TextInput 
                                    value={value.unitNumber ? value.unitNumber : null} 
                                    placeholder={"Unit"} 
                                    changeCallback={value => {
                                        this.handleChange(value, 'unitNumber')
                                    }} 
                                />
                            </div>
                        : null
                    }
                    {
                        !value.offPlan ? 
                        <PlacesAutocomplete 
                            searchOptions={searchOptions} 
                            value={this.state.autocompleteInput} 
                            onChange={value => {this.handleChange(value, 'autocomplete')}} 
                            onSelect={this.handleSelect} 
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="autocomplete-container">
                                <div className="input-container address has-after">
                                    <input {...getInputProps({
                                        placeholder: 'Search for address...',
                                        className: 'location-search-input',
                                    })} />
                                </div>
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                        const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                                        return (
                                            <div {...getSuggestionItemProps(suggestion, {className, style,})}>
                                                <span>
                                                    {suggestion.description}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                        :
                        <div className="off-plan-inputs">
                            { offPlanInputs.map(input => {
                                return (
                                    <TextInput 
                                        key={input.key}
                                        value={value[input.key] ? value[input.key] : null} 
                                        placeholder={input.placeholder} 
                                        changeCallback={value => {
                                            this.handleChange(value, input.key)
                                        }} 
                                    />
                                )
                            })}
                        </div>
                    }
                    { canPrefillFrom &&
                        <a 
                            className="address-prefill-sub" 
                            href="javascript:void(0)" 
                            onClick={this.prefillFromParent}
                        >
                            Pre-fill from { prefillName }?
                        </a>
                    }
                </div>
            </div>
        )
    }
}

export default AddressInput