import React, { Component } from 'react'
import moment from 'moment'

import { map } from 'lodash'

class Countdown extends Component {
    dates = null

    render(){
        const { liability } = this.props

        if(!liability.loanTerm){
            return null
        }

        let aDate = moment(liability.startDate).add(liability.loanTerm, 'years')
        let bDate = moment()

        let years = aDate.diff(bDate, 'years')
        bDate.add(years, 'years')

        let months = aDate.diff(bDate, 'months')
        bDate.add(months, 'months')

        let days = aDate.diff(bDate, 'days')
        
        if(!isNaN(years) && !isNaN(months) && !isNaN(days)){
            this.dates = {
                years,
                months,
                days
            }
        }
        
        if(!this.dates){
            return null
        }
        
        return (
            <div>
                <h3>
                    Loan term remaining
                </h3>
                <ul className="countdown-container">
                    {map(this.dates, (date, index) => {
                        date = date.toString()

                        if(date.length < 2){
                            date = '0'+date
                        }

                        return (
                            <li key={index}>
                                <div>
                                    <div>
                                        <figure>
                                            <span>
                                                { date }
                                            </span>
                                        </figure>
                                        <figure>
                                            <span>
                                                { date }
                                            </span>
                                        </figure>
                                    </div>
                                </div>
                                <span>
                                    { index }
                                </span>
                            </li>
                        )
                    })
                        
                    }
                </ul>
            </div>
        )
    }
}

export default Countdown