import React, { Component } from 'react'

import Clients from './Clients'
import SingleClient from './SingleClient/SingleClient'
import { Switch, Route } from 'react-router-dom'

class ClientsSwitch extends Component{
    render(){
        return (
            <div>
                <Switch location={this.props.location}>
                    <Route path={"/clients/:type/:id"} render={(routeProps) => {
                        return (
                            <SingleClient {...routeProps} />
                        )
                    }} />
                    <Route path={"/clients"} render={() => {
                        return (
                            <Clients data={this.props.data} />
                        )
                    }} />
                </Switch>
            </div>
        )
    }
}

export default ClientsSwitch