import React, { Component } from 'react'
import Main from '../Main/Main'
import LinkList from '../LinkList'
import CreateLink from '../CreateLink'
import Auth from '../Auth/Auth'
import DoEmailAction from '../DoEmailAction/DoEmailAction'
import { Switch, Route } from 'react-router-dom'

import Modal from 'components/Modal/Modal'

class App extends Component {
  render() {
    return (
      <div className="app-container">
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/do-action" component={DoEmailAction} />
          <Route path="/" component={Main} />
        </Switch>
        <Modal />
      </div>
    );
  }
}

export default App;
