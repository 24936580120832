import React, { Component } from 'react'
import Button from 'components/Button/Button'
import { graphql, compose, withApollo } from 'react-apollo'

import { SET_ONBOARDED } from 'graphql/all'

class ModalOnboarding extends Component {
    state = {
        currentSlide: 0
    }

    dismiss = () => {
        this.props.closeCallback()
    }

    componentWillUnmount = () => {
        this.props.client.mutate({
            mutation: SET_ONBOARDED,
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.currentSlide !== prevState.currentSlide){
            this.videoRefs.forEach((ref, index) => {
                if(index !== this.state.currentSlide){
                    ref.pause()
                    ref.currentTime = 0
                }
            })

            this.videoRefs[this.state.currentSlide].play()
        }
    }

    componentDidMount = () => {
        this.videoRefs[0].play()
    }

    videoRefs = []

    render(){
        const slides = [
            {
                title: "Let’s get your team in here.",
                copy: "To get things started, let’s add your staff to Zamm, if you have any. They can do different things and we can configure that later on."
            },
            {
                title: "Now let’s select the lenders you write for.",
                copy: "Adding lenders will give you and your team the right loan products to select from when adding liabilities."
            },
            {
                title: "Let’s add your clients.",
                copy: "Clients can be either people or companies, so let’s get them created in Zamm."
            },
            {
                title: "It’s time to add your clients’ liabilities and assets",
                copy: "Now that your clients have been set up, you can add their liabilities and assets. With each liability, the important events associated with them will be captured automatically (for example, interest-only or fixed rate term expiry dates). "
            },
            {
                title: "Add anything else important.",
                copy: "The best way to service your customers is having all the information in one place. Capturing all of this in Zamm makes it effortless to know all your customers. Ongoing, Zamm takes care of the rest and lets you know what to action and when."
            },
        ]

        return(
            <div className="modal-onboard">
                <div className="content-container">
                    <ul className="onboarding-slider">
                        { slides.map((slide, index) => {
                            const isLastSlide = (index + 1) == slides.length

                            return (
                                <li className={this.state.currentSlide == index ? 'active' : null} key={index}>
                                    <video loop ref={ref => this.videoRefs[index] = ref} src={'/videos/onboard-'+index+'.mp4'}></video>
                                    <div className="copy">
                                        <h2>
                                            { slide.title }
                                        </h2>
                                        <p>
                                            { slide.copy }
                                        </p>
                                        <Button icon={!isLastSlide ? "arrow-right" : null} iconOnRight={true} label={isLastSlide ? "Close" : "Next tip"} level="high" callback={() => {
                                            if(!isLastSlide){
                                                this.setState({
                                                    currentSlide: (index + 1)
                                                })
                                            }else{
                                                this.dismiss()
                                            }
                                        }} />
                                    </div>
                                </li>
                            )
                        }) }
                    </ul>
                    <ul className="onboarding-dots">
                        { slides.map((slide, index) => {
                            return (
                                <li onClick={() => {
                                    this.setState({
                                        currentSlide: index
                                    })
                                }} className={index <= this.state.currentSlide ? 'active' : null} key={index} />
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withApollo(ModalOnboarding)

  