import React, { Component } from 'react'

import TextInput from 'components/Inputs/TextInput'
import TextareaInput from 'components/Inputs/TextareaInput'
import FileInput from 'components/Inputs/FileInput'
import SwitchInput from 'components/Inputs/SwitchInput'
import SelectInput from 'components/Inputs/SelectInput'
import RepeaterInput from 'components/Inputs/RepeaterInput'
import CheckboxInput from 'components/Inputs/CheckboxInput'
import AddressInput from 'components/Inputs/AddressInput'
import EventRepeatInput from 'components/Inputs/EventRepeatInput'
import RateDisplay from 'components/Inputs/RateDisplay'

import { isEqual } from 'lodash'
import DeepDiff from 'deep-diff'

export default class ModalFormRow extends Component {
    shouldComponentUpdate = nextProps => {
        if(isEqual(this.props, nextProps)){
            return false
        }

        return true
    }

    render(){
        const {
            className,
            index,
            focusedRow,
            focusRow,
            fieldArray,
            isFirst,
            isDisabled,
            handleChange,
            values,
            errors
        } = this.props

        return (
            <li 
                className={className} 
                key={index} 
                style={{ zIndex: focusedRow == index ? 9 : 1 }} 
                onFocus={() => {focusRow(index)}} 
                onClick={() => {focusRow(index)}}
            >
                {
                    fieldArray.map((field, index) => {
                        let Component = null

                        if(field.isHidden){
                            return null
                        }

                        let isFieldDisabled = isDisabled

                        if(!isDisabled && field.hideWhenEditing){
                            isFieldDisabled = true
                        }

                        const value = values[field.key]

                        if(field.hideIfEmpty && isFieldDisabled){
                            if(field.type == 'files'){
                                if(!field.props.keys.existingFiles.length){
                                    return null
                                }
                            }else if(!value || !value.length){
                                return null
                            }
                        }

                        switch(field.type){
                            case 'text':
                            case 'number':
                                Component = TextInput
                                break
                            case 'switch':
                                Component = SwitchInput
                                break
                            case 'select':
                                Component = SelectInput
                                break
                            case 'repeater':
                                Component = RepeaterInput
                                break
                            case 'address':
                                Component = AddressInput
                                break
                            case 'textarea':
                                Component = TextareaInput
                                break
                            case 'checkbox':
                                Component = CheckboxInput
                                break
                            case 'files':
                                Component = FileInput
                                break
                            case 'event-repeat':
                                Component = EventRepeatInput
                                break
                            case 'rate':
                                Component = RateDisplay
                                break
                        }

                        return (
                            <div key={index}>
                                {
                                    Component ? 
                                    <Component 
                                        isFirst={isFirst && !index}
                                        isDisabled={isFieldDisabled} 
                                        required={field.required ? true : false} 
                                        error={errors[field.key] ? errors[field.key] : null} 
                                        changeCallback={(value, key = null) => handleChange(key ? key : field.key, value)} 
                                        value={value} 
                                        { ...field.props } 
                                        label={field.label} 
                                        subLabel={field.subLabel} 
                                        autoFocus={field.autoFocus ? true : null} 
                                    />
                                    : null
                                }
                            </div>
                        )
                    })
                }
            </li>
        )
    }
}